var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Toast.tsx
import {
  createElement as createElement5,
  forwardRef as forwardRef5,
  useEffect,
  useRef
} from "react";
import {Button} from "@twilio-paste/button";
import {CloseIcon} from "@twilio-paste/icons/esm/CloseIcon";
import {ErrorIcon} from "@twilio-paste/icons/esm/ErrorIcon";
import {NeutralIcon} from "@twilio-paste/icons/esm/NeutralIcon";
import {SuccessIcon} from "@twilio-paste/icons/esm/SuccessIcon";
import {WarningIcon} from "@twilio-paste/icons/esm/WarningIcon";
import {MediaObject, MediaFigure, MediaBody} from "@twilio-paste/media-object";

// src/ErrorToast.tsx
import {
  createElement,
  forwardRef
} from "react";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";

// src/types.ts
import {
  arrayOf,
  bool,
  func,
  node,
  oneOf,
  shape,
  string
} from "prop-types";

// src/constants.ts
var ToastVariantObject = {
  ERROR: "error",
  NEUTRAL: "neutral",
  SUCCESS: "success",
  WARNING: "warning"
};

// src/types.ts
var ToastPropTypes = {
  children: node.isRequired,
  onDismiss: func,
  variant: oneOf(["error", "neutral", "success", "warning"])
};
var ToastPortalPropTypes = {
  children: node.isRequired,
  __console_patch: bool
};
var ToastContainerPropTypes = {
  children: node.isRequired,
  __console_patch: bool
};
var ToasterPropTypes = {
  toasts: arrayOf(shape({
    id: string.isRequired,
    message: node.isRequired,
    setFocus: bool,
    variant: oneOf(Object.values(ToastVariantObject)).isRequired
  })).isRequired,
  pop: func.isRequired,
  __console_patch: bool
};

// src/ErrorToast.tsx
var ErrorToast = forwardRef((props, ref) => {
  return /* @__PURE__ */ createElement(Box, __assign(__assign({}, safelySpreadBoxProps(props)), {
    ref,
    backgroundColor: "colorBackgroundBody",
    borderColor: "colorBorderLighter",
    borderRadius: "borderRadius20",
    borderStyle: "solid",
    borderWidth: "borderWidth10",
    borderLeftWidth: "borderWidth30",
    borderLeftColor: "colorBorderErrorLight",
    boxShadow: "shadowHigh",
    padding: "space60",
    width: "size40"
  }), props.children);
});
ErrorToast.displayName = "ErrorToast";
if (true) {
  ErrorToast.propTypes = ToastPropTypes;
}

// src/NeutralToast.tsx
import {
  createElement as createElement2,
  forwardRef as forwardRef2
} from "react";
import {Box as Box2, safelySpreadBoxProps as safelySpreadBoxProps2} from "@twilio-paste/box";
var NeutralToast = forwardRef2((props, ref) => {
  return /* @__PURE__ */ createElement2(Box2, __assign(__assign({}, safelySpreadBoxProps2(props)), {
    ref,
    backgroundColor: "colorBackgroundBody",
    borderColor: "colorBorderLighter",
    borderRadius: "borderRadius20",
    borderStyle: "solid",
    borderWidth: "borderWidth10",
    borderLeftWidth: "borderWidth30",
    borderLeftColor: "colorBorderNeutral",
    boxShadow: "shadowHigh",
    padding: "space60",
    width: "size40"
  }), props.children);
});
NeutralToast.displayName = "NeutralToast";
if (true) {
  NeutralToast.propTypes = ToastPropTypes;
}

// src/SuccessToast.tsx
import {
  createElement as createElement3,
  forwardRef as forwardRef3
} from "react";
import {Box as Box3, safelySpreadBoxProps as safelySpreadBoxProps3} from "@twilio-paste/box";
var SuccessToast = forwardRef3((props, ref) => {
  return /* @__PURE__ */ createElement3(Box3, __assign(__assign({}, safelySpreadBoxProps3(props)), {
    ref,
    backgroundColor: "colorBackgroundBody",
    borderColor: "colorBorderLighter",
    borderRadius: "borderRadius20",
    borderStyle: "solid",
    borderWidth: "borderWidth10",
    borderLeftWidth: "borderWidth30",
    borderLeftColor: "colorBorderSuccessLight",
    boxShadow: "shadowHigh",
    padding: "space60",
    width: "size40"
  }), props.children);
});
SuccessToast.displayName = "SuccessToast";
if (true) {
  SuccessToast.propTypes = ToastPropTypes;
}

// src/WarningToast.tsx
import {
  createElement as createElement4,
  forwardRef as forwardRef4
} from "react";
import {Box as Box4, safelySpreadBoxProps as safelySpreadBoxProps4} from "@twilio-paste/box";
var WarningToast = forwardRef4((props, ref) => {
  return /* @__PURE__ */ createElement4(Box4, __assign(__assign({}, safelySpreadBoxProps4(props)), {
    ref,
    backgroundColor: "colorBackgroundBody",
    borderColor: "colorBorderLighter",
    borderRadius: "borderRadius20",
    borderStyle: "solid",
    borderWidth: "borderWidth10",
    borderLeftWidth: "borderWidth30",
    borderLeftColor: "colorBorderWarningLight",
    boxShadow: "shadowHigh",
    padding: "space60",
    width: "size40"
  }), props.children);
});
WarningToast.displayName = "WarningToast";
if (true) {
  WarningToast.propTypes = ToastPropTypes;
}

// src/Toast.tsx
var ToastComponentVariants = {
  error: ErrorToast,
  neutral: NeutralToast,
  success: SuccessToast,
  warning: WarningToast
};
var renderToastIcon = (variant) => {
  switch (variant) {
    case ToastVariantObject.ERROR:
      return /* @__PURE__ */ createElement5(ErrorIcon, {
        color: "colorTextError",
        decorative: false,
        title: "error: ",
        size: "sizeIcon20"
      });
    case ToastVariantObject.SUCCESS:
      return /* @__PURE__ */ createElement5(SuccessIcon, {
        color: "colorTextSuccess",
        decorative: false,
        title: "success: ",
        size: "sizeIcon20"
      });
    case ToastVariantObject.WARNING:
      return /* @__PURE__ */ createElement5(WarningIcon, {
        color: "colorTextWarning",
        decorative: false,
        title: "warning: ",
        size: "sizeIcon20"
      });
    case ToastVariantObject.NEUTRAL:
    default:
      return /* @__PURE__ */ createElement5(NeutralIcon, {
        color: "colorTextNeutral",
        decorative: false,
        title: "information: ",
        size: "sizeIcon20"
      });
  }
};
var Toast = forwardRef5((_a, ref) => {
  var {children, onDismiss, variant = "neutral", setFocus} = _a, props = __rest(_a, ["children", "onDismiss", "variant", "setFocus"]);
  const ToastComponent = ToastComponentVariants[variant];
  const buttonRef = useRef(null);
  useEffect(() => {
    if (setFocus && buttonRef.current) {
      buttonRef.current.focus({preventScroll: true});
    }
  }, [setFocus]);
  return /* @__PURE__ */ createElement5(ToastComponent, __assign({
    role: "status",
    variant,
    ref
  }, props), /* @__PURE__ */ createElement5(MediaObject, {
    as: "div"
  }, /* @__PURE__ */ createElement5(MediaFigure, {
    as: "div",
    spacing: "space60"
  }, renderToastIcon(variant)), /* @__PURE__ */ createElement5(MediaBody, {
    as: "div"
  }, children), onDismiss && typeof onDismiss === "function" && /* @__PURE__ */ createElement5(MediaFigure, {
    align: "end",
    spacing: "space40"
  }, /* @__PURE__ */ createElement5(Button, {
    onClick: onDismiss,
    variant: "link",
    ref: buttonRef,
    size: "reset"
  }, /* @__PURE__ */ createElement5(CloseIcon, {
    color: "colorTextIcon",
    decorative: false,
    title: "dismiss this toast",
    size: "sizeIcon20"
  })))));
});
Toast.displayName = "Toast";
if (true) {
  Toast.propTypes = ToastPropTypes;
}

// src/ToastContainer.tsx
import {
  createElement as createElement7,
  forwardRef as forwardRef7
} from "react";
import {Stack} from "@twilio-paste/stack";

// src/ToastPortal.tsx
import {
  createElement as createElement6,
  forwardRef as forwardRef6
} from "react";
import {StyledBase} from "@twilio-paste/theme";
import {Portal} from "@twilio-paste/reakit-library";
import {Box as Box5, safelySpreadBoxProps as safelySpreadBoxProps5} from "@twilio-paste/box";
var ToastPortal = forwardRef6((_a, ref) => {
  var {children, __console_patch} = _a, props = __rest(_a, ["children", "__console_patch"]);
  return /* @__PURE__ */ createElement6(Portal, null, /* @__PURE__ */ createElement6(StyledBase, null, /* @__PURE__ */ createElement6(Box5, __assign(__assign({}, safelySpreadBoxProps5(props)), {
    position: "fixed",
    right: "space40",
    top: "space90",
    zIndex: "zIndex90",
    ref,
    style: __console_patch ? {zIndex: 1999} : void 0
  }), children)));
});
ToastPortal.displayName = "ToastPortal";
if (true) {
  ToastPortal.propTypes = ToastPortalPropTypes;
}

// src/ToastContainer.tsx
var ToastContainer = forwardRef7((_a, ref) => {
  var {children} = _a, props = __rest(_a, ["children"]);
  return /* @__PURE__ */ createElement7(ToastPortal, __assign({}, props), /* @__PURE__ */ createElement7(Stack, {
    orientation: "vertical",
    spacing: "space40",
    ref
  }, children));
});
ToastContainer.displayName = "ToastContainer";
if (true) {
  ToastContainer.propTypes = ToastContainerPropTypes;
}

// src/Toaster.tsx
import {
  createElement as createElement8,
  useCallback,
  useEffect as useEffect2,
  useState
} from "react";
import {useTransition, animated} from "@twilio-paste/animation-library";
import {useTheme} from "@twilio-paste/theme";
import {Box as Box6} from "@twilio-paste/box";
var AnimatedToast = animated(Box6);
var Toaster = (_a) => {
  var {toasts, pop} = _a, props = __rest(_a, ["toasts", "pop"]);
  const [refMap] = useState(() => new WeakMap());
  const [returnTarget, setReturnTarget] = useState({trigger: null});
  const theme = useTheme();
  const transitions = useTransition(toasts, {
    from: {height: 0, marginBottom: "0rem", opacity: 0, transform: "translateX(100%) scale(1)"},
    enter: (item) => async (next) => {
      await next({
        height: refMap.get(item).offsetHeight,
        marginBottom: theme.space.space40,
        opacity: 1,
        transform: "translateX(0px) scale(1)"
      });
    },
    leave: {height: 0, marginBottom: "0rem", opacity: 0, transform: "translateX(0px) scale(0.8)"},
    config: {
      mass: 1,
      tension: 150,
      friction: 20
    }
  });
  const clearReturnTarget = useCallback(() => {
    setReturnTarget({trigger: null});
  }, [returnTarget]);
  useEffect2(() => {
    if (returnTarget.trigger != null && toasts.length === 0) {
      returnTarget.trigger.focus();
      clearReturnTarget();
    }
  }, [toasts]);
  return /* @__PURE__ */ createElement8(ToastPortal, __assign({}, props), transitions((styles, item, state, index) => {
    return /* @__PURE__ */ createElement8(AnimatedToast, {
      as: "div",
      key: state.key,
      style: styles
    }, /* @__PURE__ */ createElement8(Toast, __assign(__assign({
      key: item.id,
      onDismiss: () => pop(item.id)
    }, item), {
      ref: (ref) => {
        if (ref != null) {
          refMap.set(item, ref);
        }
      },
      setFocus: index === 0,
      onFocus: (e) => {
        if (e.relatedTarget && !returnTarget.trigger) {
          setReturnTarget({trigger: e.relatedTarget});
        }
      }
    }), item.message));
  }));
};
Toaster.displayName = "Toaster";
if (true) {
  Toaster.propTypes = ToasterPropTypes;
}

// src/useToaster.ts
import {
  useState as useState2
} from "react";
import {uid} from "@twilio-paste/uid-library";
var useToaster = () => {
  const [toasts, setToasts] = useState2([]);
  const pop = (id) => {
    setToasts((currentToasts) => currentToasts.filter((toast) => {
      if (toast.id === id && toast.timeOutId) {
        window.clearTimeout(toast.timeOutId);
      }
      return toast.id !== id;
    }));
  };
  const push = (newToast) => {
    const generatedID = uid(newToast);
    let timeOutId;
    if (newToast.dismissAfter != null && Number.isInteger(newToast.dismissAfter)) {
      timeOutId = window.setTimeout(pop, newToast.dismissAfter, newToast.id || generatedID);
    }
    const existingToasts = toasts.map((toast) => {
      const tmpToast = toast;
      tmpToast.setFocus = false;
      return tmpToast;
    });
    setToasts([__assign({id: generatedID, timeOutId, setFocus: true}, newToast), ...existingToasts]);
  };
  return {toasts, push, pop};
};
export {
  AnimatedToast,
  Toast,
  ToastContainer,
  ToastContainerPropTypes,
  ToastPortalPropTypes,
  ToastPropTypes,
  Toaster,
  ToasterPropTypes,
  useToaster
};
