import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/ChangelogLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Change Log`}</h1>
    <h2>{`0.2.4`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0eded1fd63f081ba9aeab5b5946218e1c5b9b316"
        }}><inlineCode parentName="a">{`0eded1fd`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1319"
        }}>{`#1319`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/SiTaggart"
        }}>{`@SiTaggart`}</a>{`! - Change internal dependencies to have minor range matching on version numbers`}</li>
    </ul>
    <h2>{`0.2.3`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ac38757f0e426531862d5c562a2f2300cfa30592"
        }}><inlineCode parentName="a">{`ac38757f`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1228"
        }}>{`#1228`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/SiTaggart"
        }}>{`@SiTaggart`}</a>{`! - Bump status of the component to production`}</li>
    </ul>
    <h2>{`0.2.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
        }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1158"
        }}>{`#1158`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/richbachman"
        }}>{`@richbachman`}</a>{`! - Pinned all twilio-paste package versions in order to keep them in sync with core when they are updated by changesets.`}</li>
    </ul>
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox-primitive@0.2.0...@twilio-paste/combobox-primitive@0.2.1"
      }}>{`0.2.1`}</a>{` (2021-01-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox-primitive`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox-primitive@0.1.11...@twilio-paste/combobox-primitive@0.2.0"
      }}>{`0.2.0`}</a>{` (2021-01-21)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ESBuild for builds (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1084"
        }}>{`#1084`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/053646011508be10477d5b732269cdb0419235d7"
        }}>{`0536460`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox-primitive@0.1.10...@twilio-paste/combobox-primitive@0.1.11"
      }}>{`0.1.11`}</a>{` (2021-01-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox-primitive`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox-primitive@0.1.9...@twilio-paste/combobox-primitive@0.1.10"
      }}>{`0.1.10`}</a>{` (2020-12-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox-primitive`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox-primitive@0.1.8...@twilio-paste/combobox-primitive@0.1.9"
      }}>{`0.1.9`}</a>{` (2020-10-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox-primitive`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox-primitive@0.1.7...@twilio-paste/combobox-primitive@0.1.8"
      }}>{`0.1.8`}</a>{` (2020-10-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox-primitive`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox-primitive@0.1.6...@twilio-paste/combobox-primitive@0.1.7"
      }}>{`0.1.7`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox-primitive`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox-primitive@0.1.5...@twilio-paste/combobox-primitive@0.1.6"
      }}>{`0.1.6`}</a>{` (2020-09-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox-primitive`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox-primitive@0.1.4...@twilio-paste/combobox-primitive@0.1.5"
      }}>{`0.1.5`}</a>{` (2020-08-21)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox-primitive:`}</strong>{` export returned state types (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/13c2c9d93a586a7e4677b2f08a926bc9dbd6dec9"
        }}>{`13c2c9d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox-primitive@0.1.3...@twilio-paste/combobox-primitive@0.1.4"
      }}>{`0.1.4`}</a>{` (2020-07-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox-primitive`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox-primitive@0.1.2...@twilio-paste/combobox-primitive@0.1.3"
      }}>{`0.1.3`}</a>{` (2020-06-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox-primitive`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox-primitive@0.1.1...@twilio-paste/combobox-primitive@0.1.2"
      }}>{`0.1.2`}</a>{` (2020-06-01)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox-primitive:`}</strong>{` export UseComboboxProps (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a480f874eca87151e3078389fa57a1b4ba6e04af"
        }}>{`a480f87`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox-primitive@0.1.0...@twilio-paste/combobox-primitive@0.1.1"
      }}>{`0.1.1`}</a>{` (2020-05-22)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox-primitive:`}</strong>{` export combobox interface and props (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1fdf8ad4eff28765d66f5f017c736c4ca6520202"
        }}>{`1fdf8ad`}</a>{`)`}</li>
    </ul>
    <h1>{`0.1.0 (2020-05-13)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox-primitive:`}</strong>{` add combobox-primitive package (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/03c44d404274e4ed11d659d893b920c3e478f4f8"
        }}>{`03c44d4`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/primitive-combobox@0.0.1...@twilio-paste/primitive-combobox@0.0.2"
      }}>{`0.0.2`}</a>{` (2019-10-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/primitive-combobox`}</p>
    <h2>{`0.0.1 (2019-08-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/primitive-combobox`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      