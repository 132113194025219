var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Table.tsx
import {
  createElement,
  forwardRef
} from "react";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";

// src/TableContext.tsx
import {
  createContext
} from "react";
var TableContext = createContext({});

// src/types.ts
import {
  bool,
  node,
  oneOf
} from "prop-types";
import {isWidthTokenProp} from "@twilio-paste/style-props";

// src/constants.ts
var TableAlignmentObject = {
  LEFT: "left",
  CENTER: "center",
  RIGHT: "right"
};
var TableLayoutObject = {
  AUTO: "auto",
  FIXED: "fixed"
};
var TableVariantObject = {
  DEFAULT: "default",
  BORDERLESS: "borderless"
};
var TableVerticalAlignmentObject = {
  TOP: "top",
  MIDDLE: "middle",
  BOTTOM: "bottom"
};

// src/types.ts
var TablePropTypes = {
  children: node.isRequired,
  striped: bool,
  tableLayout: oneOf(Object.values(TableLayoutObject)),
  variant: oneOf(Object.values(TableVariantObject))
};
var THeadPropTypes = {
  children: node.isRequired
};
var TBodyPropTypes = {
  children: node.isRequired
};
var TrPropTypes = {
  children: node.isRequired,
  verticalAlign: oneOf(Object.values(TableVerticalAlignmentObject))
};
var ThPropTypes = {
  children: node,
  textAlign: oneOf(Object.values(TableAlignmentObject)),
  width: isWidthTokenProp
};
var TdPropTypes = {
  children: node,
  textAlign: oneOf(Object.values(TableAlignmentObject))
};
var TFootPropTypes = {
  children: node.isRequired
};

// src/Table.tsx
var Table = forwardRef((_a, ref) => {
  var {striped = false, tableLayout = "auto", variant = "default"} = _a, props = __rest(_a, ["striped", "tableLayout", "variant"]);
  const tableContext = {
    striped
  };
  return /* @__PURE__ */ createElement(TableContext.Provider, {
    value: tableContext
  }, /* @__PURE__ */ createElement(Box, __assign(__assign({}, safelySpreadBoxProps(props)), {
    ref,
    as: "table",
    borderCollapse: "collapse",
    borderColor: "colorBorderLighter",
    borderSpacing: "0",
    borderStyle: "solid",
    borderWidth: variant === "borderless" ? "borderWidth0" : "borderWidth10",
    tableLayout: tableLayout === "fixed" ? "fixed" : "auto",
    width: "100%"
  })));
});
Table.displayName = "Table";
if (true) {
  Table.propTypes = TablePropTypes;
}

// src/THead.tsx
import {
  createElement as createElement2,
  forwardRef as forwardRef2
} from "react";
import {Box as Box2, safelySpreadBoxProps as safelySpreadBoxProps2} from "@twilio-paste/box";
var THead = forwardRef2((props, ref) => {
  return /* @__PURE__ */ createElement2(Box2, __assign(__assign({}, safelySpreadBoxProps2(props)), {
    ref,
    as: "thead",
    backgroundColor: "colorBackground",
    borderBottomColor: "colorBorderLighter",
    borderBottomStyle: "solid",
    borderBottomWidth: "borderWidth20"
  }));
});
THead.displayName = "THead";
if (true) {
  THead.propTypes = THeadPropTypes;
}

// src/TBody.tsx
import {
  createElement as createElement3,
  forwardRef as forwardRef3
} from "react";
import {Box as Box3, safelySpreadBoxProps as safelySpreadBoxProps3} from "@twilio-paste/box";
var TBody = forwardRef3((props, ref) => {
  return /* @__PURE__ */ createElement3(Box3, __assign(__assign({}, safelySpreadBoxProps3(props)), {
    ref,
    as: "tbody",
    backgroundColor: "colorBackgroundBody"
  }));
});
TBody.displayName = "TBody";
if (true) {
  TBody.propTypes = TBodyPropTypes;
}

// src/TFoot.tsx
import {
  createElement as createElement4,
  forwardRef as forwardRef4
} from "react";
import {Box as Box4, safelySpreadBoxProps as safelySpreadBoxProps4} from "@twilio-paste/box";
var TFoot = forwardRef4((props, ref) => {
  return /* @__PURE__ */ createElement4(Box4, __assign(__assign({}, safelySpreadBoxProps4(props)), {
    ref,
    as: "tfoot",
    backgroundColor: "colorBackground",
    borderTopStyle: "solid",
    borderTopWidth: "borderWidth20",
    borderTopColor: "colorBorderLighter"
  }));
});
TFoot.displayName = "TFoot";
if (true) {
  TFoot.propTypes = TFootPropTypes;
}

// src/Tr.tsx
import {
  createElement as createElement5,
  forwardRef as forwardRef5,
  useContext
} from "react";
import {Box as Box5, safelySpreadBoxProps as safelySpreadBoxProps5} from "@twilio-paste/box";
var Tr = forwardRef5((_a, ref) => {
  var {verticalAlign = "middle"} = _a, props = __rest(_a, ["verticalAlign"]);
  const {striped} = useContext(TableContext);
  return /* @__PURE__ */ createElement5(Box5, __assign(__assign(__assign({}, striped), safelySpreadBoxProps5(props)), {
    ref,
    as: "tr",
    borderStyle: "solid",
    borderColor: "colorBorderLighter",
    borderWidth: "borderWidth0",
    borderBottomWidth: "borderWidth10",
    verticalAlign,
    _even: {backgroundColor: striped ? "colorBackgroundRowStriped" : "transparent"},
    _last: {borderWidth: "borderWidth0"}
  }));
});
Tr.displayName = "Tr";
if (true) {
  Tr.propTypes = TrPropTypes;
}

// src/Td.tsx
import {
  createElement as createElement6,
  forwardRef as forwardRef6
} from "react";
import {Box as Box6, safelySpreadBoxProps as safelySpreadBoxProps6} from "@twilio-paste/box";
var Td = forwardRef6((_a, ref) => {
  var {textAlign = "left"} = _a, props = __rest(_a, ["textAlign"]);
  return /* @__PURE__ */ createElement6(Box6, __assign(__assign({}, safelySpreadBoxProps6(props)), {
    ref,
    as: "td",
    fontSize: "fontSize30",
    lineHeight: "lineHeight30",
    paddingBottom: "space40",
    paddingLeft: "space50",
    paddingRight: "space50",
    paddingTop: "space40",
    position: "relative",
    textAlign,
    verticalAlign: "inherit",
    wordWrap: "break-word"
  }));
});
Td.displayName = "Td";
if (true) {
  Td.propTypes = TdPropTypes;
}

// src/Th.tsx
import {
  createElement as createElement7,
  forwardRef as forwardRef7
} from "react";
import {Box as Box7, safelySpreadBoxProps as safelySpreadBoxProps7} from "@twilio-paste/box";
var Th = forwardRef7((_a, ref) => {
  var {textAlign = "left", width} = _a, props = __rest(_a, ["textAlign", "width"]);
  return /* @__PURE__ */ createElement7(Box7, __assign(__assign({}, safelySpreadBoxProps7(props)), {
    ref,
    as: "th",
    fontSize: "fontSize30",
    lineHeight: "lineHeight30",
    fontWeight: "fontWeightSemibold",
    paddingBottom: "space40",
    paddingLeft: "space50",
    paddingRight: "space50",
    paddingTop: "space40",
    position: "relative",
    textAlign,
    verticalAlign: "inherit",
    width,
    wordWrap: "break-word"
  }));
});
Th.displayName = "Th";
if (true) {
  Th.propTypes = ThPropTypes;
}
export {
  TBody,
  TBodyPropTypes,
  TFoot,
  TFootPropTypes,
  THead,
  THeadPropTypes,
  Table,
  TablePropTypes,
  Td,
  TdPropTypes,
  Th,
  ThPropTypes,
  Tr,
  TrPropTypes
};
