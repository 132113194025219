var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Disclosure.tsx
import {
  createElement,
  forwardRef
} from "react";
import {useDisclosurePrimitiveState} from "@twilio-paste/disclosure-primitive";
import {Box} from "@twilio-paste/box";
import {Card} from "@twilio-paste/card";

// src/DisclosureContext.tsx
import {
  createContext
} from "react";
var DisclosureContext = createContext({});

// src/PropTypes.ts
import {
  node,
  oneOf
} from "prop-types";
var DisclosurePropTypes = {
  children: node.isRequired,
  variant: oneOf(["default", "contained"])
};
var DisclosureContentPropTypes = {
  children: node.isRequired
};

// src/Disclosure.tsx
var Disclosure = forwardRef((_a, ref) => {
  var {children, variant = "default", state} = _a, props = __rest(_a, ["children", "variant", "state"]);
  const disclosure = state || useDisclosurePrimitiveState(__assign({animated: true}, props));
  const disclosureContext = {
    disclosure,
    variant
  };
  if (variant === "contained") {
    return /* @__PURE__ */ createElement(DisclosureContext.Provider, {
      value: disclosureContext
    }, /* @__PURE__ */ createElement(Card, {
      padding: "space0",
      ref
    }, children));
  }
  return /* @__PURE__ */ createElement(DisclosureContext.Provider, {
    value: disclosureContext
  }, /* @__PURE__ */ createElement(Box, {
    ref
  }, children));
});
Disclosure.displayName = "Disclosure";
if (true) {
  Disclosure.propTypes = DisclosurePropTypes;
}

// src/DisclosureHeading.tsx
import {
  createElement as createElement2,
  forwardRef as forwardRef2,
  useContext,
  useRef
} from "react";
import {useTheme} from "@twilio-paste/theme";
import {Box as Box2, safelySpreadBoxProps} from "@twilio-paste/box";
import {Heading, HeadingPropTypes} from "@twilio-paste/heading";
import {ChevronDisclosureExpandedIcon} from "@twilio-paste/icons/esm/ChevronDisclosureExpandedIcon";
import {DisclosurePrimitive} from "@twilio-paste/disclosure-primitive";

// src/utils.ts
import {
  useEffect,
  useState
} from "react";
var getIconHoverStyles = (isHovered, variant, ariaExpanded, ariaDisabled, space) => {
  if (!ariaDisabled) {
    let slideDistance = space.space20;
    if (variant === "heading40" || variant === "heading50" || variant === "heading60") {
      slideDistance = space.space10;
    }
    return {
      color: isHovered ? "colorText" : "colorTextIcon",
      transform: `${isHovered ? `translateX(${slideDistance})` : `translateX(0)`} ${ariaExpanded ? "rotate(0deg)" : "rotate(-90deg)"}`,
      transition: "transform 200ms ease-out"
    };
  }
  return {
    color: "colorTextIcon"
  };
};
var useHover = (elementRef) => {
  const [value, setValue] = useState(false);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  useEffect(() => {
    const node2 = elementRef == null ? void 0 : elementRef.current;
    if (node2) {
      node2.addEventListener("mouseover", handleMouseOver);
      node2.addEventListener("mouseout", handleMouseOut);
      return () => {
        node2.removeEventListener("mouseover", handleMouseOver);
        node2.removeEventListener("mouseout", handleMouseOut);
      };
    }
  }, [elementRef]);
  return value;
};

// src/constants.ts
var IconSizeFromHeading = {
  heading10: "sizeIcon90",
  heading20: "sizeIcon70",
  heading30: "sizeIcon60",
  heading40: "sizeIcon40",
  heading50: "sizeIcon30",
  heading60: "sizeIcon20"
};

// src/DisclosureHeading.tsx
var StyledDisclosureHeading = forwardRef2((_a, ref) => {
  var {children, marginBottom, renderAs, disclosureVariant, customDisabled, customFocusable, variant} = _a, props = __rest(_a, ["children", "marginBottom", "renderAs", "disclosureVariant", "customDisabled", "customFocusable", "variant"]);
  const theme = useTheme();
  const hoverRef = useRef(null);
  const isHovered = useHover(hoverRef);
  let bottomBorderRadius = "borderRadius20";
  if (disclosureVariant === "contained") {
    bottomBorderRadius = "borderRadius10";
  }
  if (disclosureVariant === "contained" && props["aria-expanded"]) {
    bottomBorderRadius = "borderRadius0";
  }
  return /* @__PURE__ */ createElement2(Heading, {
    as: renderAs,
    marginBottom: "space0",
    variant,
    ref
  }, /* @__PURE__ */ createElement2(Box2, __assign(__assign({}, safelySpreadBoxProps(props)), {
    as: "div",
    backgroundColor: props["aria-expanded"] ? "colorBackground" : "colorBackgroundBody",
    borderRadius: disclosureVariant === "contained" ? "borderRadius10" : "borderRadius20",
    borderBottomLeftRadius: bottomBorderRadius,
    borderBottomRightRadius: bottomBorderRadius,
    cursor: "pointer",
    display: "flex",
    outline: "none",
    padding: "space30",
    position: "relative",
    ref: hoverRef,
    role: "button",
    transition: "background-color 100ms ease-out",
    _hover: {
      backgroundColor: "colorBackgroundDark"
    },
    _focus: {
      boxShadow: "shadowFocus"
    },
    _disabled: {
      backgroundColor: props["aria-expanded"] ? "colorBackground" : "colorBackgroundBody",
      color: "colorTextWeak",
      cursor: "not-allowed"
    }
  }), /* @__PURE__ */ createElement2(Box2, __assign({
    as: "span",
    display: "flex",
    height: IconSizeFromHeading[variant] || "sizeIcon20",
    width: IconSizeFromHeading[variant] || "sizeIcon20"
  }, getIconHoverStyles(isHovered, variant, props[`aria-expanded`], props[`aria-disabled`], theme.space)), /* @__PURE__ */ createElement2(ChevronDisclosureExpandedIcon, {
    color: "inherit",
    decorative: true,
    size: IconSizeFromHeading[variant] || "sizeIcon20"
  })), /* @__PURE__ */ createElement2(Box2, {
    display: "flex",
    flexGrow: 1,
    minWidth: "0px"
  }, children)));
});
var DisclosureHeading = (_a) => {
  var {children, as, disabled, focusable} = _a, props = __rest(_a, ["children", "as", "disabled", "focusable"]);
  const {disclosure, variant} = useContext(DisclosureContext);
  return /* @__PURE__ */ createElement2(DisclosurePrimitive, __assign(__assign(__assign({}, disclosure), props), {
    as: StyledDisclosureHeading,
    customDisabled: disabled,
    customFocusable: focusable,
    disabled,
    disclosureVariant: variant,
    focusable,
    renderAs: as
  }), children);
};
DisclosureHeading.displayName = "DisclosureHeading";
if (true) {
  DisclosureHeading.propTypes = HeadingPropTypes;
}

// src/DisclosureContent.tsx
import {
  createElement as createElement3,
  forwardRef as forwardRef3,
  useContext as useContext2
} from "react";
import {useSpring, animated} from "@twilio-paste/animation-library";
import {Box as Box3, safelySpreadBoxProps as safelySpreadBoxProps2} from "@twilio-paste/box";
import {DisclosurePrimitiveContent} from "@twilio-paste/disclosure-primitive";
var AnimatedDisclosureContent = animated(Box3);
var DisclosureContent = forwardRef3((_a, ref) => {
  var {children, visible} = _a, props = __rest(_a, ["children", "visible"]);
  const {disclosure} = useContext2(DisclosureContext);
  const {opacity} = useSpring({
    opacity: disclosure.visible ? 1 : 0,
    onRest: disclosure.stopAnimation,
    config: {
      mass: 1,
      tension: 150,
      friction: 20,
      duration: 100
    }
  });
  return /* @__PURE__ */ createElement3(DisclosurePrimitiveContent, __assign(__assign(__assign({}, disclosure), safelySpreadBoxProps2(props)), {
    as: AnimatedDisclosureContent,
    padding: "space50",
    ref,
    style: {
      opacity
    }
  }), children);
});
DisclosureContent.displayName = "DisclosureContent";
if (true) {
  DisclosureContent.propTypes = DisclosureContentPropTypes;
}
export {
  AnimatedDisclosureContent,
  Disclosure,
  DisclosureContent,
  DisclosureHeading,
  useDisclosurePrimitiveState as useDisclosureState
};
