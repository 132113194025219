var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/index.tsx
import {
  createElement,
  forwardRef
} from "react";
import {Text, safelySpreadTextProps} from "@twilio-paste/text";

// src/PropTypes.ts
import {
  oneOf
} from "prop-types";
var HeadingPropTypes = {
  as: oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "div", "label", "span"]).isRequired,
  marginBottom: oneOf(["space0"]),
  variant: oneOf([
    "heading10",
    "heading20",
    "heading30",
    "heading40",
    "heading50",
    "heading60"
  ]).isRequired
};

// src/index.tsx
function getHeadingProps(headingVariant, marginBottom) {
  switch (headingVariant) {
    case "heading10":
      return {
        marginBottom: marginBottom || "space70",
        fontSize: "fontSize90",
        fontWeight: "fontWeightBold",
        lineHeight: "lineHeight90"
      };
    case "heading30":
      return {
        marginBottom: marginBottom || "space50",
        fontSize: "fontSize60",
        fontWeight: "fontWeightBold",
        lineHeight: "lineHeight60"
      };
    case "heading40":
      return {
        marginBottom: marginBottom || "space40",
        fontSize: "fontSize40",
        fontWeight: "fontWeightBold",
        lineHeight: "lineHeight40"
      };
    case "heading50":
      return {
        marginBottom: marginBottom || "space30",
        fontSize: "fontSize30",
        fontWeight: "fontWeightBold",
        lineHeight: "lineHeight30"
      };
    case "heading60":
      return {
        marginBottom: marginBottom || "space30",
        fontSize: "fontSize20",
        fontWeight: "fontWeightBold",
        lineHeight: "lineHeight20"
      };
    case "heading20":
    default:
      return {
        marginBottom: marginBottom || "space60",
        fontSize: "fontSize70",
        fontWeight: "fontWeightBold",
        lineHeight: "lineHeight70"
      };
  }
}
var Heading = forwardRef((_a, ref) => {
  var {as, children, id, marginBottom, variant} = _a, props = __rest(_a, ["as", "children", "id", "marginBottom", "variant"]);
  return /* @__PURE__ */ createElement(Text, __assign(__assign(__assign({}, safelySpreadTextProps(props)), getHeadingProps(variant, marginBottom)), {
    as,
    display: "block",
    id,
    color: "colorText",
    ref
  }), children);
});
Heading.displayName = "Heading";
if (true) {
  Heading.propTypes = HeadingPropTypes;
}
export {
  Heading,
  HeadingPropTypes
};
