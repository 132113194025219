import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Stack } from '@twilio-paste/stack';
import { Avatar } from '@twilio-paste/avatar';
import Changelog from '@twilio-paste/avatar/CHANGELOG.md';
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { SidebarCategoryRoutes } from "../../../../src/constants";
import { UserIcon } from '@twilio-paste/icons/esm/UserIcon';
import * as React from 'react';
export default {
  graphql,
  Anchor,
  Box,
  Text,
  Stack,
  Avatar,
  Changelog,
  Callout,
  CalloutTitle,
  CalloutText,
  SidebarCategoryRoutes,
  UserIcon,
  React
};