var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/BaseRadioCheckbox.tsx
import {
  createElement,
  forwardRef
} from "react";
import {
  bool,
  node,
  string
} from "prop-types";
import {Box} from "@twilio-paste/box";
import {Text} from "@twilio-paste/text";
import {SiblingBox} from "@twilio-paste/sibling-box";
import {Label} from "@twilio-paste/label";
import {HelpText} from "@twilio-paste/help-text";
var BaseRadioCheckboxControl = forwardRef((_a, ref) => {
  var {children, disabled} = _a, props = __rest(_a, ["children", "disabled"]);
  return /* @__PURE__ */ createElement(SiblingBox, __assign({
    as: "span",
    ref,
    display: "flex",
    backgroundColor: "colorBackgroundBody",
    borderColor: "colorBorder",
    borderStyle: "solid",
    borderWidth: "borderWidth10",
    height: "sizeSquare50",
    marginX: "space20",
    marginY: "space10",
    width: "sizeSquare50",
    _hoverSibling: {
      borderColor: "colorBorderPrimaryDark"
    },
    _focusSibling: {
      borderColor: "colorBorderPrimaryDark",
      boxShadow: "shadowFocus"
    },
    _activeSibling: !disabled ? {
      borderColor: "colorBorderPrimaryDarker",
      backgroundColor: "colorBackgroundPrimaryDarker"
    } : void 0,
    _checkedSibling: {
      borderColor: "colorBorderPrimary",
      backgroundColor: "colorBackgroundPrimary"
    },
    _disabledSibling: {
      borderColor: "colorBorderLighter"
    },
    _invalidSibling: {
      borderColor: "colorBorderError"
    },
    _invalidAndHoverSibling: {
      borderColor: "colorBorderErrorDark"
    },
    _checkedAndHoverSibling: {
      borderColor: "colorBorderPrimaryDarker",
      backgroundColor: "colorBackgroundPrimaryDark"
    },
    _checkedAndFocusSibling: {
      borderColor: "colorBorderPrimaryDarker",
      backgroundColor: "colorBackgroundPrimaryDark"
    },
    _checkedAndActiveSibling: !disabled ? {
      borderColor: "colorBorderPrimaryDarker",
      backgroundColor: "colorBackgroundPrimaryDarker"
    } : void 0,
    _checkedAndDisabledSibling: {
      borderColor: "colorBorderLighter",
      backgroundColor: "colorBackgroundDark"
    },
    _checkedAndInvalidSibling: {
      borderColor: "colorBorderError",
      backgroundColor: "colorBackgroundError"
    },
    _checkedAndInvalidAndHoverSibling: {
      borderColor: "colorBorderErrorDark",
      backgroundColor: "colorBackgroundErrorDark"
    }
  }, props), children);
});
BaseRadioCheckboxControl.displayName = "BaseRadioCheckboxControl";
if (true) {
  BaseRadioCheckboxControl.propTypes = {
    children: node.isRequired,
    disabled: bool
  };
}
var BaseRadioCheckboxLabel = forwardRef((_a, ref) => {
  var {children} = _a, props = __rest(_a, ["children"]);
  return /* @__PURE__ */ createElement(Label, __assign(__assign({}, props), {
    marginBottom: "space0",
    ref
  }), /* @__PURE__ */ createElement(Box, {
    as: "span",
    display: "flex"
  }, children));
});
BaseRadioCheckboxLabel.displayName = "BaseRadioCheckboxLabel";
if (true) {
  BaseRadioCheckboxLabel.propTypes = {
    children: node.isRequired
  };
}
var BaseRadioCheckboxLabelText = forwardRef((_a, ref) => {
  var {children} = _a, props = __rest(_a, ["children"]);
  return /* @__PURE__ */ createElement(Text, __assign({
    as: "span",
    color: "currentColor",
    marginLeft: "space20",
    fontWeight: "fontWeightNormal",
    ref
  }, props), children);
});
BaseRadioCheckboxLabelText.displayName = "BaseRadioCheckboxLabelText";
if (true) {
  BaseRadioCheckboxLabelText.propTypes = {
    children: node.isRequired
  };
}
var BaseRadioCheckboxHelpText = forwardRef(({children, helpTextId}, ref) => {
  return /* @__PURE__ */ createElement(Box, {
    as: "span",
    display: "block",
    marginLeft: "space80",
    ref
  }, /* @__PURE__ */ createElement(HelpText, {
    id: helpTextId,
    marginTop: "space0"
  }, children));
});
BaseRadioCheckboxHelpText.displayName = "BaseRadioCheckboxHelpText";
if (true) {
  BaseRadioCheckboxHelpText.propTypes = {
    children: node.isRequired,
    helpTextId: string.isRequired
  };
}
export {
  BaseRadioCheckboxControl,
  BaseRadioCheckboxHelpText,
  BaseRadioCheckboxLabel,
  BaseRadioCheckboxLabelText
};
