import { graphql } from 'gatsby';
import { Alert } from '@twilio-paste/alert';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Heading } from '@twilio-paste/heading';
import { Text } from '@twilio-paste/text';
import { Truncate } from '@twilio-paste/truncate';
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { DoDont, Do, Dont } from "../../../../src/components/DoDont";
import Changelog from '@twilio-paste/truncate/CHANGELOG.md';
import { SidebarCategoryRoutes } from "../../../../src/constants";
import * as React from 'react';
export default {
  graphql,
  Alert,
  Anchor,
  Box,
  Heading,
  Text,
  Truncate,
  Callout,
  CalloutTitle,
  CalloutText,
  DoDont,
  Do,
  Dont,
  Changelog,
  SidebarCategoryRoutes,
  React
};