import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { SiteLink } from "../../../../src/components/SiteLink";
import { Box } from '@twilio-paste/box';
import { Paragraph } from '@twilio-paste/paragraph';
import { Table, THead, TBody, Td, Th, Tr } from '@twilio-paste/table';
import { Disclosure, DisclosureContent, DisclosureHeading } from '@twilio-paste/disclosure';
import { version as coreVersion, dependencies as coreDependencies } from '@twilio-paste/core/package.json';
import { version as iconsVersion } from '@twilio-paste/icons/package.json';
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { InlineCode } from "../../../../src/components/Typography";
import Package from '../../../../../../package.json';
import * as React from 'react';
export default {
  graphql,
  Anchor,
  SiteLink,
  Box,
  Paragraph,
  Table,
  THead,
  TBody,
  Td,
  Th,
  Tr,
  Disclosure,
  DisclosureContent,
  DisclosureHeading,
  coreVersion,
  coreDependencies,
  iconsVersion,
  Callout,
  CalloutTitle,
  CalloutText,
  InlineCode,
  Package,
  React
};