import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/ChangelogLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Change Log`}</h1>
    <h2>{`3.0.3`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0eded1fd63f081ba9aeab5b5946218e1c5b9b316"
        }}><inlineCode parentName="a">{`0eded1fd`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1319"
        }}>{`#1319`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/SiTaggart"
        }}>{`@SiTaggart`}</a>{`! - Change internal dependencies to have minor range matching on version numbers`}</li>
    </ul>
    <h2>{`3.0.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/514bd5aa9fed6581bbc4c1de649457bcc8e87b3c"
        }}><inlineCode parentName="a">{`514bd5aa`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.1"
            }}>{`theme@5.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.2"
            }}>{`box@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.1"
            }}>{`style-props@3.0.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`3.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/509eba7a95325dd6f8adc3e905e22f92b7f004a9"
        }}><inlineCode parentName="a">{`509eba7a`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.1"
            }}>{`box@4.0.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`3.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4c9ed5cac36ada218824d3e24bf45d4a03a12272"
        }}><inlineCode parentName="a">{`4c9ed5ca`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/26c828d8681e0e671f28b5f2856cd1803f13953f"
        }}><inlineCode parentName="a">{`26c828d8`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.6.0"
            }}>{`design-tokens@6.6.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.0"
            }}>{`theme@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.0"
            }}>{`box@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.0"
            }}>{`style-props@3.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`2.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/944c340790b932f4714b0e6075c5641ecdbee9d6"
        }}><inlineCode parentName="a">{`944c3407`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.5.2"
            }}>{`design-tokens@6.5.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@3.0.1"
            }}>{`box@3.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.1"
            }}>{`style-props@2.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.1"
            }}>{`theme@4.3.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`2.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f1675586933bcce71a6b5c5fec7d939735763a73"
        }}><inlineCode parentName="a">{`f1675586`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.0"
            }}>{`theme@4.3.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@3.0.0"
            }}>{`box@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.0"
            }}>{`style-props@2.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`1.5.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{` `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/pull/1158"
          }}>{`#1158`}</a>{` Thanks `}<a parentName="p" {...{
            "href": "https://github.com/richbachman"
          }}>{`@richbachman`}</a>{`! - Pinned all twilio-paste package versions in order to keep them in sync with core when they are updated by changesets.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Updated dependencies [`}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`]:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.2.2"
            }}>{`theme@4.2.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@1.9.2"
            }}>{`style-props@1.9.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@2.13.2"
            }}>{`box@2.13.2`}</a></li>
        </ul>
      </li>
    </ul>
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.5.0...@twilio-paste/card@1.5.1"
      }}>{`1.5.1`}</a>{` (2021-01-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.4.7...@twilio-paste/card@1.5.0"
      }}>{`1.5.0`}</a>{` (2021-01-21)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ESBuild for builds (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1084"
        }}>{`#1084`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/053646011508be10477d5b732269cdb0419235d7"
        }}>{`0536460`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.4.6...@twilio-paste/card@1.4.7"
      }}>{`1.4.7`}</a>{` (2021-01-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.4.5...@twilio-paste/card@1.4.6"
      }}>{`1.4.6`}</a>{` (2021-01-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.4.4...@twilio-paste/card@1.4.5"
      }}>{`1.4.5`}</a>{` (2020-12-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.4.3...@twilio-paste/card@1.4.4"
      }}>{`1.4.4`}</a>{` (2020-12-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.4.2...@twilio-paste/card@1.4.3"
      }}>{`1.4.3`}</a>{` (2020-12-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.4.1...@twilio-paste/card@1.4.2"
      }}>{`1.4.2`}</a>{` (2020-12-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.4.0...@twilio-paste/card@1.4.1"
      }}>{`1.4.1`}</a>{` (2020-12-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.76...@twilio-paste/card@1.4.0"
      }}>{`1.4.0`}</a>{` (2020-12-02)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`card:`}</strong>{` wrapping with forwardRefs (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/05996a5ab82623f4a69a84225dff57cee955c420"
        }}>{`05996a5`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.75...@twilio-paste/card@1.3.76"
      }}>{`1.3.76`}</a>{` (2020-11-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.74...@twilio-paste/card@1.3.75"
      }}>{`1.3.75`}</a>{` (2020-11-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.73...@twilio-paste/card@1.3.74"
      }}>{`1.3.74`}</a>{` (2020-11-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.72...@twilio-paste/card@1.3.73"
      }}>{`1.3.73`}</a>{` (2020-10-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.71...@twilio-paste/card@1.3.72"
      }}>{`1.3.72`}</a>{` (2020-10-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.70...@twilio-paste/card@1.3.71"
      }}>{`1.3.71`}</a>{` (2020-10-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.69...@twilio-paste/card@1.3.70"
      }}>{`1.3.70`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.68...@twilio-paste/card@1.3.69"
      }}>{`1.3.69`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.67...@twilio-paste/card@1.3.68"
      }}>{`1.3.68`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.66...@twilio-paste/card@1.3.67"
      }}>{`1.3.67`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.65...@twilio-paste/card@1.3.66"
      }}>{`1.3.66`}</a>{` (2020-09-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.64...@twilio-paste/card@1.3.65"
      }}>{`1.3.65`}</a>{` (2020-09-21)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.63...@twilio-paste/card@1.3.64"
      }}>{`1.3.64`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.62...@twilio-paste/card@1.3.63"
      }}>{`1.3.63`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.61...@twilio-paste/card@1.3.62"
      }}>{`1.3.62`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.60...@twilio-paste/card@1.3.61"
      }}>{`1.3.61`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.59...@twilio-paste/card@1.3.60"
      }}>{`1.3.60`}</a>{` (2020-09-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.58...@twilio-paste/card@1.3.59"
      }}>{`1.3.59`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.57...@twilio-paste/card@1.3.58"
      }}>{`1.3.58`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.56...@twilio-paste/card@1.3.57"
      }}>{`1.3.57`}</a>{` (2020-08-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.55...@twilio-paste/card@1.3.56"
      }}>{`1.3.56`}</a>{` (2020-08-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.54...@twilio-paste/card@1.3.55"
      }}>{`1.3.55`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.53...@twilio-paste/card@1.3.54"
      }}>{`1.3.54`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.52...@twilio-paste/card@1.3.53"
      }}>{`1.3.53`}</a>{` (2020-08-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.51...@twilio-paste/card@1.3.52"
      }}>{`1.3.52`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.50...@twilio-paste/card@1.3.51"
      }}>{`1.3.51`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.49...@twilio-paste/card@1.3.50"
      }}>{`1.3.50`}</a>{` (2020-07-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.48...@twilio-paste/card@1.3.49"
      }}>{`1.3.49`}</a>{` (2020-07-30)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.47...@twilio-paste/card@1.3.48"
      }}>{`1.3.48`}</a>{` (2020-07-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.46...@twilio-paste/card@1.3.47"
      }}>{`1.3.47`}</a>{` (2020-07-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.45...@twilio-paste/card@1.3.46"
      }}>{`1.3.46`}</a>{` (2020-07-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.44...@twilio-paste/card@1.3.45"
      }}>{`1.3.45`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.43...@twilio-paste/card@1.3.44"
      }}>{`1.3.44`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.42...@twilio-paste/card@1.3.43"
      }}>{`1.3.43`}</a>{` (2020-07-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.41...@twilio-paste/card@1.3.42"
      }}>{`1.3.42`}</a>{` (2020-06-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.40...@twilio-paste/card@1.3.41"
      }}>{`1.3.41`}</a>{` (2020-06-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.39...@twilio-paste/card@1.3.40"
      }}>{`1.3.40`}</a>{` (2020-06-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.38...@twilio-paste/card@1.3.39"
      }}>{`1.3.39`}</a>{` (2020-06-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.37...@twilio-paste/card@1.3.38"
      }}>{`1.3.38`}</a>{` (2020-06-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.36...@twilio-paste/card@1.3.37"
      }}>{`1.3.37`}</a>{` (2020-06-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.35...@twilio-paste/card@1.3.36"
      }}>{`1.3.36`}</a>{` (2020-06-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.34...@twilio-paste/card@1.3.35"
      }}>{`1.3.35`}</a>{` (2020-06-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.33...@twilio-paste/card@1.3.34"
      }}>{`1.3.34`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.32...@twilio-paste/card@1.3.33"
      }}>{`1.3.33`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.31...@twilio-paste/card@1.3.32"
      }}>{`1.3.32`}</a>{` (2020-05-27)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.30...@twilio-paste/card@1.3.31"
      }}>{`1.3.31`}</a>{` (2020-05-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.29...@twilio-paste/card@1.3.30"
      }}>{`1.3.30`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.28...@twilio-paste/card@1.3.29"
      }}>{`1.3.29`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.27...@twilio-paste/card@1.3.28"
      }}>{`1.3.28`}</a>{` (2020-05-04)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`card:`}</strong>{` switch to new spacing prop validators (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/9822a9985d51e90fad111b7a57f8dfc7e151df76"
        }}>{`9822a99`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.26...@twilio-paste/card@1.3.27"
      }}>{`1.3.27`}</a>{` (2020-05-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.25...@twilio-paste/card@1.3.26"
      }}>{`1.3.26`}</a>{` (2020-04-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.24...@twilio-paste/card@1.3.25"
      }}>{`1.3.25`}</a>{` (2020-04-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.23...@twilio-paste/card@1.3.24"
      }}>{`1.3.24`}</a>{` (2020-04-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.22...@twilio-paste/card@1.3.23"
      }}>{`1.3.23`}</a>{` (2020-04-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.21...@twilio-paste/card@1.3.22"
      }}>{`1.3.22`}</a>{` (2020-04-08)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`card:`}</strong>{` package dependencies updated to be correct (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/78c6b36eb30adae4192189c87f68a5892e6d3885"
        }}>{`78c6b36`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.20...@twilio-paste/card@1.3.21"
      }}>{`1.3.21`}</a>{` (2020-04-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.19...@twilio-paste/card@1.3.20"
      }}>{`1.3.20`}</a>{` (2020-04-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.18...@twilio-paste/card@1.3.19"
      }}>{`1.3.19`}</a>{` (2020-04-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.17...@twilio-paste/card@1.3.18"
      }}>{`1.3.18`}</a>{` (2020-03-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.16...@twilio-paste/card@1.3.17"
      }}>{`1.3.17`}</a>{` (2020-03-24)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`card:`}</strong>{` switch from theme-tokens to theme package dep (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/9529fc8dae29b9578232e8a01772e6a8dedea850"
        }}>{`9529fc8`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.15...@twilio-paste/card@1.3.16"
      }}>{`1.3.16`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.14...@twilio-paste/card@1.3.15"
      }}>{`1.3.15`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.13...@twilio-paste/card@1.3.14"
      }}>{`1.3.14`}</a>{` (2020-03-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.12...@twilio-paste/card@1.3.13"
      }}>{`1.3.13`}</a>{` (2020-03-06)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`add missing transitive peerDep on '@styled-system/css' (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/fdbb81370cda71037348742a680299ce481eabfd"
        }}>{`fdbb813`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.11...@twilio-paste/card@1.3.12"
      }}>{`1.3.12`}</a>{` (2020-03-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.10...@twilio-paste/card@1.3.11"
      }}>{`1.3.11`}</a>{` (2020-02-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.9...@twilio-paste/card@1.3.10"
      }}>{`1.3.10`}</a>{` (2020-02-26)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`package dependencies and deprecation warnings (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/334"
        }}>{`#334`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0e88338511e6835a79eb0a9cea8d5b3a1cdf0a88"
        }}>{`0e88338`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.8...@twilio-paste/card@1.3.9"
      }}>{`1.3.9`}</a>{` (2020-02-14)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`card:`}</strong>{` add missing peer deps (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2529927de49bc2550c14e99a5b1fa80960a98703"
        }}>{`2529927`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.7...@twilio-paste/card@1.3.8"
      }}>{`1.3.8`}</a>{` (2020-02-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.6...@twilio-paste/card@1.3.7"
      }}>{`1.3.7`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.5...@twilio-paste/card@1.3.6"
      }}>{`1.3.6`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.4...@twilio-paste/card@1.3.5"
      }}>{`1.3.5`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.3...@twilio-paste/card@1.3.4"
      }}>{`1.3.4`}</a>{` (2020-02-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.2...@twilio-paste/card@1.3.3"
      }}>{`1.3.3`}</a>{` (2020-02-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.1...@twilio-paste/card@1.3.2"
      }}>{`1.3.2`}</a>{` (2020-01-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.3.0...@twilio-paste/card@1.3.1"
      }}>{`1.3.1`}</a>{` (2020-01-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.2.0...@twilio-paste/card@1.3.0"
      }}>{`1.3.0`}</a>{` (2019-12-20)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`card:`}</strong>{` updated card design and documentation (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/230"
        }}>{`#230`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e0e2ca79569f2b2aca1b6ba1b39fc48358381291"
        }}>{`e0e2ca7`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.1.2...@twilio-paste/card@1.2.0"
      }}>{`1.2.0`}</a>{` (2019-12-19)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`card:`}</strong>{` use style-props package and use new safelySpreadProps method (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/132173fe6fae8673d5b36938d0f8f27e78bc029f"
        }}>{`132173f`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.1.1...@twilio-paste/card@1.1.2"
      }}>{`1.1.2`}</a>{` (2019-12-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.1.0...@twilio-paste/card@1.1.1"
      }}>{`1.1.1`}</a>{` (2019-11-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@1.0.0...@twilio-paste/card@1.1.0"
      }}>{`1.1.0`}</a>{` (2019-11-18)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`card:`}</strong>{` card component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/145"
        }}>{`#145`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/9049c5f8953b8ebee68596fedbcd58f6054ef166"
        }}>{`9049c5f`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@0.0.3...@twilio-paste/card@1.0.0"
      }}>{`1.0.0`}</a>{` (2019-11-11)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` delete old icons, add new streamline icons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/129"
        }}>{`#129`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/571791ded8ee4c55bb5a3dbcebee4b17b2c7c826"
        }}>{`571791d`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`icons:`}</strong>{` removed all the inherited icons since we're moving to a new system`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(icons): update icon list for storybook`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): use new icon`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(storybook): use new icon for story`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(story): button icons should be 24px`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix: adjust icon size tokens`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat: add iconSizes as separate key in theme-tokens`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat(icons): icons package to use tokens and update icons`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): update to use tokens and new icons`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(button): use correct spinner size`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(icons): major icons package fixes`}</p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`huge overhaul to build process`}</li>
      <li parentName="ul">{`now uses rollup`}</li>
      <li parentName="ul">{`builds into cjs and esm directories`}</li>
      <li parentName="ul">{`made the package publishable to npm`}</li>
      <li parentName="ul">{`moved svg folder out of src, now src only holds react stuff`}</li>
      <li parentName="ul">{`updated scripts to use new paths and cleaned up the code`}</li>
      <li parentName="ul">{`programmatically generates rollup config from the icon-list command`}</li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`chore: add new icons dist folders to gitignore`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix: spinner and button icon usage`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat(icons): add rollup icon list cache file`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(core-bundle): sort packages for consistent builds`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore: use `}<inlineCode parentName="p">{`esm`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`es`}</inlineCode>{` in rollup for correctness`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore: yarn.lock`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): lint error`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(icons): move rollup icon list`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(spinner): use types package in story`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): swap out destructured props for explicit props`}</p>
      </li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@0.0.2...@twilio-paste/card@0.0.3"
      }}>{`0.0.3`}</a>{` (2019-10-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/card@0.0.1...@twilio-paste/card@0.0.2"
      }}>{`0.0.2`}</a>{` (2019-09-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/card`}</p>
    <h2>{`0.0.1 (2019-08-15)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`change `}<a parentName="li" {...{
          "href": "https://github.com/paste"
        }}>{`@paste`}</a>{` scope to `}<a parentName="li" {...{
          "href": "https://github.com/twilio-paste"
        }}>{`@twilio-paste`}</a>{` (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/2"
        }}>{`#2`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1d8d2ff"
        }}>{`1d8d2ff`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      