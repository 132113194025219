import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import { SiteLink } from "../../../../src/components/SiteLink";
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Anchor } from '@twilio-paste/anchor';
import { Button } from '@twilio-paste/button';
import { PlusIcon } from '@twilio-paste/icons/esm/PlusIcon';
import { LoadingIcon } from '@twilio-paste/icons/esm/LoadingIcon';
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { DoDont, Do, Dont } from "../../../../src/components/DoDont";
import Changelog from '@twilio-paste/icons/CHANGELOG.md';
import * as React from 'react';
export default {
  graphql,
  Link,
  SiteLink,
  Box,
  Text,
  Anchor,
  Button,
  PlusIcon,
  LoadingIcon,
  Callout,
  CalloutTitle,
  CalloutText,
  DoDont,
  Do,
  Dont,
  Changelog,
  React
};