import { graphql } from 'gatsby';
import { Stack } from '@twilio-paste/stack';
import { Paragraph } from '@twilio-paste/paragraph';
import { UnorderedList, ListItem } from '@twilio-paste/list';
import { Anchor } from '@twilio-paste/anchor';
import { Disclosure, DisclosureHeading, DisclosureContent } from '@twilio-paste/disclosure';
import { InlineCode } from "../../../../src/components/Typography";
import * as React from 'react';
export default {
  graphql,
  Stack,
  Paragraph,
  UnorderedList,
  ListItem,
  Anchor,
  Disclosure,
  DisclosureHeading,
  DisclosureContent,
  InlineCode,
  React
};