import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Alert } from '@twilio-paste/alert';
import { Text } from '@twilio-paste/text';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { Anchor } from '@twilio-paste/anchor';
import { Card } from '@twilio-paste/card';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { DoDont, Do, Dont } from "../../../../src/components/DoDont";
import { Blockquote } from "../../../../src/components/Blockquote";
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { Codeblock } from "../../../../src/components/codeblock";
import { SidebarCategoryRoutes } from "../../../../src/constants";
import Changelog from '@twilio-paste/alert/CHANGELOG.md';
import * as React from 'react';
export default {
  graphql,
  Img,
  Alert,
  Text,
  Box,
  Button,
  Anchor,
  Card,
  Heading,
  Paragraph,
  DoDont,
  Do,
  Dont,
  Blockquote,
  Callout,
  CalloutTitle,
  CalloutText,
  Codeblock,
  SidebarCategoryRoutes,
  Changelog,
  React
};