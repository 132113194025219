import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/ChangelogLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Change Log`}</h1>
    <h2>{`5.2.0`}</h2>
    <h3>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/3f99ec87db3825247f6f805d6755640e0c9d4fab"
        }}><inlineCode parentName="a">{`3f99ec87`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1334"
        }}>{`#1334`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/serifluous"
        }}>{`@serifluous`}</a>{`! - Add DataBarChartIcon, DataLineChartIcon, DataPieChartIcon, DataTableIcon, HideIcon, ShowIcon. Fix viewbox sizing issue with CreditCardIcon. Minor glyph update to some product logo icons.`}</li>
    </ul>
    <h2>{`5.1.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0eded1fd63f081ba9aeab5b5946218e1c5b9b316"
        }}><inlineCode parentName="a">{`0eded1fd`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1319"
        }}>{`#1319`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/SiTaggart"
        }}>{`@SiTaggart`}</a>{`! - Change internal dependencies to have minor range matching on version numbers`}</li>
    </ul>
    <h2>{`5.1.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/514bd5aa9fed6581bbc4c1de649457bcc8e87b3c"
          }}><inlineCode parentName="a">{`514bd5aa`}</inlineCode></a>{` `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/pull/1299"
          }}>{`#1299`}</a>{` Thanks `}<a parentName="p" {...{
            "href": "https://github.com/SiTaggart"
          }}>{`@SiTaggart`}</a>{`! - Correctly type the size prop to use the icons sizes from style props and not from box`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Updated dependencies [`}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/514bd5aa9fed6581bbc4c1de649457bcc8e87b3c"
          }}><inlineCode parentName="a">{`514bd5aa`}</inlineCode></a>{`]:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.1"
            }}>{`theme@5.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.2"
            }}>{`box@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.1"
            }}>{`style-props@3.0.1`}</a></li>
        </ul>
      </li>
    </ul>
    <h2>{`5.1.0`}</h2>
    <h3>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/8b5a8592848abba3975717c33ed9aed93f376087"
        }}><inlineCode parentName="a">{`8b5a8592`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1267"
        }}>{`#1267`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/dazziola"
        }}>{`@dazziola`}</a>{`! - Adding a creditcard icon`}</li>
    </ul>
    <h2>{`5.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/509eba7a95325dd6f8adc3e905e22f92b7f004a9"
        }}><inlineCode parentName="a">{`509eba7a`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.1"
            }}>{`box@4.0.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`5.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4c9ed5cac36ada218824d3e24bf45d4a03a12272"
        }}><inlineCode parentName="a">{`4c9ed5ca`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/26c828d8681e0e671f28b5f2856cd1803f13953f"
        }}><inlineCode parentName="a">{`26c828d8`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.6.0"
            }}>{`design-tokens@6.6.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.0"
            }}>{`theme@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.0"
            }}>{`box@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.0"
            }}>{`style-props@3.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`4.0.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/af77939865e030fb356dbc4193c8324e6261627a"
          }}><inlineCode parentName="a">{`af779398`}</inlineCode></a>{` `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/pull/1224"
          }}>{`#1224`}</a>{` Thanks `}<a parentName="p" {...{
            "href": "https://github.com/richbachman"
          }}>{`@richbachman`}</a>{`! - added ProductInternetOfThingsEmbeddedSIMIcon, ProductInternetOfThingsNarrowbandIcon, ProductInternetOfThingsSuperSIMIcon, ProductInternetOfThingsTrustOnboardIcon, ProductInternetOfThingsWirelessIcon icons`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Updated dependencies [`}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/944c340790b932f4714b0e6075c5641ecdbee9d6"
          }}><inlineCode parentName="a">{`944c3407`}</inlineCode></a>{`]:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.5.2"
            }}>{`design-tokens@6.5.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@3.0.1"
            }}>{`box@3.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.1"
            }}>{`style-props@2.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.1"
            }}>{`theme@4.3.1`}</a></li>
        </ul>
      </li>
    </ul>
    <h2>{`4.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7340a6a552ddc45e35a786cbee4106998911458b"
        }}><inlineCode parentName="a">{`7340a6a5`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1180"
        }}>{`#1180`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/jeevan-sg"
        }}>{`@jeevan-sg`}</a>{`! - adds product icon for event streams app`}</li>
    </ul>
    <h2>{`4.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f1675586933bcce71a6b5c5fec7d939735763a73"
        }}><inlineCode parentName="a">{`f1675586`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.0"
            }}>{`theme@4.3.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@3.0.0"
            }}>{`box@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.0"
            }}>{`style-props@2.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`3.13.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{` `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/pull/1158"
          }}>{`#1158`}</a>{` Thanks `}<a parentName="p" {...{
            "href": "https://github.com/richbachman"
          }}>{`@richbachman`}</a>{`! - Pinned all twilio-paste package versions in order to keep them in sync with core when they are updated by changesets.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Updated dependencies [`}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`]:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.2.2"
            }}>{`theme@4.2.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@1.9.2"
            }}>{`style-props@1.9.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@2.13.2"
            }}>{`box@2.13.2`}</a></li>
        </ul>
      </li>
    </ul>
    <h2>{`3.13.0`}</h2>
    <h3>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4114dac24d6b89f10aeeaeda2220825b9e146169"
        }}><inlineCode parentName="a">{`4114dac2`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1119"
        }}>{`#1119`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/vnguyen94"
        }}>{`@vnguyen94`}</a>{`! - add ElasticSIPTrunkingCapable icon`}</li>
    </ul>
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.12.0...@twilio-paste/icons@3.12.1"
      }}>{`3.12.1`}</a>{` (2021-01-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.11.2...@twilio-paste/icons@3.12.0"
      }}>{`3.12.0`}</a>{` (2021-01-21)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ESBuild for builds (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1084"
        }}>{`#1084`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/053646011508be10477d5b732269cdb0419235d7"
        }}>{`0536460`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.11.1...@twilio-paste/icons@3.11.2"
      }}>{`3.11.2`}</a>{` (2021-01-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.11.0...@twilio-paste/icons@3.11.1"
      }}>{`3.11.1`}</a>{` (2021-01-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.10.6...@twilio-paste/icons@3.11.0"
      }}>{`3.11.0`}</a>{` (2021-01-13)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add agent, calendar, link (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1092"
        }}>{`#1092`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/c89365ea70fda722caf52780728467fc8e02ef0f"
        }}>{`c89365e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.10.5...@twilio-paste/icons@3.10.6"
      }}>{`3.10.6`}</a>{` (2021-01-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.10.4...@twilio-paste/icons@3.10.5"
      }}>{`3.10.5`}</a>{` (2020-12-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.10.3...@twilio-paste/icons@3.10.4"
      }}>{`3.10.4`}</a>{` (2020-12-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.10.2...@twilio-paste/icons@3.10.3"
      }}>{`3.10.3`}</a>{` (2020-12-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.10.1...@twilio-paste/icons@3.10.2"
      }}>{`3.10.2`}</a>{` (2020-12-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.10.0...@twilio-paste/icons@3.10.1"
      }}>{`3.10.1`}</a>{` (2020-12-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.9.1...@twilio-paste/icons@3.10.0"
      }}>{`3.10.0`}</a>{` (2020-12-03)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add EIP product icon (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/994"
        }}>{`#994`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1c429015223cab45da43ebeb1f5e942a65cb7425"
        }}>{`1c42901`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.9.0...@twilio-paste/icons@3.9.1"
      }}>{`3.9.1`}</a>{` (2020-12-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.8.4...@twilio-paste/icons@3.9.0"
      }}>{`3.9.0`}</a>{` (2020-11-16)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add Frontline product icon (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/908"
        }}>{`#908`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a9f5f065fe2f03cf0cb6bf7d3e54a6987f14fbfd"
        }}>{`a9f5f06`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.8.3...@twilio-paste/icons@3.8.4"
      }}>{`3.8.4`}</a>{` (2020-11-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.8.2...@twilio-paste/icons@3.8.3"
      }}>{`3.8.3`}</a>{` (2020-11-10)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` correct case of ProductTwiMLBins.svg (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/919"
        }}>{`#919`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ec1c168890d15dc24fdbe4b8f3cee3e7c066958f"
        }}>{`ec1c168`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.8.1...@twilio-paste/icons@3.8.2"
      }}>{`3.8.2`}</a>{` (2020-11-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.8.0...@twilio-paste/icons@3.8.1"
      }}>{`3.8.1`}</a>{` (2020-11-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.7.3...@twilio-paste/icons@3.8.0"
      }}>{`3.8.0`}</a>{` (2020-10-30)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add BuiltIn, Custom, Filter, User, and update Search (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/889"
        }}>{`#889`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7b4933a5155847196dc3723bc7ac4e3565206577"
        }}>{`7b4933a`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.7.2...@twilio-paste/icons@3.7.3"
      }}>{`3.7.3`}</a>{` (2020-10-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.7.1...@twilio-paste/icons@3.7.2"
      }}>{`3.7.2`}</a>{` (2020-10-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.7.0...@twilio-paste/icons@3.7.1"
      }}>{`3.7.1`}</a>{` (2020-10-21)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.6.2...@twilio-paste/icons@3.7.0"
      }}>{`3.7.0`}</a>{` (2020-10-19)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add and update icons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/526a8ad04ff48b930d9f07f05ae7c177cdc183a3"
        }}>{`526a8ad`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.6.1...@twilio-paste/icons@3.6.2"
      }}>{`3.6.2`}</a>{` (2020-10-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.6.0...@twilio-paste/icons@3.6.1"
      }}>{`3.6.1`}</a>{` (2020-10-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.5.8...@twilio-paste/icons@3.6.0"
      }}>{`3.6.0`}</a>{` (2020-10-09)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add TwiML Bins product icon (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/832"
        }}>{`#832`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/3b213099868be0d12435d00931be7523ea9f14ce"
        }}>{`3b21309`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.5.7...@twilio-paste/icons@3.5.8"
      }}>{`3.5.8`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.5.6...@twilio-paste/icons@3.5.7"
      }}>{`3.5.7`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.5.5...@twilio-paste/icons@3.5.6"
      }}>{`3.5.6`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.5.4...@twilio-paste/icons@3.5.5"
      }}>{`3.5.5`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.5.3...@twilio-paste/icons@3.5.4"
      }}>{`3.5.4`}</a>{` (2020-09-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.5.2...@twilio-paste/icons@3.5.3"
      }}>{`3.5.3`}</a>{` (2020-09-21)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.5.1...@twilio-paste/icons@3.5.2"
      }}>{`3.5.2`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.5.0...@twilio-paste/icons@3.5.1"
      }}>{`3.5.1`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.4.0...@twilio-paste/icons@3.5.0"
      }}>{`3.5.0`}</a>{` (2020-09-14)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add product admin, expand, shrink icons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/752"
        }}>{`#752`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ee9e32f88d28580c7386c5a38e90f283b95ddde4"
        }}>{`ee9e32f`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.3.0...@twilio-paste/icons@3.4.0"
      }}>{`3.4.0`}</a>{` (2020-09-10)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add asset and api icons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/744"
        }}>{`#744`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/bba48d01e25babf8f137a7ad708b97ce046ebea7"
        }}>{`bba48d0`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.2.3...@twilio-paste/icons@3.3.0"
      }}>{`3.3.0`}</a>{` (2020-09-09)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add connecteddecices icon and update close (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ba592e9162a991f80dea00adebfd8978ee8ec3e3"
        }}>{`ba592e9`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.2.2...@twilio-paste/icons@3.2.3"
      }}>{`3.2.3`}</a>{` (2020-09-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.2.1...@twilio-paste/icons@3.2.2"
      }}>{`3.2.2`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.2.0...@twilio-paste/icons@3.2.1"
      }}>{`3.2.1`}</a>{` (2020-09-08)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` reset line height on the icon wrapper (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/5b5fe8e3a2cb148cc8a7e7affeb734558d9a1bba"
        }}>{`5b5fe8e`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.1.2...@twilio-paste/icons@3.2.0"
      }}>{`3.2.0`}</a>{` (2020-09-03)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add a json list export (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/6ddb0105cb10841ddf4207504492c4dcd8fb65f8"
        }}>{`6ddb010`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.1.1...@twilio-paste/icons@3.1.2"
      }}>{`3.1.2`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.1.0...@twilio-paste/icons@3.1.1"
      }}>{`3.1.1`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.0.4...@twilio-paste/icons@3.1.0"
      }}>{`3.1.0`}</a>{` (2020-08-24)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` resized icons to 20px line heights (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/8ce184cfb7a0fd4488efbbbe2ea414bf0ef1c676"
        }}>{`8ce184c`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.0.3...@twilio-paste/icons@3.0.4"
      }}>{`3.0.4`}</a>{` (2020-08-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.0.2...@twilio-paste/icons@3.0.3"
      }}>{`3.0.3`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.0.1...@twilio-paste/icons@3.0.2"
      }}>{`3.0.2`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@3.0.0...@twilio-paste/icons@3.0.1"
      }}>{`3.0.1`}</a>{` (2020-08-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.6.4...@twilio-paste/icons@3.0.0"
      }}>{`3.0.0`}</a>{` (2020-08-05)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` use display=block by default and rename 'iconColor' prop to 'color' (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/637"
        }}>{`#637`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/82beef487c514b2eab5c40b1ef1409842dd4ca82"
        }}>{`82beef4`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`icons:`}</strong>{` This change renames a key prop and changes the display mode from inline-flex to block`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix: use new icon prop (iconColor -> color)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`test: update snapshots`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(icons): update readme to mention correct display value`}</p>
      </li>
    </ul>
    <p>{`Co-authored-by: Rich Bachman `}<a parentName="p" {...{
        "href": "mailto:rb@richbachman.com"
      }}>{`rb@richbachman.com`}</a></p>
    <ul>
      <li parentName="ul">{`chore(website): update icon doc prop table`}</li>
    </ul>
    <p>{`Co-authored-by: Rich Bachman `}<a parentName="p" {...{
        "href": "mailto:rb@richbachman.com"
      }}>{`rb@richbachman.com`}</a></p>
    <p>{`Co-authored-by: Rich Bachman `}<a parentName="p" {...{
        "href": "mailto:rb@richbachman.com"
      }}>{`rb@richbachman.com`}</a></p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.6.3...@twilio-paste/icons@2.6.4"
      }}>{`2.6.4`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.6.2...@twilio-paste/icons@2.6.3"
      }}>{`2.6.3`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.6.1...@twilio-paste/icons@2.6.2"
      }}>{`2.6.2`}</a>{` (2020-07-30)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.6.0...@twilio-paste/icons@2.6.1"
      }}>{`2.6.1`}</a>{` (2020-07-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.5.1...@twilio-paste/icons@2.6.0"
      }}>{`2.6.0`}</a>{` (2020-07-28)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add some icons and update old icons for UDL (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/c9d5010e445ad9bc4f142ba541a9b540698adc02"
        }}>{`c9d5010`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.5.0...@twilio-paste/icons@2.5.1"
      }}>{`2.5.1`}</a>{` (2020-07-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.4.3...@twilio-paste/icons@2.5.0"
      }}>{`2.5.0`}</a>{` (2020-07-20)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add player icons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/589"
        }}>{`#589`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4fb1a4b9a523a24edc0f497c0c634440fa0096a8"
        }}>{`4fb1a4b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.4.2...@twilio-paste/icons@2.4.3"
      }}>{`2.4.3`}</a>{` (2020-07-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.4.1...@twilio-paste/icons@2.4.2"
      }}>{`2.4.2`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.4.0...@twilio-paste/icons@2.4.1"
      }}>{`2.4.1`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.3.2...@twilio-paste/icons@2.4.0"
      }}>{`2.4.0`}</a>{` (2020-07-02)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add display prop (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4c9027d1a673b0d0f90c8df8856b322c7ae9e6f2"
        }}>{`4c9027d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.3.1...@twilio-paste/icons@2.3.2"
      }}>{`2.3.2`}</a>{` (2020-07-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.3.0...@twilio-paste/icons@2.3.1"
      }}>{`2.3.1`}</a>{` (2020-07-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.2.10...@twilio-paste/icons@2.3.0"
      }}>{`2.3.0`}</a>{` (2020-06-25)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add arrows, chevrons, attach; update linkexternal (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/531"
        }}>{`#531`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2e5ecd324be97fdf7732cb85ced38f03810d75e9"
        }}>{`2e5ecd3`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.2.9...@twilio-paste/icons@2.2.10"
      }}>{`2.2.10`}</a>{` (2020-06-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.2.8...@twilio-paste/icons@2.2.9"
      }}>{`2.2.9`}</a>{` (2020-06-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.2.7...@twilio-paste/icons@2.2.8"
      }}>{`2.2.8`}</a>{` (2020-06-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.2.6...@twilio-paste/icons@2.2.7"
      }}>{`2.2.7`}</a>{` (2020-06-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.2.5...@twilio-paste/icons@2.2.6"
      }}>{`2.2.6`}</a>{` (2020-06-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.2.4...@twilio-paste/icons@2.2.5"
      }}>{`2.2.5`}</a>{` (2020-06-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.2.3...@twilio-paste/icons@2.2.4"
      }}>{`2.2.4`}</a>{` (2020-06-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.2.2...@twilio-paste/icons@2.2.3"
      }}>{`2.2.3`}</a>{` (2020-06-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.2.1...@twilio-paste/icons@2.2.2"
      }}>{`2.2.2`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.2.0...@twilio-paste/icons@2.2.1"
      }}>{`2.2.1`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.1.7...@twilio-paste/icons@2.2.0"
      }}>{`2.2.0`}</a>{` (2020-05-28)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`add delete and download icons and SVGs (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/465"
        }}>{`#465`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/15a83759fdb8ae1838e3d798d195cc0a824be5ef"
        }}>{`15a8375`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.1.6...@twilio-paste/icons@2.1.7"
      }}>{`2.1.7`}</a>{` (2020-05-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.1.5...@twilio-paste/icons@2.1.6"
      }}>{`2.1.6`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.1.4...@twilio-paste/icons@2.1.5"
      }}>{`2.1.5`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.1.3...@twilio-paste/icons@2.1.4"
      }}>{`2.1.4`}</a>{` (2020-05-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.1.2...@twilio-paste/icons@2.1.3"
      }}>{`2.1.3`}</a>{` (2020-05-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.1.1...@twilio-paste/icons@2.1.2"
      }}>{`2.1.2`}</a>{` (2020-04-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.1.0...@twilio-paste/icons@2.1.1"
      }}>{`2.1.1`}</a>{` (2020-04-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.0.11...@twilio-paste/icons@2.1.0"
      }}>{`2.1.0`}</a>{` (2020-04-20)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update status icons and add success (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/656b9925afe1ff2b3ad3838cbbb91b2d86341f25"
        }}>{`656b992`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.0.10...@twilio-paste/icons@2.0.11"
      }}>{`2.0.11`}</a>{` (2020-04-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.0.9...@twilio-paste/icons@2.0.10"
      }}>{`2.0.10`}</a>{` (2020-04-08)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` package dependencies updated to be correct (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/5d2d2b8b7d150306d8ade7e1890bbe1ab12c04f1"
        }}>{`5d2d2b8`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.0.8...@twilio-paste/icons@2.0.9"
      }}>{`2.0.9`}</a>{` (2020-04-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.0.7...@twilio-paste/icons@2.0.8"
      }}>{`2.0.8`}</a>{` (2020-04-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.0.6...@twilio-paste/icons@2.0.7"
      }}>{`2.0.7`}</a>{` (2020-04-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.0.5...@twilio-paste/icons@2.0.6"
      }}>{`2.0.6`}</a>{` (2020-03-24)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` switch from theme-tokens to theme package dep (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/cfbb07f47b9075c169f11ad630a92dafbce854a7"
        }}>{`cfbb07f`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.0.4...@twilio-paste/icons@2.0.5"
      }}>{`2.0.5`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.0.3...@twilio-paste/icons@2.0.4"
      }}>{`2.0.4`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.0.2...@twilio-paste/icons@2.0.3"
      }}>{`2.0.3`}</a>{` (2020-03-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.0.1...@twilio-paste/icons@2.0.2"
      }}>{`2.0.2`}</a>{` (2020-03-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@2.0.0...@twilio-paste/icons@2.0.1"
      }}>{`2.0.1`}</a>{` (2020-03-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.7.2...@twilio-paste/icons@2.0.0"
      }}>{`2.0.0`}</a>{` (2020-03-02)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` change IconWrapper to inline-flex (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e02f3d53dd082fb3354abb957d93f5c861f83343"
        }}>{`e02f3d5`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` rename SVG file to remove 'Icon' suffix (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f0437d90983b474a231f4d9ec7e83fdd7d9d5233"
        }}>{`f0437d9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update default icon size (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7e3d2e01188746f80fd94e0cce2430ab6852689e"
        }}>{`7e3d2e0`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` update several icons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/94f5c703d9d8ed110d7f5e5c7288ce5698f82822"
        }}>{`94f5c70`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`storybook:`}</strong>{` incorrect import package (types -> style-props) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b199a1030749238f6965227524cd7178a3302d31"
        }}>{`b199a10`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` Renamed some SVG files to remove icon suffix`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.7.1...@twilio-paste/icons@1.7.2"
      }}>{`1.7.2`}</a>{` (2020-02-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.7.0...@twilio-paste/icons@1.7.1"
      }}>{`1.7.1`}</a>{` (2020-02-26)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`package dependencies and deprecation warnings (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/334"
        }}>{`#334`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0e88338511e6835a79eb0a9cea8d5b3a1cdf0a88"
        }}>{`0e88338`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.6.0...@twilio-paste/icons@1.7.0"
      }}>{`1.7.0`}</a>{` (2020-02-21)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add UploadToCloudIcon (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/323"
        }}>{`#323`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/01b824d649f5a0baaf7fc7faffa953c0feb8522b"
        }}>{`01b824d`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.5.0...@twilio-paste/icons@1.6.0"
      }}>{`1.6.0`}</a>{` (2020-02-20)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add ChevronDown icon (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ebb83606c82427be2336a77ad560f4f4cf8ab999"
        }}>{`ebb8360`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add ChevronUp icon (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/5070b68bc9de04c7de2495dee872df2531011530"
        }}>{`5070b68`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add LinkExternal icon (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/fa14ca30477b1ec3b195362eb27766164c423899"
        }}>{`fa14ca3`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.4.6...@twilio-paste/icons@1.5.0"
      }}>{`1.5.0`}</a>{` (2020-02-19)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add checkmark circle icon (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/317"
        }}>{`#317`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/af76993403ad2f12e949f73953892db320fbd218"
        }}>{`af76993`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.4.5...@twilio-paste/icons@1.4.6"
      }}>{`1.4.6`}</a>{` (2020-02-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.4.4...@twilio-paste/icons@1.4.5"
      }}>{`1.4.5`}</a>{` (2020-02-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.4.3...@twilio-paste/icons@1.4.4"
      }}>{`1.4.4`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.4.2...@twilio-paste/icons@1.4.3"
      }}>{`1.4.3`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.4.1...@twilio-paste/icons@1.4.2"
      }}>{`1.4.2`}</a>{` (2020-02-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.4.0...@twilio-paste/icons@1.4.1"
      }}>{`1.4.1`}</a>{` (2020-01-27)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` adjust the twilio org product icon so it builds (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/281"
        }}>{`#281`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/8a4d04c522805fa636164a93c2f364a51e5fad9a"
        }}>{`8a4d04c`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.3.1...@twilio-paste/icons@1.4.0"
      }}>{`1.4.0`}</a>{` (2020-01-25)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add the twilio org product icon (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/270"
        }}>{`#270`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f07f64166f9d03ad46ac63ef1010cec55b2fbc66"
        }}>{`f07f641`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.3.0...@twilio-paste/icons@1.3.1"
      }}>{`1.3.1`}</a>{` (2020-01-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.2.1...@twilio-paste/icons@1.3.0"
      }}>{`1.3.0`}</a>{` (2020-01-17)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add MoreIcon (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/260"
        }}>{`#260`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/8983c35b9ee491a8f3f5244436d54da9a67928a8"
        }}>{`8983c35`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.2.0...@twilio-paste/icons@1.2.1"
      }}>{`1.2.1`}</a>{` (2019-12-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.1.1...@twilio-paste/icons@1.2.0"
      }}>{`1.2.0`}</a>{` (2019-12-19)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` add CopyIcon (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/235"
        }}>{`#235`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d51d0d266514d0a2122f50d0fdeb29b62f7501a0"
        }}>{`d51d0d2`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` adding close, error, information, warning (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/239"
        }}>{`#239`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f9b632b55d44bf3df07755a2ff4f57646365abcc"
        }}>{`f9b632b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.1.0...@twilio-paste/icons@1.1.1"
      }}>{`1.1.1`}</a>{` (2019-12-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/icons`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.0.2...@twilio-paste/icons@1.1.0"
      }}>{`1.1.0`}</a>{` (2019-11-20)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` make decorative required + icon docs (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/165"
        }}>{`#165`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/15ccbc2e0f259d60af83bf8503c8ee18a1191cb8"
        }}>{`15ccbc2`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.0.1...@twilio-paste/icons@1.0.2"
      }}>{`1.0.2`}</a>{` (2019-11-18)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` remove vague anchor do dont (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/177"
        }}>{`#177`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/8530c5679b48da46ddc82a9a7154bca78cacc30a"
        }}>{`8530c56`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@1.0.0...@twilio-paste/icons@1.0.1"
      }}>{`1.0.1`}</a>{` (2019-11-12)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`prettier updated, updating formatting (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/86448312b017f13720735525e516f086704b1813"
        }}>{`8644831`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@0.4.0...@twilio-paste/icons@1.0.0"
      }}>{`1.0.0`}</a>{` (2019-11-11)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` delete old icons, add new streamline icons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/129"
        }}>{`#129`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/571791ded8ee4c55bb5a3dbcebee4b17b2c7c826"
        }}>{`571791d`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`icons:`}</strong>{` removed all the inherited icons since we're moving to a new system`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(icons): update icon list for storybook`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): use new icon`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(storybook): use new icon for story`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(story): button icons should be 24px`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix: adjust icon size tokens`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat: add iconSizes as separate key in theme-tokens`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat(icons): icons package to use tokens and update icons`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): update to use tokens and new icons`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(button): use correct spinner size`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(icons): major icons package fixes`}</p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`huge overhaul to build process`}</li>
      <li parentName="ul">{`now uses rollup`}</li>
      <li parentName="ul">{`builds into cjs and esm directories`}</li>
      <li parentName="ul">{`made the package publishable to npm`}</li>
      <li parentName="ul">{`moved svg folder out of src, now src only holds react stuff`}</li>
      <li parentName="ul">{`updated scripts to use new paths and cleaned up the code`}</li>
      <li parentName="ul">{`programmatically generates rollup config from the icon-list command`}</li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`chore: add new icons dist folders to gitignore`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix: spinner and button icon usage`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat(icons): add rollup icon list cache file`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(core-bundle): sort packages for consistent builds`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore: use `}<inlineCode parentName="p">{`esm`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`es`}</inlineCode>{` in rollup for correctness`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore: yarn.lock`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): lint error`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(icons): move rollup icon list`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(spinner): use types package in story`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): swap out destructured props for explicit props`}</p>
      </li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@0.3.1...@twilio-paste/icons@0.4.0"
      }}>{`0.4.0`}</a>{` (2019-10-29)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`core:`}</strong>{` a core bundle (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/138"
        }}>{`#138`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f6ab2eb924c29235d6889fb55960c788b96fdf01"
        }}>{`f6ab2eb`}</a>{`)`}</li>
      <li parentName="ul">{`finalise the Text component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/151"
        }}>{`#151`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b51c272cf420380f73c31c3474ebdf23cb7cad3a"
        }}>{`b51c272`}</a>{`)`}</li>
      <li parentName="ul">{`upgrading to styled-system v5 (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/18"
        }}>{`#18`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2b3ebd8b2f701a0c6e8b75ab6978ba936814f455"
        }}>{`2b3ebd8`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/icons@0.3.0...@twilio-paste/icons@0.3.1"
      }}>{`0.3.1`}</a>{` (2019-09-16)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` adding some polish (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/67"
        }}>{`#67`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2644657"
        }}>{`2644657`}</a>{`)`}</li>
    </ul>
    <h1>{`0.3.0 (2019-08-15)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`change `}<a parentName="li" {...{
          "href": "https://github.com/paste"
        }}>{`@paste`}</a>{` scope to `}<a parentName="li" {...{
          "href": "https://github.com/twilio-paste"
        }}>{`@twilio-paste`}</a>{` (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/2"
        }}>{`#2`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1d8d2ff"
        }}>{`1d8d2ff`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ComponentHeader component, component overview fixes, more (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/20"
        }}>{`#20`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/875e124"
        }}>{`875e124`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      