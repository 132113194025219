import { graphql } from 'gatsby';
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { Box } from '@twilio-paste/box';
import { Paragraph } from '@twilio-paste/paragraph';
import { Stack } from '@twilio-paste/stack';
import { Flex } from '@twilio-paste/flex';
import { Anchor } from '@twilio-paste/anchor';
import { Grid } from '@twilio-paste/grid';
import { Card } from '@twilio-paste/card';
import { LinkExternalIcon } from '@twilio-paste/icons/esm/LinkExternalIcon';
import { Link } from 'gatsby';
import * as React from 'react';
export default {
  graphql,
  Callout,
  CalloutTitle,
  CalloutText,
  Box,
  Paragraph,
  Stack,
  Flex,
  Anchor,
  Grid,
  Card,
  LinkExternalIcon,
  Link,
  React
};