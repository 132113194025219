var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Checkbox.tsx
import {
  createElement,
  forwardRef,
  useContext
} from "react";
import {
  bool,
  func,
  node,
  oneOfType,
  string
} from "prop-types";
import {useUID} from "@twilio-paste/uid-library";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
import {CheckboxCheckIcon} from "@twilio-paste/icons/esm/CheckboxCheckIcon";
import {MinusIcon} from "@twilio-paste/icons/esm/MinusIcon";
import {
  BaseRadioCheckboxControl,
  BaseRadioCheckboxLabel,
  BaseRadioCheckboxLabelText,
  BaseRadioCheckboxHelpText
} from "@twilio-paste/base-radio-checkbox";
import {MediaObject, MediaFigure, MediaBody} from "@twilio-paste/media-object";
import {RequiredDot} from "@twilio-paste/label";

// src/CheckboxContext.tsx
import {
  createContext
} from "react";
var CheckboxContext = createContext({
  disabled: false,
  name: void 0,
  hasError: void 0,
  onChange: () => {
  }
});

// src/Checkbox.tsx
var HiddenCheckbox = forwardRef((props, ref) => /* @__PURE__ */ createElement(Box, __assign(__assign({}, props), {
  ref,
  as: "input",
  type: "checkbox",
  size: "size0",
  border: "none",
  overflow: "hidden",
  padding: "space0",
  margin: "space0",
  whiteSpace: "nowrap",
  textTransform: "none",
  position: "absolute",
  clip: "rect(0 0 0 0)"
})));
var CheckboxIcon = ({checked, disabled, indeterminate}) => {
  const color = disabled && (checked || indeterminate) ? "colorTextInverseWeaker" : "colorTextInverse";
  if (indeterminate) {
    return /* @__PURE__ */ createElement(MinusIcon, {
      decorative: true,
      color,
      size: "sizeIcon10"
    });
  }
  return /* @__PURE__ */ createElement(CheckboxCheckIcon, {
    hidden: !checked,
    decorative: true,
    color,
    size: "sizeIcon10"
  });
};
var Checkbox = forwardRef((_a, ref) => {
  var {checked, children, helpText, id, indeterminate, isSelectAll, isSelectAllChild, required} = _a, props = __rest(_a, ["checked", "children", "helpText", "id", "indeterminate", "isSelectAll", "isSelectAllChild", "required"]);
  const helpTextId = useUID();
  const checkboxGroupContext = useContext(CheckboxContext);
  const disabled = props.disabled != null ? props.disabled : checkboxGroupContext.disabled;
  const name = props.name != null ? props.name : checkboxGroupContext.name;
  const hasError = props.hasError != null ? props.hasError : checkboxGroupContext.hasError;
  const handleChange = props.onChange != null ? props.onChange : checkboxGroupContext.onChange;
  let paddingLeft = null;
  let checkboxBackground = null;
  if (isSelectAll) {
    paddingLeft = "space20";
    if (checked || indeterminate) {
      checkboxBackground = "colorBackgroundPrimaryLightest";
    } else if (!disabled) {
      checkboxBackground = "colorBackground";
    }
  }
  if (isSelectAllChild) {
    paddingLeft = "space30";
  }
  return /* @__PURE__ */ createElement(Box, {
    backgroundColor: checkboxBackground,
    borderRadius: isSelectAll ? "borderRadius10" : null,
    display: "inline-flex",
    flexDirection: "column",
    padding: isSelectAll ? "space30" : null,
    paddingBottom: isSelectAll ? "space20" : null,
    paddingLeft,
    paddingRight: isSelectAllChild ? "space30" : null,
    paddingTop: isSelectAll ? "space20" : null
  }, /* @__PURE__ */ createElement(HiddenCheckbox, __assign(__assign({}, safelySpreadBoxProps(props)), {
    checked,
    disabled,
    name,
    onChange: handleChange,
    "aria-describedby": helpTextId,
    "aria-checked": indeterminate ? "mixed" : checked,
    "aria-invalid": hasError,
    id,
    required,
    ref
  })), /* @__PURE__ */ createElement(BaseRadioCheckboxLabel, {
    disabled,
    htmlFor: id
  }, /* @__PURE__ */ createElement(BaseRadioCheckboxControl, {
    alignItems: "center",
    borderRadius: "borderRadius10",
    disabled,
    position: "relative",
    type: "checkbox"
  }, /* @__PURE__ */ createElement(CheckboxIcon, {
    checked,
    disabled,
    indeterminate
  })), /* @__PURE__ */ createElement(BaseRadioCheckboxLabelText, {
    fontWeight: isSelectAll ? null : "fontWeightNormal"
  }, /* @__PURE__ */ createElement(MediaObject, {
    verticalAlign: "top"
  }, required && /* @__PURE__ */ createElement(MediaFigure, {
    spacing: "space20"
  }, /* @__PURE__ */ createElement(RequiredDot, null)), /* @__PURE__ */ createElement(MediaBody, null, children)))), helpText && /* @__PURE__ */ createElement(BaseRadioCheckboxHelpText, {
    helpTextId
  }, helpText));
});
Checkbox.displayName = "Checkbox";
if (true) {
  Checkbox.propTypes = {
    children: node.isRequired,
    disabled: bool,
    name: string,
    onChange: func,
    hasError: bool,
    helpText: oneOfType([string, node]),
    id: string,
    indeterminate: bool,
    isSelectAll: bool,
    isSelectAllChild: bool
  };
}

// src/CheckboxGroup.tsx
import {
  Children,
  cloneElement,
  createElement as createElement2,
  forwardRef as forwardRef2,
  isValidElement,
  useCallback,
  useMemo
} from "react";
import {
  bool as bool2,
  func as func2,
  oneOf,
  string as string2
} from "prop-types";
import {InlineControlGroup} from "@twilio-paste/inline-control-group";
var CheckboxGroup = forwardRef2((_a, ref) => {
  var {children, disabled = false, errorText, isSelectAll = false, name, onChange, orientation = "vertical"} = _a, props = __rest(_a, ["children", "disabled", "errorText", "isSelectAll", "name", "onChange", "orientation"]);
  const onChangeHandler = useCallback((event) => {
    if (onChange != null) {
      onChange(event.target.checked);
    }
  }, [onChange]);
  const contextValue = useMemo(() => {
    return {
      disabled,
      name,
      onChange: onChangeHandler,
      hasError: errorText ? true : void 0
    };
  }, [disabled, name, onChangeHandler]);
  return /* @__PURE__ */ createElement2(CheckboxContext.Provider, {
    value: contextValue
  }, /* @__PURE__ */ createElement2(InlineControlGroup, __assign(__assign({}, props), {
    disabled,
    errorText,
    name,
    orientation,
    ref
  }), Children.map(children, (child, index) => {
    return isValidElement(child) ? cloneElement(child, {
      isSelectAll: isSelectAll && index === 0,
      isSelectAllChild: isSelectAll && orientation === "vertical" && index !== 0
    }) : child;
  })));
});
CheckboxGroup.displayName = "CheckboxGroup";
if (true) {
  CheckboxGroup.propTypes = {
    isSelectAll: bool2,
    name: string2.isRequired,
    onChange: func2,
    disabled: bool2,
    errorText: string2,
    helpText: string2,
    orientation: oneOf(["vertical", "horizontal"])
  };
}

// src/CheckboxDisclaimer.tsx
import {
  Fragment,
  createElement as createElement3,
  forwardRef as forwardRef3
} from "react";
import {
  node as node2,
  oneOfType as oneOfType2,
  string as string3
} from "prop-types";
import {Box as Box2} from "@twilio-paste/box";
import {HelpText} from "@twilio-paste/help-text";
var CheckboxDisclaimer = forwardRef3((_a, ref) => {
  var {children, errorText} = _a, props = __rest(_a, ["children", "errorText"]);
  return /* @__PURE__ */ createElement3(Fragment, null, /* @__PURE__ */ createElement3(Box2, {
    backgroundColor: "colorBackground",
    borderRadius: "borderRadius20",
    padding: "space50"
  }, /* @__PURE__ */ createElement3(Checkbox, __assign(__assign({}, props), {
    hasError: errorText != null,
    ref
  }), children)), errorText && /* @__PURE__ */ createElement3(Box2, {
    marginTop: "space30",
    marginLeft: "space50"
  }, /* @__PURE__ */ createElement3(HelpText, {
    variant: "error"
  }, errorText)));
});
CheckboxDisclaimer.displayName = "CheckboxDisclaimer";
if (true) {
  CheckboxDisclaimer.propTypes = {
    children: node2.isRequired,
    errorText: oneOfType2([string3, node2])
  };
}
export {
  Checkbox,
  CheckboxDisclaimer,
  CheckboxGroup
};
