import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/ChangelogLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Change Log`}</h1>
    <h2>{`4.0.3`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0eded1fd63f081ba9aeab5b5946218e1c5b9b316"
        }}><inlineCode parentName="a">{`0eded1fd`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1319"
        }}>{`#1319`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/SiTaggart"
        }}>{`@SiTaggart`}</a>{`! - Change internal dependencies to have minor range matching on version numbers`}</li>
    </ul>
    <h2>{`4.0.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/514bd5aa9fed6581bbc4c1de649457bcc8e87b3c"
        }}><inlineCode parentName="a">{`514bd5aa`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.1"
            }}>{`theme@5.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.2"
            }}>{`box@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.1"
            }}>{`style-props@3.0.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`4.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/509eba7a95325dd6f8adc3e905e22f92b7f004a9"
        }}><inlineCode parentName="a">{`509eba7a`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.1"
            }}>{`box@4.0.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`4.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4c9ed5cac36ada218824d3e24bf45d4a03a12272"
        }}><inlineCode parentName="a">{`4c9ed5ca`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/26c828d8681e0e671f28b5f2856cd1803f13953f"
        }}><inlineCode parentName="a">{`26c828d8`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.6.0"
            }}>{`design-tokens@6.6.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.0"
            }}>{`theme@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.0"
            }}>{`box@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.0"
            }}>{`style-props@3.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`3.0.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ac38757f0e426531862d5c562a2f2300cfa30592"
        }}><inlineCode parentName="a">{`ac38757f`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1228"
        }}>{`#1228`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/SiTaggart"
        }}>{`@SiTaggart`}</a>{`! - Bump status of the component to production`}</li>
    </ul>
    <h2>{`3.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/944c340790b932f4714b0e6075c5641ecdbee9d6"
        }}><inlineCode parentName="a">{`944c3407`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.5.2"
            }}>{`design-tokens@6.5.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@3.0.1"
            }}>{`box@3.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.1"
            }}>{`style-props@2.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.1"
            }}>{`theme@4.3.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`3.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f1675586933bcce71a6b5c5fec7d939735763a73"
        }}><inlineCode parentName="a">{`f1675586`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.0"
            }}>{`theme@4.3.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@3.0.0"
            }}>{`box@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.0"
            }}>{`style-props@2.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`2.1.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{` `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/pull/1158"
          }}>{`#1158`}</a>{` Thanks `}<a parentName="p" {...{
            "href": "https://github.com/richbachman"
          }}>{`@richbachman`}</a>{`! - Pinned all twilio-paste package versions in order to keep them in sync with core when they are updated by changesets.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Updated dependencies [`}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`]:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.2.2"
            }}>{`theme@4.2.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@1.9.2"
            }}>{`style-props@1.9.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@2.13.2"
            }}>{`box@2.13.2`}</a></li>
        </ul>
      </li>
    </ul>
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@2.1.0...@twilio-paste/aspect-ratio@2.1.1"
      }}>{`2.1.1`}</a>{` (2021-01-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@2.0.3...@twilio-paste/aspect-ratio@2.1.0"
      }}>{`2.1.0`}</a>{` (2021-01-21)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ESBuild for builds (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1084"
        }}>{`#1084`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/053646011508be10477d5b732269cdb0419235d7"
        }}>{`0536460`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@2.0.2...@twilio-paste/aspect-ratio@2.0.3"
      }}>{`2.0.3`}</a>{` (2021-01-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@2.0.1...@twilio-paste/aspect-ratio@2.0.2"
      }}>{`2.0.2`}</a>{` (2021-01-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@2.0.0...@twilio-paste/aspect-ratio@2.0.1"
      }}>{`2.0.1`}</a>{` (2020-12-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.88...@twilio-paste/aspect-ratio@2.0.0"
      }}>{`2.0.0`}</a>{` (2020-12-17)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`aspect-ratio:`}</strong>{` add forwardRef (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/aa46d216a9198f3ab330440516f62f71f9910a17"
        }}>{`aa46d21`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`aspect-ratio:`}</strong>{` component is now using fowardRef`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.87...@twilio-paste/aspect-ratio@1.0.88"
      }}>{`1.0.88`}</a>{` (2020-12-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.86...@twilio-paste/aspect-ratio@1.0.87"
      }}>{`1.0.87`}</a>{` (2020-12-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.85...@twilio-paste/aspect-ratio@1.0.86"
      }}>{`1.0.86`}</a>{` (2020-12-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.84...@twilio-paste/aspect-ratio@1.0.85"
      }}>{`1.0.85`}</a>{` (2020-12-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.83...@twilio-paste/aspect-ratio@1.0.84"
      }}>{`1.0.84`}</a>{` (2020-12-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.82...@twilio-paste/aspect-ratio@1.0.83"
      }}>{`1.0.83`}</a>{` (2020-11-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.81...@twilio-paste/aspect-ratio@1.0.82"
      }}>{`1.0.82`}</a>{` (2020-11-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.80...@twilio-paste/aspect-ratio@1.0.81"
      }}>{`1.0.81`}</a>{` (2020-11-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.79...@twilio-paste/aspect-ratio@1.0.80"
      }}>{`1.0.80`}</a>{` (2020-11-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.78...@twilio-paste/aspect-ratio@1.0.79"
      }}>{`1.0.79`}</a>{` (2020-10-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.77...@twilio-paste/aspect-ratio@1.0.78"
      }}>{`1.0.78`}</a>{` (2020-10-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.76...@twilio-paste/aspect-ratio@1.0.77"
      }}>{`1.0.77`}</a>{` (2020-10-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.75...@twilio-paste/aspect-ratio@1.0.76"
      }}>{`1.0.76`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.74...@twilio-paste/aspect-ratio@1.0.75"
      }}>{`1.0.75`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.73...@twilio-paste/aspect-ratio@1.0.74"
      }}>{`1.0.74`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.72...@twilio-paste/aspect-ratio@1.0.73"
      }}>{`1.0.73`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.71...@twilio-paste/aspect-ratio@1.0.72"
      }}>{`1.0.72`}</a>{` (2020-09-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.70...@twilio-paste/aspect-ratio@1.0.71"
      }}>{`1.0.71`}</a>{` (2020-09-21)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.69...@twilio-paste/aspect-ratio@1.0.70"
      }}>{`1.0.70`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.68...@twilio-paste/aspect-ratio@1.0.69"
      }}>{`1.0.69`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.67...@twilio-paste/aspect-ratio@1.0.68"
      }}>{`1.0.68`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.66...@twilio-paste/aspect-ratio@1.0.67"
      }}>{`1.0.67`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.65...@twilio-paste/aspect-ratio@1.0.66"
      }}>{`1.0.66`}</a>{` (2020-09-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.64...@twilio-paste/aspect-ratio@1.0.65"
      }}>{`1.0.65`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.63...@twilio-paste/aspect-ratio@1.0.64"
      }}>{`1.0.64`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.62...@twilio-paste/aspect-ratio@1.0.63"
      }}>{`1.0.63`}</a>{` (2020-08-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.61...@twilio-paste/aspect-ratio@1.0.62"
      }}>{`1.0.62`}</a>{` (2020-08-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.60...@twilio-paste/aspect-ratio@1.0.61"
      }}>{`1.0.61`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.59...@twilio-paste/aspect-ratio@1.0.60"
      }}>{`1.0.60`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.58...@twilio-paste/aspect-ratio@1.0.59"
      }}>{`1.0.59`}</a>{` (2020-08-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.57...@twilio-paste/aspect-ratio@1.0.58"
      }}>{`1.0.58`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.56...@twilio-paste/aspect-ratio@1.0.57"
      }}>{`1.0.57`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.55...@twilio-paste/aspect-ratio@1.0.56"
      }}>{`1.0.56`}</a>{` (2020-07-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.54...@twilio-paste/aspect-ratio@1.0.55"
      }}>{`1.0.55`}</a>{` (2020-07-30)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.53...@twilio-paste/aspect-ratio@1.0.54"
      }}>{`1.0.54`}</a>{` (2020-07-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.52...@twilio-paste/aspect-ratio@1.0.53"
      }}>{`1.0.53`}</a>{` (2020-07-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.51...@twilio-paste/aspect-ratio@1.0.52"
      }}>{`1.0.52`}</a>{` (2020-07-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.50...@twilio-paste/aspect-ratio@1.0.51"
      }}>{`1.0.51`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.49...@twilio-paste/aspect-ratio@1.0.50"
      }}>{`1.0.50`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.48...@twilio-paste/aspect-ratio@1.0.49"
      }}>{`1.0.49`}</a>{` (2020-07-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.47...@twilio-paste/aspect-ratio@1.0.48"
      }}>{`1.0.48`}</a>{` (2020-06-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.46...@twilio-paste/aspect-ratio@1.0.47"
      }}>{`1.0.47`}</a>{` (2020-06-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.45...@twilio-paste/aspect-ratio@1.0.46"
      }}>{`1.0.46`}</a>{` (2020-06-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.44...@twilio-paste/aspect-ratio@1.0.45"
      }}>{`1.0.45`}</a>{` (2020-06-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.43...@twilio-paste/aspect-ratio@1.0.44"
      }}>{`1.0.44`}</a>{` (2020-06-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.42...@twilio-paste/aspect-ratio@1.0.43"
      }}>{`1.0.43`}</a>{` (2020-06-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.41...@twilio-paste/aspect-ratio@1.0.42"
      }}>{`1.0.42`}</a>{` (2020-06-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.40...@twilio-paste/aspect-ratio@1.0.41"
      }}>{`1.0.41`}</a>{` (2020-06-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.39...@twilio-paste/aspect-ratio@1.0.40"
      }}>{`1.0.40`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.38...@twilio-paste/aspect-ratio@1.0.39"
      }}>{`1.0.39`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.37...@twilio-paste/aspect-ratio@1.0.38"
      }}>{`1.0.38`}</a>{` (2020-05-27)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.36...@twilio-paste/aspect-ratio@1.0.37"
      }}>{`1.0.37`}</a>{` (2020-05-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.35...@twilio-paste/aspect-ratio@1.0.36"
      }}>{`1.0.36`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.34...@twilio-paste/aspect-ratio@1.0.35"
      }}>{`1.0.35`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.33...@twilio-paste/aspect-ratio@1.0.34"
      }}>{`1.0.34`}</a>{` (2020-05-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.32...@twilio-paste/aspect-ratio@1.0.33"
      }}>{`1.0.33`}</a>{` (2020-05-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.31...@twilio-paste/aspect-ratio@1.0.32"
      }}>{`1.0.32`}</a>{` (2020-04-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.30...@twilio-paste/aspect-ratio@1.0.31"
      }}>{`1.0.31`}</a>{` (2020-04-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.29...@twilio-paste/aspect-ratio@1.0.30"
      }}>{`1.0.30`}</a>{` (2020-04-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.28...@twilio-paste/aspect-ratio@1.0.29"
      }}>{`1.0.29`}</a>{` (2020-04-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.27...@twilio-paste/aspect-ratio@1.0.28"
      }}>{`1.0.28`}</a>{` (2020-04-08)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`aspect-ratio:`}</strong>{` package dependencies updated to be correct (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/875f22be00765a4c8edf1b88ed99b949c5e75554"
        }}>{`875f22b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.26...@twilio-paste/aspect-ratio@1.0.27"
      }}>{`1.0.27`}</a>{` (2020-04-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.25...@twilio-paste/aspect-ratio@1.0.26"
      }}>{`1.0.26`}</a>{` (2020-04-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.24...@twilio-paste/aspect-ratio@1.0.25"
      }}>{`1.0.25`}</a>{` (2020-04-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.23...@twilio-paste/aspect-ratio@1.0.24"
      }}>{`1.0.24`}</a>{` (2020-03-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.22...@twilio-paste/aspect-ratio@1.0.23"
      }}>{`1.0.23`}</a>{` (2020-03-24)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`aspect-ratio:`}</strong>{` switch from theme-tokens to theme package dep (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/17876fd11443bff39bf966ed9a750cdfd2414e4f"
        }}>{`17876fd`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.21...@twilio-paste/aspect-ratio@1.0.22"
      }}>{`1.0.22`}</a>{` (2020-03-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.20...@twilio-paste/aspect-ratio@1.0.21"
      }}>{`1.0.21`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.19...@twilio-paste/aspect-ratio@1.0.20"
      }}>{`1.0.20`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.18...@twilio-paste/aspect-ratio@1.0.19"
      }}>{`1.0.19`}</a>{` (2020-03-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.17...@twilio-paste/aspect-ratio@1.0.18"
      }}>{`1.0.18`}</a>{` (2020-03-06)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`add missing transitive peerDep on '@styled-system/css' (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/fdbb81370cda71037348742a680299ce481eabfd"
        }}>{`fdbb813`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.16...@twilio-paste/aspect-ratio@1.0.17"
      }}>{`1.0.17`}</a>{` (2020-03-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.15...@twilio-paste/aspect-ratio@1.0.16"
      }}>{`1.0.16`}</a>{` (2020-02-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.14...@twilio-paste/aspect-ratio@1.0.15"
      }}>{`1.0.15`}</a>{` (2020-02-26)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`package dependencies and deprecation warnings (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/334"
        }}>{`#334`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0e88338511e6835a79eb0a9cea8d5b3a1cdf0a88"
        }}>{`0e88338`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.13...@twilio-paste/aspect-ratio@1.0.14"
      }}>{`1.0.14`}</a>{` (2020-02-14)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`aspect-ratio:`}</strong>{` add missing peer deps (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/5e1000db3505b82d80ba4b5e197c78abb63769ec"
        }}>{`5e1000d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.12...@twilio-paste/aspect-ratio@1.0.13"
      }}>{`1.0.13`}</a>{` (2020-02-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.11...@twilio-paste/aspect-ratio@1.0.12"
      }}>{`1.0.12`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.10...@twilio-paste/aspect-ratio@1.0.11"
      }}>{`1.0.11`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.9...@twilio-paste/aspect-ratio@1.0.10"
      }}>{`1.0.10`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.8...@twilio-paste/aspect-ratio@1.0.9"
      }}>{`1.0.9`}</a>{` (2020-02-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.7...@twilio-paste/aspect-ratio@1.0.8"
      }}>{`1.0.8`}</a>{` (2020-01-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.6...@twilio-paste/aspect-ratio@1.0.7"
      }}>{`1.0.7`}</a>{` (2020-01-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.5...@twilio-paste/aspect-ratio@1.0.6"
      }}>{`1.0.6`}</a>{` (2019-12-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.4...@twilio-paste/aspect-ratio@1.0.5"
      }}>{`1.0.5`}</a>{` (2019-12-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.3...@twilio-paste/aspect-ratio@1.0.4"
      }}>{`1.0.4`}</a>{` (2019-12-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.2...@twilio-paste/aspect-ratio@1.0.3"
      }}>{`1.0.3`}</a>{` (2019-11-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.1...@twilio-paste/aspect-ratio@1.0.2"
      }}>{`1.0.2`}</a>{` (2019-11-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@1.0.0...@twilio-paste/aspect-ratio@1.0.1"
      }}>{`1.0.1`}</a>{` (2019-11-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@0.2.1...@twilio-paste/aspect-ratio@1.0.0"
      }}>{`1.0.0`}</a>{` (2019-11-11)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` delete old icons, add new streamline icons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/129"
        }}>{`#129`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/571791ded8ee4c55bb5a3dbcebee4b17b2c7c826"
        }}>{`571791d`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`icons:`}</strong>{` removed all the inherited icons since we're moving to a new system`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(icons): update icon list for storybook`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): use new icon`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(storybook): use new icon for story`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(story): button icons should be 24px`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix: adjust icon size tokens`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat: add iconSizes as separate key in theme-tokens`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat(icons): icons package to use tokens and update icons`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): update to use tokens and new icons`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(button): use correct spinner size`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(icons): major icons package fixes`}</p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`huge overhaul to build process`}</li>
      <li parentName="ul">{`now uses rollup`}</li>
      <li parentName="ul">{`builds into cjs and esm directories`}</li>
      <li parentName="ul">{`made the package publishable to npm`}</li>
      <li parentName="ul">{`moved svg folder out of src, now src only holds react stuff`}</li>
      <li parentName="ul">{`updated scripts to use new paths and cleaned up the code`}</li>
      <li parentName="ul">{`programmatically generates rollup config from the icon-list command`}</li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`chore: add new icons dist folders to gitignore`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix: spinner and button icon usage`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat(icons): add rollup icon list cache file`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(core-bundle): sort packages for consistent builds`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore: use `}<inlineCode parentName="p">{`esm`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`es`}</inlineCode>{` in rollup for correctness`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore: yarn.lock`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): lint error`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(icons): move rollup icon list`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(spinner): use types package in story`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): swap out destructured props for explicit props`}</p>
      </li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@0.2.0...@twilio-paste/aspect-ratio@0.2.1"
      }}>{`0.2.1`}</a>{` (2019-10-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/aspect-ratio`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/aspect-ratio@0.1.1...@twilio-paste/aspect-ratio@0.2.0"
      }}>{`0.2.0`}</a>{` (2019-10-29)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` aspect-ratio docs page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/104"
        }}>{`#104`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7a78689891468180929bb3cea45207ac09699f81"
        }}>{`7a78689`}</a>{`)`}</li>
      <li parentName="ul">{`upgrading to styled-system v5 (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/18"
        }}>{`#18`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2b3ebd8b2f701a0c6e8b75ab6978ba936814f455"
        }}>{`2b3ebd8`}</a>{`)`}</li>
    </ul>
    <h2>{`0.1.1 (2019-09-16)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` composable do-dont, add anchor examples (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/81"
        }}>{`#81`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a799f1b"
        }}>{`a799f1b`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      