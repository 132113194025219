import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Stack } from '@twilio-paste/stack';
import { MediaObject, MediaFigure, MediaBody } from '@twilio-paste/media-object';
import { ErrorIcon } from '@twilio-paste/icons/esm/ErrorIcon';
import { Disclosure, DisclosureHeading, DisclosureContent, useDisclosureState } from '@twilio-paste/disclosure';
import Changelog from '@twilio-paste/disclosure/CHANGELOG.md';
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { SidebarCategoryRoutes } from "../../../../src/constants";
import { StateHookExample } from "../../../../src/component-examples/DisclosureExamples";
import * as React from 'react';
export default {
  graphql,
  Anchor,
  Box,
  Text,
  Stack,
  MediaObject,
  MediaFigure,
  MediaBody,
  ErrorIcon,
  Disclosure,
  DisclosureHeading,
  DisclosureContent,
  useDisclosureState,
  Changelog,
  Callout,
  CalloutTitle,
  CalloutText,
  SidebarCategoryRoutes,
  StateHookExample,
  React
};