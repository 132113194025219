import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Heading } from '@twilio-paste/heading';
import { Button } from '@twilio-paste/button';
import { Separator } from '@twilio-paste/separator';
import { AspectRatio } from '@twilio-paste/aspect-ratio';
import { Box } from '@twilio-paste/box';
import { Popover, PopoverContainer, PopoverButton } from '@twilio-paste/popover';
import Changelog from '@twilio-paste/popover/CHANGELOG.md';
import { Text } from '@twilio-paste/text';
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { SidebarCategoryRoutes } from "../../../../src/constants";
import { defaultExample, rightExample } from "../../../../src/component-examples/PopoverExamples";
import * as React from 'react';
export default {
  graphql,
  Anchor,
  Heading,
  Button,
  Separator,
  AspectRatio,
  Box,
  Popover,
  PopoverContainer,
  PopoverButton,
  Changelog,
  Text,
  Callout,
  CalloutTitle,
  CalloutText,
  SidebarCategoryRoutes,
  defaultExample,
  rightExample,
  React
};