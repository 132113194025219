import { graphql } from 'gatsby';
import { Alert } from '@twilio-paste/alert';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Breadcrumb, BreadcrumbItem } from "../../../../src/components/breadcrumb";
import { Button } from '@twilio-paste/button';
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { Card } from '@twilio-paste/card';
import { FormInput, FormLabel, FormHelpText, Select, Option } from '@twilio-paste/form';
import { Grid, Column } from '@twilio-paste/grid';
import { Heading } from '@twilio-paste/heading';
import { MediaObject, MediaFigure, MediaBody } from '@twilio-paste/media-object';
import { Paragraph } from '@twilio-paste/paragraph';
import { Stack } from '@twilio-paste/stack';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@twilio-paste/tabs';
import { Text } from '@twilio-paste/text';
import { Toast } from '@twilio-paste/toast';
import { ErrorIcon } from "@twilio-paste/icons/esm/ErrorIcon";
import { SuccessIcon } from "@twilio-paste/icons/esm/SuccessIcon";
import { Codeblock } from "../../../../src/components/codeblock";
import { useUID } from 'react-uid';
import { Modal, ModalBody, ModalFooter, ModalFooterActions, ModalHeader, ModalHeading } from '@twilio-paste/modal';
import { defaultExample, initialFocusExample, wideExample } from "../../../../src/component-examples/ModalExamples";
import * as React from 'react';
export default {
  graphql,
  Alert,
  Anchor,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Callout,
  CalloutTitle,
  CalloutText,
  Card,
  FormInput,
  FormLabel,
  FormHelpText,
  Select,
  Option,
  Grid,
  Column,
  Heading,
  MediaObject,
  MediaFigure,
  MediaBody,
  Paragraph,
  Stack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Toast,
  ErrorIcon,
  SuccessIcon,
  Codeblock,
  useUID,
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalHeading,
  defaultExample,
  initialFocusExample,
  wideExample,
  React
};