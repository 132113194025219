var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/index.tsx
import {
  createElement,
  forwardRef
} from "react";
import {Text, safelySpreadTextProps} from "@twilio-paste/text";
import {isMarginTokenProp} from "@twilio-paste/style-props";
var List = forwardRef((_a, ref) => {
  var {as, children} = _a, props = __rest(_a, ["as", "children"]);
  return /* @__PURE__ */ createElement(Text, __assign(__assign({}, props), {
    ref,
    as,
    marginLeft: "space70",
    fontSize: "fontSize30",
    lineHeight: "lineHeight40",
    fontWeight: "fontWeightNormal",
    color: "colorText"
  }), children);
});
List.displayName = "List";
var OrderedList = forwardRef((_a, ref) => {
  var {children, marginTop, marginBottom} = _a, props = __rest(_a, ["children", "marginTop", "marginBottom"]);
  return /* @__PURE__ */ createElement(List, __assign(__assign({}, safelySpreadTextProps(props)), {
    ref,
    as: "ol",
    marginTop,
    marginBottom
  }), children);
});
OrderedList.defaultProps = {
  marginBottom: "space70"
};
if (true) {
  OrderedList.propTypes = {
    marginTop: isMarginTokenProp,
    marginBottom: isMarginTokenProp
  };
}
OrderedList.displayName = "OrderedList";
var UnorderedList = forwardRef((_a, ref) => {
  var {children, marginTop, marginBottom} = _a, props = __rest(_a, ["children", "marginTop", "marginBottom"]);
  return /* @__PURE__ */ createElement(List, __assign(__assign({}, safelySpreadTextProps(props)), {
    ref,
    as: "ul",
    marginTop,
    marginBottom
  }), children);
});
UnorderedList.defaultProps = {
  marginBottom: "space70"
};
if (true) {
  UnorderedList.propTypes = {
    marginTop: isMarginTokenProp,
    marginBottom: isMarginTokenProp
  };
}
UnorderedList.displayName = "UnorderedList";
var ListItem = forwardRef((props, ref) => {
  return /* @__PURE__ */ createElement(Text, __assign(__assign({}, safelySpreadTextProps(props)), {
    ref,
    as: "li",
    marginTop: "space30",
    marginBottom: "space30",
    fontSize: "fontSize30",
    lineHeight: "lineHeight40",
    fontWeight: "fontWeightNormal",
    color: "colorText"
  }), props.children);
});
ListItem.displayName = "ListItem";
export {
  ListItem,
  OrderedList,
  UnorderedList
};
