import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/ChangelogLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Change Log`}</h1>
    <h2>{`0.2.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ac38757f0e426531862d5c562a2f2300cfa30592"
        }}><inlineCode parentName="a">{`ac38757f`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1228"
        }}>{`#1228`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/SiTaggart"
        }}>{`@SiTaggart`}</a>{`! - Bump status of the component to production`}</li>
    </ul>
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/modal-dialog-primitive@0.2.0...@twilio-paste/modal-dialog-primitive@0.2.1"
      }}>{`0.2.1`}</a>{` (2021-01-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/modal-dialog-primitive`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/modal-dialog-primitive@0.1.9...@twilio-paste/modal-dialog-primitive@0.2.0"
      }}>{`0.2.0`}</a>{` (2021-01-21)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ESBuild for builds (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1084"
        }}>{`#1084`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/053646011508be10477d5b732269cdb0419235d7"
        }}>{`0536460`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/modal-dialog-primitive@0.1.8...@twilio-paste/modal-dialog-primitive@0.1.9"
      }}>{`0.1.9`}</a>{` (2021-01-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/modal-dialog-primitive`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/modal-dialog-primitive@0.1.7...@twilio-paste/modal-dialog-primitive@0.1.8"
      }}>{`0.1.8`}</a>{` (2020-10-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/modal-dialog-primitive`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/modal-dialog-primitive@0.1.6...@twilio-paste/modal-dialog-primitive@0.1.7"
      }}>{`0.1.7`}</a>{` (2020-10-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/modal-dialog-primitive`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/modal-dialog-primitive@0.1.5...@twilio-paste/modal-dialog-primitive@0.1.6"
      }}>{`0.1.6`}</a>{` (2020-09-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/modal-dialog-primitive`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/modal-dialog-primitive@0.1.4...@twilio-paste/modal-dialog-primitive@0.1.5"
      }}>{`0.1.5`}</a>{` (2020-07-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/modal-dialog-primitive`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/modal-dialog-primitive@0.1.3...@twilio-paste/modal-dialog-primitive@0.1.4"
      }}>{`0.1.4`}</a>{` (2020-06-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/modal-dialog-primitive`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/modal-dialog-primitive@0.1.2...@twilio-paste/modal-dialog-primitive@0.1.3"
      }}>{`0.1.3`}</a>{` (2020-05-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/modal-dialog-primitive`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/modal-dialog-primitive@0.1.1...@twilio-paste/modal-dialog-primitive@0.1.2"
      }}>{`0.1.2`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/modal-dialog-primitive`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/modal-dialog-primitive@0.1.0...@twilio-paste/modal-dialog-primitive@0.1.1"
      }}>{`0.1.1`}</a>{` (2020-02-26)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`package dependencies and deprecation warnings (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/334"
        }}>{`#334`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0e88338511e6835a79eb0a9cea8d5b3a1cdf0a88"
        }}>{`0e88338`}</a>{`)`}</li>
    </ul>
    <h1>{`0.1.0 (2020-02-19)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`modal-dialog-primitive:`}</strong>{` rename exports (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/fcdd574a868b398abab9b1cfe0606772ae43123b"
        }}>{`fcdd574`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`modal-dialog-primitive:`}</strong>{` add package (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ba350fc1541be23f7aa4920e3f618386693a9e13"
        }}>{`ba350fc`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/modal-dialog-primitive@0.0.1...@twilio-paste/modal-dialog-primitive@0.0.2"
      }}>{`0.0.2`}</a>{` (2019-10-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/modal-dialog-primitive`}</p>
    <h2>{`0.0.1 (2019-08-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/modal-dialog-primitive`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      