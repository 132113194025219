var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/index.tsx
import {
  Children,
  cloneElement,
  createElement,
  forwardRef,
  isValidElement,
  useMemo
} from "react";
import {
  bool,
  node,
  string
} from "prop-types";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
import {Anchor} from "@twilio-paste/anchor";
import {Text} from "@twilio-paste/text";
import {useUIDSeed} from "@twilio-paste/uid-library";
var BreadcrumbSeparator = () => /* @__PURE__ */ createElement(Text, {
  as: "span",
  color: "colorTextWeak",
  fontSize: "fontSize20",
  lineHeight: "lineHeight20",
  paddingLeft: "space20",
  paddingRight: "space20",
  role: "presentation"
}, "/");
var BreadcrumbItem = forwardRef((_a, ref) => {
  var {children, href, last} = _a, props = __rest(_a, ["children", "href", "last"]);
  return /* @__PURE__ */ createElement(Box, __assign(__assign({}, safelySpreadBoxProps(props)), {
    alignItems: "center",
    as: "li",
    color: "colorText",
    display: "inline-flex",
    fontSize: "fontSize20",
    lineHeight: "lineHeight20"
  }), href ? /* @__PURE__ */ createElement(Anchor, {
    href,
    ref
  }, children) : /* @__PURE__ */ createElement(Text, {
    "aria-current": "page",
    as: "span",
    fontSize: "fontSize20",
    lineHeight: "lineHeight20",
    ref
  }, children), !last && /* @__PURE__ */ createElement(BreadcrumbSeparator, null));
});
BreadcrumbItem.displayName = "BreadcrumbItem";
if (true) {
  BreadcrumbItem.propTypes = {
    children: node.isRequired,
    href: string,
    last: bool
  };
}
var Breadcrumb = forwardRef((_a, ref) => {
  var {children} = _a, props = __rest(_a, ["children"]);
  const [childrenCount, validChildren] = useMemo(() => [
    Children.count(children),
    Children.toArray(children).filter((child) => isValidElement(child) || typeof child === "string")
  ], [children]);
  const keySeed = useUIDSeed();
  return /* @__PURE__ */ createElement(Box, __assign(__assign({}, safelySpreadBoxProps(props)), {
    "aria-label": "breadcrumb",
    as: "nav",
    ref
  }), /* @__PURE__ */ createElement(Box, {
    alignItems: "center",
    as: "ol",
    display: "inline-flex",
    listStyleType: "none",
    margin: "space0",
    padding: "space0"
  }, validChildren.map((child, index) => cloneElement(child, {
    last: childrenCount === index + 1,
    key: keySeed(`breadcrumb-${index}`)
  }))));
});
Breadcrumb.displayName = "Breadcrumb";
if (true) {
  Breadcrumb.propTypes = {
    children: node.isRequired
  };
}
export {
  Breadcrumb,
  BreadcrumbItem
};
