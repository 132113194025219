var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/HelpText.tsx
import {
  createElement,
  forwardRef
} from "react";
import {
  oneOf
} from "prop-types";
import {Box} from "@twilio-paste/box";
import {Flex} from "@twilio-paste/flex";
import {Text, safelySpreadTextProps} from "@twilio-paste/text";
import {ErrorIcon} from "@twilio-paste/icons/esm/ErrorIcon";
var HelpTextVariants = {
  DEFAULT: "default",
  ERROR: "error",
  ERROR_INVERSE: "error_inverse",
  INVERSE: "inverse"
};
var HelpText = forwardRef((_a, ref) => {
  var {marginTop, children, variant} = _a, props = __rest(_a, ["marginTop", "children", "variant"]);
  let icon = null;
  switch (variant) {
    case HelpTextVariants.ERROR:
      icon = /* @__PURE__ */ createElement(Box, {
        flexShrink: 0
      }, /* @__PURE__ */ createElement(ErrorIcon, {
        color: "colorTextError",
        decorative: true,
        size: "sizeIcon20"
      }));
      break;
    case HelpTextVariants.ERROR_INVERSE:
      icon = /* @__PURE__ */ createElement(Box, {
        flexShrink: 0
      }, /* @__PURE__ */ createElement(ErrorIcon, {
        color: "colorTextErrorLight",
        decorative: true,
        size: "sizeIcon20"
      }));
      break;
    default:
      break;
  }
  let textColor = "colorTextWeak";
  if (variant === "error") {
    textColor = "colorTextError";
  } else if (variant === "error_inverse") {
    textColor = "colorTextErrorLight";
  } else if (variant === "inverse") {
    textColor = "colorTextInverseWeak";
  }
  return /* @__PURE__ */ createElement(Flex, {
    marginTop: marginTop || "space30",
    ref
  }, icon, /* @__PURE__ */ createElement(Text, __assign(__assign({}, safelySpreadTextProps(props)), {
    as: "div",
    color: textColor,
    fontSize: "fontSize30",
    lineHeight: "lineHeight30",
    marginLeft: icon ? "space20" : void 0
  }), children));
});
HelpText.displayName = "HelpText";
if (true) {
  HelpText.propTypes = {
    marginTop: oneOf(["space0"])
  };
}
export {
  HelpText,
  HelpTextVariants
};
