var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/themeProvider.tsx
import {
  createElement,
  useEffect
} from "react";
import {useReducedMotion, Globals as AnimatedGlobals} from "@twilio-paste/animation-library";
import {styled, StylingGlobals, ThemeProvider as StyledThemeProvider} from "@twilio-paste/styling-library";

// src/themes/default/index.ts
import {
  backgroundColors,
  borderColors,
  borderWidths,
  radii,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  boxShadows,
  sizings,
  spacings,
  textColors,
  zIndices
} from "@twilio-paste/design-tokens";

// src/generateThemeFromTokens.ts
var generateThemeFromTokens = (backgroundColors4, borderColors4, borderWidths4, radii4, fonts4, fontSizes4, fontWeights4, lineHeights4, boxShadows4, sizings4, spacings4, textColors4, zIndices4) => {
  const breakpoints = [sizings4.size40, sizings4.size100, sizings4.size120];
  return {
    shadows: boxShadows4,
    borderWidths: borderWidths4,
    radii: radii4,
    breakpoints,
    textColors: textColors4,
    borderColors: borderColors4,
    backgroundColors: backgroundColors4,
    fonts: fonts4,
    fontSizes: fontSizes4,
    fontWeights: fontWeights4,
    lineHeights: lineHeights4,
    widths: sizings4,
    maxWidths: sizings4,
    minWidths: sizings4,
    heights: sizings4,
    maxHeights: sizings4,
    minHeights: sizings4,
    sizes: sizings4,
    iconSizes: {
      sizeIcon10: sizings4.sizeIcon10,
      sizeIcon20: sizings4.sizeIcon20,
      sizeIcon30: sizings4.sizeIcon30,
      sizeIcon40: sizings4.sizeIcon40,
      sizeIcon50: sizings4.sizeIcon50,
      sizeIcon60: sizings4.sizeIcon60,
      sizeIcon70: sizings4.sizeIcon70,
      sizeIcon80: sizings4.sizeIcon80,
      sizeIcon90: sizings4.sizeIcon90,
      sizeIcon100: sizings4.sizeIcon100,
      sizeIcon110: sizings4.sizeIcon110
    },
    space: spacings4,
    zIndices: zIndices4
  };
};

// src/themes/default/index.ts
var DefaultTheme = generateThemeFromTokens(backgroundColors, borderColors, borderWidths, radii, fonts, fontSizes, fontWeights, lineHeights, boxShadows, sizings, spacings, textColors, zIndices);

// src/themes/sendgrid/index.ts
import {
  backgroundColors as backgroundColors2,
  borderColors as borderColors2,
  borderWidths as borderWidths2,
  radii as radii2,
  fonts as fonts2,
  fontSizes as fontSizes2,
  fontWeights as fontWeights2,
  lineHeights as lineHeights2,
  boxShadows as boxShadows2,
  sizings as sizings2,
  spacings as spacings2,
  textColors as textColors2,
  zIndices as zIndices2
} from "@twilio-paste/design-tokens/dist/themes/sendgrid/tokens.es6";
var SendGridTheme = generateThemeFromTokens(backgroundColors2, borderColors2, borderWidths2, radii2, fonts2, fontSizes2, fontWeights2, lineHeights2, boxShadows2, sizings2, spacings2, textColors2, zIndices2);

// src/themes/console/index.ts
import {
  backgroundColors as backgroundColors3,
  borderColors as borderColors3,
  borderWidths as borderWidths3,
  radii as radii3,
  fonts as fonts3,
  fontSizes as fontSizes3,
  fontWeights as fontWeights3,
  lineHeights as lineHeights3,
  boxShadows as boxShadows3,
  sizings as sizings3,
  spacings as spacings3,
  textColors as textColors3,
  zIndices as zIndices3
} from "@twilio-paste/design-tokens/dist/themes/console/tokens.es6";
var ConsoleTheme = generateThemeFromTokens(backgroundColors3, borderColors3, borderWidths3, radii3, fonts3, fontSizes3, fontWeights3, lineHeights3, boxShadows3, sizings3, spacings3, textColors3, zIndices3);

// src/styles/global.ts
import {css} from "@twilio-paste/styling-library";
var pasteGlobalStyles = css({
  html: {
    fontSize: "100%"
  },
  body: {
    margin: 0,
    fontSize: "fontSize30"
  },
  "*, *::after, *::before": {
    boxSizing: "border-box"
  },
  "@media (prefers-reduced-motion: reduce)": {
    "*": {
      animationDuration: "0 !important",
      animationIterationCount: "1 !important",
      transitionDuration: "0 !important",
      scrollBehavior: "auto !important"
    }
  },
  ":root": {
    ["--reach-dialog"]: "1"
  }
});

// src/styles/base.ts
import {css as css2} from "@twilio-paste/styling-library";
var pasteBaseStyles = css2({
  color: "colorText",
  fontSize: "fontSize30",
  fontFamily: "fontFamilyText",
  lineHeight: "lineHeight30",
  fontWeight: "fontWeightNormal",
  fontVariantNumeric: "tabular-nums"
});

// src/styles/fonts.ts
import {EmotionCSS} from "@twilio-paste/styling-library";
var pasteFonts = EmotionCSS`
/* -------------------------------------------------------
Variable font.
*/
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url("https://assets.twilio.com/public_assets/paste-fonts/main-1.2.0/Inter-roman.var.woff2?v=3.15") format("woff2");
}
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url("https://assets.twilio.com/public_assets/paste-fonts/main-1.2.0/Inter-italic.var.woff2?v=3.15") format("woff2");
}
/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
@font-face {
  font-family: 'Inter var experimental';
  font-weight: 100 900;
  font-display: swap;
  font-style: oblique 0deg 8deg;
  src: url("https://assets.twilio.com/public_assets/paste-fonts/main-1.2.0/Inter.var.woff2?v=3.15") format("woff2");
}

/*
Fira Mono
*/
@font-face {
  font-family: 'Fira Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(''),
       url('https://assets.twilio.com/public_assets/paste-fonts/main-1.2.0/fira-mono-v9-latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff2') format('woff2'),
       url('https://assets.twilio.com/public_assets/paste-fonts/main-1.2.0/fira-mono-v9-latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-regular.woff') format('woff');
}
@font-face {
  font-family: 'Fira Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(''),
       url('https://assets.twilio.com/public_assets/paste-fonts/main-1.2.0/fira-mono-v9-latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff2') format('woff2'),
       url('https://assets.twilio.com/public_assets/paste-fonts/main-1.2.0/fira-mono-v9-latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-700.woff') format('woff');
}
@font-face {
  font-family: 'Fira Mono';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local(''),
       url('https://assets.twilio.com/public_assets/paste-fonts/main-1.2.0/fira-mono-v9-latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff2') format('woff2'),
       url('https://assets.twilio.com/public_assets/paste-fonts/main-1.2.0/fira-mono-v9-latin-ext_latin_greek-ext_greek_cyrillic-ext_cyrillic-500.woff') format('woff');
}
`;

// src/constants.ts
var ThemeVariants = {
  DEFAULT: "default",
  CONSOLE: "console",
  SENDGRID: "sendgrid",
  FLEX: "flex"
};

// src/themeProvider.tsx
var StyledBase = styled.div(pasteBaseStyles);
function getProviderThemeProps(theme, customBreakpoints) {
  switch (theme) {
    case ThemeVariants.SENDGRID:
      return __assign(__assign({}, SendGridTheme), {
        breakpoints: customBreakpoints || SendGridTheme.breakpoints
      });
    case ThemeVariants.CONSOLE:
      return __assign(__assign({}, ConsoleTheme), {
        breakpoints: customBreakpoints || ConsoleTheme.breakpoints
      });
    case ThemeVariants.FLEX:
    case ThemeVariants.DEFAULT:
    default:
      return __assign(__assign({}, DefaultTheme), {
        breakpoints: customBreakpoints || DefaultTheme.breakpoints
      });
  }
}
var ThemeProvider = (_a) => {
  var {
    customBreakpoints,
    theme = ThemeVariants.DEFAULT,
    disableAnimations = false
  } = _a, props = __rest(_a, [
    "customBreakpoints",
    "theme",
    "disableAnimations"
  ]);
  const prefersReducedMotion = useReducedMotion();
  useEffect(() => {
    AnimatedGlobals.assign({
      skipAnimation: disableAnimations || prefersReducedMotion
    });
  }, [prefersReducedMotion]);
  const providerThemeProps = getProviderThemeProps(theme, customBreakpoints);
  return /* @__PURE__ */ createElement(StyledThemeProvider, {
    theme: providerThemeProps
  }, /* @__PURE__ */ createElement(StylingGlobals, {
    styles: pasteGlobalStyles({theme: providerThemeProps})
  }), /* @__PURE__ */ createElement(StylingGlobals, {
    styles: pasteFonts
  }), /* @__PURE__ */ createElement(StyledBase, __assign({}, props)));
};

// src/themeConsumer.tsx
import {
  createElement as createElement2
} from "react";
import {ThemeContext} from "@twilio-paste/styling-library";
var ThemeConsumer = (_a) => {
  var {children} = _a, props = __rest(_a, ["children"]);
  if (children == null || typeof children !== "function") {
    throw new Error("[ThemeConsumer]: You must pass a function as children");
  }
  return /* @__PURE__ */ createElement2(ThemeContext.Consumer, null, (theme) => {
    const newTheme = theme;
    return children(__assign(__assign({}, props), {theme: newTheme}));
  });
};

// src/withTheme.ts
import {withTheme} from "@twilio-paste/styling-library";

// src/useTheme.ts
import {
  useContext
} from "react";
import {ThemeContext as ThemeContext2} from "@twilio-paste/styling-library";
var useTheme = () => {
  const context = useContext(ThemeContext2);
  if (!context) {
    throw new Error("[useHook]: must be used within the @twilio-paste/theme provider");
  }
  return context;
};

// src/generateTokensFromTheme.ts
var generateTokensFromTheme = (backgroundColors4, borderColors4, borderWidths4, radii4, fonts4, fontSizes4, fontWeights4, lineHeights4, shadows, sizes, space, textColors4, zIndices4) => {
  return {
    boxShadows: shadows,
    borderWidths: borderWidths4,
    radii: radii4,
    textColors: textColors4,
    borderColors: borderColors4,
    backgroundColors: backgroundColors4,
    fonts: fonts4,
    fontSizes: fontSizes4,
    fontWeights: fontWeights4,
    lineHeights: lineHeights4,
    sizings: sizes,
    spacings: space,
    zIndices: zIndices4
  };
};

// src/utils/remToPx.ts
var PX_PER_REM = 16;
var remToPx = (rem, type = "number") => {
  const remValue = typeof rem === "string" ? Number.parseFloat(rem.replace("rem", "")) : rem;
  const pxValue = Math.round(remValue * PX_PER_REM);
  return type === "number" ? pxValue : `${pxValue}px`;
};

// src/index.tsx
var Theme = {
  Provider: ThemeProvider,
  Consumer: ThemeConsumer
};
export {
  ConsoleTheme,
  DefaultTheme,
  SendGridTheme,
  StyledBase,
  Theme,
  ThemeVariants,
  generateThemeFromTokens,
  generateTokensFromTheme,
  pasteBaseStyles,
  pasteFonts,
  pasteGlobalStyles,
  remToPx,
  useTheme,
  withTheme
};
