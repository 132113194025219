import { graphql } from 'gatsby';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { StyledBase } from '@twilio-paste/theme';
import { Text } from '@twilio-paste/text';
import { Stack } from '@twilio-paste/stack';
import Changelog from '@twilio-paste/tabs-primitive/CHANGELOG.md';
import { useTabPrimitiveState, TabPrimitive, TabPrimitiveList, TabPrimitivePanel, TabPrimitiveStateReturn } from '@twilio-paste/tabs-primitive';
import { SidebarCategoryRoutes } from "../../../../src/constants";
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { horizontalExample, verticalExample, styledExample } from "../../../../src/component-examples/TabsPrimitiveExamples";
import * as React from 'react';
export default {
  graphql,
  Box,
  Button,
  StyledBase,
  Text,
  Stack,
  Changelog,
  useTabPrimitiveState,
  TabPrimitive,
  TabPrimitiveList,
  TabPrimitivePanel,
  TabPrimitiveStateReturn,
  SidebarCategoryRoutes,
  Callout,
  CalloutTitle,
  CalloutText,
  horizontalExample,
  verticalExample,
  styledExample,
  React
};