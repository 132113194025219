import { graphql } from 'gatsby';
import { Box } from '@twilio-paste/box';
import { ScreenReaderOnly } from '@twilio-paste/screen-reader-only';
import { Text } from '@twilio-paste/text';
import { Flex } from '@twilio-paste/flex';
import { Paragraph } from '@twilio-paste/paragraph';
import { Heading } from '@twilio-paste/heading';
import { Card } from '@twilio-paste/card';
import { Button } from '@twilio-paste/button';
import { Anchor } from '@twilio-paste/anchor';
import { Table, THead, TBody, Tr, Td, Th } from '@twilio-paste/table';
import Changelog from '@twilio-paste/screen-reader-only/CHANGELOG.md';
import { SidebarCategoryRoutes } from "../../../../src/constants";
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { DoDont, Do, Dont } from "../../../../src/components/DoDont";
import { InlineCode } from "../../../../src/components/Typography";
import * as React from 'react';
export default {
  graphql,
  Box,
  ScreenReaderOnly,
  Text,
  Flex,
  Paragraph,
  Heading,
  Card,
  Button,
  Anchor,
  Table,
  THead,
  TBody,
  Tr,
  Td,
  Th,
  Changelog,
  SidebarCategoryRoutes,
  Callout,
  CalloutTitle,
  CalloutText,
  DoDont,
  Do,
  Dont,
  InlineCode,
  React
};