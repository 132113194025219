import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { Card } from '@twilio-paste/card';
import { FormInput, FormLabel } from '@twilio-paste/form';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { Stack } from '@twilio-paste/stack';
import { SidebarCategoryRoutes } from "../../../../src/constants";
import Changelog from '@twilio-paste/stack/CHANGELOG.md';
import * as React from 'react';
export default {
  graphql,
  Anchor,
  Box,
  Button,
  Card,
  FormInput,
  FormLabel,
  Heading,
  Paragraph,
  Stack,
  SidebarCategoryRoutes,
  Changelog,
  React
};