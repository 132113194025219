import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/ChangelogLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Change Log`}</h1>
    <h2>{`7.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/0eded1fd63f081ba9aeab5b5946218e1c5b9b316"
          }}><inlineCode parentName="a">{`0eded1fd`}</inlineCode></a>{` `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/pull/1319"
          }}>{`#1319`}</a>{` Thanks `}<a parentName="p" {...{
            "href": "https://github.com/SiTaggart"
          }}>{`@SiTaggart`}</a>{`! - Change internal dependencies to have minor range matching on version numbers`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Updated dependencies [`}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/0eded1fd63f081ba9aeab5b5946218e1c5b9b316"
          }}><inlineCode parentName="a">{`0eded1fd`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/1bcb8b3093920fdd871f8a13b498a8eb99201200"
          }}><inlineCode parentName="a">{`1bcb8b30`}</inlineCode></a>{`]:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:button@7.0.0"
            }}>{`button@7.0.0`}</a></li>
        </ul>
      </li>
    </ul>
    <h2>{`6.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/514bd5aa9fed6581bbc4c1de649457bcc8e87b3c"
        }}><inlineCode parentName="a">{`514bd5aa`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/514bd5aa9fed6581bbc4c1de649457bcc8e87b3c"
        }}><inlineCode parentName="a">{`514bd5aa`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.1"
            }}>{`theme@5.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@5.1.1"
            }}>{`icons@5.1.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:button@6.0.1"
            }}>{`button@6.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:help-text@6.0.1"
            }}>{`help-text@6.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input@3.0.3"
            }}>{`input@3.0.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input-box@4.0.2"
            }}>{`input-box@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:label@6.0.1"
            }}>{`label@6.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:screen-reader-only@6.0.1"
            }}>{`screen-reader-only@6.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@7.0.1"
            }}>{`spinner@7.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:flex@2.0.2"
            }}>{`flex@2.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@2.0.2"
            }}>{`stack@2.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.2"
            }}>{`box@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:text@4.0.1"
            }}>{`text@4.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.1"
            }}>{`style-props@3.0.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`6.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/8b5a8592848abba3975717c33ed9aed93f376087"
        }}><inlineCode parentName="a">{`8b5a8592`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@5.1.0"
            }}>{`icons@5.1.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:button@6.0.0"
            }}>{`button@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:help-text@6.0.0"
            }}>{`help-text@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:screen-reader-only@6.0.0"
            }}>{`screen-reader-only@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@7.0.0"
            }}>{`spinner@7.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:label@6.0.0"
            }}>{`label@6.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`5.0.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/14d339680a7d3fb53b62b70762e4d2e786b473d6"
        }}><inlineCode parentName="a">{`14d33968`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input@3.0.2"
            }}>{`input@3.0.2`}</a></li>
        </ul></li>
    </ul>
    <h2>{`5.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/509eba7a95325dd6f8adc3e905e22f92b7f004a9"
        }}><inlineCode parentName="a">{`509eba7a`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.1"
            }}>{`box@4.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:button@5.0.1"
            }}>{`button@5.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:help-text@5.0.1"
            }}>{`help-text@5.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input@3.0.1"
            }}>{`input@3.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input-box@4.0.1"
            }}>{`input-box@4.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:label@5.0.1"
            }}>{`label@5.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:screen-reader-only@5.0.1"
            }}>{`screen-reader-only@5.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@6.0.1"
            }}>{`spinner@6.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:flex@2.0.1"
            }}>{`flex@2.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@2.0.1"
            }}>{`stack@2.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@5.0.1"
            }}>{`icons@5.0.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`5.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b1b4ac39ac70ec70bd9e150f2b8f94a123f1e1a6"
        }}><inlineCode parentName="a">{`b1b4ac39`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4c9ed5cac36ada218824d3e24bf45d4a03a12272"
        }}><inlineCode parentName="a">{`4c9ed5ca`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/26c828d8681e0e671f28b5f2856cd1803f13953f"
        }}><inlineCode parentName="a">{`26c828d8`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:label@5.0.0"
            }}>{`label@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.6.0"
            }}>{`design-tokens@6.6.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.0"
            }}>{`theme@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:button@5.0.0"
            }}>{`button@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:help-text@5.0.0"
            }}>{`help-text@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input@3.0.0"
            }}>{`input@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input-box@4.0.0"
            }}>{`input-box@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:screen-reader-only@5.0.0"
            }}>{`screen-reader-only@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@6.0.0"
            }}>{`spinner@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:flex@2.0.0"
            }}>{`flex@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@2.0.0"
            }}>{`stack@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.0"
            }}>{`box@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:text@4.0.0"
            }}>{`text@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@5.0.0"
            }}>{`icons@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.0"
            }}>{`style-props@3.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`4.0.3`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ac38757f0e426531862d5c562a2f2300cfa30592"
        }}><inlineCode parentName="a">{`ac38757f`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:help-text@4.0.3"
            }}>{`help-text@4.0.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input@2.0.2"
            }}>{`input@2.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:screen-reader-only@4.0.3"
            }}>{`screen-reader-only@4.0.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:flex@1.0.2"
            }}>{`flex@1.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@1.0.2"
            }}>{`stack@1.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:combobox-primitive@0.2.3"
            }}>{`combobox-primitive@0.2.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:label@4.0.3"
            }}>{`label@4.0.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:button@4.0.3"
            }}>{`button@4.0.3`}</a></li>
        </ul></li>
    </ul>
    <h2>{`4.0.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/944c340790b932f4714b0e6075c5641ecdbee9d6"
        }}><inlineCode parentName="a">{`944c3407`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/af77939865e030fb356dbc4193c8324e6261627a"
        }}><inlineCode parentName="a">{`af779398`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.5.2"
            }}>{`design-tokens@6.5.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@4.0.2"
            }}>{`icons@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:button@4.0.2"
            }}>{`button@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:help-text@4.0.2"
            }}>{`help-text@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input@2.0.1"
            }}>{`input@2.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input-box@3.0.1"
            }}>{`input-box@3.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:label@4.0.2"
            }}>{`label@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:screen-reader-only@4.0.2"
            }}>{`screen-reader-only@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@5.0.2"
            }}>{`spinner@5.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:flex@1.0.1"
            }}>{`flex@1.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@1.0.1"
            }}>{`stack@1.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@3.0.1"
            }}>{`box@3.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:text@3.0.1"
            }}>{`text@3.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.1"
            }}>{`style-props@2.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.1"
            }}>{`theme@4.3.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`4.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7340a6a552ddc45e35a786cbee4106998911458b"
        }}><inlineCode parentName="a">{`7340a6a5`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@4.0.1"
            }}>{`icons@4.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:button@4.0.1"
            }}>{`button@4.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:help-text@4.0.1"
            }}>{`help-text@4.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:screen-reader-only@4.0.1"
            }}>{`screen-reader-only@4.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@5.0.1"
            }}>{`spinner@5.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:label@4.0.1"
            }}>{`label@4.0.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`4.0.0`}</h2>
    <h3>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f1675586933bcce71a6b5c5fec7d939735763a73"
        }}><inlineCode parentName="a">{`f1675586`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1117"
        }}>{`#1117`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/TheSisb"
        }}>{`@TheSisb`}</a>{`! - Comboboxes are now virtualized, but only when the 'groupItemsBy' and 'optionTemplate' props are not provided.`}</li>
    </ul>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f1675586933bcce71a6b5c5fec7d939735763a73"
        }}><inlineCode parentName="a">{`f1675586`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.0"
            }}>{`theme@4.3.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:button@4.0.0"
            }}>{`button@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:help-text@4.0.0"
            }}>{`help-text@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input@2.0.0"
            }}>{`input@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input-box@3.0.0"
            }}>{`input-box@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:label@4.0.0"
            }}>{`label@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:screen-reader-only@4.0.0"
            }}>{`screen-reader-only@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@5.0.0"
            }}>{`spinner@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:flex@1.0.0"
            }}>{`flex@1.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@1.0.0"
            }}>{`stack@1.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@3.0.0"
            }}>{`box@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:text@3.0.0"
            }}>{`text@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@4.0.0"
            }}>{`icons@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.0"
            }}>{`style-props@2.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`3.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{` `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/pull/1158"
          }}>{`#1158`}</a>{` Thanks `}<a parentName="p" {...{
            "href": "https://github.com/richbachman"
          }}>{`@richbachman`}</a>{`! - Pinned all twilio-paste package versions in order to keep them in sync with core when they are updated by changesets.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Updated dependencies [`}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`]:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:label@3.0.1"
            }}>{`label@3.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@0.3.2"
            }}>{`stack@0.3.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.2.2"
            }}>{`theme@4.2.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:button@3.0.1"
            }}>{`button@3.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input-box@2.1.2"
            }}>{`input-box@2.1.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:combobox-primitive@0.2.2"
            }}>{`combobox-primitive@0.2.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:flex@0.5.2"
            }}>{`flex@0.5.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@1.9.2"
            }}>{`style-props@1.9.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:input@1.2.3"
            }}>{`input@1.2.3`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@2.13.2"
            }}>{`box@2.13.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:screen-reader-only@3.0.1"
            }}>{`screen-reader-only@3.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@3.13.1"
            }}>{`icons@3.13.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:help-text@3.0.1"
            }}>{`help-text@3.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:text@2.5.2"
            }}>{`text@2.5.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@4.0.1"
            }}>{`spinner@4.0.1`}</a></li>
        </ul>
      </li>
    </ul>
    <h2>{`3.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4114dac24d6b89f10aeeaeda2220825b9e146169"
        }}><inlineCode parentName="a">{`4114dac2`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@3.13.0"
            }}>{`icons@3.13.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:button@3.0.0"
            }}>{`button@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:help-text@3.0.0"
            }}>{`help-text@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:screen-reader-only@3.0.0"
            }}>{`screen-reader-only@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@4.0.0"
            }}>{`spinner@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:label@3.0.0"
            }}>{`label@3.0.0`}</a></li>
        </ul></li>
    </ul>
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@2.1.0...@twilio-paste/combobox@2.1.1"
      }}>{`2.1.1`}</a>{` (2021-01-25)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` genericise some prop iterfaces for friendlier typings (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/66f3174fddef030ad6c66a65b77725377c7b556e"
        }}>{`66f3174`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@2.0.7...@twilio-paste/combobox@2.1.0"
      }}>{`2.1.0`}</a>{` (2021-01-21)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ESBuild for builds (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1084"
        }}>{`#1084`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/053646011508be10477d5b732269cdb0419235d7"
        }}>{`0536460`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@2.0.6...@twilio-paste/combobox@2.0.7"
      }}>{`2.0.7`}</a>{` (2021-01-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@2.0.5...@twilio-paste/combobox@2.0.6"
      }}>{`2.0.6`}</a>{` (2021-01-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@2.0.4...@twilio-paste/combobox@2.0.5"
      }}>{`2.0.5`}</a>{` (2021-01-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@2.0.3...@twilio-paste/combobox@2.0.4"
      }}>{`2.0.4`}</a>{` (2021-01-08)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` fix grouped indexes so keyboard selection works (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1070"
        }}>{`#1070`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e5b1a9fd2048256dc3b72da1ccc94812cb6fd803"
        }}>{`e5b1a9f`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@2.0.2...@twilio-paste/combobox@2.0.3"
      }}>{`2.0.3`}</a>{` (2021-01-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@2.0.1...@twilio-paste/combobox@2.0.2"
      }}>{`2.0.2`}</a>{` (2021-01-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@2.0.0...@twilio-paste/combobox@2.0.1"
      }}>{`2.0.1`}</a>{` (2020-12-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.31...@twilio-paste/combobox@2.0.0"
      }}>{`2.0.0`}</a>{` (2020-12-17)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` add forwardRef (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ca8ef5b4fc2b469cb23b6fea5ca4d9f12069f900"
        }}>{`ca8ef5b`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` component is now using fowardRef`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.30...@twilio-paste/combobox@1.4.31"
      }}>{`1.4.31`}</a>{` (2020-12-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.29...@twilio-paste/combobox@1.4.30"
      }}>{`1.4.30`}</a>{` (2020-12-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.28...@twilio-paste/combobox@1.4.29"
      }}>{`1.4.29`}</a>{` (2020-12-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.27...@twilio-paste/combobox@1.4.28"
      }}>{`1.4.28`}</a>{` (2020-12-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.26...@twilio-paste/combobox@1.4.27"
      }}>{`1.4.27`}</a>{` (2020-12-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.25...@twilio-paste/combobox@1.4.26"
      }}>{`1.4.26`}</a>{` (2020-12-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.24...@twilio-paste/combobox@1.4.25"
      }}>{`1.4.25`}</a>{` (2020-12-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.23...@twilio-paste/combobox@1.4.24"
      }}>{`1.4.24`}</a>{` (2020-11-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.22...@twilio-paste/combobox@1.4.23"
      }}>{`1.4.23`}</a>{` (2020-11-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.21...@twilio-paste/combobox@1.4.22"
      }}>{`1.4.22`}</a>{` (2020-11-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.20...@twilio-paste/combobox@1.4.21"
      }}>{`1.4.21`}</a>{` (2020-11-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.19...@twilio-paste/combobox@1.4.20"
      }}>{`1.4.20`}</a>{` (2020-11-10)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` bump listbox zIndex token (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/917"
        }}>{`#917`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e38f7e449936645a79b99b8b6869c5378b2e0854"
        }}>{`e38f7e4`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.18...@twilio-paste/combobox@1.4.19"
      }}>{`1.4.19`}</a>{` (2020-11-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.17...@twilio-paste/combobox@1.4.18"
      }}>{`1.4.18`}</a>{` (2020-11-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.16...@twilio-paste/combobox@1.4.17"
      }}>{`1.4.17`}</a>{` (2020-11-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.15...@twilio-paste/combobox@1.4.16"
      }}>{`1.4.16`}</a>{` (2020-11-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.14...@twilio-paste/combobox@1.4.15"
      }}>{`1.4.15`}</a>{` (2020-10-30)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.13...@twilio-paste/combobox@1.4.14"
      }}>{`1.4.14`}</a>{` (2020-10-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.12...@twilio-paste/combobox@1.4.13"
      }}>{`1.4.13`}</a>{` (2020-10-28)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` ensure listbox optionn ids in groups are always unique (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/893"
        }}>{`#893`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/91903a6b7fe2682b6c132722a6e82aa141ec497f"
        }}>{`91903a6`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.11...@twilio-paste/combobox@1.4.12"
      }}>{`1.4.12`}</a>{` (2020-10-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.10...@twilio-paste/combobox@1.4.11"
      }}>{`1.4.11`}</a>{` (2020-10-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.9...@twilio-paste/combobox@1.4.10"
      }}>{`1.4.10`}</a>{` (2020-10-21)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.8...@twilio-paste/combobox@1.4.9"
      }}>{`1.4.9`}</a>{` (2020-10-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.7...@twilio-paste/combobox@1.4.8"
      }}>{`1.4.8`}</a>{` (2020-10-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.6...@twilio-paste/combobox@1.4.7"
      }}>{`1.4.7`}</a>{` (2020-10-13)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` hide chevron if set to autocomplete (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/833"
        }}>{`#833`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/41de66566a317e0a282fa4200660ce134739e6b7"
        }}>{`41de665`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.5...@twilio-paste/combobox@1.4.6"
      }}>{`1.4.6`}</a>{` (2020-10-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.4...@twilio-paste/combobox@1.4.5"
      }}>{`1.4.5`}</a>{` (2020-10-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.3...@twilio-paste/combobox@1.4.4"
      }}>{`1.4.4`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.2...@twilio-paste/combobox@1.4.3"
      }}>{`1.4.3`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.1...@twilio-paste/combobox@1.4.2"
      }}>{`1.4.2`}</a>{` (2020-10-07)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` correctly handle empty values to passed in props (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e83e572f13e4fe6b406e3d05d82401189043e2d9"
        }}>{`e83e572`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.4.0...@twilio-paste/combobox@1.4.1"
      }}>{`1.4.1`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.24...@twilio-paste/combobox@1.4.0"
      }}>{`1.4.0`}</a>{` (2020-10-07)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`comboxbox:`}</strong>{` update to use form components (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a7c53cfd8d1191ec44fa55c3d7d0c77d0e3267e9"
        }}>{`a7c53cf`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.23...@twilio-paste/combobox@1.3.24"
      }}>{`1.3.24`}</a>{` (2020-09-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.22...@twilio-paste/combobox@1.3.23"
      }}>{`1.3.23`}</a>{` (2020-09-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.21...@twilio-paste/combobox@1.3.22"
      }}>{`1.3.22`}</a>{` (2020-09-21)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.20...@twilio-paste/combobox@1.3.21"
      }}>{`1.3.21`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.19...@twilio-paste/combobox@1.3.20"
      }}>{`1.3.20`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.18...@twilio-paste/combobox@1.3.19"
      }}>{`1.3.19`}</a>{` (2020-09-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.17...@twilio-paste/combobox@1.3.18"
      }}>{`1.3.18`}</a>{` (2020-09-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.16...@twilio-paste/combobox@1.3.17"
      }}>{`1.3.17`}</a>{` (2020-09-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.15...@twilio-paste/combobox@1.3.16"
      }}>{`1.3.16`}</a>{` (2020-09-09)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` handling refs in downshift v6 is different (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/526cfc9b44204c018e2485c77057b4a29da579c7"
        }}>{`526cfc9`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.14...@twilio-paste/combobox@1.3.15"
      }}>{`1.3.15`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.13...@twilio-paste/combobox@1.3.14"
      }}>{`1.3.14`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.12...@twilio-paste/combobox@1.3.13"
      }}>{`1.3.13`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.11...@twilio-paste/combobox@1.3.12"
      }}>{`1.3.12`}</a>{` (2020-09-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.10...@twilio-paste/combobox@1.3.11"
      }}>{`1.3.11`}</a>{` (2020-09-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.9...@twilio-paste/combobox@1.3.10"
      }}>{`1.3.10`}</a>{` (2020-09-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.8...@twilio-paste/combobox@1.3.9"
      }}>{`1.3.9`}</a>{` (2020-09-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.7...@twilio-paste/combobox@1.3.8"
      }}>{`1.3.8`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.6...@twilio-paste/combobox@1.3.7"
      }}>{`1.3.7`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.5...@twilio-paste/combobox@1.3.6"
      }}>{`1.3.6`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.4...@twilio-paste/combobox@1.3.5"
      }}>{`1.3.5`}</a>{` (2020-08-27)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.3...@twilio-paste/combobox@1.3.4"
      }}>{`1.3.4`}</a>{` (2020-08-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.2...@twilio-paste/combobox@1.3.3"
      }}>{`1.3.3`}</a>{` (2020-08-21)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` provide a way to use the state hook (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/8c4cb813dde677bec0f544470d2c35f53a87d22f"
        }}>{`8c4cb81`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.1...@twilio-paste/combobox@1.3.2"
      }}>{`1.3.2`}</a>{` (2020-08-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.3.0...@twilio-paste/combobox@1.3.1"
      }}>{`1.3.1`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.2.2...@twilio-paste/combobox@1.3.0"
      }}>{`1.3.0`}</a>{` (2020-08-12)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` add inverse variant (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/603bccea4d6e31b365d5a2c07ab51196048bf757"
        }}>{`603bcce`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.2.1...@twilio-paste/combobox@1.2.2"
      }}>{`1.2.2`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.2.0...@twilio-paste/combobox@1.2.1"
      }}>{`1.2.1`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.1.0...@twilio-paste/combobox@1.2.0"
      }}>{`1.2.0`}</a>{` (2020-08-07)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` unified design language update (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d2e1fc114bd125c0a868b23e064d1afc0ca11635"
        }}>{`d2e1fc1`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.0.1...@twilio-paste/combobox@1.1.0"
      }}>{`1.1.0`}</a>{` (2020-08-06)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` update combobox styles for UDL (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e65ea9aa74a14e2dd73f3b84f10546a1225b2199"
        }}>{`e65ea9a`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@1.0.0...@twilio-paste/combobox@1.0.1"
      }}>{`1.0.1`}</a>{` (2020-08-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.3.4...@twilio-paste/combobox@1.0.0"
      }}>{`1.0.0`}</a>{` (2020-08-05)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` use display=block by default and rename 'iconColor' prop to 'color' (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/637"
        }}>{`#637`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/82beef487c514b2eab5c40b1ef1409842dd4ca82"
        }}>{`82beef4`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`icons:`}</strong>{` This change renames a key prop and changes the display mode from inline-flex to block`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix: use new icon prop (iconColor -> color)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`test: update snapshots`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(icons): update readme to mention correct display value`}</p>
      </li>
    </ul>
    <p>{`Co-authored-by: Rich Bachman `}<a parentName="p" {...{
        "href": "mailto:rb@richbachman.com"
      }}>{`rb@richbachman.com`}</a></p>
    <ul>
      <li parentName="ul">{`chore(website): update icon doc prop table`}</li>
    </ul>
    <p>{`Co-authored-by: Rich Bachman `}<a parentName="p" {...{
        "href": "mailto:rb@richbachman.com"
      }}>{`rb@richbachman.com`}</a></p>
    <p>{`Co-authored-by: Rich Bachman `}<a parentName="p" {...{
        "href": "mailto:rb@richbachman.com"
      }}>{`rb@richbachman.com`}</a></p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.3.3...@twilio-paste/combobox@0.3.4"
      }}>{`0.3.4`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.3.2...@twilio-paste/combobox@0.3.3"
      }}>{`0.3.3`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.3.1...@twilio-paste/combobox@0.3.2"
      }}>{`0.3.2`}</a>{` (2020-07-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.3.0...@twilio-paste/combobox@0.3.1"
      }}>{`0.3.1`}</a>{` (2020-07-30)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.20...@twilio-paste/combobox@0.3.0"
      }}>{`0.3.0`}</a>{` (2020-07-29)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` add option groups and controlled input (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/6e3b21e3142a76344ccd2e264ce80ba7427fdb59"
        }}>{`6e3b21e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.19...@twilio-paste/combobox@0.2.20"
      }}>{`0.2.20`}</a>{` (2020-07-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.18...@twilio-paste/combobox@0.2.19"
      }}>{`0.2.19`}</a>{` (2020-07-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.17...@twilio-paste/combobox@0.2.18"
      }}>{`0.2.18`}</a>{` (2020-07-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.16...@twilio-paste/combobox@0.2.17"
      }}>{`0.2.17`}</a>{` (2020-07-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.15...@twilio-paste/combobox@0.2.16"
      }}>{`0.2.16`}</a>{` (2020-07-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.14...@twilio-paste/combobox@0.2.15"
      }}>{`0.2.15`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.13...@twilio-paste/combobox@0.2.14"
      }}>{`0.2.14`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.12...@twilio-paste/combobox@0.2.13"
      }}>{`0.2.13`}</a>{` (2020-07-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.11...@twilio-paste/combobox@0.2.12"
      }}>{`0.2.12`}</a>{` (2020-07-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.10...@twilio-paste/combobox@0.2.11"
      }}>{`0.2.11`}</a>{` (2020-07-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.9...@twilio-paste/combobox@0.2.10"
      }}>{`0.2.10`}</a>{` (2020-07-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.8...@twilio-paste/combobox@0.2.9"
      }}>{`0.2.9`}</a>{` (2020-06-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.7...@twilio-paste/combobox@0.2.8"
      }}>{`0.2.8`}</a>{` (2020-06-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.6...@twilio-paste/combobox@0.2.7"
      }}>{`0.2.7`}</a>{` (2020-06-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.5...@twilio-paste/combobox@0.2.6"
      }}>{`0.2.6`}</a>{` (2020-06-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.4...@twilio-paste/combobox@0.2.5"
      }}>{`0.2.5`}</a>{` (2020-06-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.3...@twilio-paste/combobox@0.2.4"
      }}>{`0.2.4`}</a>{` (2020-06-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.2...@twilio-paste/combobox@0.2.3"
      }}>{`0.2.3`}</a>{` (2020-06-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.1...@twilio-paste/combobox@0.2.2"
      }}>{`0.2.2`}</a>{` (2020-06-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.2.0...@twilio-paste/combobox@0.2.1"
      }}>{`0.2.1`}</a>{` (2020-06-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.1.8...@twilio-paste/combobox@0.2.0"
      }}>{`0.2.0`}</a>{` (2020-06-08)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` expose onHighlightedIndexChange, onIsOpenChange props (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/5683733f07b81a7cd88eab60c00fcc1f6217392a"
        }}>{`5683733`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.1.7...@twilio-paste/combobox@0.1.8"
      }}>{`0.1.8`}</a>{` (2020-06-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.1.6...@twilio-paste/combobox@0.1.7"
      }}>{`0.1.7`}</a>{` (2020-06-04)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` sync styles with Select styles (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/291b68e662327a404fcf443a776fd8a2a26e8aa5"
        }}>{`291b68e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.1.5...@twilio-paste/combobox@0.1.6"
      }}>{`0.1.6`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.1.4...@twilio-paste/combobox@0.1.5"
      }}>{`0.1.5`}</a>{` (2020-06-01)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` expose onSelectedItemChange and UseComboboxProp (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ca07615a6f2968ff59bb26222221f0f725ddb25c"
        }}>{`ca07615`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.1.3...@twilio-paste/combobox@0.1.4"
      }}>{`0.1.4`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.1.2...@twilio-paste/combobox@0.1.3"
      }}>{`0.1.3`}</a>{` (2020-05-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.1.1...@twilio-paste/combobox@0.1.2"
      }}>{`0.1.2`}</a>{` (2020-05-27)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.1.0...@twilio-paste/combobox@0.1.1"
      }}>{`0.1.1`}</a>{` (2020-05-27)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.0.2...@twilio-paste/combobox@0.1.0"
      }}>{`0.1.0`}</a>{` (2020-05-22)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`combobox:`}</strong>{` add combobox package (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a0d2bfa955b95a54e61ef725c3cae2e6eb7f85ab"
        }}>{`a0d2bfa`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/combobox@0.0.1...@twilio-paste/combobox@0.0.2"
      }}>{`0.0.2`}</a>{` (2019-10-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>
    <h2>{`0.0.1 (2019-08-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/combobox`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      