import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/ChangelogLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Change Log`}</h1>
    <h2>{`4.0.3`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0eded1fd63f081ba9aeab5b5946218e1c5b9b316"
        }}><inlineCode parentName="a">{`0eded1fd`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1319"
        }}>{`#1319`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/SiTaggart"
        }}>{`@SiTaggart`}</a>{`! - Change internal dependencies to have minor range matching on version numbers`}</li>
    </ul>
    <h2>{`4.0.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/514bd5aa9fed6581bbc4c1de649457bcc8e87b3c"
        }}><inlineCode parentName="a">{`514bd5aa`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.1"
            }}>{`theme@5.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.1"
            }}>{`style-props@3.0.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`4.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/509eba7a95325dd6f8adc3e905e22f92b7f004a9"
        }}><inlineCode parentName="a">{`509eba7a`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1253"
        }}>{`#1253`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/TheSisb"
        }}>{`@TheSisb`}</a>{`! - Added 'datetime' prop Typescript typing to Box`}</li>
    </ul>
    <h2>{`4.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4c9ed5cac36ada218824d3e24bf45d4a03a12272"
        }}><inlineCode parentName="a">{`4c9ed5ca`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/26c828d8681e0e671f28b5f2856cd1803f13953f"
        }}><inlineCode parentName="a">{`26c828d8`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.6.0"
            }}>{`design-tokens@6.6.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.0"
            }}>{`theme@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.0"
            }}>{`style-props@3.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`3.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/944c340790b932f4714b0e6075c5641ecdbee9d6"
        }}><inlineCode parentName="a">{`944c3407`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.5.2"
            }}>{`design-tokens@6.5.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.1"
            }}>{`style-props@2.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.1"
            }}>{`theme@4.3.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`3.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f1675586933bcce71a6b5c5fec7d939735763a73"
        }}><inlineCode parentName="a">{`f1675586`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.0"
            }}>{`theme@4.3.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.0"
            }}>{`style-props@2.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`2.13.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{` `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/pull/1158"
          }}>{`#1158`}</a>{` Thanks `}<a parentName="p" {...{
            "href": "https://github.com/richbachman"
          }}>{`@richbachman`}</a>{`! - Pinned all twilio-paste package versions in order to keep them in sync with core when they are updated by changesets.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Updated dependencies [`}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`]:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.2.2"
            }}>{`theme@4.2.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@1.9.2"
            }}>{`style-props@1.9.2`}</a></li>
        </ul>
      </li>
    </ul>
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.13.0...@twilio-paste/box@2.13.1"
      }}>{`2.13.1`}</a>{` (2021-01-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.12.3...@twilio-paste/box@2.13.0"
      }}>{`2.13.0`}</a>{` (2021-01-21)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ESBuild for builds (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1084"
        }}>{`#1084`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/053646011508be10477d5b732269cdb0419235d7"
        }}>{`0536460`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.12.2...@twilio-paste/box@2.12.3"
      }}>{`2.12.3`}</a>{` (2021-01-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.12.1...@twilio-paste/box@2.12.2"
      }}>{`2.12.2`}</a>{` (2021-01-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.12.0...@twilio-paste/box@2.12.1"
      }}>{`2.12.1`}</a>{` (2020-12-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.11.13...@twilio-paste/box@2.12.0"
      }}>{`2.12.0`}</a>{` (2020-12-15)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` remove propType checking (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e7806727947732728dfeb86b1b953c76e2f5c766"
        }}>{`e780672`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.11.12...@twilio-paste/box@2.11.13"
      }}>{`2.11.13`}</a>{` (2020-12-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.11.11...@twilio-paste/box@2.11.12"
      }}>{`2.11.12`}</a>{` (2020-12-11)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` accomodate the lack of color key on theme (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/8c861a5b1d32593eeeffcc62c85f65cea57daf5c"
        }}>{`8c861a5`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.11.10...@twilio-paste/box@2.11.11"
      }}>{`2.11.11`}</a>{` (2020-12-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.11.9...@twilio-paste/box@2.11.10"
      }}>{`2.11.10`}</a>{` (2020-12-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.11.8...@twilio-paste/box@2.11.9"
      }}>{`2.11.9`}</a>{` (2020-11-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.11.7...@twilio-paste/box@2.11.8"
      }}>{`2.11.8`}</a>{` (2020-11-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.11.6...@twilio-paste/box@2.11.7"
      }}>{`2.11.7`}</a>{` (2020-11-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.11.5...@twilio-paste/box@2.11.6"
      }}>{`2.11.6`}</a>{` (2020-10-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.11.4...@twilio-paste/box@2.11.5"
      }}>{`2.11.5`}</a>{` (2020-10-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.11.3...@twilio-paste/box@2.11.4"
      }}>{`2.11.4`}</a>{` (2020-10-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.11.2...@twilio-paste/box@2.11.3"
      }}>{`2.11.3`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.11.1...@twilio-paste/box@2.11.2"
      }}>{`2.11.2`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.11.0...@twilio-paste/box@2.11.1"
      }}>{`2.11.1`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.10.6...@twilio-paste/box@2.11.0"
      }}>{`2.11.0`}</a>{` (2020-10-07)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` allow some select related props in the interface (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/60876f8ba398a6bc682344b4880b76bd1b6f2814"
        }}>{`60876f8`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.10.5...@twilio-paste/box@2.10.6"
      }}>{`2.10.6`}</a>{` (2020-09-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.10.4...@twilio-paste/box@2.10.5"
      }}>{`2.10.5`}</a>{` (2020-09-21)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.10.3...@twilio-paste/box@2.10.4"
      }}>{`2.10.4`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.10.2...@twilio-paste/box@2.10.3"
      }}>{`2.10.3`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.10.1...@twilio-paste/box@2.10.2"
      }}>{`2.10.2`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.10.0...@twilio-paste/box@2.10.1"
      }}>{`2.10.1`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.9.6...@twilio-paste/box@2.10.0"
      }}>{`2.10.0`}</a>{` (2020-09-03)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` add support for word-break prop (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/136893ef0a79c2f2228ed323b22b0f2d2980e149"
        }}>{`136893e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.9.5...@twilio-paste/box@2.9.6"
      }}>{`2.9.6`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.9.4...@twilio-paste/box@2.9.5"
      }}>{`2.9.5`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.9.3...@twilio-paste/box@2.9.4"
      }}>{`2.9.4`}</a>{` (2020-08-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.9.2...@twilio-paste/box@2.9.3"
      }}>{`2.9.3`}</a>{` (2020-08-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.9.1...@twilio-paste/box@2.9.2"
      }}>{`2.9.2`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.9.0...@twilio-paste/box@2.9.1"
      }}>{`2.9.1`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.8.3...@twilio-paste/box@2.9.0"
      }}>{`2.9.0`}</a>{` (2020-08-06)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` added two style props and refactored typings (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/06541c8f1e764661a664e8b6147d0530eafa6bce"
        }}>{`06541c8`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.8.2...@twilio-paste/box@2.8.3"
      }}>{`2.8.3`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.8.1...@twilio-paste/box@2.8.2"
      }}>{`2.8.2`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.8.0...@twilio-paste/box@2.8.1"
      }}>{`2.8.1`}</a>{` (2020-07-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.7.0...@twilio-paste/box@2.8.0"
      }}>{`2.8.0`}</a>{` (2020-07-30)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` add link attribute typings and backgroundAttachment (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/bc33774ab49156083dcdad1ea021fbb73468fac2"
        }}>{`bc33774`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` accept typography style props and space X/Y props (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/882dd4d6516e6732e8155096c666912a4c1a49cc"
        }}>{`882dd4d`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.6.5...@twilio-paste/box@2.7.0"
      }}>{`2.7.0`}</a>{` (2020-07-29)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`radio:`}</strong>{` allow disabling individual radios (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/599"
        }}>{`#599`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4c5b21b332eb74d7de181d64ea6a92b2c94d5235"
        }}>{`4c5b21b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.6.4...@twilio-paste/box@2.6.5"
      }}>{`2.6.5`}</a>{` (2020-07-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.6.3...@twilio-paste/box@2.6.4"
      }}>{`2.6.4`}</a>{` (2020-07-15)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` allow alt and src props on box (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0acbaff49c645c65699e827f479b711f0de7f594"
        }}>{`0acbaff`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.6.2...@twilio-paste/box@2.6.3"
      }}>{`2.6.3`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.6.1...@twilio-paste/box@2.6.2"
      }}>{`2.6.2`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.6.0...@twilio-paste/box@2.6.1"
      }}>{`2.6.1`}</a>{` (2020-07-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.5.5...@twilio-paste/box@2.6.0"
      }}>{`2.6.0`}</a>{` (2020-06-29)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` accept textTransform css prop (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/5cb9c8f8db3ec9f5b4ec6b9406db2173caa9ba0f"
        }}>{`5cb9c8f`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.5.4...@twilio-paste/box@2.5.5"
      }}>{`2.5.5`}</a>{` (2020-06-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.5.3...@twilio-paste/box@2.5.4"
      }}>{`2.5.4`}</a>{` (2020-06-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.5.2...@twilio-paste/box@2.5.3"
      }}>{`2.5.3`}</a>{` (2020-06-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.5.1...@twilio-paste/box@2.5.2"
      }}>{`2.5.2`}</a>{` (2020-06-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.5.0...@twilio-paste/box@2.5.1"
      }}>{`2.5.1`}</a>{` (2020-06-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.4.9...@twilio-paste/box@2.5.0"
      }}>{`2.5.0`}</a>{` (2020-06-10)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` enable css 'textOverflow' prop, for ellipsis (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/af0abd6428cc0c8f80f829500c59b635a2cac9fa"
        }}>{`af0abd6`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.4.8...@twilio-paste/box@2.4.9"
      }}>{`2.4.9`}</a>{` (2020-06-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.4.7...@twilio-paste/box@2.4.8"
      }}>{`2.4.8`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.4.6...@twilio-paste/box@2.4.7"
      }}>{`2.4.7`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.4.5...@twilio-paste/box@2.4.6"
      }}>{`2.4.6`}</a>{` (2020-05-27)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` allow text-decoration style prop (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/50aac91fc5c50a12f9a3043fb47ca76530a21e37"
        }}>{`50aac91`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.4.4...@twilio-paste/box@2.4.5"
      }}>{`2.4.5`}</a>{` (2020-05-20)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` allow ref on the interface of a box (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/5853b396cc83aa4008e3a1abcbce9df59b06a8d0"
        }}>{`5853b39`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.4.3...@twilio-paste/box@2.4.4"
      }}>{`2.4.4`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.4.2...@twilio-paste/box@2.4.3"
      }}>{`2.4.3`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.4.1...@twilio-paste/box@2.4.2"
      }}>{`2.4.2`}</a>{` (2020-05-04)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` switch to new spacing prop validators (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/045625e664a9558420f5a8832de0d785422c48d2"
        }}>{`045625e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.4.0...@twilio-paste/box@2.4.1"
      }}>{`2.4.1`}</a>{` (2020-05-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.3.1...@twilio-paste/box@2.4.0"
      }}>{`2.4.0`}</a>{` (2020-04-25)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` export interface prevent undefined pseudo selectors (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7e6363ef93d9f58b2461a78fd4120dfd7020dc1a"
        }}>{`7e6363e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.3.0...@twilio-paste/box@2.3.1"
      }}>{`2.3.1`}</a>{` (2020-04-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.2.10...@twilio-paste/box@2.3.0"
      }}>{`2.3.0`}</a>{` (2020-04-17)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`components:`}</strong>{` promote text components, spinner and primitive to prod (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/5605adc99021be20dafd3fedfeb745490a50c7e7"
        }}>{`5605adc`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.2.9...@twilio-paste/box@2.2.10"
      }}>{`2.2.10`}</a>{` (2020-04-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.2.8...@twilio-paste/box@2.2.9"
      }}>{`2.2.9`}</a>{` (2020-04-08)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` package dependencies updated to be correct (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/87f302ad26d33361e4daf54b4d6f0c1480b23138"
        }}>{`87f302a`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.2.7...@twilio-paste/box@2.2.8"
      }}>{`2.2.8`}</a>{` (2020-04-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.2.6...@twilio-paste/box@2.2.7"
      }}>{`2.2.7`}</a>{` (2020-04-07)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` add missing invalid, indeterminate, checked pseudo selectors (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a2d1f2887ae66a687195c914256949a3180a17d9"
        }}>{`a2d1f28`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.2.5...@twilio-paste/box@2.2.6"
      }}>{`2.2.6`}</a>{` (2020-04-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.2.4...@twilio-paste/box@2.2.5"
      }}>{`2.2.5`}</a>{` (2020-03-28)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` remove min-width:0 as a default (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7b8e55b5a47eb7a5cb052acac8450a980cb92a55"
        }}>{`7b8e55b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.2.3...@twilio-paste/box@2.2.4"
      }}>{`2.2.4`}</a>{` (2020-03-24)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` switch from theme-tokens to theme package dep (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/071e77affd04ee1b26a4dd26e4fc6179dd71ff93"
        }}>{`071e77a`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.2.2...@twilio-paste/box@2.2.3"
      }}>{`2.2.3`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.2.1...@twilio-paste/box@2.2.2"
      }}>{`2.2.2`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.2.0...@twilio-paste/box@2.2.1"
      }}>{`2.2.1`}</a>{` (2020-03-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.1.12...@twilio-paste/box@2.2.0"
      }}>{`2.2.0`}</a>{` (2020-03-06)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`add missing transitive peerDep on '@styled-system/css' (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/fdbb81370cda71037348742a680299ce481eabfd"
        }}>{`fdbb813`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` add pseudo-class props and missing props (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/9463ddd81a4a6ca2546940e70ca44a76baca074e"
        }}>{`9463ddd`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.1.11...@twilio-paste/box@2.1.12"
      }}>{`2.1.12`}</a>{` (2020-03-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.1.10...@twilio-paste/box@2.1.11"
      }}>{`2.1.11`}</a>{` (2020-02-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.1.9...@twilio-paste/box@2.1.10"
      }}>{`2.1.10`}</a>{` (2020-02-26)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`package dependencies and deprecation warnings (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/334"
        }}>{`#334`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0e88338511e6835a79eb0a9cea8d5b3a1cdf0a88"
        }}>{`0e88338`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.1.8...@twilio-paste/box@2.1.9"
      }}>{`2.1.9`}</a>{` (2020-02-14)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` add missing peer deps (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2281923bd7ab169a99cefb2dd2a9d995d8751a02"
        }}>{`2281923`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.1.7...@twilio-paste/box@2.1.8"
      }}>{`2.1.8`}</a>{` (2020-02-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.1.6...@twilio-paste/box@2.1.7"
      }}>{`2.1.7`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.1.5...@twilio-paste/box@2.1.6"
      }}>{`2.1.6`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.1.4...@twilio-paste/box@2.1.5"
      }}>{`2.1.5`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.1.3...@twilio-paste/box@2.1.4"
      }}>{`2.1.4`}</a>{` (2020-02-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.1.2...@twilio-paste/box@2.1.3"
      }}>{`2.1.3`}</a>{` (2020-01-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.1.1...@twilio-paste/box@2.1.2"
      }}>{`2.1.2`}</a>{` (2020-01-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.1.0...@twilio-paste/box@2.1.1"
      }}>{`2.1.1`}</a>{` (2019-12-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.0.4...@twilio-paste/box@2.1.0"
      }}>{`2.1.0`}</a>{` (2019-12-19)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` use style-props package, create method to safelySpreadProps (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/dc9f7fbc89d7ccfc382dc74185e8a699c658d55e"
        }}>{`dc9f7fb`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.0.3...@twilio-paste/box@2.0.4"
      }}>{`2.0.4`}</a>{` (2019-12-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.0.2...@twilio-paste/box@2.0.3"
      }}>{`2.0.3`}</a>{` (2019-11-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.0.1...@twilio-paste/box@2.0.2"
      }}>{`2.0.2`}</a>{` (2019-11-18)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` update some blues, reds, and focus shadow (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/185"
        }}>{`#185`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/98d70cc046db0284e151fab7f427ee16b8874c4a"
        }}>{`98d70cc`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` remove vague anchor do dont (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/177"
        }}>{`#177`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/8530c5679b48da46ddc82a9a7154bca78cacc30a"
        }}>{`8530c56`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@2.0.0...@twilio-paste/box@2.0.1"
      }}>{`2.0.1`}</a>{` (2019-11-12)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`prettier updated, updating formatting (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/86448312b017f13720735525e516f086704b1813"
        }}>{`8644831`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@1.1.0...@twilio-paste/box@2.0.0"
      }}>{`2.0.0`}</a>{` (2019-11-11)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` delete old icons, add new streamline icons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/129"
        }}>{`#129`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/571791ded8ee4c55bb5a3dbcebee4b17b2c7c826"
        }}>{`571791d`}</a>{`)`}</li>
      <li parentName="ul">{`enable theme switching on the docsite (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/124"
        }}>{`#124`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/df797e57ff1268367053d717f3b3c2ca48a0aa4f"
        }}>{`df797e5`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`icons:`}</strong>{` removed all the inherited icons since we're moving to a new system`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(icons): update icon list for storybook`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): use new icon`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(storybook): use new icon for story`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(story): button icons should be 24px`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix: adjust icon size tokens`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat: add iconSizes as separate key in theme-tokens`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat(icons): icons package to use tokens and update icons`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): update to use tokens and new icons`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(button): use correct spinner size`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(icons): major icons package fixes`}</p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`huge overhaul to build process`}</li>
      <li parentName="ul">{`now uses rollup`}</li>
      <li parentName="ul">{`builds into cjs and esm directories`}</li>
      <li parentName="ul">{`made the package publishable to npm`}</li>
      <li parentName="ul">{`moved svg folder out of src, now src only holds react stuff`}</li>
      <li parentName="ul">{`updated scripts to use new paths and cleaned up the code`}</li>
      <li parentName="ul">{`programmatically generates rollup config from the icon-list command`}</li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`chore: add new icons dist folders to gitignore`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix: spinner and button icon usage`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat(icons): add rollup icon list cache file`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(core-bundle): sort packages for consistent builds`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore: use `}<inlineCode parentName="p">{`esm`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`es`}</inlineCode>{` in rollup for correctness`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore: yarn.lock`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): lint error`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(icons): move rollup icon list`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(spinner): use types package in story`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): swap out destructured props for explicit props`}</p>
      </li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@1.0.0...@twilio-paste/box@1.1.0"
      }}>{`1.1.0`}</a>{` (2019-10-31)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`update types package to styled-system v5 format (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/154"
        }}>{`#154`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/117c41e463b11440582e740c28cc067c06d16e67"
        }}>{`117c41e`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@0.3.1...@twilio-paste/box@1.0.0"
      }}>{`1.0.0`}</a>{` (2019-10-29)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` polish and fix (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/125"
        }}>{`#125`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d151badadfa4db8ade1425df9336e0155ce09619"
        }}>{`d151bad`}</a>{`)`}</li>
      <li parentName="ul">{`finalise the Text component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/151"
        }}>{`#151`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b51c272cf420380f73c31c3474ebdf23cb7cad3a"
        }}>{`b51c272`}</a>{`)`}</li>
      <li parentName="ul">{`upgrading to styled-system v5 (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/18"
        }}>{`#18`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2b3ebd8b2f701a0c6e8b75ab6978ba936814f455"
        }}>{`2b3ebd8`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`box:`}</strong>{` many Box API changes`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(theme-tokens): add sizes key for styled-system responsiveness`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(absolute): update to newer Box`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(types): allow for responsive values via array`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(box): update story and light tweaks to OverflowProps`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore: update yarn.lock file`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(types): many more types fixes`}</p>
      </li>
    </ul>
    <p>{`refactor: moved ValueOf into types package`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`fix(box): eslint error in story`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(box): using the correct BorderProps definition`}</p>
      </li>
    </ul>
    <p>{`fix: we shouldn't allow users to pass the `}<inlineCode parentName="p">{`border`}</inlineCode>{` shorthand, it circumvents tokens`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`fix: lint errors`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(website): update box docs`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(storybook): use theme breakpoints`}</p>
      </li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/box@0.3.0...@twilio-paste/box@0.3.1"
      }}>{`0.3.1`}</a>{` (2019-09-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/box`}</p>
    <h1>{`0.3.0 (2019-08-15)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`change `}<a parentName="li" {...{
          "href": "https://github.com/paste"
        }}>{`@paste`}</a>{` scope to `}<a parentName="li" {...{
          "href": "https://github.com/twilio-paste"
        }}>{`@twilio-paste`}</a>{` (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/2"
        }}>{`#2`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1d8d2ff"
        }}>{`1d8d2ff`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ComponentHeader component, component overview fixes, more (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/20"
        }}>{`#20`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/875e124"
        }}>{`875e124`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` Add the tokens page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/19"
        }}>{`#19`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/878d3c5"
        }}>{`878d3c5`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      