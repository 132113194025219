// src/index.tsx
import {
  styled,
  css,
  compose,
  layout,
  space,
  background,
  typography,
  border,
  boxShadow,
  position,
  flexbox,
  system
} from "@twilio-paste/styling-library";

// src/PseudoPropStyles.ts
var PseudoPropStyles = {
  _hover: "&:hover",
  _active: "&:active, &[data-active=true]",
  _focus: "&:focus",
  _visited: "&:visited",
  _even: "&:nth-of-type(even)",
  _odd: "&:nth-of-type(odd)",
  _disabled: "&:disabled, &:disabled:focus, &:disabled:hover, &[aria-disabled=true], &[aria-disabled=true]:focus, &[aria-disabled=true]:hover",
  _checked: "&:checked, &[aria-checked=true]",
  _mixed: "&:indeterminate, &[aria-checked=mixed]",
  _selected: "&[aria-selected=true]",
  _invalid: "&:invalid, &[aria-invalid=true]",
  _pressed: "&[aria-pressed=true]",
  _readOnly: "&[aria-readonly=true], &[readonly]",
  _first: "&:first-of-type",
  _last: "&:last-of-type",
  _expanded: "&[aria-expanded=true]",
  _grabbed: "&[aria-grabbed=true]",
  _notFirst: "&:not(:first-of-type)",
  _notLast: "&:not(:last-of-type)",
  _groupHover: "[role=group]:hover &",
  _before: "&:before",
  _after: "&:after",
  _focusWithin: "&:focus-within",
  _placeholder: "&::placeholder",
  __moz_focus_inner: "&::-moz-focus-inner"
};

// src/SafelySpreadProps.ts
import {
  LAYOUT_PROPS,
  SPACE_PROPS,
  BACKGROUND_PROPS,
  BORDER_PROPS,
  SHADOW_PROPS,
  POSITION_PROPS,
  FLEXBOX_PROPS,
  TYPOGRAPHY_PROPS
} from "@twilio-paste/style-props";
var BOX_PROPS_TO_BLOCK = [
  ...LAYOUT_PROPS,
  ...SPACE_PROPS,
  ...BACKGROUND_PROPS,
  ...BORDER_PROPS,
  ...SHADOW_PROPS,
  ...POSITION_PROPS,
  ...FLEXBOX_PROPS,
  ...TYPOGRAPHY_PROPS,
  ...Object.keys(PseudoPropStyles),
  "backgroundColor",
  "borderColor",
  "className",
  "style"
];
var safelySpreadBoxProps = (props) => {
  return Object.keys(props).reduce((newProps, key) => {
    if (!BOX_PROPS_TO_BLOCK.includes(key)) {
      newProps[key] = props[key];
    }
    return newProps;
  }, {});
};

// src/index.tsx
var extraConfig = system({
  color: {
    property: "color",
    scale: "textColors"
  },
  backgroundColor: {
    property: "backgroundColor",
    scale: "backgroundColors"
  },
  borderColor: {
    property: "borderColor",
    scale: "borderColors"
  },
  borderBottomColor: {
    property: "borderBottomColor",
    scale: "borderColors"
  },
  borderLeftColor: {
    property: "borderLeftColor",
    scale: "borderColors"
  },
  borderRightColor: {
    property: "borderRightColor",
    scale: "borderColors"
  },
  borderTopColor: {
    property: "borderTopColor",
    scale: "borderColors"
  },
  content: true,
  cursor: true,
  appearance: true,
  transition: true,
  transform: true,
  animation: true,
  transformOrigin: true,
  visibility: true,
  userSelect: true,
  pointerEvents: true,
  boxSizing: true,
  resize: true,
  listStyleType: true,
  listStylePosition: true,
  listStyleImage: true,
  objectFit: true,
  objectPosition: true,
  outline: true,
  float: true,
  willChange: true,
  clip: true,
  backgroundAttachment: true,
  textAlign: true,
  textTransform: true,
  textDecoration: true,
  textOverflow: true,
  whiteSpace: true,
  wordBreak: true,
  wordWrap: true,
  opacity: true,
  borderCollapse: true,
  borderSpacing: true,
  tableLayout: true
});
var getPseudoStyles = (props) => {
  const pseudoProps = Object.keys(props).filter((propName) => propName.startsWith("_"));
  if (pseudoProps.length === 0) {
    return {};
  }
  const pseudoStyles = {};
  pseudoProps.forEach((pseudoProp) => {
    if (PseudoPropStyles[pseudoProp] != null) {
      pseudoStyles[PseudoPropStyles[pseudoProp]] = props[pseudoProp];
    }
  });
  return css(pseudoStyles);
};
var Box = styled.div({
  boxSizing: "border-box"
}, compose(space, layout, flexbox, background, border, boxShadow, position, typography, extraConfig), getPseudoStyles);
Box.displayName = "Box";
export {
  BOX_PROPS_TO_BLOCK,
  Box,
  safelySpreadBoxProps
};
