import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/ChangelogLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Change Log`}</h1>
    <h2>{`5.0.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0eded1fd63f081ba9aeab5b5946218e1c5b9b316"
        }}><inlineCode parentName="a">{`0eded1fd`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1319"
        }}>{`#1319`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/SiTaggart"
        }}>{`@SiTaggart`}</a>{`! - Change internal dependencies to have minor range matching on version numbers`}</li>
    </ul>
    <h2>{`5.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/514bd5aa9fed6581bbc4c1de649457bcc8e87b3c"
        }}><inlineCode parentName="a">{`514bd5aa`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1299"
        }}>{`#1299`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/SiTaggart"
        }}>{`@SiTaggart`}</a>{`! - Correctly type the generic theme type to only include icon sizes in the iconSizes key`}</li>
    </ul>
    <h2>{`5.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/26c828d8681e0e671f28b5f2856cd1803f13953f"
          }}><inlineCode parentName="a">{`26c828d8`}</inlineCode></a>{` `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/pull/1236"
          }}>{`#1236`}</a>{` Thanks `}<a parentName="p" {...{
            "href": "https://github.com/richbachman"
          }}>{`@richbachman`}</a>{`! - Changed SendGrid theme link test so it passes with the correct color.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Updated dependencies [`}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/4c9ed5cac36ada218824d3e24bf45d4a03a12272"
          }}><inlineCode parentName="a">{`4c9ed5ca`}</inlineCode></a>{`]:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.6.0"
            }}>{`design-tokens@6.6.0`}</a></li>
        </ul>
      </li>
    </ul>
    <h2>{`4.3.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/944c340790b932f4714b0e6075c5641ecdbee9d6"
        }}><inlineCode parentName="a">{`944c3407`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.5.2"
            }}>{`design-tokens@6.5.2`}</a></li>
        </ul></li>
    </ul>
    <h2>{`4.3.0`}</h2>
    <h3>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f1675586933bcce71a6b5c5fec7d939735763a73"
        }}><inlineCode parentName="a">{`f1675586`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1117"
        }}>{`#1117`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/TheSisb"
        }}>{`@TheSisb`}</a>{`! - Added 'remToPx' utility function.`}</li>
    </ul>
    <h2>{`4.2.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
        }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1158"
        }}>{`#1158`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/richbachman"
        }}>{`@richbachman`}</a>{`! - Pinned all twilio-paste package versions in order to keep them in sync with core when they are updated by changesets.y`}</li>
    </ul>
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@4.2.0...@twilio-paste/theme@4.2.1"
      }}>{`4.2.1`}</a>{` (2021-01-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@4.1.2...@twilio-paste/theme@4.2.0"
      }}>{`4.2.0`}</a>{` (2021-01-21)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ESBuild for builds (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1084"
        }}>{`#1084`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/053646011508be10477d5b732269cdb0419235d7"
        }}>{`0536460`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@4.1.1...@twilio-paste/theme@4.1.2"
      }}>{`4.1.2`}</a>{` (2021-01-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@4.1.0...@twilio-paste/theme@4.1.1"
      }}>{`4.1.1`}</a>{` (2021-01-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@4.0.1...@twilio-paste/theme@4.1.0"
      }}>{`4.1.0`}</a>{` (2020-12-17)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`theme:`}</strong>{` load fonts.css file from theme provider (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/acef2a54ad76639c8063d134c374792bd7cff181"
        }}>{`acef2a5`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@4.0.0...@twilio-paste/theme@4.0.1"
      }}>{`4.0.1`}</a>{` (2020-12-11)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`theme:`}</strong>{` add public access for npm publish (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ac823f88a028fb05f1e92282c4446d59a4db6551"
        }}>{`ac823f8`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.3.3...@twilio-paste/theme@4.0.0"
      }}>{`4.0.0`}</a>{` (2020-12-11)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`theme:`}</strong>{` add ability to generate a theme from design tokens (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/9b816639c6f63690048ce74afcaa80995d8fecb4"
        }}>{`9b81663`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`theme:`}</strong>{` add ability to map a theme back to design token groupings (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/8f7e6f9e3ee3215f76f58755944d571512007680"
        }}>{`8f7e6f9`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`theme:`}</strong>{` export paste global styles (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b0e5d75c7377661aea60adf3a68f2ffe63d00c3e"
        }}>{`b0e5d75`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`theme:`}</strong>{` remove the color key from theme (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d5f9e68c4030d0e80e55e39a6ea590b908c25bb1"
        }}>{`d5f9e68`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`theme:`}</strong>{` Color key is removed from the theme. This makes for a better experience as there are no duplicate colors. Each type of color has their own key on theme`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.3.2...@twilio-paste/theme@3.3.3"
      }}>{`3.3.3`}</a>{` (2020-12-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.3.1...@twilio-paste/theme@3.3.2"
      }}>{`3.3.2`}</a>{` (2020-11-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.3.0...@twilio-paste/theme@3.3.1"
      }}>{`3.3.1`}</a>{` (2020-11-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.27...@twilio-paste/theme@3.3.0"
      }}>{`3.3.0`}</a>{` (2020-11-05)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`theme:`}</strong>{` add prop to manually disable animations (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/902"
        }}>{`#902`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7d92127f9fe6a60970a60705a0a6b0e2218fb81a"
        }}>{`7d92127`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.26...@twilio-paste/theme@3.2.27"
      }}>{`3.2.27`}</a>{` (2020-10-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.25...@twilio-paste/theme@3.2.26"
      }}>{`3.2.26`}</a>{` (2020-10-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.24...@twilio-paste/theme@3.2.25"
      }}>{`3.2.25`}</a>{` (2020-10-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.23...@twilio-paste/theme@3.2.24"
      }}>{`3.2.24`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.22...@twilio-paste/theme@3.2.23"
      }}>{`3.2.23`}</a>{` (2020-10-07)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`theme:`}</strong>{` hide reach/dialog css warning (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/821"
        }}>{`#821`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e110d1edf9b93258bede7d6d566e9b9826d9c057"
        }}>{`e110d1e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.21...@twilio-paste/theme@3.2.22"
      }}>{`3.2.22`}</a>{` (2020-09-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.20...@twilio-paste/theme@3.2.21"
      }}>{`3.2.21`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.19...@twilio-paste/theme@3.2.20"
      }}>{`3.2.20`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.18...@twilio-paste/theme@3.2.19"
      }}>{`3.2.19`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.17...@twilio-paste/theme@3.2.18"
      }}>{`3.2.18`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.16...@twilio-paste/theme@3.2.17"
      }}>{`3.2.17`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.15...@twilio-paste/theme@3.2.16"
      }}>{`3.2.16`}</a>{` (2020-08-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.14...@twilio-paste/theme@3.2.15"
      }}>{`3.2.15`}</a>{` (2020-08-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.13...@twilio-paste/theme@3.2.14"
      }}>{`3.2.14`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.12...@twilio-paste/theme@3.2.13"
      }}>{`3.2.13`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.11...@twilio-paste/theme@3.2.12"
      }}>{`3.2.12`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.10...@twilio-paste/theme@3.2.11"
      }}>{`3.2.11`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.9...@twilio-paste/theme@3.2.10"
      }}>{`3.2.10`}</a>{` (2020-07-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.8...@twilio-paste/theme@3.2.9"
      }}>{`3.2.9`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.7...@twilio-paste/theme@3.2.8"
      }}>{`3.2.8`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.6...@twilio-paste/theme@3.2.7"
      }}>{`3.2.7`}</a>{` (2020-07-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.5...@twilio-paste/theme@3.2.6"
      }}>{`3.2.6`}</a>{` (2020-06-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.4...@twilio-paste/theme@3.2.5"
      }}>{`3.2.5`}</a>{` (2020-06-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.3...@twilio-paste/theme@3.2.4"
      }}>{`3.2.4`}</a>{` (2020-06-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.2...@twilio-paste/theme@3.2.3"
      }}>{`3.2.3`}</a>{` (2020-06-16)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`theme:`}</strong>{` export StyledBase (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/fb25eeb2228a128aa7cea54a9fcb0ff3bc80604b"
        }}>{`fb25eeb`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.1...@twilio-paste/theme@3.2.2"
      }}>{`3.2.2`}</a>{` (2020-06-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.2.0...@twilio-paste/theme@3.2.1"
      }}>{`3.2.1`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.1.7...@twilio-paste/theme@3.2.0"
      }}>{`3.2.0`}</a>{` (2020-06-01)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`theme:`}</strong>{` disable animations when prefers-reduced-motion, cleanup (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2cbe91982af27be4b3fccc62e3280b49a0f50539"
        }}>{`2cbe919`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.1.6...@twilio-paste/theme@3.1.7"
      }}>{`3.1.7`}</a>{` (2020-05-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.1.5...@twilio-paste/theme@3.1.6"
      }}>{`3.1.6`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.1.4...@twilio-paste/theme@3.1.5"
      }}>{`3.1.5`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.1.3...@twilio-paste/theme@3.1.4"
      }}>{`3.1.4`}</a>{` (2020-05-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.1.2...@twilio-paste/theme@3.1.3"
      }}>{`3.1.3`}</a>{` (2020-04-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.1.1...@twilio-paste/theme@3.1.2"
      }}>{`3.1.2`}</a>{` (2020-04-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.1.0...@twilio-paste/theme@3.1.1"
      }}>{`3.1.1`}</a>{` (2020-04-07)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`theme:`}</strong>{` export theme base styles to be reusable (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/28f04af18bdf4ab00d48cd522968dc27edfc22ad"
        }}>{`28f04af`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.0.8...@twilio-paste/theme@3.1.0"
      }}>{`3.1.0`}</a>{` (2020-03-24)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`theme:`}</strong>{` merge theme-tokens into theme package (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f913561b076b05be792e6fedae1c1727ecd282b7"
        }}>{`f913561`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.0.7...@twilio-paste/theme@3.0.8"
      }}>{`3.0.8`}</a>{` (2020-03-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.0.6...@twilio-paste/theme@3.0.7"
      }}>{`3.0.7`}</a>{` (2020-03-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.0.5...@twilio-paste/theme@3.0.6"
      }}>{`3.0.6`}</a>{` (2020-03-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.0.4...@twilio-paste/theme@3.0.5"
      }}>{`3.0.5`}</a>{` (2020-02-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.0.3...@twilio-paste/theme@3.0.4"
      }}>{`3.0.4`}</a>{` (2020-02-26)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`package dependencies and deprecation warnings (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/334"
        }}>{`#334`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0e88338511e6835a79eb0a9cea8d5b3a1cdf0a88"
        }}>{`0e88338`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.0.2...@twilio-paste/theme@3.0.3"
      }}>{`3.0.3`}</a>{` (2020-02-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.0.1...@twilio-paste/theme@3.0.2"
      }}>{`3.0.2`}</a>{` (2020-02-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@3.0.0...@twilio-paste/theme@3.0.1"
      }}>{`3.0.1`}</a>{` (2020-01-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@2.0.5...@twilio-paste/theme@3.0.0"
      }}>{`3.0.0`}</a>{` (2019-12-20)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`theme:`}</strong>{` add the console theme and update default theme (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/3951f823454be266dd2b6d4cb805c437df33d86b"
        }}>{`3951f82`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`theme:`}</strong>{` Theme provider default theme is now DEFAULT, not CONSOLE. If you are not providing a theme prop but want the console theme you must now explicitly set the theme to console`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@2.0.4...@twilio-paste/theme@2.0.5"
      }}>{`2.0.5`}</a>{` (2019-12-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@2.0.3...@twilio-paste/theme@2.0.4"
      }}>{`2.0.4`}</a>{` (2019-12-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@2.0.2...@twilio-paste/theme@2.0.3"
      }}>{`2.0.3`}</a>{` (2019-11-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@2.0.1...@twilio-paste/theme@2.0.2"
      }}>{`2.0.2`}</a>{` (2019-11-18)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` change color-text-link token values (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/172"
        }}>{`#172`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f0a215cb283f74f268387e744099e337c6489ebd"
        }}>{`f0a215c`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@2.0.0...@twilio-paste/theme@2.0.1"
      }}>{`2.0.1`}</a>{` (2019-11-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@1.0.1...@twilio-paste/theme@2.0.0"
      }}>{`2.0.0`}</a>{` (2019-11-11)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`global styles by passing props (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/169"
        }}>{`#169`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/65c15c090c88279faaa2d2c07f91ae8d845e3e20"
        }}>{`65c15c0`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` delete old icons, add new streamline icons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/129"
        }}>{`#129`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/571791ded8ee4c55bb5a3dbcebee4b17b2c7c826"
        }}>{`571791d`}</a>{`)`}</li>
      <li parentName="ul">{`enable theme switching on the docsite (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/124"
        }}>{`#124`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/df797e57ff1268367053d717f3b3c2ca48a0aa4f"
        }}>{`df797e5`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` removed all the inherited icons since we're moving to a new system`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@1.0.0...@twilio-paste/theme@1.0.1"
      }}>{`1.0.1`}</a>{` (2019-10-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/theme`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@0.4.1...@twilio-paste/theme@1.0.0"
      }}>{`1.0.0`}</a>{` (2019-10-29)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`design token and theme package documentation (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/103"
        }}>{`#103`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/30601132ccdec8f0fee79e0f293c80d0c0cff335"
        }}>{`3060113`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` polish and fix (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/125"
        }}>{`#125`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d151badadfa4db8ade1425df9336e0155ce09619"
        }}>{`d151bad`}</a>{`)`}</li>
      <li parentName="ul">{`upgrading to styled-system v5 (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/18"
        }}>{`#18`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2b3ebd8b2f701a0c6e8b75ab6978ba936814f455"
        }}>{`2b3ebd8`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`box:`}</strong>{` many Box API changes`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/theme@0.4.0...@twilio-paste/theme@0.4.1"
      }}>{`0.4.1`}</a>{` (2019-09-16)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` fix SendGrid token values (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/71"
        }}>{`#71`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/396fccf"
        }}>{`396fccf`}</a>{`)`}</li>
    </ul>
    <h1>{`0.4.0 (2019-08-15)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`change `}<a parentName="li" {...{
          "href": "https://github.com/paste"
        }}>{`@paste`}</a>{` scope to `}<a parentName="li" {...{
          "href": "https://github.com/twilio-paste"
        }}>{`@twilio-paste`}</a>{` (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/2"
        }}>{`#2`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1d8d2ff"
        }}>{`1d8d2ff`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ComponentHeader component, component overview fixes, more (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/20"
        }}>{`#20`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/875e124"
        }}>{`875e124`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` `}{`[DSYS-1046]`}{` website structure and navigation (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/306586e"
        }}>{`306586e`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      