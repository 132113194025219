export const borderRadius0 = "0";
export const borderRadius10 = "2px";
export const borderRadius20 = "4px";
export const borderRadius30 = "8px";
export const borderRadiusCircle = "50%";
export const borderWidth0 = "0";
export const borderWidth10 = "1px";
export const borderWidth20 = "2px";
export const borderWidth30 = "4px";
export const borderWidth40 = "8px";
export const colorBackground = "rgb(248, 248, 249)";
export const colorBackgroundBody = "rgb(255, 255, 255)";
export const colorBackgroundBodyInverse = "rgb(40, 42, 43)";
export const colorBackgroundBrand = "rgb(35, 54, 89)";
export const colorBackgroundBrandHighlight = "rgb(242, 47, 70)";
export const colorBackgroundBrandHighlightLightest = "rgba(242, 47, 70, 0.1)";
export const colorBackgroundDark = "rgb(228, 231, 233)";
export const colorBackgroundDarker = "rgb(200, 204, 207)";
export const colorBackgroundDarkest = "rgb(112, 117, 120)";
export const colorBackgroundDestructive = "rgb(226, 37, 37)";
export const colorBackgroundDestructiveDark = "rgb(146, 18, 0)";
export const colorBackgroundDestructiveDarker = "rgb(96, 1, 1)";
export const colorBackgroundDestructiveLight = "rgb(236, 182, 182)";
export const colorBackgroundDestructiveLighter = "rgb(245, 221, 221)";
export const colorBackgroundDestructiveLightest = "rgb(255, 233, 231)";
export const colorBackgroundError = "rgb(226, 37, 37)";
export const colorBackgroundErrorDark = "rgb(146, 18, 0)";
export const colorBackgroundErrorLightest = "rgb(255, 233, 231)";
export const colorBackgroundInverse = "rgba(255, 255, 255, 0.1)";
export const colorBackgroundInverseLight = "rgba(255, 255, 255, 0.2)";
export const colorBackgroundNeutralLightest = "rgb(245, 248, 255)";
export const colorBackgroundNew = "rgb(231, 220, 250)";
export const colorBackgroundOverlay = "rgba(40, 42, 43, 0.5)";
export const colorBackgroundPrimary = "rgb(2, 122, 197)";
export const colorBackgroundPrimaryDark = "rgb(0, 62, 130)";
export const colorBackgroundPrimaryDarker = "rgb(3, 44, 94)";
export const colorBackgroundPrimaryDarkest = "rgb(0, 9, 43)";
export const colorBackgroundPrimaryLight = "rgb(179, 211, 233)";
export const colorBackgroundPrimaryLighter = "rgb(220, 234, 244)";
export const colorBackgroundPrimaryLightest = "rgb(245, 248, 255)";
export const colorBackgroundRequired = "rgb(222, 88, 88)";
export const colorBackgroundRowStriped = "rgb(248, 248, 249)";
export const colorBackgroundSubaccount = "rgb(255, 241, 179)";
export const colorBackgroundSuccess = "rgb(0, 153, 74)";
export const colorBackgroundSuccessLightest = "rgb(224, 255, 239)";
export const colorBackgroundTrial = "rgb(199, 255, 226)";
export const colorBackgroundUser = "rgb(200, 175, 240)";
export const colorBackgroundWarning = "rgb(228, 98, 22)";
export const colorBackgroundWarningLightest = "rgb(255, 236, 216)";
export const colorBorder = "rgb(136, 140, 142)";
export const colorBorderDark = "rgb(136, 140, 142)";
export const colorBorderDestructive = "rgb(226, 37, 37)";
export const colorBorderDestructiveDark = "rgb(146, 18, 0)";
export const colorBorderDestructiveDarker = "rgb(96, 1, 1)";
export const colorBorderDestructiveLight = "rgb(236, 182, 182)";
export const colorBorderDestructiveLighter = "rgb(245, 221, 221)";
export const colorBorderError = "rgb(226, 37, 37)";
export const colorBorderErrorDark = "rgb(146, 18, 0)";
export const colorBorderErrorLight = "rgb(222, 88, 88)";
export const colorBorderErrorLightest = "rgb(255, 233, 231)";
export const colorBorderInverse = "rgba(255, 255, 255, 0.5)";
export const colorBorderInverseDarker = "rgba(255, 255, 255, 0.2)";
export const colorBorderInverseLighter = "rgba(255, 255, 255, 0.9)";
export const colorBorderInverseLightest = "rgb(255, 255, 255)";
export const colorBorderLight = "rgb(200, 204, 207)";
export const colorBorderLighter = "rgb(228, 231, 233)";
export const colorBorderNeutral = "rgb(2, 122, 197)";
export const colorBorderNeutralLight = "rgb(200, 204, 207)";
export const colorBorderPrimary = "rgb(2, 122, 197)";
export const colorBorderPrimaryDark = "rgb(0, 62, 130)";
export const colorBorderPrimaryDarker = "rgb(3, 44, 94)";
export const colorBorderPrimaryLight = "rgb(179, 211, 233)";
export const colorBorderPrimaryLighter = "rgb(220, 234, 244)";
export const colorBorderSuccess = "rgb(0, 153, 74)";
export const colorBorderSuccessLight = "rgb(35, 191, 110)";
export const colorBorderSuccessLightest = "rgb(224, 255, 239)";
export const colorBorderWarning = "rgb(228, 98, 22)";
export const colorBorderWarningLight = "rgb(242, 133, 16)";
export const colorBorderWarningLightest = "rgb(255, 236, 216)";
export const colorBrand = "rgb(35, 54, 89)";
export const colorBrandHighlight = "rgb(242, 47, 70)";
export const colorGray0 = "rgb(255, 255, 255)";
export const colorGray10 = "rgb(249, 250, 251)";
export const colorGray100 = "rgb(40, 42, 43)";
export const colorGray20 = "rgb(248, 248, 249)";
export const colorGray30 = "rgb(242, 243, 244)";
export const colorGray40 = "rgb(228, 231, 233)";
export const colorGray50 = "rgb(200, 204, 207)";
export const colorGray60 = "rgb(136, 140, 142)";
export const colorGray70 = "rgb(112, 117, 120)";
export const colorGray80 = "rgb(79, 83, 85)";
export const colorGray90 = "rgb(66, 69, 71)";
export const colorText = "rgb(40, 42, 43)";
export const colorTextBrandHighlight = "rgb(242, 47, 70)";
export const colorTextBrandInverse = "rgb(255, 255, 255)";
export const colorTextError = "rgb(226, 37, 37)";
export const colorTextErrorDark = "rgb(146, 18, 0)";
export const colorTextErrorLight = "rgb(236, 182, 182)";
export const colorTextIcon = "rgb(112, 117, 120)";
export const colorTextIconInverse = "rgb(200, 204, 207)";
export const colorTextInverse = "rgb(255, 255, 255)";
export const colorTextInverseWeak = "rgb(200, 204, 207)";
export const colorTextInverseWeaker = "rgb(136, 140, 142)";
export const colorTextLink = "rgb(0, 94, 166)";
export const colorTextLinkDark = "rgb(0, 62, 130)";
export const colorTextLinkDarker = "rgb(3, 44, 94)";
export const colorTextLinkDestructive = "rgb(226, 37, 37)";
export const colorTextLinkDestructiveDark = "rgb(178, 6, 0)";
export const colorTextLinkDestructiveDarker = "rgb(96, 1, 1)";
export const colorTextLinkDestructiveLight = "rgb(236, 182, 182)";
export const colorTextLinkLight = "rgb(179, 211, 233)";
export const colorTextNeutral = "rgb(0, 62, 130)";
export const colorTextNew = "rgb(109, 46, 209)";
export const colorTextSuccess = "rgb(0, 153, 74)";
export const colorTextWarning = "rgb(228, 98, 22)";
export const colorTextWarningDark = "rgb(168, 62, 0)";
export const colorTextWeak = "rgb(112, 117, 120)";
export const colorTextWeaker = "rgb(200, 204, 207)";
export const fontFamilyCode = "'Fira Mono', 'Courier New', Courier, monospace";
export const fontFamilyText = "'Whitney SSm A', 'Whitney SSm B', 'Helvetica Neue', Helvetica, Arial, sans-serif";
export const fontSize10 = "0.625rem";
export const fontSize100 = "2.5rem";
export const fontSize110 = "3rem";
export const fontSize20 = "0.75rem";
export const fontSize30 = "0.875rem";
export const fontSize40 = "1rem";
export const fontSize50 = "1.125rem";
export const fontSize60 = "1.25rem";
export const fontSize70 = "1.5rem";
export const fontSize80 = "1.75rem";
export const fontSize90 = "2rem";
export const fontWeightBold = "700";
export const fontWeightLight = "300";
export const fontWeightMedium = "500";
export const fontWeightNormal = "400";
export const fontWeightSemibold = "500";
export const lineHeight0 = "0";
export const lineHeight10 = "1rem";
export const lineHeight100 = "3.25rem";
export const lineHeight110 = "4rem";
export const lineHeight20 = "1.25rem";
export const lineHeight30 = "1.25rem";
export const lineHeight40 = "1.5rem";
export const lineHeight50 = "1.75rem";
export const lineHeight60 = "1.75rem";
export const lineHeight70 = "2rem";
export const lineHeight80 = "2.5rem";
export const lineHeight90 = "2.75rem";
export const shadow = "0 8px 16px 0 rgba(40, 42, 43, 0.2)";
export const shadowBorder = "0 0 0 1px #888c8e";
export const shadowBorderDestructive = "0 0 0 1px #e22525";
export const shadowBorderDestructiveDarker = "0 0 0 1px #600101";
export const shadowBorderDestructiveLight = "0 0 0 1px #ecb6b6";
export const shadowBorderDestructiveLighter = "0 0 0 1px #f5dddd";
export const shadowBorderError = "0 0 0 1px #e22525";
export const shadowBorderErrorDark = "0 0 0 1px #921200";
export const shadowBorderErrorLight = "0 0 0 1px #ecb6b6";
export const shadowBorderInverse = "0 0 0 1px rgba(255, 255, 255, 0.5)";
export const shadowBorderInverseDarker = "0 0 0 1px rgba(255, 255, 255, 0.2)";
export const shadowBorderInverseLight = "0 0 0 1px rgba(255, 255, 255, 0.9)";
export const shadowBorderInverseLightest = "0 0 0 1px #ffffff";
export const shadowBorderLight = "0 0 0 1px #e4e7e9";
export const shadowBorderLighter = "0 0 0 1px #f8f8f9";
export const shadowBorderPrimary = "0 0 0 1px #027ac5";
export const shadowBorderPrimaryDark = "0 0 0 1px #003e82";
export const shadowBorderPrimaryDarker = "0 0 0 1px #032c5e";
export const shadowBorderPrimaryLight = "0 0 0 1px #b3d3e9";
export const shadowBorderPrimaryLighter = "0 0 0 1px #dceaf4";
export const shadowCard = "0 2px 4px 0 rgba(40, 42, 43, 0.3)";
export const shadowFocus = "0 0 0 4px rgba(2, 122, 197, 0.8)";
export const shadowFocusInverse = "0 0 0 4px rgba(255, 255, 255, 0.4)";
export const shadowHigh = "0 12px 24px 4px rgba(40, 42, 43, 0.2)";
export const shadowLow = "0 2px 4px 0 rgba(40, 42, 43, 0.3)";
export const size0 = "0";
export const size10 = "5.5rem";
export const size100 = "64rem";
export const size110 = "70.5rem";
export const size120 = "77rem";
export const size20 = "12rem";
export const size30 = "18.5rem";
export const size40 = "25rem";
export const size50 = "31.5rem";
export const size60 = "38rem";
export const size70 = "44.5rem";
export const size80 = "51rem";
export const size90 = "57.5rem";
export const sizeIcon10 = "1rem";
export const sizeIcon100 = "3.25rem";
export const sizeIcon110 = "4rem";
export const sizeIcon20 = "1.25rem";
export const sizeIcon30 = "1.25rem";
export const sizeIcon40 = "1.5rem";
export const sizeIcon50 = "1.75rem";
export const sizeIcon60 = "1.75rem";
export const sizeIcon70 = "2rem";
export const sizeIcon80 = "2.5rem";
export const sizeIcon90 = "2.75rem";
export const sizeSidebar = "13.75rem";
export const sizeSquare0 = "0";
export const sizeSquare10 = "0.125rem";
export const sizeSquare100 = "2.25rem";
export const sizeSquare110 = "2.5rem";
export const sizeSquare120 = "2.75rem";
export const sizeSquare130 = "3rem";
export const sizeSquare140 = "3.25rem";
export const sizeSquare150 = "3.5rem";
export const sizeSquare160 = "3.75rem";
export const sizeSquare170 = "4rem";
export const sizeSquare180 = "4.25rem";
export const sizeSquare190 = "4.5rem";
export const sizeSquare20 = "0.25rem";
export const sizeSquare200 = "4.75rem";
export const sizeSquare25 = "0.375rem";
export const sizeSquare30 = "0.5rem";
export const sizeSquare40 = "0.75rem";
export const sizeSquare50 = "1rem";
export const sizeSquare60 = "1.25rem";
export const sizeSquare70 = "1.5rem";
export const sizeSquare80 = "1.75rem";
export const sizeSquare90 = "2rem";
export const space0 = "0";
export const space10 = "0.125rem";
export const space100 = "2.25rem";
export const space110 = "2.5rem";
export const space120 = "2.75rem";
export const space130 = "3rem";
export const space140 = "3.25rem";
export const space150 = "3.5rem";
export const space160 = "3.75rem";
export const space170 = "4rem";
export const space180 = "4.25rem";
export const space190 = "4.5rem";
export const space20 = "0.25rem";
export const space200 = "4.75rem";
export const space30 = "0.5rem";
export const space40 = "0.75rem";
export const space50 = "1rem";
export const space60 = "1.25rem";
export const space70 = "1.5rem";
export const space80 = "1.75rem";
export const space90 = "2rem";
export const spaceNegative10 = "-0.125rem";
export const spaceNegative100 = "-2.25rem";
export const spaceNegative110 = "-2.5rem";
export const spaceNegative120 = "-2.75rem";
export const spaceNegative130 = "-3rem";
export const spaceNegative140 = "-3.25rem";
export const spaceNegative150 = "-3.5rem";
export const spaceNegative160 = "-3.75rem";
export const spaceNegative170 = "-4rem";
export const spaceNegative180 = "-4.25rem";
export const spaceNegative190 = "-4.5rem";
export const spaceNegative20 = "-0.25rem";
export const spaceNegative200 = "-4.75rem";
export const spaceNegative30 = "-0.5rem";
export const spaceNegative40 = "-0.75rem";
export const spaceNegative50 = "-1rem";
export const spaceNegative60 = "-1.25rem";
export const spaceNegative70 = "-1.5rem";
export const spaceNegative80 = "-1.75rem";
export const spaceNegative90 = "-2rem";
export const zIndex0 = "2000";
export const zIndex10 = "2010";
export const zIndex20 = "2020";
export const zIndex30 = "2030";
export const zIndex40 = "2040";
export const zIndex50 = "2050";
export const zIndex60 = "2060";
export const zIndex70 = "2070";
export const zIndex80 = "2080";
export const zIndex90 = "2090";
export const backgroundColors = {
colorBackground,
colorBackgroundBody,
colorBackgroundBodyInverse,
colorBackgroundBrand,
colorBackgroundBrandHighlight,
colorBackgroundBrandHighlightLightest,
colorBackgroundDark,
colorBackgroundDarker,
colorBackgroundDarkest,
colorBackgroundDestructive,
colorBackgroundDestructiveDark,
colorBackgroundDestructiveDarker,
colorBackgroundDestructiveLight,
colorBackgroundDestructiveLighter,
colorBackgroundDestructiveLightest,
colorBackgroundError,
colorBackgroundErrorDark,
colorBackgroundErrorLightest,
colorBackgroundInverse,
colorBackgroundInverseLight,
colorBackgroundNeutralLightest,
colorBackgroundNew,
colorBackgroundOverlay,
colorBackgroundPrimary,
colorBackgroundPrimaryDark,
colorBackgroundPrimaryDarker,
colorBackgroundPrimaryDarkest,
colorBackgroundPrimaryLight,
colorBackgroundPrimaryLighter,
colorBackgroundPrimaryLightest,
colorBackgroundRequired,
colorBackgroundRowStriped,
colorBackgroundSubaccount,
colorBackgroundSuccess,
colorBackgroundSuccessLightest,
colorBackgroundTrial,
colorBackgroundUser,
colorBackgroundWarning,
colorBackgroundWarningLightest,
};
export const borderColors = {
colorBorder,
colorBorderDark,
colorBorderDestructive,
colorBorderDestructiveDark,
colorBorderDestructiveDarker,
colorBorderDestructiveLight,
colorBorderDestructiveLighter,
colorBorderError,
colorBorderErrorDark,
colorBorderErrorLight,
colorBorderErrorLightest,
colorBorderInverse,
colorBorderInverseDarker,
colorBorderInverseLighter,
colorBorderInverseLightest,
colorBorderLight,
colorBorderLighter,
colorBorderNeutral,
colorBorderNeutralLight,
colorBorderPrimary,
colorBorderPrimaryDark,
colorBorderPrimaryDarker,
colorBorderPrimaryLight,
colorBorderPrimaryLighter,
colorBorderSuccess,
colorBorderSuccessLight,
colorBorderSuccessLightest,
colorBorderWarning,
colorBorderWarningLight,
colorBorderWarningLightest,
};
export const borderWidths = {
borderWidth0,
borderWidth10,
borderWidth20,
borderWidth30,
borderWidth40,
};
export const boxShadows = {
shadow,
shadowBorder,
shadowBorderDestructive,
shadowBorderDestructiveDarker,
shadowBorderDestructiveLight,
shadowBorderDestructiveLighter,
shadowBorderError,
shadowBorderErrorDark,
shadowBorderErrorLight,
shadowBorderInverse,
shadowBorderInverseDarker,
shadowBorderInverseLight,
shadowBorderInverseLightest,
shadowBorderLight,
shadowBorderLighter,
shadowBorderPrimary,
shadowBorderPrimaryDark,
shadowBorderPrimaryDarker,
shadowBorderPrimaryLight,
shadowBorderPrimaryLighter,
shadowCard,
shadowFocus,
shadowFocusInverse,
shadowHigh,
shadowLow,
};
export const colors = {
colorBrand,
colorBrandHighlight,
colorGray0,
colorGray10,
colorGray100,
colorGray20,
colorGray30,
colorGray40,
colorGray50,
colorGray60,
colorGray70,
colorGray80,
colorGray90,
};
export const fonts = {
fontFamilyCode,
fontFamilyText,
};
export const fontSizes = {
fontSize10,
fontSize100,
fontSize110,
fontSize20,
fontSize30,
fontSize40,
fontSize50,
fontSize60,
fontSize70,
fontSize80,
fontSize90,
};
export const fontWeights = {
fontWeightBold,
fontWeightLight,
fontWeightMedium,
fontWeightNormal,
fontWeightSemibold,
};
export const lineHeights = {
lineHeight0,
lineHeight10,
lineHeight100,
lineHeight110,
lineHeight20,
lineHeight30,
lineHeight40,
lineHeight50,
lineHeight60,
lineHeight70,
lineHeight80,
lineHeight90,
};
export const radii = {
borderRadius0,
borderRadius10,
borderRadius20,
borderRadius30,
borderRadiusCircle,
};
export const sizings = {
size0,
size10,
size100,
size110,
size120,
size20,
size30,
size40,
size50,
size60,
size70,
size80,
size90,
sizeIcon10,
sizeIcon100,
sizeIcon110,
sizeIcon20,
sizeIcon30,
sizeIcon40,
sizeIcon50,
sizeIcon60,
sizeIcon70,
sizeIcon80,
sizeIcon90,
sizeSidebar,
sizeSquare0,
sizeSquare10,
sizeSquare100,
sizeSquare110,
sizeSquare120,
sizeSquare130,
sizeSquare140,
sizeSquare150,
sizeSquare160,
sizeSquare170,
sizeSquare180,
sizeSquare190,
sizeSquare20,
sizeSquare200,
sizeSquare25,
sizeSquare30,
sizeSquare40,
sizeSquare50,
sizeSquare60,
sizeSquare70,
sizeSquare80,
sizeSquare90,
};
export const spacings = {
space0,
space10,
space100,
space110,
space120,
space130,
space140,
space150,
space160,
space170,
space180,
space190,
space20,
space200,
space30,
space40,
space50,
space60,
space70,
space80,
space90,
spaceNegative10,
spaceNegative100,
spaceNegative110,
spaceNegative120,
spaceNegative130,
spaceNegative140,
spaceNegative150,
spaceNegative160,
spaceNegative170,
spaceNegative180,
spaceNegative190,
spaceNegative20,
spaceNegative200,
spaceNegative30,
spaceNegative40,
spaceNegative50,
spaceNegative60,
spaceNegative70,
spaceNegative80,
spaceNegative90,
};
export const textColors = {
colorText,
colorTextBrandHighlight,
colorTextBrandInverse,
colorTextError,
colorTextErrorDark,
colorTextErrorLight,
colorTextIcon,
colorTextIconInverse,
colorTextInverse,
colorTextInverseWeak,
colorTextInverseWeaker,
colorTextLink,
colorTextLinkDark,
colorTextLinkDarker,
colorTextLinkDestructive,
colorTextLinkDestructiveDark,
colorTextLinkDestructiveDarker,
colorTextLinkDestructiveLight,
colorTextLinkLight,
colorTextNeutral,
colorTextNew,
colorTextSuccess,
colorTextWarning,
colorTextWarningDark,
colorTextWeak,
colorTextWeaker,
};
export const zIndices = {
zIndex0,
zIndex10,
zIndex20,
zIndex30,
zIndex40,
zIndex50,
zIndex60,
zIndex70,
zIndex80,
zIndex90,
};