var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Tabs.tsx
import {
  createElement,
  forwardRef,
  useMemo
} from "react";
import {
  oneOf,
  string
} from "prop-types";
import {Flex} from "@twilio-paste/flex";
import {useTabPrimitiveState} from "@twilio-paste/tabs-primitive";

// src/TabsContext.tsx
import {
  createContext
} from "react";
var TabsContext = createContext({});

// src/Tabs.tsx
var Tabs = forwardRef((_a, ref) => {
  var {children, orientation = "horizontal", state, variant} = _a, initialState = __rest(_a, ["children", "orientation", "state", "variant"]);
  const tab = state || useTabPrimitiveState(__assign({orientation}, initialState));
  const value = useMemo(() => __assign(__assign({}, tab), {variant}), [...Object.values(tab), variant]);
  const returnValue = /* @__PURE__ */ createElement(TabsContext.Provider, {
    value
  }, children);
  if (tab.orientation === "vertical") {
    return /* @__PURE__ */ createElement(Flex, {
      ref,
      wrap: false,
      vAlignContent: "stretch"
    }, returnValue);
  }
  return returnValue;
});
if (true) {
  Tabs.propTypes = {
    selectedId: string,
    orientation: oneOf(["horizontal", "vertical", null]),
    variant: oneOf(["fitted", null])
  };
}
Tabs.displayName = "Tabs";

// src/Tab.tsx
import {
  createElement as createElement2,
  forwardRef as forwardRef2,
  useContext,
  useMemo as useMemo2
} from "react";
import {
  bool,
  string as string2
} from "prop-types";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
import {TabPrimitive} from "@twilio-paste/tabs-primitive";
var getTabBoxStyles = (orientation, variant) => {
  switch (orientation) {
    case "vertical":
      return {
        borderLeftColor: "transparent",
        borderLeftStyle: "solid",
        borderLeftWidth: "borderWidth20",
        color: "colorTextWeak",
        display: "block",
        marginBottom: "space40",
        paddingBottom: "space30",
        paddingLeft: "space50",
        paddingRight: "space50",
        paddingTop: "space30",
        _last: {
          marginBottom: "space0"
        },
        _selected: {
          borderLeftColor: "colorBorderPrimary",
          color: "colorTextLink"
        },
        _hover: {
          borderLeftColor: "colorBorderPrimaryDarker",
          color: "colorTextLinkDarker"
        },
        _disabled: {
          borderLeftColor: "transparent",
          color: "colorTextWeaker"
        },
        _focus: {
          borderLeftColor: "colorBorderPrimaryDarker",
          boxShadow: "shadowFocus",
          color: "colorTextLinkDarker",
          outline: "none"
        }
      };
    case "horizontal":
    default:
      return {
        borderBottomColor: "transparent",
        borderBottomStyle: "solid",
        borderBottomWidth: "borderWidth20",
        color: "colorTextWeak",
        display: "inline-block",
        flexBasis: variant === "fitted" ? "50%" : void 0,
        flexGrow: variant === "fitted" ? 1 : void 0,
        flexShrink: variant === "fitted" ? 1 : void 0,
        marginRight: variant === "fitted" ? "space0" : "space70",
        minWidth: "sizeSquare130",
        paddingBottom: "space40",
        paddingLeft: "space20",
        paddingRight: "space20",
        paddingTop: "space40",
        textAlign: variant === "fitted" ? "center" : void 0,
        _last: {
          marginRight: "space0"
        },
        _selected: {
          borderBottomColor: "colorBorderPrimary",
          color: "colorTextLink"
        },
        _hover: {
          borderBottomColor: "colorBorderPrimaryDarker",
          color: "colorTextLinkDarker"
        },
        _disabled: {
          borderBottomColor: "transparent",
          color: "colorTextWeaker"
        },
        _focus: {
          borderBottomColor: "colorBorderPrimaryDarker",
          boxShadow: "shadowFocus",
          color: "colorTextLinkDarker",
          outline: "none"
        }
      };
  }
};
var Tab = forwardRef2((_a, ref) => {
  var {children} = _a, tabProps = __rest(_a, ["children"]);
  const tab = useContext(TabsContext);
  const boxStyles = useMemo2(() => getTabBoxStyles(tab.orientation, tab.variant), [tab.orientation, tab.variant]);
  return /* @__PURE__ */ createElement2(TabPrimitive, __assign(__assign(__assign({}, tab), tabProps), {
    ref
  }), (props) => {
    return /* @__PURE__ */ createElement2(Box, __assign(__assign(__assign({}, safelySpreadBoxProps(props)), boxStyles), {
      as: "span",
      cursor: props["aria-disabled"] ? "not-allowed" : "pointer",
      fontSize: "fontSize30",
      fontWeight: "fontWeightSemibold",
      overflow: tab.orientation !== "vertical" ? "hidden" : void 0,
      position: "relative",
      textOverflow: tab.orientation !== "vertical" ? "ellipsis" : void 0,
      transition: "border-color 100ms ease, color 100ms ease",
      whiteSpace: tab.orientation !== "vertical" ? "nowrap" : void 0
    }), children);
  });
});
if (true) {
  Tab.propTypes = {
    id: string2,
    focusable: bool,
    disabled: bool
  };
}
Tab.displayName = "Tab";

// src/TabList.tsx
import {
  createElement as createElement3,
  forwardRef as forwardRef3,
  useContext as useContext2
} from "react";
import {
  bool as bool2,
  string as string3
} from "prop-types";
import {Box as Box2} from "@twilio-paste/box";
import {TabPrimitiveList} from "@twilio-paste/tabs-primitive";
var HorizontalTabList = ({children}) => /* @__PURE__ */ createElement3(Box2, {
  display: "flex",
  borderBottomWidth: "borderWidth10",
  borderBottomColor: "colorBorderLight",
  borderBottomStyle: "solid",
  marginBottom: "space60"
}, children);
var VerticalTabList = ({children}) => /* @__PURE__ */ createElement3(Box2, {
  borderLeftWidth: "borderWidth10",
  borderLeftColor: "colorBorderLight",
  borderLeftStyle: "solid",
  marginRight: "space110",
  minWidth: "size20",
  maxWidth: "size40"
}, children);
var TabList = forwardRef3((_a, ref) => {
  var {children, variant} = _a, props = __rest(_a, ["children", "variant"]);
  const tab = useContext2(TabsContext);
  const TabListWrapper = tab.orientation === "vertical" ? VerticalTabList : HorizontalTabList;
  return /* @__PURE__ */ createElement3(TabPrimitiveList, __assign(__assign(__assign({}, tab), props), {
    ref
  }), /* @__PURE__ */ createElement3(TabListWrapper, null, children));
});
if (true) {
  TabList.propTypes = {
    "aria-label": string3.isRequired,
    focusable: bool2,
    disabled: bool2
  };
}
TabList.displayName = "TabList";

// src/TabPanel.tsx
import {
  createElement as createElement4,
  forwardRef as forwardRef4,
  useContext as useContext3
} from "react";
import {
  string as string4
} from "prop-types";
import {TabPrimitivePanel} from "@twilio-paste/tabs-primitive";
var TabPanel = forwardRef4((_a, ref) => {
  var {children} = _a, props = __rest(_a, ["children"]);
  const tab = useContext3(TabsContext);
  return /* @__PURE__ */ createElement4(TabPrimitivePanel, __assign(__assign(__assign({}, tab), props), {
    ref
  }), children);
});
if (true) {
  TabPanel.propTypes = {
    id: string4,
    tabId: string4
  };
}
TabPanel.displayName = "TabPanel";

// src/TabPanels.tsx
import {
  createElement as createElement5,
  forwardRef as forwardRef5
} from "react";
import {Box as Box3, safelySpreadBoxProps as safelySpreadBoxProps2} from "@twilio-paste/box";
var TabPanels = forwardRef5((_a, ref) => {
  var {children} = _a, props = __rest(_a, ["children"]);
  return /* @__PURE__ */ createElement5(Box3, __assign(__assign({}, safelySpreadBoxProps2(props)), {
    width: "100%",
    ref
  }), children);
});
TabPanels.displayName = "TabPanels";
export {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useTabPrimitiveState as useTabState
};
