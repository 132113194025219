import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Avatar } from '@twilio-paste/avatar';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { Card } from '@twilio-paste/card';
import { Heading } from '@twilio-paste/heading';
import { Stack } from '@twilio-paste/stack';
import { Table, THead, TBody, TFoot, Tr, Th, Td } from '@twilio-paste/table';
import { Text } from '@twilio-paste/text';
import { Tooltip } from '@twilio-paste/tooltip';
import { Truncate } from '@twilio-paste/truncate';
import { UnorderedList, ListItem } from '@twilio-paste/list';
import { InformationIcon } from '@twilio-paste/icons/esm/InformationIcon';
import Changelog from '@twilio-paste/table/CHANGELOG.md';
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { DoDont, Do, Dont } from "../../../../src/components/DoDont";
import { SidebarCategoryRoutes } from "../../../../src/constants";
import * as React from 'react';
export default {
  graphql,
  Anchor,
  Avatar,
  Box,
  Button,
  Card,
  Heading,
  Stack,
  Table,
  THead,
  TBody,
  TFoot,
  Tr,
  Th,
  Td,
  Text,
  Tooltip,
  Truncate,
  UnorderedList,
  ListItem,
  InformationIcon,
  Changelog,
  Callout,
  CalloutTitle,
  CalloutText,
  DoDont,
  Do,
  Dont,
  SidebarCategoryRoutes,
  React
};