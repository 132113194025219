import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Blockquote } from "../../../../src/components/Blockquote";
import { TokensList } from "../../../../src/components/tokens-list";
import * as React from 'react';
export default {
  graphql,
  Anchor,
  Box,
  Text,
  Blockquote,
  TokensList,
  React
};