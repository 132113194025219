var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/InlineControlGroup.tsx
import {
  Children,
  createElement,
  forwardRef
} from "react";
import {
  bool,
  node,
  oneOf,
  oneOfType,
  string
} from "prop-types";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
import {Label} from "@twilio-paste/label";
import {HelpText} from "@twilio-paste/help-text";
var InlineControlGroup = forwardRef((_a, ref) => {
  var {children, disabled, errorText, helpText, legend, orientation = "vertical", required} = _a, props = __rest(_a, ["children", "disabled", "errorText", "helpText", "legend", "orientation", "required"]);
  return /* @__PURE__ */ createElement(Box, __assign(__assign({}, safelySpreadBoxProps(props)), {
    as: "fieldset",
    margin: "space0",
    padding: "space0",
    borderWidth: "borderWidth0",
    ref
  }), /* @__PURE__ */ createElement(Label, {
    as: "legend",
    htmlFor: void 0,
    required,
    marginBottom: "space0",
    disabled
  }, legend), helpText && /* @__PURE__ */ createElement(HelpText, {
    marginTop: "space0"
  }, helpText), /* @__PURE__ */ createElement(Box, {
    marginLeft: "space20",
    marginRight: "space20"
  }, Children.map(children, (child) => {
    return /* @__PURE__ */ createElement(Box, {
      display: orientation === "horizontal" ? "inline-block" : "block",
      marginTop: "space40",
      marginRight: orientation === "horizontal" ? "space70" : null
    }, child);
  }), errorText && /* @__PURE__ */ createElement(Box, {
    marginTop: "space40"
  }, /* @__PURE__ */ createElement(HelpText, {
    variant: "error"
  }, errorText))));
});
InlineControlGroup.displayName = "InlineControlGroup";
if (true) {
  InlineControlGroup.propTypes = {
    children: node.isRequired,
    disabled: bool,
    errorText: oneOfType([string, node]),
    helpText: oneOfType([string, node]),
    legend: oneOfType([string, node]).isRequired,
    name: string.isRequired,
    orientation: oneOf(["vertical", "horizontal"]),
    required: bool
  };
}
export {
  InlineControlGroup
};
