var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/index.tsx
import {
  createElement,
  forwardRef
} from "react";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
import {isPaddingTokenProp} from "@twilio-paste/style-props";
var Card = forwardRef((_a, ref) => {
  var {children, padding = "space60", paddingBottom, paddingLeft, paddingRight, paddingTop} = _a, props = __rest(_a, ["children", "padding", "paddingBottom", "paddingLeft", "paddingRight", "paddingTop"]);
  return /* @__PURE__ */ createElement(Box, __assign(__assign({}, safelySpreadBoxProps(props)), {
    ref,
    as: "article",
    borderWidth: "borderWidth20",
    borderColor: "colorBorderLighter",
    borderStyle: "solid",
    borderRadius: "borderRadius20",
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    backgroundColor: "colorBackgroundBody"
  }), children);
});
if (true) {
  Card.propTypes = {
    padding: isPaddingTokenProp,
    paddingTop: isPaddingTokenProp,
    paddingRight: isPaddingTokenProp,
    paddingBottom: isPaddingTokenProp,
    paddingLeft: isPaddingTokenProp
  };
}
Card.displayName = "Card";
export {
  Card
};
