import { graphql } from 'gatsby';
import { Label } from '@twilio-paste/label';
import { TextArea } from '@twilio-paste/textarea';
import { HelpText } from '@twilio-paste/help-text';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { Table, THead, TBody, Td, Th, Tr } from '@twilio-paste/table';
import { InformationIcon } from '@twilio-paste/icons/esm/InformationIcon';
import { UnorderedList, ListItem } from '@twilio-paste/list';
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { DoDont, Do, Dont } from "../../../../src/components/DoDont";
import { Codeblock } from "../../../../src/components/codeblock";
import { SidebarCategoryRoutes } from "../../../../src/constants";
import Changelog from '@twilio-paste/textarea/CHANGELOG.md';
import * as React from 'react';
export default {
  graphql,
  Label,
  TextArea,
  HelpText,
  Anchor,
  Box,
  Button,
  Table,
  THead,
  TBody,
  Td,
  Th,
  Tr,
  InformationIcon,
  UnorderedList,
  ListItem,
  Callout,
  CalloutTitle,
  CalloutText,
  DoDont,
  Do,
  Dont,
  Codeblock,
  SidebarCategoryRoutes,
  Changelog,
  React
};