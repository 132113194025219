import * as React from "react";
import {useUID} from "@twilio-paste/uid-library";
import {IconWrapper} from "./helpers/IconWrapper";
const CheckboxLineIcon = ({as, display, size, color, title, decorative}) => {
  const titleId = `CheckboxLineIcon-${useUID()}`;
  if (!decorative && title == null) {
    throw new Error("[CheckboxLineIcon]: Missing a title for non-decorative icon.");
  }
  return /* @__PURE__ */ React.createElement(IconWrapper, {
    as,
    display,
    size,
    color
  }, /* @__PURE__ */ React.createElement("svg", {
    role: "img",
    "aria-hidden": decorative,
    width: "100%",
    height: "100%",
    viewBox: "0 0 20 20",
    "aria-labelledby": titleId
  }, title ? /* @__PURE__ */ React.createElement("title", {
    id: titleId
  }, title) : null, /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    fillRule: "evenodd",
    d: "M14.5 8.5a1.5 1.5 0 01.175 2.99l-.175.01h-9a1.5 1.5 0 01-.175-2.99L5.5 8.5h9z"
  })));
};
CheckboxLineIcon.displayName = "CheckboxLineIcon";
export {
  CheckboxLineIcon
};
