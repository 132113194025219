import * as React from "react";
import {useUID} from "@twilio-paste/uid-library";
import {IconWrapper} from "./helpers/IconWrapper";
const ChevronDisclosureCollapsedIcon = ({
  as,
  display,
  size,
  color,
  title,
  decorative
}) => {
  const titleId = `ChevronDisclosureCollapsedIcon-${useUID()}`;
  if (!decorative && title == null) {
    throw new Error("[ChevronDisclosureCollapsedIcon]: Missing a title for non-decorative icon.");
  }
  return /* @__PURE__ */ React.createElement(IconWrapper, {
    as,
    display,
    size,
    color
  }, /* @__PURE__ */ React.createElement("svg", {
    role: "img",
    "aria-hidden": decorative,
    width: "100%",
    height: "100%",
    viewBox: "0 0 20 20",
    "aria-labelledby": titleId
  }, title ? /* @__PURE__ */ React.createElement("title", {
    id: titleId
  }, title) : null, /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    fillRule: "evenodd",
    d: "M12.382 9.742l-4.371-3.67a.305.305 0 00-.442.052.34.34 0 00-.069.206v7.34c0 .182.14.33.314.33.072 0 .141-.026.197-.072l4.371-3.67a.341.341 0 000-.516z"
  })));
};
ChevronDisclosureCollapsedIcon.displayName = "ChevronDisclosureCollapsedIcon";
export {
  ChevronDisclosureCollapsedIcon
};
