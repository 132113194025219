// src/background.ts
var BACKGROUND_PROPS = [
  "background",
  "backgroundColor",
  "backgroundImage",
  "backgroundSize",
  "backgroundPosition",
  "backgroundRepeat",
  "backgroundAttachment"
];

// src/border.ts
var BORDER_RADIUS_PROPS = [
  "borderRadius",
  "borderBottomLeftRadius",
  "borderBottomRightRadius",
  "borderTopLeftRadius",
  "borderTopRightRadius"
];
var BORDER_WIDTH_PROPS = [
  "borderWidth",
  "borderBottomWidth",
  "borderLeftWidth",
  "borderRightWidth",
  "borderTopWidth"
];
var BORDER_COLOR_PROPS = [
  "borderColor",
  "borderBottomColor",
  "borderLeftColor",
  "borderRightColor",
  "borderTopColor"
];
var BORDER_STYLE_PROPS = [
  "borderStyle",
  "borderTopStyle",
  "borderRightStyle",
  "borderBottomStyle",
  "borderLeftStyle"
];
var BORDER_PROPS = [
  ...BORDER_RADIUS_PROPS,
  ...BORDER_WIDTH_PROPS,
  ...BORDER_COLOR_PROPS,
  ...BORDER_STYLE_PROPS,
  "border",
  "borderTop",
  "borderRight",
  "borderBottom",
  "borderLeft",
  "borderX",
  "borderY"
];

// src/flexbox.ts
var FLEXBOX_PROPS = [
  "alignContent",
  "alignItems",
  "alignSelf",
  "flex",
  "flexBasis",
  "flexDirection",
  "flexGrow",
  "flexShrink",
  "flexWrap",
  "justifyContent",
  "justifyItems",
  "justifySelf",
  "order"
];

// src/layout.ts
var OVERFLOW_PROPS = ["overflow", "overflowX", "overflowY"];
var LAYOUT_PROPS = [
  ...OVERFLOW_PROPS,
  "width",
  "minWidth",
  "maxWidth",
  "height",
  "minHeight",
  "maxHeight",
  "size",
  "display",
  "verticalAlign"
];

// src/position.ts
var POSITION_PROPS = ["position", "left", "right", "top", "bottom", "zIndex"];

// src/shadow.ts
var SHADOW_PROPS = ["boxShadow", "textShadow"];

// src/space.ts
var MARGIN_PROPS = [
  "margin",
  "m",
  "marginTop",
  "mt",
  "marginRight",
  "mr",
  "marginBottom",
  "mb",
  "marginLeft",
  "ml",
  "marginX",
  "mx",
  "marginY",
  "my"
];
var PADDING_PROPS = [
  "padding",
  "p",
  "paddingTop",
  "pt",
  "paddingRight",
  "pr",
  "paddingBottom",
  "pb",
  "paddingLeft",
  "pl",
  "paddingX",
  "px",
  "paddingY",
  "py"
];
var SPACE_PROPS = [...MARGIN_PROPS, ...PADDING_PROPS];

// src/typography.ts
var TYPOGRAPHY_PROPS = [
  "fontFamily",
  "fontSize",
  "fontStyle",
  "fontWeight",
  "letterSpacing",
  "lineHeight",
  "textAlign",
  "color",
  "textDecoration",
  "textOverflow",
  "textTransform",
  "whiteSpace",
  "wordBreak",
  "wordWrap"
];

// src/proptypes/background.ts
import {DefaultTheme} from "@twilio-paste/theme";

// src/proptypes/utils/propValidator.ts
var propValidator = (optionsList) => {
  return (props, propName, componentName) => {
    const suppliedValue = props[propName];
    const propError = new Error(`[${componentName}]: invalid prop supplied "${propName}=${suppliedValue}", expected a token value. See https://paste.twilio.design/tokens for available options.`);
    const isInvalidToken = (value) => !optionsList.includes(value);
    if (suppliedValue != null) {
      if (Array.isArray(suppliedValue)) {
        for (let i = 0; i < suppliedValue.length; i++) {
          if (isInvalidToken(suppliedValue[i])) {
            return propError;
          }
        }
      } else if (isInvalidToken(suppliedValue)) {
        return propError;
      }
    }
    return null;
  };
};

// src/proptypes/background.ts
var BackgroundColorOptions = ["none", "transparent", ...Object.keys(DefaultTheme.backgroundColors)];
var isBackgroundColorTokenProp = propValidator(BackgroundColorOptions);

// src/proptypes/border.ts
import {DefaultTheme as DefaultTheme2} from "@twilio-paste/theme";
var BorderRadiusOptions = Object.keys(DefaultTheme2.radii);
var BorderWidthOptions = Object.keys(DefaultTheme2.borderWidths);
var BorderColorOptions = ["transparent", ...Object.keys(DefaultTheme2.borderColors)];
var isBorderRadiusTokenProp = propValidator(BorderRadiusOptions);
var isBorderWidthTokenProp = propValidator(BorderWidthOptions);
var isBorderColorTokenProp = propValidator(BorderColorOptions);

// src/proptypes/layout.ts
import {
  number,
  oneOfType as oneOfType2,
  string
} from "prop-types";
import {DefaultTheme as DefaultTheme3} from "@twilio-paste/theme";

// src/proptypes/utils/responsivePropValidator.ts
import {
  arrayOf,
  oneOfType
} from "prop-types";
var ResponsiveProp = (type) => {
  return oneOfType([type, arrayOf(type)]);
};

// src/proptypes/layout.ts
var IconSizeOptions = Object.keys(DefaultTheme3.iconSizes);
var isWidthTokenProp = ResponsiveProp(oneOfType2([string, number]));
var isMinWidthTokenProp = ResponsiveProp(oneOfType2([string, number]));
var isMaxWidthTokenProp = ResponsiveProp(oneOfType2([string, number]));
var isHeightTokenProp = ResponsiveProp(oneOfType2([string, number]));
var isMinHeightTokenProp = ResponsiveProp(oneOfType2([string, number]));
var isMaxHeightTokenProp = ResponsiveProp(oneOfType2([string, number]));
var isIconSizeTokenProp = propValidator(IconSizeOptions);

// src/proptypes/position.ts
import {DefaultTheme as DefaultTheme4} from "@twilio-paste/theme";
var ZIndexOptions = Object.keys(DefaultTheme4.zIndices);
var isZIndexTokenProp = propValidator(ZIndexOptions);

// src/proptypes/shadow.ts
import {DefaultTheme as DefaultTheme5} from "@twilio-paste/theme";
var BoxShadowOptions = ["none", ...Object.keys(DefaultTheme5.shadows)];
var isBoxShadowTokenProp = propValidator(BoxShadowOptions);

// src/proptypes/space.ts
import {DefaultTheme as DefaultTheme6} from "@twilio-paste/theme";
var SpaceOptions = Object.keys(DefaultTheme6.space);
var MarginOptions = ["auto", ...Object.keys(DefaultTheme6.space)];
var isSpaceTokenProp = propValidator(SpaceOptions);
var isPaddingTokenProp = propValidator(SpaceOptions);
var isMarginTokenProp = propValidator(MarginOptions);

// src/proptypes/typography.ts
import {DefaultTheme as DefaultTheme7} from "@twilio-paste/theme";
var FontFamilyOptions = ["inherit", ...Object.keys(DefaultTheme7.fonts)];
var FontSizeOptions = ["100%", "inherit", ...Object.keys(DefaultTheme7.fontSizes)];
var FontWeightOptions = ["inherit", ...Object.keys(DefaultTheme7.fontWeights)];
var LineHeightOptions = ["unset", "inherit", ...Object.keys(DefaultTheme7.lineHeights)];
var TextColorOptions = ["currentColor", "inherit", ...Object.keys(DefaultTheme7.textColors)];
var isFontFamilyTokenProp = propValidator(FontFamilyOptions);
var isFontSizeTokenProp = propValidator(FontSizeOptions);
var isFontWeightTokenProp = propValidator(FontWeightOptions);
var isLineHeightTokenProp = propValidator(LineHeightOptions);
var isTextColorTokenProp = propValidator(TextColorOptions);

// src/proptypes/helpers.ts
import {
  oneOf
} from "prop-types";
var StyleResetProp = oneOf(["none", "inherit", "initial", "unset"]);
export {
  BACKGROUND_PROPS,
  BORDER_COLOR_PROPS,
  BORDER_PROPS,
  BORDER_RADIUS_PROPS,
  BORDER_STYLE_PROPS,
  BORDER_WIDTH_PROPS,
  FLEXBOX_PROPS,
  LAYOUT_PROPS,
  MARGIN_PROPS,
  OVERFLOW_PROPS,
  PADDING_PROPS,
  POSITION_PROPS,
  ResponsiveProp,
  SHADOW_PROPS,
  SPACE_PROPS,
  StyleResetProp,
  TYPOGRAPHY_PROPS,
  isBackgroundColorTokenProp,
  isBorderColorTokenProp,
  isBorderRadiusTokenProp,
  isBorderWidthTokenProp,
  isBoxShadowTokenProp,
  isFontFamilyTokenProp,
  isFontSizeTokenProp,
  isFontWeightTokenProp,
  isHeightTokenProp,
  isIconSizeTokenProp,
  isLineHeightTokenProp,
  isMarginTokenProp,
  isMaxHeightTokenProp,
  isMaxWidthTokenProp,
  isMinHeightTokenProp,
  isMinWidthTokenProp,
  isPaddingTokenProp,
  isSpaceTokenProp,
  isTextColorTokenProp,
  isWidthTokenProp,
  isZIndexTokenProp
};
