var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/InputBox.tsx
import {
  createElement as createElement6,
  forwardRef as forwardRef6
} from "react";
import {
  bool as bool2,
  node as node4,
  oneOf as oneOf4
} from "prop-types";

// src/FauxInput/index.tsx
import {
  createElement as createElement3,
  forwardRef as forwardRef3
} from "react";

// src/FauxInput/DefaultFauxInput.tsx
import {
  createElement,
  forwardRef
} from "react";
import {Box} from "@twilio-paste/box";

// src/types.ts
import {
  bool,
  node,
  oneOf
} from "prop-types";
var FauxInputPropTypes = {
  children: node.isRequired,
  disabled: bool,
  hasError: bool,
  readOnly: bool,
  type: oneOf(["text", "email", "hidden", "number", "password", "search", "tel"]),
  variant: oneOf(["default", "inverse"])
};

// src/FauxInput/DefaultFauxInput.tsx
var DefaultFauxInput = forwardRef(({disabled, hasError, readOnly, children, type}, ref) => {
  let backgroundColor = "colorBackgroundBody";
  let boxShadow = "shadowBorder";
  let boxShadowHover = "shadowBorderPrimaryDark";
  let boxShadowActive = "shadowBorderPrimaryDarker";
  if (disabled && type !== "hidden") {
    boxShadow = "shadowBorderLight";
    boxShadowHover = "shadowBorderLight";
    backgroundColor = "colorBackground";
    boxShadowActive = "shadowBorderLight";
  } else if (readOnly && type !== "hidden") {
    boxShadowHover = "shadowBorder";
    backgroundColor = "colorBackground";
  } else if (hasError && type !== "hidden") {
    boxShadow = "shadowBorderError";
    boxShadowHover = "shadowBorderErrorDark";
  } else if (type === "hidden") {
    boxShadow = null;
    boxShadowHover = null;
    backgroundColor = null;
    boxShadowActive = null;
  }
  return /* @__PURE__ */ createElement(Box, {
    ref,
    display: "flex",
    width: "100%",
    backgroundColor,
    boxShadow,
    borderRadius: "borderRadius20",
    transition: "box-shadow 100ms ease-in",
    cursor: disabled ? "not-allowed" : "text",
    _hover: {
      boxShadow: boxShadowHover
    },
    _focusWithin: {
      boxShadow: "shadowFocus"
    },
    _active: {
      boxShadow: boxShadowActive
    }
  }, children);
});
DefaultFauxInput.displayName = "DefaultFauxInput";
if (true) {
  DefaultFauxInput.propTypes = FauxInputPropTypes;
}

// src/FauxInput/InverseFauxInput.tsx
import {
  createElement as createElement2,
  forwardRef as forwardRef2
} from "react";
import {Box as Box2} from "@twilio-paste/box";
var InverseFauxInput = forwardRef2(({disabled, hasError, readOnly, children, type}, ref) => {
  let backgroundColor = "colorBackgroundInverse";
  let boxShadow = "shadowBorderInverse";
  let boxShadowHover = "shadowBorderInverseLightest";
  if (disabled && type !== "hidden") {
    backgroundColor = "colorBackgroundInverseLight";
    boxShadow = "shadowBorderInverseDarker";
    boxShadowHover = "shadowBorderInverseDarker";
  } else if (readOnly && type !== "hidden") {
    backgroundColor = "colorBackgroundInverseLight";
    boxShadowHover = "shadowBorderInverse";
  } else if (hasError && type !== "hidden") {
    boxShadow = "shadowBorderErrorLight";
  } else if (type === "hidden") {
    backgroundColor = null;
    boxShadow = null;
    boxShadowHover = null;
  }
  return /* @__PURE__ */ createElement2(Box2, {
    ref,
    display: "flex",
    width: "100%",
    backgroundColor,
    boxShadow,
    borderRadius: "borderRadius20",
    color: "colorTextInverse",
    transition: "box-shadow 100ms ease-in",
    cursor: disabled ? "not-allowed" : "text",
    _hover: {
      boxShadow: boxShadowHover
    },
    _focusWithin: {
      backgroundColor: "colorBackgroundInverse",
      boxShadow: "shadowFocusInverse"
    },
    _active: {
      boxShadow: boxShadowHover
    }
  }, children);
});
InverseFauxInput.displayName = "InverseFauxInput";
if (true) {
  InverseFauxInput.propTypes = FauxInputPropTypes;
}

// src/FauxInput/index.tsx
var FauxInputVariants = {
  default: DefaultFauxInput,
  inverse: InverseFauxInput
};
var FieldWrapper = forwardRef3(({children, disabled, hasError, readOnly, type, variant = "default"}, ref) => {
  const FieldWrapperComponent = FauxInputVariants[variant];
  return /* @__PURE__ */ createElement3(FieldWrapperComponent, {
    disabled,
    hasError,
    readOnly,
    type,
    variant,
    ref
  }, children);
});
FieldWrapper.displayName = "FieldWrapper";
if (true) {
  FieldWrapper.propTypes = FauxInputPropTypes;
}

// src/Prefix.tsx
import {
  createElement as createElement4,
  forwardRef as forwardRef4
} from "react";
import {
  node as node2,
  oneOf as oneOf2
} from "prop-types";
import {Box as Box3} from "@twilio-paste/box";
var Prefix = forwardRef4(({children, disabled, variant}, ref) => {
  let backgroundColor = "colorBackground";
  let borderColor = "colorBorderLighter";
  if (disabled && variant === "inverse") {
    backgroundColor = "none";
    borderColor = "colorBorderInverseDarker";
  } else if (variant === "inverse") {
    backgroundColor = "colorBackgroundInverseLight";
    borderColor = "colorBorderInverse";
  }
  if (children == null)
    return null;
  return /* @__PURE__ */ createElement4(Box3, {
    alignItems: "flex-start",
    backgroundColor,
    borderBottomLeftRadius: "borderRadius20",
    borderRightColor: borderColor,
    borderRightStyle: "solid",
    borderRightWidth: "borderWidth10",
    borderTopLeftRadius: "borderRadius20",
    display: "flex",
    lineHeight: "lineHeight20",
    padding: "space30",
    ref
  }, children);
});
Prefix.displayName = "Prefix";
if (true) {
  Prefix.propTypes = {
    children: node2.isRequired,
    variant: oneOf2(["default", "inverse"])
  };
}

// src/Suffix.tsx
import {
  createElement as createElement5,
  forwardRef as forwardRef5
} from "react";
import {
  node as node3,
  oneOf as oneOf3
} from "prop-types";
import {Box as Box4} from "@twilio-paste/box";
var Suffix = forwardRef5(({children, disabled, variant}, ref) => {
  let backgroundColor = "colorBackground";
  let borderColor = "colorBorderLighter";
  if (disabled && variant === "inverse") {
    backgroundColor = "none";
    borderColor = "colorBorderInverseDarker";
  } else if (variant === "inverse") {
    backgroundColor = "colorBackgroundInverseLight";
    borderColor = "colorBorderInverse";
  }
  if (children == null)
    return null;
  return /* @__PURE__ */ createElement5(Box4, {
    alignItems: "flex-start",
    backgroundColor,
    borderBottomRightRadius: "borderRadius20",
    borderLeftColor: borderColor,
    borderLeftStyle: "solid",
    borderLeftWidth: "borderWidth10",
    borderTopRightRadius: "borderRadius20",
    display: "flex",
    lineHeight: "lineHeight20",
    padding: "space30",
    ref
  }, children);
});
Suffix.displayName = "Suffix";
if (true) {
  Suffix.propTypes = {
    children: node3.isRequired,
    variant: oneOf3(["default", "inverse"])
  };
}

// src/InputBox.tsx
var InputBox = forwardRef6((_a, ref) => {
  var {children, disabled, hasError, insertAfter, insertBefore, readOnly, type, variant} = _a, props = __rest(_a, ["children", "disabled", "hasError", "insertAfter", "insertBefore", "readOnly", "type", "variant"]);
  return /* @__PURE__ */ createElement6(FieldWrapper, __assign({
    disabled,
    hasError,
    readOnly,
    type,
    variant,
    ref
  }, props), insertBefore && /* @__PURE__ */ createElement6(Prefix, {
    disabled,
    variant
  }, insertBefore), children, insertAfter && /* @__PURE__ */ createElement6(Suffix, {
    disabled,
    variant
  }, insertAfter));
});
InputBox.displayName = "InputBox";
if (true) {
  InputBox.propTypes = {
    children: node4.isRequired,
    disabled: bool2,
    hasError: bool2,
    insertAfter: node4,
    insertBefore: node4,
    readOnly: bool2,
    type: oneOf4(["text", "email", "hidden", "number", "password", "search", "tel"]),
    variant: oneOf4(["default", "inverse"])
  };
}

// src/InputChevronWrapper.tsx
import {
  createElement as createElement7,
  forwardRef as forwardRef7
} from "react";
import {Box as Box5} from "@twilio-paste/box";
var InputChevronWrapper = forwardRef7(({children}, ref) => /* @__PURE__ */ createElement7(Box5, {
  alignItems: "center",
  display: "inline-flex",
  position: "absolute",
  pointerEvents: "none",
  right: "space30",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: "zIndex10",
  ref
}, children));
export {
  InputBox,
  InputChevronWrapper,
  Prefix,
  Suffix
};
