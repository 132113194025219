import { graphql } from 'gatsby';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Avatar } from '@twilio-paste/avatar';
import { Paragraph } from '@twilio-paste/paragraph';
import { Alert } from '@twilio-paste/alert';
import { Menu, MenuButton, SubMenuButton, MenuItem, MenuGroup, MenuSeparator, useMenuState } from '@twilio-paste/menu';
import { MediaObject, MediaFigure, MediaBody } from '@twilio-paste/media-object';
import { ChevronDownIcon } from '@twilio-paste/icons/esm/ChevronDownIcon';
import { subMenuExample } from "../../../../src/component-examples/MenuExamples.ts";
import { Blockquote } from "../../../../src/components/Blockquote";
import { SidebarCategoryRoutes, TWILIO_RED } from "../../../../src/constants";
import { PasteIcon } from "../../../../src/components/icons/PasteIcon";
import Changelog from '@twilio-paste/media-object/CHANGELOG.md';
import * as React from 'react';
export default {
  graphql,
  Box,
  Text,
  Avatar,
  Paragraph,
  Alert,
  Menu,
  MenuButton,
  SubMenuButton,
  MenuItem,
  MenuGroup,
  MenuSeparator,
  useMenuState,
  MediaObject,
  MediaFigure,
  MediaBody,
  ChevronDownIcon,
  subMenuExample,
  Blockquote,
  SidebarCategoryRoutes,
  TWILIO_RED,
  PasteIcon,
  Changelog,
  React
};