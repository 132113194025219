import * as React from "react";
import {useUID} from "@twilio-paste/uid-library";
import {IconWrapper} from "./helpers/IconWrapper";
const DataBarChartIcon = ({as, display, size, color, title, decorative}) => {
  const titleId = `DataBarChartIcon-${useUID()}`;
  if (!decorative && title == null) {
    throw new Error("[DataBarChartIcon]: Missing a title for non-decorative icon.");
  }
  return /* @__PURE__ */ React.createElement(IconWrapper, {
    as,
    display,
    size,
    color
  }, /* @__PURE__ */ React.createElement("svg", {
    role: "img",
    "aria-hidden": decorative,
    width: "100%",
    height: "100%",
    viewBox: "0 0 20 20",
    fill: "none",
    "aria-labelledby": titleId
  }, title ? /* @__PURE__ */ React.createElement("title", {
    id: titleId
  }, title) : null, /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M8.293 3.293A1 1 0 019 3h2a1 1 0 011 1v12h1V8a1 1 0 011-1h2a1 1 0 011 1v8h.5a.5.5 0 010 1h-.998H13.5h-11a.5.5 0 110-1H3v-6a1 1 0 011-1h2a1 1 0 011 1v6h1V4a1 1 0 01.293-.707zM16 16V8h-2v8h2zM9 4v12h2V4H9zm-5 6h2v6H4v-6z"
  })));
};
DataBarChartIcon.displayName = "DataBarChartIcon";
export {
  DataBarChartIcon
};
