import { graphql } from 'gatsby';
import { useUID } from '@twilio-paste/uid-library';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { Button } from '@twilio-paste/button';
import { Grid, Column } from '@twilio-paste/grid';
import { Modal, ModalBody, ModalFooter, ModalFooterActions, ModalHeader, ModalHeading } from '@twilio-paste/modal';
import { FormLabel, FormInput, Select, Option } from '@twilio-paste/form';
import Changelog from '@twilio-paste/modal/CHANGELOG.md';
import { DoDont, Do, Dont } from "../../../../src/components/DoDont";
import { Codeblock } from "../../../../src/components/codeblock";
import { SidebarCategoryRoutes } from "../../../../src/constants";
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { defaultExample, initialFocusExample, wideExample } from "../../../../src/component-examples/ModalExamples";
import * as React from 'react';
export default {
  graphql,
  useUID,
  Anchor,
  Box,
  Text,
  Heading,
  Paragraph,
  Button,
  Grid,
  Column,
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalHeading,
  FormLabel,
  FormInput,
  Select,
  Option,
  Changelog,
  DoDont,
  Do,
  Dont,
  Codeblock,
  SidebarCategoryRoutes,
  Callout,
  CalloutTitle,
  CalloutText,
  defaultExample,
  initialFocusExample,
  wideExample,
  React
};