import * as React from "react";
import {useUID} from "@twilio-paste/uid-library";
import {IconWrapper} from "./helpers/IconWrapper";
const FilterIcon = ({as, display, size, color, title, decorative}) => {
  const titleId = `FilterIcon-${useUID()}`;
  if (!decorative && title == null) {
    throw new Error("[FilterIcon]: Missing a title for non-decorative icon.");
  }
  return /* @__PURE__ */ React.createElement(IconWrapper, {
    as,
    display,
    size,
    color
  }, /* @__PURE__ */ React.createElement("svg", {
    role: "img",
    "aria-hidden": decorative,
    width: "100%",
    height: "100%",
    viewBox: "0 0 20 20",
    "aria-labelledby": titleId
  }, title ? /* @__PURE__ */ React.createElement("title", {
    id: titleId
  }, title) : null, /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    fillRule: "evenodd",
    d: "M12 13a1 1 0 010 2H8a1 1 0 010-2h4zm2-4a1 1 0 010 2H6a1 1 0 010-2h8zm2-4a1 1 0 010 2H4a1 1 0 110-2h12z"
  })));
};
FilterIcon.displayName = "FilterIcon";
export {
  FilterIcon
};
