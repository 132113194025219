var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/index.tsx
import {
  Children,
  createElement,
  forwardRef,
  isValidElement,
  useMemo
} from "react";
import {
  oneOf
} from "prop-types";
import {useUIDSeed} from "@twilio-paste/uid-library";
import {
  isSpaceTokenProp,
  ResponsiveProp
} from "@twilio-paste/style-props";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
var getStackDisplay = (orientation) => {
  if (Array.isArray(orientation)) {
    return orientation.map((value) => {
      if (value === "horizontal") {
        return "flex";
      }
      return "block";
    });
  }
  if (orientation === "horizontal") {
    return "flex";
  }
  return "block";
};
var getStackStyles = (orientation) => {
  const styles = {
    display: getStackDisplay(orientation),
    alignItems: "center",
    flexWrap: "wrap"
  };
  return styles;
};
var getStackChildMargins = (orientation, spacing) => {
  let styles = {};
  if (Array.isArray(orientation)) {
    const marginRight = [];
    const marginBottom = [];
    orientation.forEach((value, i) => {
      marginRight[i] = value === "horizontal" ? spacing : "space0";
      marginBottom[i] = value === "horizontal" ? "space0" : spacing;
    });
    styles = {
      marginRight,
      marginBottom
    };
  }
  if (orientation === "horizontal") {
    styles = {marginRight: spacing};
  }
  if (orientation === "vertical") {
    styles = {marginBottom: spacing};
  }
  return styles;
};
var Stack = forwardRef((_a, ref) => {
  var {children, orientation, spacing} = _a, props = __rest(_a, ["children", "orientation", "spacing"]);
  const [childrenCount, validChildren] = useMemo(() => [
    Children.count(children),
    Children.toArray(children).filter((child) => isValidElement(child) || typeof child === "string")
  ], [children]);
  const stackStyles = useMemo(() => getStackStyles(orientation), [orientation]);
  const childMargins = useMemo(() => getStackChildMargins(orientation, spacing), [orientation, spacing]);
  const keySeed = useUIDSeed();
  return /* @__PURE__ */ createElement(Box, __assign(__assign(__assign({}, safelySpreadBoxProps(props)), stackStyles), {
    ref
  }), validChildren.map((child, index) => {
    return /* @__PURE__ */ createElement(Box, __assign(__assign({}, childrenCount !== index + 1 ? childMargins : null), {
      key: keySeed(`stack-${index}`)
    }), child);
  }));
});
Stack.displayName = "Stack";
if (true) {
  Stack.propTypes = {
    orientation: ResponsiveProp(oneOf(["horizontal", "vertical"])).isRequired,
    spacing: isSpaceTokenProp
  };
}
export {
  Stack,
  getStackChildMargins,
  getStackDisplay,
  getStackStyles
};
