// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-index-mdx": () => import("./../src/pages/404/index.mdx" /* webpackChunkName: "component---src-pages-404-index-mdx" */),
  "component---src-pages-changelog-index-mdx": () => import("./../src/pages/changelog/index.mdx" /* webpackChunkName: "component---src-pages-changelog-index-mdx" */),
  "component---src-pages-components-alert-index-mdx": () => import("./../src/pages/components/alert/index.mdx" /* webpackChunkName: "component---src-pages-components-alert-index-mdx" */),
  "component---src-pages-components-anchor-index-mdx": () => import("./../src/pages/components/anchor/index.mdx" /* webpackChunkName: "component---src-pages-components-anchor-index-mdx" */),
  "component---src-pages-components-avatar-index-mdx": () => import("./../src/pages/components/avatar/index.mdx" /* webpackChunkName: "component---src-pages-components-avatar-index-mdx" */),
  "component---src-pages-components-breadcrumb-index-mdx": () => import("./../src/pages/components/breadcrumb/index.mdx" /* webpackChunkName: "component---src-pages-components-breadcrumb-index-mdx" */),
  "component---src-pages-components-button-index-mdx": () => import("./../src/pages/components/button/index.mdx" /* webpackChunkName: "component---src-pages-components-button-index-mdx" */),
  "component---src-pages-components-card-index-mdx": () => import("./../src/pages/components/card/index.mdx" /* webpackChunkName: "component---src-pages-components-card-index-mdx" */),
  "component---src-pages-components-checkbox-index-mdx": () => import("./../src/pages/components/checkbox/index.mdx" /* webpackChunkName: "component---src-pages-components-checkbox-index-mdx" */),
  "component---src-pages-components-combobox-index-mdx": () => import("./../src/pages/components/combobox/index.mdx" /* webpackChunkName: "component---src-pages-components-combobox-index-mdx" */),
  "component---src-pages-components-disclosure-index-mdx": () => import("./../src/pages/components/disclosure/index.mdx" /* webpackChunkName: "component---src-pages-components-disclosure-index-mdx" */),
  "component---src-pages-components-heading-index-mdx": () => import("./../src/pages/components/heading/index.mdx" /* webpackChunkName: "component---src-pages-components-heading-index-mdx" */),
  "component---src-pages-components-index-mdx": () => import("./../src/pages/components/index.mdx" /* webpackChunkName: "component---src-pages-components-index-mdx" */),
  "component---src-pages-components-input-index-mdx": () => import("./../src/pages/components/input/index.mdx" /* webpackChunkName: "component---src-pages-components-input-index-mdx" */),
  "component---src-pages-components-list-index-mdx": () => import("./../src/pages/components/list/index.mdx" /* webpackChunkName: "component---src-pages-components-list-index-mdx" */),
  "component---src-pages-components-menu-index-mdx": () => import("./../src/pages/components/menu/index.mdx" /* webpackChunkName: "component---src-pages-components-menu-index-mdx" */),
  "component---src-pages-components-modal-index-mdx": () => import("./../src/pages/components/modal/index.mdx" /* webpackChunkName: "component---src-pages-components-modal-index-mdx" */),
  "component---src-pages-components-paragraph-index-mdx": () => import("./../src/pages/components/paragraph/index.mdx" /* webpackChunkName: "component---src-pages-components-paragraph-index-mdx" */),
  "component---src-pages-components-popover-index-mdx": () => import("./../src/pages/components/popover/index.mdx" /* webpackChunkName: "component---src-pages-components-popover-index-mdx" */),
  "component---src-pages-components-radio-group-index-mdx": () => import("./../src/pages/components/radio-group/index.mdx" /* webpackChunkName: "component---src-pages-components-radio-group-index-mdx" */),
  "component---src-pages-components-screen-reader-only-index-mdx": () => import("./../src/pages/components/screen-reader-only/index.mdx" /* webpackChunkName: "component---src-pages-components-screen-reader-only-index-mdx" */),
  "component---src-pages-components-select-index-mdx": () => import("./../src/pages/components/select/index.mdx" /* webpackChunkName: "component---src-pages-components-select-index-mdx" */),
  "component---src-pages-components-separator-index-mdx": () => import("./../src/pages/components/separator/index.mdx" /* webpackChunkName: "component---src-pages-components-separator-index-mdx" */),
  "component---src-pages-components-spinner-index-mdx": () => import("./../src/pages/components/spinner/index.mdx" /* webpackChunkName: "component---src-pages-components-spinner-index-mdx" */),
  "component---src-pages-components-table-index-mdx": () => import("./../src/pages/components/table/index.mdx" /* webpackChunkName: "component---src-pages-components-table-index-mdx" */),
  "component---src-pages-components-tabs-index-mdx": () => import("./../src/pages/components/tabs/index.mdx" /* webpackChunkName: "component---src-pages-components-tabs-index-mdx" */),
  "component---src-pages-components-textarea-index-mdx": () => import("./../src/pages/components/textarea/index.mdx" /* webpackChunkName: "component---src-pages-components-textarea-index-mdx" */),
  "component---src-pages-components-toast-index-mdx": () => import("./../src/pages/components/toast/index.mdx" /* webpackChunkName: "component---src-pages-components-toast-index-mdx" */),
  "component---src-pages-components-tooltip-index-mdx": () => import("./../src/pages/components/tooltip/index.mdx" /* webpackChunkName: "component---src-pages-components-tooltip-index-mdx" */),
  "component---src-pages-components-truncate-index-mdx": () => import("./../src/pages/components/truncate/index.mdx" /* webpackChunkName: "component---src-pages-components-truncate-index-mdx" */),
  "component---src-pages-content-get-support-index-mdx": () => import("./../src/pages/content/get-support/index.mdx" /* webpackChunkName: "component---src-pages-content-get-support-index-mdx" */),
  "component---src-pages-content-index-mdx": () => import("./../src/pages/content/index.mdx" /* webpackChunkName: "component---src-pages-content-index-mdx" */),
  "component---src-pages-content-product-style-guide-index-mdx": () => import("./../src/pages/content/product-style-guide/index.mdx" /* webpackChunkName: "component---src-pages-content-product-style-guide-index-mdx" */),
  "component---src-pages-content-voice-and-tone-index-mdx": () => import("./../src/pages/content/voice-and-tone/index.mdx" /* webpackChunkName: "component---src-pages-content-voice-and-tone-index-mdx" */),
  "component---src-pages-content-writing-for-components-index-mdx": () => import("./../src/pages/content/writing-for-components/index.mdx" /* webpackChunkName: "component---src-pages-content-writing-for-components-index-mdx" */),
  "component---src-pages-getting-started-about-paste-index-mdx": () => import("./../src/pages/getting-started/about-paste/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-about-paste-index-mdx" */),
  "component---src-pages-getting-started-design-index-mdx": () => import("./../src/pages/getting-started/design/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-design-index-mdx" */),
  "component---src-pages-getting-started-engineering-index-mdx": () => import("./../src/pages/getting-started/engineering/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-engineering-index-mdx" */),
  "component---src-pages-getting-started-faqs-index-mdx": () => import("./../src/pages/getting-started/faqs/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-faqs-index-mdx" */),
  "component---src-pages-getting-started-working-guide-index-mdx": () => import("./../src/pages/getting-started/working-guide/index.mdx" /* webpackChunkName: "component---src-pages-getting-started-working-guide-index-mdx" */),
  "component---src-pages-icons-how-to-add-an-icon-index-mdx": () => import("./../src/pages/icons/how-to-add-an-icon/index.mdx" /* webpackChunkName: "component---src-pages-icons-how-to-add-an-icon-index-mdx" */),
  "component---src-pages-icons-index-mdx": () => import("./../src/pages/icons/index.mdx" /* webpackChunkName: "component---src-pages-icons-index-mdx" */),
  "component---src-pages-icons-usage-guidelines-index-mdx": () => import("./../src/pages/icons/usage-guidelines/index.mdx" /* webpackChunkName: "component---src-pages-icons-usage-guidelines-index-mdx" */),
  "component---src-pages-illustrations-index-mdx": () => import("./../src/pages/illustrations/index.mdx" /* webpackChunkName: "component---src-pages-illustrations-index-mdx" */),
  "component---src-pages-inclusive-design-index-mdx": () => import("./../src/pages/inclusive-design/index.mdx" /* webpackChunkName: "component---src-pages-inclusive-design-index-mdx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-layout-aspect-ratio-index-mdx": () => import("./../src/pages/layout/aspect-ratio/index.mdx" /* webpackChunkName: "component---src-pages-layout-aspect-ratio-index-mdx" */),
  "component---src-pages-layout-flex-index-mdx": () => import("./../src/pages/layout/flex/index.mdx" /* webpackChunkName: "component---src-pages-layout-flex-index-mdx" */),
  "component---src-pages-layout-grid-index-mdx": () => import("./../src/pages/layout/grid/index.mdx" /* webpackChunkName: "component---src-pages-layout-grid-index-mdx" */),
  "component---src-pages-layout-index-mdx": () => import("./../src/pages/layout/index.mdx" /* webpackChunkName: "component---src-pages-layout-index-mdx" */),
  "component---src-pages-layout-media-object-index-mdx": () => import("./../src/pages/layout/media-object/index.mdx" /* webpackChunkName: "component---src-pages-layout-media-object-index-mdx" */),
  "component---src-pages-layout-stack-index-mdx": () => import("./../src/pages/layout/stack/index.mdx" /* webpackChunkName: "component---src-pages-layout-stack-index-mdx" */),
  "component---src-pages-libraries-codemods-index-mdx": () => import("./../src/pages/libraries/codemods/index.mdx" /* webpackChunkName: "component---src-pages-libraries-codemods-index-mdx" */),
  "component---src-pages-libraries-index-mdx": () => import("./../src/pages/libraries/index.mdx" /* webpackChunkName: "component---src-pages-libraries-index-mdx" */),
  "component---src-pages-libraries-uid-library-index-mdx": () => import("./../src/pages/libraries/uid-library/index.mdx" /* webpackChunkName: "component---src-pages-libraries-uid-library-index-mdx" */),
  "component---src-pages-patterns-button-vs-anchor-index-mdx": () => import("./../src/pages/patterns/button-vs-anchor/index.mdx" /* webpackChunkName: "component---src-pages-patterns-button-vs-anchor-index-mdx" */),
  "component---src-pages-patterns-contribute-index-mdx": () => import("./../src/pages/patterns/contribute/index.mdx" /* webpackChunkName: "component---src-pages-patterns-contribute-index-mdx" */),
  "component---src-pages-patterns-create-index-mdx": () => import("./../src/pages/patterns/create/index.mdx" /* webpackChunkName: "component---src-pages-patterns-create-index-mdx" */),
  "component---src-pages-patterns-delete-index-mdx": () => import("./../src/pages/patterns/delete/index.mdx" /* webpackChunkName: "component---src-pages-patterns-delete-index-mdx" */),
  "component---src-pages-patterns-index-mdx": () => import("./../src/pages/patterns/index.mdx" /* webpackChunkName: "component---src-pages-patterns-index-mdx" */),
  "component---src-pages-patterns-notifications-index-mdx": () => import("./../src/pages/patterns/notifications/index.mdx" /* webpackChunkName: "component---src-pages-patterns-notifications-index-mdx" */),
  "component---src-pages-patterns-object-details-index-mdx": () => import("./../src/pages/patterns/object-details/index.mdx" /* webpackChunkName: "component---src-pages-patterns-object-details-index-mdx" */),
  "component---src-pages-primitives-box-index-mdx": () => import("./../src/pages/primitives/box/index.mdx" /* webpackChunkName: "component---src-pages-primitives-box-index-mdx" */),
  "component---src-pages-primitives-combobox-primitive-index-mdx": () => import("./../src/pages/primitives/combobox-primitive/index.mdx" /* webpackChunkName: "component---src-pages-primitives-combobox-primitive-index-mdx" */),
  "component---src-pages-primitives-disclosure-primitive-index-mdx": () => import("./../src/pages/primitives/disclosure-primitive/index.mdx" /* webpackChunkName: "component---src-pages-primitives-disclosure-primitive-index-mdx" */),
  "component---src-pages-primitives-index-mdx": () => import("./../src/pages/primitives/index.mdx" /* webpackChunkName: "component---src-pages-primitives-index-mdx" */),
  "component---src-pages-primitives-menu-primitive-index-mdx": () => import("./../src/pages/primitives/menu-primitive/index.mdx" /* webpackChunkName: "component---src-pages-primitives-menu-primitive-index-mdx" */),
  "component---src-pages-primitives-modal-dialog-primitive-index-mdx": () => import("./../src/pages/primitives/modal-dialog-primitive/index.mdx" /* webpackChunkName: "component---src-pages-primitives-modal-dialog-primitive-index-mdx" */),
  "component---src-pages-primitives-non-modal-dialog-primitive-index-mdx": () => import("./../src/pages/primitives/non-modal-dialog-primitive/index.mdx" /* webpackChunkName: "component---src-pages-primitives-non-modal-dialog-primitive-index-mdx" */),
  "component---src-pages-primitives-tabs-primitive-index-mdx": () => import("./../src/pages/primitives/tabs-primitive/index.mdx" /* webpackChunkName: "component---src-pages-primitives-tabs-primitive-index-mdx" */),
  "component---src-pages-primitives-text-index-mdx": () => import("./../src/pages/primitives/text/index.mdx" /* webpackChunkName: "component---src-pages-primitives-text-index-mdx" */),
  "component---src-pages-primitives-tooltip-primitive-index-mdx": () => import("./../src/pages/primitives/tooltip-primitive/index.mdx" /* webpackChunkName: "component---src-pages-primitives-tooltip-primitive-index-mdx" */),
  "component---src-pages-principles-index-mdx": () => import("./../src/pages/principles/index.mdx" /* webpackChunkName: "component---src-pages-principles-index-mdx" */),
  "component---src-pages-roadmap-index-mdx": () => import("./../src/pages/roadmap/index.mdx" /* webpackChunkName: "component---src-pages-roadmap-index-mdx" */),
  "component---src-pages-tokens-design-tokens-package-index-mdx": () => import("./../src/pages/tokens/design-tokens-package/index.mdx" /* webpackChunkName: "component---src-pages-tokens-design-tokens-package-index-mdx" */),
  "component---src-pages-tokens-how-to-compose-custom-ui-with-tokens-index-mdx": () => import("./../src/pages/tokens/how-to-compose-custom-ui-with-tokens/index.mdx" /* webpackChunkName: "component---src-pages-tokens-how-to-compose-custom-ui-with-tokens-index-mdx" */),
  "component---src-pages-tokens-index-mdx": () => import("./../src/pages/tokens/index.mdx" /* webpackChunkName: "component---src-pages-tokens-index-mdx" */),
  "component---src-pages-tokens-theme-package-index-mdx": () => import("./../src/pages/tokens/theme-package/index.mdx" /* webpackChunkName: "component---src-pages-tokens-theme-package-index-mdx" */)
}

