import { graphql } from 'gatsby';
import { Alert } from '@twilio-paste/alert';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Breadcrumb, BreadcrumbItem } from "../../../../src/components/breadcrumb";
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { Card } from '@twilio-paste/card';
import { Disclosure, DisclosureHeading, DisclosureContent } from '@twilio-paste/disclosure';
import { Flex } from '@twilio-paste/flex';
import { FormInput, FormLabel, FormHelpText } from '@twilio-paste/form';
import { Grid, Column } from '@twilio-paste/grid';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { Stack } from '@twilio-paste/stack';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@twilio-paste/tabs';
import { Text } from '@twilio-paste/text';
import { Toast } from '@twilio-paste/toast';
import { ErrorIcon } from '@twilio-paste/icons/esm/ErrorIcon';
import { SuccessIcon } from '@twilio-paste/icons/esm/SuccessIcon';
import * as React from 'react';
export default {
  graphql,
  Alert,
  Anchor,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Callout,
  CalloutTitle,
  CalloutText,
  Card,
  Disclosure,
  DisclosureHeading,
  DisclosureContent,
  Flex,
  FormInput,
  FormLabel,
  FormHelpText,
  Grid,
  Column,
  Heading,
  Paragraph,
  Stack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Toast,
  ErrorIcon,
  SuccessIcon,
  React
};