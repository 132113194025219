import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Breadcrumb, BreadcrumbItem } from "../../../../src/components/breadcrumb";
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { Card } from '@twilio-paste/card';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { Stack } from '@twilio-paste/stack';
import { Text } from '@twilio-paste/text';
import { UnorderedList, ListItem } from '@twilio-paste/list';
import { PlusIcon } from '@twilio-paste/icons/esm/PlusIcon';
import * as React from 'react';
export default {
  graphql,
  Anchor,
  Breadcrumb,
  BreadcrumbItem,
  Box,
  Button,
  Callout,
  CalloutTitle,
  CalloutText,
  Card,
  Heading,
  Paragraph,
  Stack,
  Text,
  UnorderedList,
  ListItem,
  PlusIcon,
  React
};