import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { DoDont, Do, Dont } from "../../../../src/components/DoDont";
import * as React from 'react';
export default {
  graphql,
  Anchor,
  Callout,
  CalloutTitle,
  CalloutText,
  DoDont,
  Do,
  Dont,
  React
};