import * as React from "react";
import {useUID} from "@twilio-paste/uid-library";
import {IconWrapper} from "./helpers/IconWrapper";
const ChevronDisclosureIcon = ({as, display, size, color, title, decorative}) => {
  const titleId = `ChevronDisclosureIcon-${useUID()}`;
  if (!decorative && title == null) {
    throw new Error("[ChevronDisclosureIcon]: Missing a title for non-decorative icon.");
  }
  return /* @__PURE__ */ React.createElement(IconWrapper, {
    as,
    display,
    size,
    color
  }, /* @__PURE__ */ React.createElement("svg", {
    role: "img",
    "aria-hidden": decorative,
    width: "100%",
    height: "100%",
    viewBox: "0 0 24 24",
    "aria-labelledby": titleId
  }, title ? /* @__PURE__ */ React.createElement("title", {
    id: titleId
  }, title) : null, /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    fillRule: "evenodd",
    d: "M16.258 14.882l3.67-4.371a.305.305 0 00-.052-.442.34.34 0 00-.206-.069h-7.34c-.182 0-.33.14-.33.314 0 .072.026.141.072.197l3.67 4.371a.341.341 0 00.516 0z"
  })));
};
ChevronDisclosureIcon.displayName = "ChevronDisclosureIcon";
export {
  ChevronDisclosureIcon
};
