import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/ChangelogLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Change Log`}</h1>
    <h2>{`7.0.0`}</h2>
    <h3>{`Major Changes`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/1bcb8b3093920fdd871f8a13b498a8eb99201200"
          }}><inlineCode parentName="a">{`1bcb8b30`}</inlineCode></a>{` `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/pull/1307"
          }}>{`#1307`}</a>{` Thanks `}<a parentName="p" {...{
            "href": "https://github.com/TheSisb"
          }}>{`@TheSisb`}</a>{`! - BREAKING CHANGES:`}</p>
        <p parentName="li">{`Button components used as links (as="a" and href="") now automatically add an arrow icon if children is a string. This is a design breaking change that you should be aware of when upgrading.`}</p>
        <p parentName="li">{`Button also adds additional validation to throw an error if the `}<inlineCode parentName="p">{`disabled`}</inlineCode>{` or `}<inlineCode parentName="p">{`loading`}</inlineCode>{` props are set to `}<inlineCode parentName="p">{`true`}</inlineCode>{` for a Button as link. This is a functionality breaking change to existing disabled link buttons.`}</p>
      </li>
    </ul>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0eded1fd63f081ba9aeab5b5946218e1c5b9b316"
        }}><inlineCode parentName="a">{`0eded1fd`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1319"
        }}>{`#1319`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/SiTaggart"
        }}>{`@SiTaggart`}</a>{`! - Change internal dependencies to have minor range matching on version numbers`}</li>
    </ul>
    <h2>{`6.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/514bd5aa9fed6581bbc4c1de649457bcc8e87b3c"
        }}><inlineCode parentName="a">{`514bd5aa`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/514bd5aa9fed6581bbc4c1de649457bcc8e87b3c"
        }}><inlineCode parentName="a">{`514bd5aa`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.1"
            }}>{`theme@5.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@5.1.1"
            }}>{`icons@5.1.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@7.0.1"
            }}>{`spinner@7.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@2.0.2"
            }}>{`stack@2.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.2"
            }}>{`box@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.1"
            }}>{`style-props@3.0.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`6.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/8b5a8592848abba3975717c33ed9aed93f376087"
        }}><inlineCode parentName="a">{`8b5a8592`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@5.1.0"
            }}>{`icons@5.1.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@7.0.0"
            }}>{`spinner@7.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`5.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/509eba7a95325dd6f8adc3e905e22f92b7f004a9"
        }}><inlineCode parentName="a">{`509eba7a`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.1"
            }}>{`box@4.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@6.0.1"
            }}>{`spinner@6.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@2.0.1"
            }}>{`stack@2.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@5.0.1"
            }}>{`icons@5.0.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`5.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4c9ed5cac36ada218824d3e24bf45d4a03a12272"
        }}><inlineCode parentName="a">{`4c9ed5ca`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/26c828d8681e0e671f28b5f2856cd1803f13953f"
        }}><inlineCode parentName="a">{`26c828d8`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.6.0"
            }}>{`design-tokens@6.6.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.0"
            }}>{`theme@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@6.0.0"
            }}>{`spinner@6.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@2.0.0"
            }}>{`stack@2.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.0"
            }}>{`box@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@5.0.0"
            }}>{`icons@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.0"
            }}>{`style-props@3.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`4.0.3`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ac38757f0e426531862d5c562a2f2300cfa30592"
        }}><inlineCode parentName="a">{`ac38757f`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@1.0.2"
            }}>{`stack@1.0.2`}</a></li>
        </ul></li>
    </ul>
    <h2>{`4.0.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/944c340790b932f4714b0e6075c5641ecdbee9d6"
        }}><inlineCode parentName="a">{`944c3407`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/af77939865e030fb356dbc4193c8324e6261627a"
        }}><inlineCode parentName="a">{`af779398`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.5.2"
            }}>{`design-tokens@6.5.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@4.0.2"
            }}>{`icons@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@5.0.2"
            }}>{`spinner@5.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@1.0.1"
            }}>{`stack@1.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@3.0.1"
            }}>{`box@3.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.1"
            }}>{`style-props@2.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.1"
            }}>{`theme@4.3.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`4.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7340a6a552ddc45e35a786cbee4106998911458b"
        }}><inlineCode parentName="a">{`7340a6a5`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@4.0.1"
            }}>{`icons@4.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@5.0.1"
            }}>{`spinner@5.0.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`4.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f1675586933bcce71a6b5c5fec7d939735763a73"
        }}><inlineCode parentName="a">{`f1675586`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.0"
            }}>{`theme@4.3.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@5.0.0"
            }}>{`spinner@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@1.0.0"
            }}>{`stack@1.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@3.0.0"
            }}>{`box@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@4.0.0"
            }}>{`icons@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.0"
            }}>{`style-props@2.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`3.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{` `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/pull/1158"
          }}>{`#1158`}</a>{` Thanks `}<a parentName="p" {...{
            "href": "https://github.com/richbachman"
          }}>{`@richbachman`}</a>{`! - Pinned all twilio-paste package versions in order to keep them in sync with core when they are updated by changesets.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Updated dependencies [`}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`]:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:stack@0.3.2"
            }}>{`stack@0.3.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.2.2"
            }}>{`theme@4.2.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@1.9.2"
            }}>{`style-props@1.9.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@2.13.2"
            }}>{`box@2.13.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@3.13.1"
            }}>{`icons@3.13.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@4.0.1"
            }}>{`spinner@4.0.1`}</a></li>
        </ul>
      </li>
    </ul>
    <h2>{`3.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4114dac24d6b89f10aeeaeda2220825b9e146169"
        }}><inlineCode parentName="a">{`4114dac2`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:icons@3.13.0"
            }}>{`icons@3.13.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:spinner@4.0.0"
            }}>{`spinner@4.0.0`}</a></li>
        </ul></li>
    </ul>
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.5.0...@twilio-paste/button@2.5.1"
      }}>{`2.5.1`}</a>{` (2021-01-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.4.15...@twilio-paste/button@2.5.0"
      }}>{`2.5.0`}</a>{` (2021-01-21)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ESBuild for builds (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1084"
        }}>{`#1084`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/053646011508be10477d5b732269cdb0419235d7"
        }}>{`0536460`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.4.14...@twilio-paste/button@2.4.15"
      }}>{`2.4.15`}</a>{` (2021-01-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.4.13...@twilio-paste/button@2.4.14"
      }}>{`2.4.14`}</a>{` (2021-01-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.4.12...@twilio-paste/button@2.4.13"
      }}>{`2.4.13`}</a>{` (2021-01-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.4.11...@twilio-paste/button@2.4.12"
      }}>{`2.4.12`}</a>{` (2021-01-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.4.10...@twilio-paste/button@2.4.11"
      }}>{`2.4.11`}</a>{` (2020-12-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.4.9...@twilio-paste/button@2.4.10"
      }}>{`2.4.10`}</a>{` (2020-12-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.4.8...@twilio-paste/button@2.4.9"
      }}>{`2.4.9`}</a>{` (2020-12-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.4.7...@twilio-paste/button@2.4.8"
      }}>{`2.4.8`}</a>{` (2020-12-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.4.6...@twilio-paste/button@2.4.7"
      }}>{`2.4.7`}</a>{` (2020-12-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.4.5...@twilio-paste/button@2.4.6"
      }}>{`2.4.6`}</a>{` (2020-12-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.4.4...@twilio-paste/button@2.4.5"
      }}>{`2.4.5`}</a>{` (2020-12-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.4.3...@twilio-paste/button@2.4.4"
      }}>{`2.4.4`}</a>{` (2020-12-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.4.2...@twilio-paste/button@2.4.3"
      }}>{`2.4.3`}</a>{` (2020-11-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.4.1...@twilio-paste/button@2.4.2"
      }}>{`2.4.2`}</a>{` (2020-11-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.4.0...@twilio-paste/button@2.4.1"
      }}>{`2.4.1`}</a>{` (2020-11-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.28...@twilio-paste/button@2.4.0"
      }}>{`2.4.0`}</a>{` (2020-11-09)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` add icon_small size (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/56ed79d98a5e9603980ee2a85c29b0c5a9068ef3"
        }}>{`56ed79d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.27...@twilio-paste/button@2.3.28"
      }}>{`2.3.28`}</a>{` (2020-11-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.26...@twilio-paste/button@2.3.27"
      }}>{`2.3.27`}</a>{` (2020-11-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.25...@twilio-paste/button@2.3.26"
      }}>{`2.3.26`}</a>{` (2020-11-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.24...@twilio-paste/button@2.3.25"
      }}>{`2.3.25`}</a>{` (2020-10-30)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.23...@twilio-paste/button@2.3.24"
      }}>{`2.3.24`}</a>{` (2020-10-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.22...@twilio-paste/button@2.3.23"
      }}>{`2.3.23`}</a>{` (2020-10-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.21...@twilio-paste/button@2.3.22"
      }}>{`2.3.22`}</a>{` (2020-10-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.20...@twilio-paste/button@2.3.21"
      }}>{`2.3.21`}</a>{` (2020-10-21)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.19...@twilio-paste/button@2.3.20"
      }}>{`2.3.20`}</a>{` (2020-10-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.18...@twilio-paste/button@2.3.19"
      }}>{`2.3.19`}</a>{` (2020-10-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.17...@twilio-paste/button@2.3.18"
      }}>{`2.3.18`}</a>{` (2020-10-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.16...@twilio-paste/button@2.3.17"
      }}>{`2.3.17`}</a>{` (2020-10-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.15...@twilio-paste/button@2.3.16"
      }}>{`2.3.16`}</a>{` (2020-10-07)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` remove center justify content rule if reset (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/246c82bb489b4223f9ecbab07a1d14c6325415e4"
        }}>{`246c82b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.14...@twilio-paste/button@2.3.15"
      }}>{`2.3.15`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.13...@twilio-paste/button@2.3.14"
      }}>{`2.3.14`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.12...@twilio-paste/button@2.3.13"
      }}>{`2.3.13`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.11...@twilio-paste/button@2.3.12"
      }}>{`2.3.12`}</a>{` (2020-09-28)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` center content within buttons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/68d96a710b0d09b02dcf4e85ae615080769fa218"
        }}>{`68d96a7`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.10...@twilio-paste/button@2.3.11"
      }}>{`2.3.11`}</a>{` (2020-09-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.9...@twilio-paste/button@2.3.10"
      }}>{`2.3.10`}</a>{` (2020-09-21)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.8...@twilio-paste/button@2.3.9"
      }}>{`2.3.9`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.7...@twilio-paste/button@2.3.8"
      }}>{`2.3.8`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.6...@twilio-paste/button@2.3.7"
      }}>{`2.3.7`}</a>{` (2020-09-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.5...@twilio-paste/button@2.3.6"
      }}>{`2.3.6`}</a>{` (2020-09-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.4...@twilio-paste/button@2.3.5"
      }}>{`2.3.5`}</a>{` (2020-09-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.3...@twilio-paste/button@2.3.4"
      }}>{`2.3.4`}</a>{` (2020-09-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.2...@twilio-paste/button@2.3.3"
      }}>{`2.3.3`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.1...@twilio-paste/button@2.3.2"
      }}>{`2.3.2`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.3.0...@twilio-paste/button@2.3.1"
      }}>{`2.3.1`}</a>{` (2020-09-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.2.0...@twilio-paste/button@2.3.0"
      }}>{`2.3.0`}</a>{` (2020-09-02)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` add inverse_link button variant (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f3250a1ee1063848879d947c445d6c9df5d7052b"
        }}>{`f3250a1`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.1.6...@twilio-paste/button@2.2.0"
      }}>{`2.2.0`}</a>{` (2020-08-31)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` add desctructive_secondary variant (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/52dbfec786f63d11090c8082cee1132951ad18ce"
        }}>{`52dbfec`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.1.5...@twilio-paste/button@2.1.6"
      }}>{`2.1.6`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.1.4...@twilio-paste/button@2.1.5"
      }}>{`2.1.5`}</a>{` (2020-08-27)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` remove unnecessary link error (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/697"
        }}>{`#697`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0e92b7358d6751d6758f30828d0aea410c053314"
        }}>{`0e92b73`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.1.3...@twilio-paste/button@2.1.4"
      }}>{`2.1.4`}</a>{` (2020-08-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.1.2...@twilio-paste/button@2.1.3"
      }}>{`2.1.3`}</a>{` (2020-08-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.1.1...@twilio-paste/button@2.1.2"
      }}>{`2.1.2`}</a>{` (2020-08-12)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` reset variant should inherit type styles (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/efdd3d63e41cc6ff468371680fbb361b12be08c7"
        }}>{`efdd3d6`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.1.0...@twilio-paste/button@2.1.1"
      }}>{`2.1.1`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.0.2...@twilio-paste/button@2.1.0"
      }}>{`2.1.0`}</a>{` (2020-08-12)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` add inverse variant (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4a6f24139fa43edfd048894c887adea8e821d401"
        }}>{`4a6f241`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.0.1...@twilio-paste/button@2.0.2"
      }}>{`2.0.2`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@2.0.0...@twilio-paste/button@2.0.1"
      }}>{`2.0.1`}</a>{` (2020-08-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.28...@twilio-paste/button@2.0.0"
      }}>{`2.0.0`}</a>{` (2020-08-05)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` use display=block by default and rename 'iconColor' prop to 'color' (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/637"
        }}>{`#637`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/82beef487c514b2eab5c40b1ef1409842dd4ca82"
        }}>{`82beef4`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`icons:`}</strong>{` This change renames a key prop and changes the display mode from inline-flex to block`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix: use new icon prop (iconColor -> color)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`test: update snapshots`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(icons): update readme to mention correct display value`}</p>
      </li>
    </ul>
    <p>{`Co-authored-by: Rich Bachman `}<a parentName="p" {...{
        "href": "mailto:rb@richbachman.com"
      }}>{`rb@richbachman.com`}</a></p>
    <ul>
      <li parentName="ul">{`chore(website): update icon doc prop table`}</li>
    </ul>
    <p>{`Co-authored-by: Rich Bachman `}<a parentName="p" {...{
        "href": "mailto:rb@richbachman.com"
      }}>{`rb@richbachman.com`}</a></p>
    <p>{`Co-authored-by: Rich Bachman `}<a parentName="p" {...{
        "href": "mailto:rb@richbachman.com"
      }}>{`rb@richbachman.com`}</a></p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.27...@twilio-paste/button@1.2.28"
      }}>{`1.2.28`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.26...@twilio-paste/button@1.2.27"
      }}>{`1.2.27`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.25...@twilio-paste/button@1.2.26"
      }}>{`1.2.26`}</a>{` (2020-07-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.24...@twilio-paste/button@1.2.25"
      }}>{`1.2.25`}</a>{` (2020-07-30)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.23...@twilio-paste/button@1.2.24"
      }}>{`1.2.24`}</a>{` (2020-07-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.22...@twilio-paste/button@1.2.23"
      }}>{`1.2.23`}</a>{` (2020-07-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.21...@twilio-paste/button@1.2.22"
      }}>{`1.2.22`}</a>{` (2020-07-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.20...@twilio-paste/button@1.2.21"
      }}>{`1.2.21`}</a>{` (2020-07-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.19...@twilio-paste/button@1.2.20"
      }}>{`1.2.20`}</a>{` (2020-07-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.18...@twilio-paste/button@1.2.19"
      }}>{`1.2.19`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.17...@twilio-paste/button@1.2.18"
      }}>{`1.2.18`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.16...@twilio-paste/button@1.2.17"
      }}>{`1.2.17`}</a>{` (2020-07-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.15...@twilio-paste/button@1.2.16"
      }}>{`1.2.16`}</a>{` (2020-07-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.14...@twilio-paste/button@1.2.15"
      }}>{`1.2.15`}</a>{` (2020-07-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.13...@twilio-paste/button@1.2.14"
      }}>{`1.2.14`}</a>{` (2020-06-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.12...@twilio-paste/button@1.2.13"
      }}>{`1.2.13`}</a>{` (2020-06-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.11...@twilio-paste/button@1.2.12"
      }}>{`1.2.12`}</a>{` (2020-06-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.10...@twilio-paste/button@1.2.11"
      }}>{`1.2.11`}</a>{` (2020-06-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.9...@twilio-paste/button@1.2.10"
      }}>{`1.2.10`}</a>{` (2020-06-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.8...@twilio-paste/button@1.2.9"
      }}>{`1.2.9`}</a>{` (2020-06-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.7...@twilio-paste/button@1.2.8"
      }}>{`1.2.8`}</a>{` (2020-06-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.6...@twilio-paste/button@1.2.7"
      }}>{`1.2.7`}</a>{` (2020-06-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.5...@twilio-paste/button@1.2.6"
      }}>{`1.2.6`}</a>{` (2020-06-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.4...@twilio-paste/button@1.2.5"
      }}>{`1.2.5`}</a>{` (2020-06-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.3...@twilio-paste/button@1.2.4"
      }}>{`1.2.4`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.2...@twilio-paste/button@1.2.3"
      }}>{`1.2.3`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.1...@twilio-paste/button@1.2.2"
      }}>{`1.2.2`}</a>{` (2020-05-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.2.0...@twilio-paste/button@1.2.1"
      }}>{`1.2.1`}</a>{` (2020-05-27)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.45...@twilio-paste/button@1.2.0"
      }}>{`1.2.0`}</a>{` (2020-05-20)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` expose button ref (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/469694af3899d05f7d54fd66f13a7d32a23792d2"
        }}>{`469694a`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.44...@twilio-paste/button@1.1.45"
      }}>{`1.1.45`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.43...@twilio-paste/button@1.1.44"
      }}>{`1.1.44`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.42...@twilio-paste/button@1.1.43"
      }}>{`1.1.43`}</a>{` (2020-05-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.41...@twilio-paste/button@1.1.42"
      }}>{`1.1.42`}</a>{` (2020-05-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.40...@twilio-paste/button@1.1.41"
      }}>{`1.1.41`}</a>{` (2020-04-29)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` allow html button attributes, rework prop destructuring (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/6b8be875c3220237ab2ed4b6878b87ec30f44127"
        }}>{`6b8be87`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.39...@twilio-paste/button@1.1.40"
      }}>{`1.1.40`}</a>{` (2020-04-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.38...@twilio-paste/button@1.1.39"
      }}>{`1.1.39`}</a>{` (2020-04-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.37...@twilio-paste/button@1.1.38"
      }}>{`1.1.38`}</a>{` (2020-04-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.36...@twilio-paste/button@1.1.37"
      }}>{`1.1.37`}</a>{` (2020-04-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.35...@twilio-paste/button@1.1.36"
      }}>{`1.1.36`}</a>{` (2020-04-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.34...@twilio-paste/button@1.1.35"
      }}>{`1.1.35`}</a>{` (2020-04-08)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` package dependencies updated to be correct (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/9c82da548c4de61c879c7cce226d795b9703065e"
        }}>{`9c82da5`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.33...@twilio-paste/button@1.1.34"
      }}>{`1.1.34`}</a>{` (2020-04-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.32...@twilio-paste/button@1.1.33"
      }}>{`1.1.33`}</a>{` (2020-04-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.31...@twilio-paste/button@1.1.32"
      }}>{`1.1.32`}</a>{` (2020-04-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.30...@twilio-paste/button@1.1.31"
      }}>{`1.1.31`}</a>{` (2020-03-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.29...@twilio-paste/button@1.1.30"
      }}>{`1.1.30`}</a>{` (2020-03-24)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` switch from theme-tokens to theme package dep (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d9441c23e251f5be29217c8bb9a2d1644f3eec26"
        }}>{`d9441c2`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.28...@twilio-paste/button@1.1.29"
      }}>{`1.1.29`}</a>{` (2020-03-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.27...@twilio-paste/button@1.1.28"
      }}>{`1.1.28`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.26...@twilio-paste/button@1.1.27"
      }}>{`1.1.27`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.25...@twilio-paste/button@1.1.26"
      }}>{`1.1.26`}</a>{` (2020-03-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.24...@twilio-paste/button@1.1.25"
      }}>{`1.1.25`}</a>{` (2020-03-06)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`add missing transitive peerDep on '@styled-system/css' (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/fdbb81370cda71037348742a680299ce481eabfd"
        }}>{`fdbb813`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.23...@twilio-paste/button@1.1.24"
      }}>{`1.1.24`}</a>{` (2020-03-04)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` change borders to box-shadow (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/689061d4c4c9a5f033d74c4af7e0ca1f211b12d1"
        }}>{`689061d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.22...@twilio-paste/button@1.1.23"
      }}>{`1.1.23`}</a>{` (2020-03-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.21...@twilio-paste/button@1.1.22"
      }}>{`1.1.22`}</a>{` (2020-03-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.20...@twilio-paste/button@1.1.21"
      }}>{`1.1.21`}</a>{` (2020-02-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.19...@twilio-paste/button@1.1.20"
      }}>{`1.1.20`}</a>{` (2020-02-26)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`package dependencies and deprecation warnings (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/334"
        }}>{`#334`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0e88338511e6835a79eb0a9cea8d5b3a1cdf0a88"
        }}>{`0e88338`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.18...@twilio-paste/button@1.1.19"
      }}>{`1.1.19`}</a>{` (2020-02-21)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.17...@twilio-paste/button@1.1.18"
      }}>{`1.1.18`}</a>{` (2020-02-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.16...@twilio-paste/button@1.1.17"
      }}>{`1.1.17`}</a>{` (2020-02-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.15...@twilio-paste/button@1.1.16"
      }}>{`1.1.16`}</a>{` (2020-02-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.14...@twilio-paste/button@1.1.15"
      }}>{`1.1.15`}</a>{` (2020-02-14)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` add missing peer deps (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/38416d89c69a0238ba8cdd3a31705bcf57be7bdf"
        }}>{`38416d8`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.13...@twilio-paste/button@1.1.14"
      }}>{`1.1.14`}</a>{` (2020-02-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.12...@twilio-paste/button@1.1.13"
      }}>{`1.1.13`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.11...@twilio-paste/button@1.1.12"
      }}>{`1.1.12`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.10...@twilio-paste/button@1.1.11"
      }}>{`1.1.11`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.9...@twilio-paste/button@1.1.10"
      }}>{`1.1.10`}</a>{` (2020-02-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.8...@twilio-paste/button@1.1.9"
      }}>{`1.1.9`}</a>{` (2020-02-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.7...@twilio-paste/button@1.1.8"
      }}>{`1.1.8`}</a>{` (2020-01-27)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.6...@twilio-paste/button@1.1.7"
      }}>{`1.1.7`}</a>{` (2020-01-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.5...@twilio-paste/button@1.1.6"
      }}>{`1.1.6`}</a>{` (2020-01-24)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`storybook:`}</strong>{` fix vrt on loading button (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/275"
        }}>{`#275`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a560e89a960b5a0c4d03b95d6473fd55dbd732ee"
        }}>{`a560e89`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.4...@twilio-paste/button@1.1.5"
      }}>{`1.1.5`}</a>{` (2020-01-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.3...@twilio-paste/button@1.1.4"
      }}>{`1.1.4`}</a>{` (2019-12-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.2...@twilio-paste/button@1.1.3"
      }}>{`1.1.3`}</a>{` (2019-12-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.1...@twilio-paste/button@1.1.2"
      }}>{`1.1.2`}</a>{` (2019-12-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.1.0...@twilio-paste/button@1.1.1"
      }}>{`1.1.1`}</a>{` (2019-11-27)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` defensively set hover/focus/active styles from legacy globals (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/205"
        }}>{`#205`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2a9ce57a61a0ef9c1aadc0cfe0c9c112b4bc784e"
        }}>{`2a9ce57`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.0.2...@twilio-paste/button@1.1.0"
      }}>{`1.1.0`}</a>{` (2019-11-20)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`remap console semibold to medium font weight (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/195"
        }}>{`#195`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/c01f6b30cb0d9d9f24b1a8556b07bee8060f9052"
        }}>{`c01f6b3`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` make decorative required + icon docs (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/165"
        }}>{`#165`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/15ccbc2e0f259d60af83bf8503c8ee18a1191cb8"
        }}>{`15ccbc2`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.0.1...@twilio-paste/button@1.0.2"
      }}>{`1.0.2`}</a>{` (2019-11-18)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`correct button heights add new default icon size (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/186"
        }}>{`#186`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2e54478f975cb1930bd6d397d1214947db97c2a3"
        }}>{`2e54478`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` update some blues, reds, and focus shadow (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/185"
        }}>{`#185`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/98d70cc046db0284e151fab7f427ee16b8874c4a"
        }}>{`98d70cc`}</a>{`)`}</li>
      <li parentName="ul">{`update destructive button styles to be filled (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/189"
        }}>{`#189`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/c29b7cae1e4999ddcee75ba1897e3cf09c17e6df"
        }}>{`c29b7ca`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@1.0.0...@twilio-paste/button@1.0.1"
      }}>{`1.0.1`}</a>{` (2019-11-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@0.3.1...@twilio-paste/button@1.0.0"
      }}>{`1.0.0`}</a>{` (2019-11-11)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` add font-weight semibold to small buttons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/166"
        }}>{`#166`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/582245b10fe397bb5530dc7349a5b6b08b61d54e"
        }}>{`582245b`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` update button docs with loading and icon states (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/167"
        }}>{`#167`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/73808897ef181da087a5a80a17a80a74409a6c3c"
        }}>{`7380889`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` delete old icons, add new streamline icons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/129"
        }}>{`#129`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/571791ded8ee4c55bb5a3dbcebee4b17b2c7c826"
        }}>{`571791d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`typography:`}</strong>{` heading component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/149"
        }}>{`#149`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4e033e6f3e58566107271b18057532e768420827"
        }}>{`4e033e6`}</a>{`)`}</li>
      <li parentName="ul">{`enable theme switching on the docsite (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/124"
        }}>{`#124`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/df797e57ff1268367053d717f3b3c2ca48a0aa4f"
        }}>{`df797e5`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`icons:`}</strong>{` removed all the inherited icons since we're moving to a new system`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(icons): update icon list for storybook`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): use new icon`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(storybook): use new icon for story`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(story): button icons should be 24px`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix: adjust icon size tokens`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat: add iconSizes as separate key in theme-tokens`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat(icons): icons package to use tokens and update icons`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): update to use tokens and new icons`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(button): use correct spinner size`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(icons): major icons package fixes`}</p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`huge overhaul to build process`}</li>
      <li parentName="ul">{`now uses rollup`}</li>
      <li parentName="ul">{`builds into cjs and esm directories`}</li>
      <li parentName="ul">{`made the package publishable to npm`}</li>
      <li parentName="ul">{`moved svg folder out of src, now src only holds react stuff`}</li>
      <li parentName="ul">{`updated scripts to use new paths and cleaned up the code`}</li>
      <li parentName="ul">{`programmatically generates rollup config from the icon-list command`}</li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`chore: add new icons dist folders to gitignore`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix: spinner and button icon usage`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat(icons): add rollup icon list cache file`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(core-bundle): sort packages for consistent builds`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore: use `}<inlineCode parentName="p">{`esm`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`es`}</inlineCode>{` in rollup for correctness`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore: yarn.lock`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): lint error`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(icons): move rollup icon list`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(spinner): use types package in story`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): swap out destructured props for explicit props`}</p>
      </li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@0.3.0...@twilio-paste/button@0.3.1"
      }}>{`0.3.1`}</a>{` (2019-10-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/button`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@0.2.1...@twilio-paste/button@0.3.0"
      }}>{`0.3.0`}</a>{` (2019-10-29)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` border-color should transition with background-color (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/130"
        }}>{`#130`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/fda61ba05973a7ff9b652f7f234b2ed8a678bed4"
        }}>{`fda61ba`}</a>{`)`}</li>
      <li parentName="ul">{`remove the underline from button states (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/147"
        }}>{`#147`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/70d4c1534b5a026e2e24ba80fd9ec9bda062a762"
        }}>{`70d4c15`}</a>{`), closes `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/54"
        }}>{`#54`}</a></li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`upgrading to styled-system v5 (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/18"
        }}>{`#18`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2b3ebd8b2f701a0c6e8b75ab6978ba936814f455"
        }}>{`2b3ebd8`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/button@0.2.0...@twilio-paste/button@0.2.1"
      }}>{`0.2.1`}</a>{` (2019-09-16)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` adding some polish (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/67"
        }}>{`#67`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2644657"
        }}>{`2644657`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`button:`}</strong>{` adjust primary, secondary, desctructive styles (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/72"
        }}>{`#72`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/670faef"
        }}>{`670faef`}</a>{`)`}</li>
    </ul>
    <h1>{`0.2.0 (2019-08-15)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`change `}<a parentName="li" {...{
          "href": "https://github.com/paste"
        }}>{`@paste`}</a>{` scope to `}<a parentName="li" {...{
          "href": "https://github.com/twilio-paste"
        }}>{`@twilio-paste`}</a>{` (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/2"
        }}>{`#2`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1d8d2ff"
        }}>{`1d8d2ff`}</a>{`)`}</li>
      <li parentName="ul">{`run eslint across the right files and fix any issues that arise. (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/21"
        }}>{`#21`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2fcc872"
        }}>{`2fcc872`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`add Absolute component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/24"
        }}>{`#24`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ff2f1d9"
        }}>{`ff2f1d9`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      