var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Option.tsx
import {
  createElement,
  forwardRef
} from "react";
import {
  node,
  string
} from "prop-types";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
var Option = forwardRef((_a, ref) => {
  var {children} = _a, props = __rest(_a, ["children"]);
  if (props.selected) {
    console.warn('"selected" is a blocked prop on this component, please use "value" on the select element.');
  }
  return /* @__PURE__ */ createElement(Box, __assign(__assign({
    as: "option",
    ref
  }, safelySpreadBoxProps(props)), {
    size: void 0,
    height: void 0,
    width: void 0,
    background: "none",
    color: "inherit",
    fontFamily: "fontFamilyText",
    lineHeight: "lineHeight50",
    paddingTop: "space30",
    paddingBottom: "space30",
    paddingLeft: "space20"
  }), children);
});
Option.displayName = "Option";
if (true) {
  Option.propTypes = {
    value: string.isRequired,
    children: node.isRequired
  };
}

// src/OptionGroup.tsx
import {
  createElement as createElement2,
  forwardRef as forwardRef2
} from "react";
import {
  node as node2,
  string as string2
} from "prop-types";
import {Box as Box2, safelySpreadBoxProps as safelySpreadBoxProps2} from "@twilio-paste/box";
var OptionGroup = forwardRef2((_a, ref) => {
  var {label, children} = _a, props = __rest(_a, ["label", "children"]);
  return /* @__PURE__ */ createElement2(Box2, __assign(__assign({
    ref,
    label
  }, safelySpreadBoxProps2(props)), {
    as: "optgroup",
    background: "none",
    color: "inherit",
    fontFamily: "fontFamilyText",
    fontWeight: "fontWeightMedium",
    fontStyle: "normal",
    margin: "space20"
  }), children);
});
OptionGroup.displayName = "OptionGroup";
if (true) {
  OptionGroup.propTypes = {
    label: string2.isRequired,
    children: node2.isRequired
  };
}

// src/Select.tsx
import {
  createElement as createElement3,
  forwardRef as forwardRef3
} from "react";
import {
  bool,
  func,
  string as string3
} from "prop-types";
import {Box as Box3, safelySpreadBoxProps as safelySpreadBoxProps3} from "@twilio-paste/box";
import {ChevronDownIcon} from "@twilio-paste/icons/esm/ChevronDownIcon";
import {InputBox, InputChevronWrapper} from "@twilio-paste/input-box";
var SelectElement = forwardRef3((_a, ref) => {
  var {variant, size} = _a, props = __rest(_a, ["variant", "size"]);
  return /* @__PURE__ */ createElement3(Box3, __assign(__assign({}, safelySpreadBoxProps3(props)), {
    as: "select",
    ref,
    size,
    height: void 0,
    appearance: "none",
    background: "none",
    border: "none",
    borderRadius: "borderRadius20",
    boxShadow: "none",
    color: "inherit",
    cursor: "pointer",
    display: "block",
    fontFamily: "inherit",
    fontSize: "fontSize30",
    fontWeight: "fontWeightNormal",
    lineHeight: "lineHeight20",
    outline: "none",
    paddingBottom: "space30",
    paddingLeft: "space40",
    paddingRight: "space100",
    paddingTop: "space30",
    resize: "none",
    width: "100%",
    _disabled: {
      color: variant === "inverse" ? "colorTextInverseWeaker" : "colorTextWeaker",
      cursor: "not-allowed"
    }
  }));
});
var Select = forwardRef3((_a, ref) => {
  var {disabled, hasError, insertBefore, insertAfter, children, size, multiple, variant} = _a, props = __rest(_a, ["disabled", "hasError", "insertBefore", "insertAfter", "children", "size", "multiple", "variant"]);
  let iconColor = "colorTextIcon";
  if (disabled) {
    iconColor = "colorTextWeaker";
  } else if (variant === "inverse") {
    iconColor = "colorTextInverseWeak";
  }
  return /* @__PURE__ */ createElement3(InputBox, {
    disabled,
    hasError,
    insertAfter,
    insertBefore,
    variant
  }, /* @__PURE__ */ createElement3(Box3, {
    display: "flex",
    width: "100%",
    position: "relative"
  }, /* @__PURE__ */ createElement3(SelectElement, __assign(__assign({
    "aria-invalid": hasError,
    "data-not-selectize": "true",
    disabled,
    ref
  }, props), {
    multiple,
    size: multiple ? size : 0,
    variant
  }), children), !multiple && /* @__PURE__ */ createElement3(InputChevronWrapper, null, /* @__PURE__ */ createElement3(ChevronDownIcon, {
    "aria-hidden": "true",
    decorative: true,
    color: iconColor,
    size: "sizeIcon30"
  }))));
});
Select.displayName = "Select";
if (true) {
  Select.propTypes = {
    id: string3,
    hasError: bool,
    onChange: func
  };
}
export {
  Option,
  OptionGroup,
  Select
};
