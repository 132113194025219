var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Input.tsx
import {
  createElement,
  forwardRef
} from "react";
import {
  bool,
  func,
  oneOf,
  string
} from "prop-types";
import {styled, css} from "@twilio-paste/styling-library";
import {InputBox} from "@twilio-paste/input-box";

// src/utils.ts
var PROPS_TO_BLOCK = ["className", "style", "size", "height", "width"];
var safelySpreadFormControlProps = (props) => {
  return Object.keys(props).reduce((newProps, key) => {
    if (!PROPS_TO_BLOCK.includes(key)) {
      newProps[key] = props[key];
    }
    return newProps;
  }, {});
};

// src/Input.tsx
var InputElement = styled.input((props) => css({
  appearance: "none",
  background: "transparent",
  border: "none",
  borderRadius: "borderRadius20",
  boxShadow: "none",
  color: "inherit",
  display: "block",
  fontFamily: "inherit",
  fontSize: "fontSize30",
  fontWeight: "fontWeightNormal",
  lineHeight: "lineHeight20",
  outline: "none",
  paddingBottom: "space30",
  paddingLeft: "space40",
  paddingRight: "space40",
  paddingTop: "space30",
  resize: "none",
  width: "100%",
  "&::placeholder": {
    color: props.variant === "inverse" ? "colorTextInverseWeak" : "colorTextWeak",
    fontStyle: "italic"
  },
  "&:focus::placeholder": {
    color: props.variant === "inverse" ? "colorTextInverseWeak" : "colorTextWeak"
  },
  "&:disabled": {
    color: props.variant === "inverse" ? "colorTextInverseWeaker" : "colorTextWeaker",
    cursor: "not-allowed"
  }
}));
var Input = forwardRef((_a, ref) => {
  var {
    disabled,
    hasError,
    id,
    insertAfter,
    insertBefore,
    name,
    placeholder,
    readOnly,
    required,
    type,
    value,
    variant
  } = _a, props = __rest(_a, [
    "disabled",
    "hasError",
    "id",
    "insertAfter",
    "insertBefore",
    "name",
    "placeholder",
    "readOnly",
    "required",
    "type",
    "value",
    "variant"
  ]);
  const typeProps = {type};
  if (type === "number") {
    typeProps.type = "text";
    typeProps.inputmode = "numeric";
    typeProps.pattern = "[0-9]*";
  }
  return /* @__PURE__ */ createElement(InputBox, {
    disabled,
    hasError,
    insertAfter,
    insertBefore,
    readOnly,
    type,
    variant
  }, /* @__PURE__ */ createElement(InputElement, __assign(__assign(__assign({
    "aria-invalid": hasError,
    "aria-readonly": readOnly
  }, safelySpreadFormControlProps(props)), typeProps), {
    disabled,
    id,
    name,
    placeholder,
    readOnly,
    ref,
    required,
    value,
    variant
  })));
});
Input.displayName = "Input";
if (true) {
  Input.propTypes = {
    disabled: bool,
    hasError: bool,
    id: string,
    name: string,
    onBlur: func,
    onChange: func,
    onFocus: func,
    placeholder: string,
    readOnly: bool,
    required: bool,
    type: oneOf(["text", "email", "hidden", "number", "password", "search", "tel"]).isRequired,
    value: string
  };
}
export {
  Input,
  InputElement
};
