var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/index.tsx
import {
  createElement,
  forwardRef
} from "react";
import {
  oneOf
} from "prop-types";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
import {isMarginTokenProp} from "@twilio-paste/style-props";
var Separator = forwardRef((_a, ref) => {
  var {orientation, horizontalSpacing, verticalSpacing} = _a, props = __rest(_a, ["orientation", "horizontalSpacing", "verticalSpacing"]);
  return /* @__PURE__ */ createElement(Box, __assign(__assign({}, safelySpreadBoxProps(props)), {
    "aria-orientation": orientation,
    margin: "space0",
    marginBottom: verticalSpacing,
    marginLeft: horizontalSpacing,
    marginRight: horizontalSpacing,
    marginTop: verticalSpacing,
    as: "hr",
    borderWidth: "borderWidth0",
    borderColor: "colorBorderLight",
    borderStyle: "solid",
    borderBottomWidth: orientation === "horizontal" ? "borderWidth10" : null,
    borderLeftWidth: orientation === "vertical" ? "borderWidth10" : null,
    width: orientation === "horizontal" ? "auto" : null,
    height: orientation === "vertical" ? "auto" : null,
    ref
  }));
});
Separator.displayName = "Separator";
if (true) {
  Separator.propTypes = {
    orientation: oneOf(["horizontal", "vertical"]).isRequired,
    horizontalSpacing: isMarginTokenProp,
    verticalSpacing: isMarginTokenProp
  };
}
export {
  Separator
};
