var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Radio.tsx
import {
  createElement,
  forwardRef,
  useContext
} from "react";
import {
  bool,
  func,
  node,
  oneOfType,
  string
} from "prop-types";
import {useUID} from "@twilio-paste/uid-library";
import {Box} from "@twilio-paste/box";
import {
  BaseRadioCheckboxControl,
  BaseRadioCheckboxLabel,
  BaseRadioCheckboxLabelText,
  BaseRadioCheckboxHelpText
} from "@twilio-paste/base-radio-checkbox";

// src/RadioContext.tsx
import {
  createContext
} from "react";
var RadioContext = createContext({
  name: "",
  value: "",
  disabled: false,
  hasError: false,
  onChange: () => {
  }
});

// src/Radio.tsx
var HiddenRadio = forwardRef((props, ref) => /* @__PURE__ */ createElement(Box, __assign({
  as: "input",
  type: "radio",
  size: "size0",
  border: "none",
  overflow: "hidden",
  padding: "space0",
  margin: "space0",
  whiteSpace: "nowrap",
  textTransform: "none",
  position: "absolute",
  clip: "rect(0 0 0 0)",
  ref
}, props)));
var Radio = forwardRef((_a, ref) => {
  var {id, name, value, checked, disabled, hasError, onChange, children, helpText} = _a, props = __rest(_a, ["id", "name", "value", "checked", "disabled", "hasError", "onChange", "children", "helpText"]);
  const helpTextId = useUID();
  const radioGroupContext = useContext(RadioContext);
  const state = {
    name: name != null ? name : radioGroupContext.name,
    checked: checked != null ? checked : radioGroupContext.value === value,
    disabled: disabled != null ? disabled : radioGroupContext.disabled,
    hasError: hasError != null ? hasError : radioGroupContext.hasError,
    onChange: onChange != null ? onChange : radioGroupContext.onChange
  };
  return /* @__PURE__ */ createElement(Box, {
    position: "relative",
    display: "inline-flex",
    alignItems: "flex-start",
    flexDirection: "column",
    verticalAlign: "top"
  }, /* @__PURE__ */ createElement(HiddenRadio, __assign(__assign(__assign({}, props), state), {
    value,
    "aria-describedby": helpTextId,
    "aria-invalid": state.hasError,
    id,
    ref
  })), /* @__PURE__ */ createElement(BaseRadioCheckboxLabel, {
    disabled: state.disabled,
    htmlFor: id
  }, /* @__PURE__ */ createElement(BaseRadioCheckboxControl, {
    borderRadius: "borderRadiusCircle",
    disabled: state.disabled,
    type: "radio"
  }, /* @__PURE__ */ createElement(Box, {
    as: "span",
    backgroundColor: state.disabled && state.checked ? "colorBackgroundDarkest" : "colorBackgroundBody",
    borderRadius: "borderRadiusCircle",
    height: "sizeSquare25",
    width: "sizeSquare25"
  })), /* @__PURE__ */ createElement(BaseRadioCheckboxLabelText, null, children)), helpText && /* @__PURE__ */ createElement(BaseRadioCheckboxHelpText, {
    helpTextId
  }, helpText));
});
Radio.displayName = "Radio";
if (true) {
  Radio.propTypes = {
    id: string,
    value: string,
    name: string,
    checked: bool,
    disabled: bool,
    hasError: bool,
    helpText: oneOfType([string, node]),
    onChange: func,
    children: node.isRequired
  };
}

// src/RadioGroup.tsx
import {
  createElement as createElement2,
  forwardRef as forwardRef2,
  useMemo
} from "react";
import {
  func as func2,
  string as string2
} from "prop-types";
import {InlineControlGroup} from "@twilio-paste/inline-control-group";
var RadioGroup = forwardRef2((_a, ref) => {
  var {name, value, onChange, disabled = false, errorText, children} = _a, props = __rest(_a, ["name", "value", "onChange", "disabled", "errorText", "children"]);
  const onChangeHandler = useMemo(() => {
    return (event) => {
      if (onChange != null) {
        onChange(event.target.value);
      }
    };
  }, [onChange]);
  const contextValue = useMemo(() => {
    return {
      name,
      value: value || "",
      disabled,
      hasError: errorText != null,
      onChange: onChangeHandler
    };
  }, [name, value, disabled, errorText, onChangeHandler]);
  return /* @__PURE__ */ createElement2(RadioContext.Provider, {
    value: contextValue
  }, /* @__PURE__ */ createElement2(InlineControlGroup, __assign(__assign({}, props), {
    disabled,
    errorText,
    name,
    ref
  }), children));
});
RadioGroup.displayName = "RadioGroup";
if (true) {
  RadioGroup.propTypes = {
    name: string2.isRequired,
    onChange: func2.isRequired,
    value: string2
  };
}
export {
  Radio,
  RadioGroup
};
