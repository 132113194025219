import * as React from "react";
import {useUID} from "@twilio-paste/uid-library";
import {IconWrapper} from "./helpers/IconWrapper";
const ArrowForwardIcon = ({as, display, size, color, title, decorative}) => {
  const titleId = `ArrowForwardIcon-${useUID()}`;
  if (!decorative && title == null) {
    throw new Error("[ArrowForwardIcon]: Missing a title for non-decorative icon.");
  }
  return /* @__PURE__ */ React.createElement(IconWrapper, {
    as,
    display,
    size,
    color
  }, /* @__PURE__ */ React.createElement("svg", {
    role: "img",
    "aria-hidden": decorative,
    width: "100%",
    height: "100%",
    viewBox: "0 0 20 20",
    "aria-labelledby": titleId
  }, title ? /* @__PURE__ */ React.createElement("title", {
    id: titleId
  }, title) : null, /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    fillRule: "evenodd",
    d: "M14.991 9.91l.007.05v.08l-.01.07-.01.029a.489.489 0 01-.205.272l-3.832 3.444a.596.596 0 01-.78 0 .459.459 0 01-.063-.632l.063-.069 2.957-2.659H5.513A.504.504 0 015 10a.5.5 0 01.42-.488l.093-.008h7.604l-2.956-2.658a.459.459 0 01-.063-.632l.063-.069a.598.598 0 01.704-.057l.076.057 3.832 3.444c.098.064.172.16.206.272l.012.049z"
  })));
};
ArrowForwardIcon.displayName = "ArrowForwardIcon";
export {
  ArrowForwardIcon
};
