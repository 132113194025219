import { graphql } from 'gatsby';
import { Box } from '@twilio-paste/box';
import { Card } from '@twilio-paste/card';
import { Heading } from '@twilio-paste/heading';
import { Paragraph } from '@twilio-paste/paragraph';
import { DoDont, Do, Dont } from "../../../../src/components/DoDont";
import { SidebarCategoryRoutes } from "../../../../src/constants";
import Changelog from '@twilio-paste/heading/CHANGELOG.md';
import * as React from 'react';
export default {
  graphql,
  Box,
  Card,
  Heading,
  Paragraph,
  DoDont,
  Do,
  Dont,
  SidebarCategoryRoutes,
  Changelog,
  React
};