import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/ChangelogLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Change Log`}</h1>
    <h2>{`6.6.0`}</h2>
    <h3>{`Minor Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4c9ed5cac36ada218824d3e24bf45d4a03a12272"
        }}><inlineCode parentName="a">{`4c9ed5ca`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1236"
        }}>{`#1236`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/richbachman"
        }}>{`@richbachman`}</a>{`! - The SendGrid theme now inherits everything from the Default theme, except for font family. The SendGrid theme uses Colfax for `}<inlineCode parentName="li">{`font-family-text`}</inlineCode>{`.`}</li>
    </ul>
    <h2>{`6.5.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/944c340790b932f4714b0e6075c5641ecdbee9d6"
        }}><inlineCode parentName="a">{`944c3407`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1221"
        }}>{`#1221`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/SiTaggart"
        }}>{`@SiTaggart`}</a>{`! - Pointed font-weight-light to the font-weight-normal alias in the default theme as we do not want people using 300 weight text in our default UIs`}</li>
    </ul>
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.5.0...@twilio-paste/design-tokens@6.5.1"
      }}>{`6.5.1`}</a>{` (2021-01-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.4.1...@twilio-paste/design-tokens@6.5.0"
      }}>{`6.5.0`}</a>{` (2021-01-21)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ESBuild for builds (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1084"
        }}>{`#1084`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/053646011508be10477d5b732269cdb0419235d7"
        }}>{`0536460`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.4.0...@twilio-paste/design-tokens@6.4.1"
      }}>{`6.4.1`}</a>{` (2021-01-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.3.7...@twilio-paste/design-tokens@6.4.0"
      }}>{`6.4.0`}</a>{` (2020-12-17)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` switch default theme font family to inter var (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/796a3bbe2634a0f6c21c487b216c779b5069e6bb"
        }}>{`796a3bb`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.3.6...@twilio-paste/design-tokens@6.3.7"
      }}>{`6.3.7`}</a>{` (2020-12-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.3.5...@twilio-paste/design-tokens@6.3.6"
      }}>{`6.3.6`}</a>{` (2020-11-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.3.4...@twilio-paste/design-tokens@6.3.5"
      }}>{`6.3.5`}</a>{` (2020-10-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.3.3...@twilio-paste/design-tokens@6.3.4"
      }}>{`6.3.4`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.3.2...@twilio-paste/design-tokens@6.3.3"
      }}>{`6.3.3`}</a>{` (2020-09-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.3.1...@twilio-paste/design-tokens@6.3.2"
      }}>{`6.3.2`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.3.0...@twilio-paste/design-tokens@6.3.1"
      }}>{`6.3.1`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.2.2...@twilio-paste/design-tokens@6.3.0"
      }}>{`6.3.0`}</a>{` (2020-09-08)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` add line-height-0 (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/693165f246a3f4400febf299e446c920c2553f95"
        }}>{`693165f`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.2.1...@twilio-paste/design-tokens@6.2.2"
      }}>{`6.2.2`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.2.0...@twilio-paste/design-tokens@6.2.1"
      }}>{`6.2.1`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.1.1...@twilio-paste/design-tokens@6.2.0"
      }}>{`6.2.0`}</a>{` (2020-08-24)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-token:`}</strong>{` adjust the line-height scale (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/65310915230ae89c1f0ed11bc084d525ca2add55"
        }}>{`6531091`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.1.0...@twilio-paste/design-tokens@6.1.1"
      }}>{`6.1.1`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.0.1...@twilio-paste/design-tokens@6.1.0"
      }}>{`6.1.0`}</a>{` (2020-08-12)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` add missing udl boxShadow tokens (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2b6f6a1fa8e19b08a24832f0d501a8cc377dffbd"
        }}>{`2b6f6a1`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@6.0.0...@twilio-paste/design-tokens@6.0.1"
      }}>{`6.0.1`}</a>{` (2020-08-04)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` tidy offset aliases for ease of use (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0b8a00c90e2ffff8840641231578273f5fa45e60"
        }}>{`0b8a00c`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@5.5.0...@twilio-paste/design-tokens@6.0.0"
      }}>{`6.0.0`}</a>{` (2020-08-04)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` updates to default and console aliases and tokens (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a3d02e2acfa4ea9e8a9ffd1f83d21f7c22cd4b92"
        }}>{`a3d02e2`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` `}<inlineCode parentName="li">{`color-background-inverse`}</inlineCode>{` has been changed to a transparent color. Use `}<inlineCode parentName="li">{`color-background-body-inverse`}</inlineCode>{` instead.
`}<inlineCode parentName="li">{`color-text-label`}</inlineCode>{` has been removed.
`}<inlineCode parentName="li">{`color-text-placeholder`}</inlineCode>{` has been removed.`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@5.4.0...@twilio-paste/design-tokens@5.5.0"
      }}>{`5.5.0`}</a>{` (2020-07-22)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` add negative space tokens (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/3bc718fc9bcb4d8be952c70f71ceb21b0b307be2"
        }}>{`3bc718f`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@5.3.2...@twilio-paste/design-tokens@5.4.0"
      }}>{`5.4.0`}</a>{` (2020-07-14)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` add color-border-light and color-border-lighter (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/83d0b045bed4cc904fbc5ab4f69ff7ed62909071"
        }}>{`83d0b04`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@5.3.1...@twilio-paste/design-tokens@5.3.2"
      }}>{`5.3.2`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@5.3.0...@twilio-paste/design-tokens@5.3.1"
      }}>{`5.3.1`}</a>{` (2020-07-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@5.2.2...@twilio-paste/design-tokens@5.3.0"
      }}>{`5.3.0`}</a>{` (2020-06-25)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` adding 2 new border-width tokens (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/69ae496699d53b3837bf11542b7104846c82e86e"
        }}>{`69ae496`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@5.2.1...@twilio-paste/design-tokens@5.2.2"
      }}>{`5.2.2`}</a>{` (2020-06-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@5.2.0...@twilio-paste/design-tokens@5.2.1"
      }}>{`5.2.1`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@5.1.0...@twilio-paste/design-tokens@5.2.0"
      }}>{`5.2.0`}</a>{` (2020-05-20)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` create color-text-weaker token (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/3c63e32a00fac5d3750f8b177759ae1862761802"
        }}>{`3c63e32`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@5.0.0...@twilio-paste/design-tokens@5.1.0"
      }}>{`5.1.0`}</a>{` (2020-05-07)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` friendly token values (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/427"
        }}>{`#427`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/43104e6f21e928299c83b7f8d1f3175396aea791"
        }}>{`43104e6`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@4.5.1...@twilio-paste/design-tokens@5.0.0"
      }}>{`5.0.0`}</a>{` (2020-05-07)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` updates to border and shadow-border tokens (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/c7f687d07a2bdfc5466269c05a398c16adf29536"
        }}>{`c7f687d`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` Removed and renamed some border and shadow border tokens`}</li>
    </ul>
    <ul>
      <li parentName="ul">{`removed color-border-input -> use color-border`}</li>
      <li parentName="ul">{`removed color-border-input-hover`}</li>
      <li parentName="ul">{`removed color-border-input-focus`}</li>
      <li parentName="ul">{`renamed shadow-border-input -> shadow-border`}</li>
      <li parentName="ul">{`renamed shadow-border-input-hover -> shadow-border-primary-dark`}</li>
      <li parentName="ul">{`renamed shadow-border-input-disabled -> shadow-border-light`}</li>
      <li parentName="ul">{`renamed shadow-border-input-error -> shadow-border-error`}</li>
      <li parentName="ul">{`renamed shadow-border-input-error-hover -> shadow-border-error-dark`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@4.5.0...@twilio-paste/design-tokens@4.5.1"
      }}>{`4.5.1`}</a>{` (2020-05-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@4.4.1...@twilio-paste/design-tokens@4.5.0"
      }}>{`4.5.0`}</a>{` (2020-04-25)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` add background and square sizes tokens (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7c8f8e6d2656e681da22c5552e833d4f0a947e1d"
        }}>{`7c8f8e6`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@4.4.0...@twilio-paste/design-tokens@4.4.1"
      }}>{`4.4.1`}</a>{` (2020-04-22)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`tokens:`}</strong>{` Add shadow-border-input-`}{`*`}{` aliases, tokens to themes (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/400"
        }}>{`#400`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/a5531003b713b56f1fd5078cccdbdc4879943fc5"
        }}>{`a553100`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@4.3.0...@twilio-paste/design-tokens@4.4.0"
      }}>{`4.4.0`}</a>{` (2020-04-07)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` add overlay background token, switch to rgb colors (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d9f783b5d46767ab2d433f5619b71fe98c0092fa"
        }}>{`d9f783b`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@4.2.0...@twilio-paste/design-tokens@4.3.0"
      }}>{`4.3.0`}</a>{` (2020-03-11)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` add boxShadow tokens for input borders (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/20022ecc1f4987ae59bd365eca4c1797089cfa7a"
        }}>{`20022ec`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@4.1.4...@twilio-paste/design-tokens@4.2.0"
      }}>{`4.2.0`}</a>{` (2020-03-02)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` consistent iconsize to line-height tokens (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/339"
        }}>{`#339`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/00cb4417551a046518bfc7a049201e49ddac1641"
        }}>{`00cb441`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@4.1.3...@twilio-paste/design-tokens@4.1.4"
      }}>{`4.1.4`}</a>{` (2020-02-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@4.1.2...@twilio-paste/design-tokens@4.1.3"
      }}>{`4.1.3`}</a>{` (2020-02-26)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`package dependencies and deprecation warnings (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/334"
        }}>{`#334`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0e88338511e6835a79eb0a9cea8d5b3a1cdf0a88"
        }}>{`0e88338`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@4.1.1...@twilio-paste/design-tokens@4.1.2"
      }}>{`4.1.2`}</a>{` (2020-02-13)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` change sg gray-70 and color-border-inpt (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/311"
        }}>{`#311`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/642cfcc78ef021f4c100a1c4eaecf786a2ad3655"
        }}>{`642cfcc`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@4.1.0...@twilio-paste/design-tokens@4.1.1"
      }}>{`4.1.1`}</a>{` (2020-02-03)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`tokens:`}</strong>{` update tokens for sg disabled buttons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/286"
        }}>{`#286`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/27074eb97e9ec32d5b2a8ec07b48da1fe79f6323"
        }}>{`27074eb`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@4.0.0...@twilio-paste/design-tokens@4.1.0"
      }}>{`4.1.0`}</a>{` (2020-01-17)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-token:`}</strong>{` add new status tokens (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/462f4713ab092268bd40b9bfd0d987043a7d7805"
        }}>{`462f471`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@3.0.5...@twilio-paste/design-tokens@4.0.0"
      }}>{`4.0.0`}</a>{` (2019-12-20)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` create paste and console themes (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d550e4e3ad29ef8d428031ff3e915e8a511df1c8"
        }}>{`d550e4e`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` Move the default theme values to be the console theme
Make the default theme the Paste theme based on new unified design language`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@3.0.4...@twilio-paste/design-tokens@3.0.5"
      }}>{`3.0.5`}</a>{` (2019-12-19)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`SG:`}</strong>{` change `}<inlineCode parentName="li">{`color-text-weak`}</inlineCode>{` to \`gray-80 for a11y (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/225"
        }}>{`#225`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/12e12614acb87d70fcc07be92f8c04496c787214"
        }}>{`12e1261`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@3.0.3...@twilio-paste/design-tokens@3.0.4"
      }}>{`3.0.4`}</a>{` (2019-12-05)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`console:`}</strong>{` change "color-text-inverse-weak" for a11y (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/218"
        }}>{`#218`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/c29de58f7371634f331e8c8fc98902ea638001aa"
        }}>{`c29de58`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@3.0.2...@twilio-paste/design-tokens@3.0.3"
      }}>{`3.0.3`}</a>{` (2019-11-20)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`remap console semibold to medium font weight (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/195"
        }}>{`#195`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/c01f6b30cb0d9d9f24b1a8556b07bee8060f9052"
        }}>{`c01f6b3`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@3.0.1...@twilio-paste/design-tokens@3.0.2"
      }}>{`3.0.2`}</a>{` (2019-11-18)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` change color-text-link token values (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/172"
        }}>{`#172`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f0a215cb283f74f268387e744099e337c6489ebd"
        }}>{`f0a215c`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` update lightest console color palette (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/174"
        }}>{`#174`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/98b625cc0998381b9a9d43cf590d1bc9cbbed6b6"
        }}>{`98b625c`}</a>{`)`}</li>
      <li parentName="ul">{`correct button heights add new default icon size (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/186"
        }}>{`#186`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2e54478f975cb1930bd6d397d1214947db97c2a3"
        }}>{`2e54478`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` update some blues, reds, and focus shadow (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/185"
        }}>{`#185`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/98d70cc046db0284e151fab7f427ee16b8874c4a"
        }}>{`98d70cc`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@3.0.0...@twilio-paste/design-tokens@3.0.1"
      }}>{`3.0.1`}</a>{` (2019-11-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/design-tokens`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@2.5.0...@twilio-paste/design-tokens@3.0.0"
      }}>{`3.0.0`}</a>{` (2019-11-11)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` delete old icons, add new streamline icons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/129"
        }}>{`#129`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/571791ded8ee4c55bb5a3dbcebee4b17b2c7c826"
        }}>{`571791d`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`typography:`}</strong>{` heading component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/149"
        }}>{`#149`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4e033e6f3e58566107271b18057532e768420827"
        }}>{`4e033e6`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` removed all the inherited icons since we're moving to a new system`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@2.4.0...@twilio-paste/design-tokens@2.5.0"
      }}>{`2.5.0`}</a>{` (2019-10-29)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`token formatter tests (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/102"
        }}>{`#102`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1599e1e89b2d64eb2a2898f0240199ac36428f5b"
        }}>{`1599e1e`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`design token and theme package documentation (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/103"
        }}>{`#103`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/30601132ccdec8f0fee79e0f293c80d0c0cff335"
        }}>{`3060113`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/design-tokens@2.3.0...@twilio-paste/design-tokens@2.4.0"
      }}>{`2.4.0`}</a>{` (2019-09-16)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` fix SendGrid token values (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/71"
        }}>{`#71`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/396fccf"
        }}>{`396fccf`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` typo on word `}<inlineCode parentName="li">{`borderr`}</inlineCode>{` (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/56"
        }}>{`#56`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/6b5edd1"
        }}>{`6b5edd1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`tokens:`}</strong>{` correct the box shadow tokens category and type (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/95"
        }}>{`#95`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/fe0f946"
        }}>{`fe0f946`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`tokens:`}</strong>{` Add box-shadow and line-height tokens (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/35"
        }}>{`#35`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/706643b"
        }}>{`706643b`}</a>{`)`}</li>
    </ul>
    <h1>{`2.3.0 (2019-08-15)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`design-tokens:`}</strong>{` resolve path correctly (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/6f89111"
        }}>{`6f89111`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`tokens:`}</strong>{` added missing SG token values (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/22"
        }}>{`#22`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/23d7385"
        }}>{`23d7385`}</a>{`)`}</li>
      <li parentName="ul">{`change `}<a parentName="li" {...{
          "href": "https://github.com/paste"
        }}>{`@paste`}</a>{` scope to `}<a parentName="li" {...{
          "href": "https://github.com/twilio-paste"
        }}>{`@twilio-paste`}</a>{` (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/2"
        }}>{`#2`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1d8d2ff"
        }}>{`1d8d2ff`}</a>{`)`}</li>
      <li parentName="ul">{`run eslint across the right files and fix any issues that arise. (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/21"
        }}>{`#21`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2fcc872"
        }}>{`2fcc872`}</a>{`)`}</li>
      <li parentName="ul">{`swap light/dark border token colors (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0a99d3c"
        }}>{`0a99d3c`}</a>{`)`}</li>
      <li parentName="ul">{`Type-checking fixes (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/12"
        }}>{`#12`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/be02450"
        }}>{`be02450`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`change the z-index scale to 10s (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/34"
        }}>{`#34`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/ed05089"
        }}>{`ed05089`}</a>{`)`}</li>
      <li parentName="ul">{`ComponentHeader component, component overview fixes, more (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/20"
        }}>{`#20`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/875e124"
        }}>{`875e124`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` Add text color token accessibility rating to the token page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/28"
        }}>{`#28`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/74f12d1"
        }}>{`74f12d1`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` Add the tokens page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/19"
        }}>{`#19`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/878d3c5"
        }}>{`878d3c5`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` Callout component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/25"
        }}>{`#25`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/e4a3d00"
        }}>{`e4a3d00`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      