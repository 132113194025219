// src/index.tsx
import {
  createElement,
  forwardRef
} from "react";
import {
  node,
  string
} from "prop-types";
import {Box} from "@twilio-paste/box";
import {styled} from "@twilio-paste/styling-library";
var RATIO_REGEX = /^(\d+:\d*)$/;
var isCorrectPattern = (ratio) => RATIO_REGEX.test(ratio);
var handlePropValidation = ({ratio}) => {
  const hasRatio = ratio != null && ratio !== "";
  if (!hasRatio) {
    throw new Error(`[Paste: AspectRatio] Missing 'ratio' prop.`);
  }
  if (!isCorrectPattern(ratio)) {
    throw new Error(`[Paste: AspectRatio] 'ratio' is invalid. Use a colon separated number pattern (4:3).`);
  }
};
var AspectRatioContainer = styled.div`
  position: relative;

  embed,
  iframe,
  object,
  video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`;
var AspectRatio = forwardRef((props, ref) => {
  handlePropValidation(props);
  const aspectArray = props.ratio.split(":").map(Number);
  const aspectPercent = aspectArray[1] / aspectArray[0] * 100;
  return /* @__PURE__ */ createElement(AspectRatioContainer, {
    ref,
    style: {paddingBottom: `${aspectPercent}%`}
  }, /* @__PURE__ */ createElement(Box, {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  }, props.children));
});
AspectRatio.displayName = "AspectRatio";
if (true) {
  AspectRatio.propTypes = {
    children: node.isRequired,
    ratio: string.isRequired
  };
}
export {
  AspectRatio
};
