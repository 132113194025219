module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145457417-1","anonymize":true,"respectDNT":true},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1632,"linkImagesToOriginal":false},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx","packages":"/opt/buildhome/repo/packages/paste-website/src/layouts/ChangelogLayout.tsx","websiteCore":"/opt/buildhome/repo/packages/paste-website/src/layouts/GenericLayout.tsx"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1632,"linkImagesToOriginal":false}}]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Paste: The Design System for building Twilio customer experiences","short_name":"Paste","start_url":"/","background_color":"#fff","theme_color":"#f22f46","display":"standalone","icon":"static/logo.svg"},
    }]
