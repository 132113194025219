var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __commonJS = (callback, module) => () => {
  if (!module) {
    module = {exports: {}};
    callback(module.exports, module);
  }
  return module.exports;
};

// ../../../../node_modules/lodash.groupby/index.js
var require_lodash = __commonJS((exports, module) => {
  var LARGE_ARRAY_SIZE = 200;
  var FUNC_ERROR_TEXT = "Expected a function";
  var HASH_UNDEFINED = "__lodash_hash_undefined__";
  var UNORDERED_COMPARE_FLAG = 1;
  var PARTIAL_COMPARE_FLAG = 2;
  var INFINITY = 1 / 0;
  var MAX_SAFE_INTEGER = 9007199254740991;
  var argsTag = "[object Arguments]";
  var arrayTag = "[object Array]";
  var boolTag = "[object Boolean]";
  var dateTag = "[object Date]";
  var errorTag = "[object Error]";
  var funcTag = "[object Function]";
  var genTag = "[object GeneratorFunction]";
  var mapTag = "[object Map]";
  var numberTag = "[object Number]";
  var objectTag = "[object Object]";
  var promiseTag = "[object Promise]";
  var regexpTag = "[object RegExp]";
  var setTag = "[object Set]";
  var stringTag = "[object String]";
  var symbolTag = "[object Symbol]";
  var weakMapTag = "[object WeakMap]";
  var arrayBufferTag = "[object ArrayBuffer]";
  var dataViewTag = "[object DataView]";
  var float32Tag = "[object Float32Array]";
  var float64Tag = "[object Float64Array]";
  var int8Tag = "[object Int8Array]";
  var int16Tag = "[object Int16Array]";
  var int32Tag = "[object Int32Array]";
  var uint8Tag = "[object Uint8Array]";
  var uint8ClampedTag = "[object Uint8ClampedArray]";
  var uint16Tag = "[object Uint16Array]";
  var uint32Tag = "[object Uint32Array]";
  var reIsDeepProp = /\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/;
  var reIsPlainProp = /^\w*$/;
  var reLeadingDot = /^\./;
  var rePropName = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g;
  var reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
  var reEscapeChar = /\\(\\)?/g;
  var reIsHostCtor = /^\[object .+?Constructor\]$/;
  var reIsUint = /^(?:0|[1-9]\d*)$/;
  var typedArrayTags = {};
  typedArrayTags[float32Tag] = typedArrayTags[float64Tag] = typedArrayTags[int8Tag] = typedArrayTags[int16Tag] = typedArrayTags[int32Tag] = typedArrayTags[uint8Tag] = typedArrayTags[uint8ClampedTag] = typedArrayTags[uint16Tag] = typedArrayTags[uint32Tag] = true;
  typedArrayTags[argsTag] = typedArrayTags[arrayTag] = typedArrayTags[arrayBufferTag] = typedArrayTags[boolTag] = typedArrayTags[dataViewTag] = typedArrayTags[dateTag] = typedArrayTags[errorTag] = typedArrayTags[funcTag] = typedArrayTags[mapTag] = typedArrayTags[numberTag] = typedArrayTags[objectTag] = typedArrayTags[regexpTag] = typedArrayTags[setTag] = typedArrayTags[stringTag] = typedArrayTags[weakMapTag] = false;
  var freeGlobal = typeof global == "object" && global && global.Object === Object && global;
  var freeSelf = typeof self == "object" && self && self.Object === Object && self;
  var root = freeGlobal || freeSelf || Function("return this")();
  var freeExports = typeof exports == "object" && exports && !exports.nodeType && exports;
  var freeModule = freeExports && typeof module == "object" && module && !module.nodeType && module;
  var moduleExports = freeModule && freeModule.exports === freeExports;
  var freeProcess = moduleExports && freeGlobal.process;
  var nodeUtil = function() {
    try {
      return freeProcess && freeProcess.binding("util");
    } catch (e) {
    }
  }();
  var nodeIsTypedArray = nodeUtil && nodeUtil.isTypedArray;
  function arrayAggregator(array, setter, iteratee, accumulator) {
    var index = -1, length = array ? array.length : 0;
    while (++index < length) {
      var value = array[index];
      setter(accumulator, value, iteratee(value), array);
    }
    return accumulator;
  }
  function arraySome(array, predicate) {
    var index = -1, length = array ? array.length : 0;
    while (++index < length) {
      if (predicate(array[index], index, array)) {
        return true;
      }
    }
    return false;
  }
  function baseProperty(key) {
    return function(object2) {
      return object2 == null ? void 0 : object2[key];
    };
  }
  function baseTimes(n, iteratee) {
    var index = -1, result = Array(n);
    while (++index < n) {
      result[index] = iteratee(index);
    }
    return result;
  }
  function baseUnary(func2) {
    return function(value) {
      return func2(value);
    };
  }
  function getValue(object2, key) {
    return object2 == null ? void 0 : object2[key];
  }
  function isHostObject(value) {
    var result = false;
    if (value != null && typeof value.toString != "function") {
      try {
        result = !!(value + "");
      } catch (e) {
      }
    }
    return result;
  }
  function mapToArray(map) {
    var index = -1, result = Array(map.size);
    map.forEach(function(value, key) {
      result[++index] = [key, value];
    });
    return result;
  }
  function overArg(func2, transform) {
    return function(arg) {
      return func2(transform(arg));
    };
  }
  function setToArray(set) {
    var index = -1, result = Array(set.size);
    set.forEach(function(value) {
      result[++index] = value;
    });
    return result;
  }
  var arrayProto = Array.prototype;
  var funcProto = Function.prototype;
  var objectProto = Object.prototype;
  var coreJsData = root["__core-js_shared__"];
  var maskSrcKey = function() {
    var uid = /[^.]+$/.exec(coreJsData && coreJsData.keys && coreJsData.keys.IE_PROTO || "");
    return uid ? "Symbol(src)_1." + uid : "";
  }();
  var funcToString = funcProto.toString;
  var hasOwnProperty = objectProto.hasOwnProperty;
  var objectToString = objectProto.toString;
  var reIsNative = RegExp("^" + funcToString.call(hasOwnProperty).replace(reRegExpChar, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$");
  var Symbol = root.Symbol;
  var Uint8Array2 = root.Uint8Array;
  var propertyIsEnumerable = objectProto.propertyIsEnumerable;
  var splice = arrayProto.splice;
  var nativeKeys = overArg(Object.keys, Object);
  var DataView = getNative(root, "DataView");
  var Map2 = getNative(root, "Map");
  var Promise2 = getNative(root, "Promise");
  var Set = getNative(root, "Set");
  var WeakMap = getNative(root, "WeakMap");
  var nativeCreate = getNative(Object, "create");
  var dataViewCtorString = toSource(DataView);
  var mapCtorString = toSource(Map2);
  var promiseCtorString = toSource(Promise2);
  var setCtorString = toSource(Set);
  var weakMapCtorString = toSource(WeakMap);
  var symbolProto = Symbol ? Symbol.prototype : void 0;
  var symbolValueOf = symbolProto ? symbolProto.valueOf : void 0;
  var symbolToString = symbolProto ? symbolProto.toString : void 0;
  function Hash(entries) {
    var index = -1, length = entries ? entries.length : 0;
    this.clear();
    while (++index < length) {
      var entry = entries[index];
      this.set(entry[0], entry[1]);
    }
  }
  function hashClear() {
    this.__data__ = nativeCreate ? nativeCreate(null) : {};
  }
  function hashDelete(key) {
    return this.has(key) && delete this.__data__[key];
  }
  function hashGet(key) {
    var data = this.__data__;
    if (nativeCreate) {
      var result = data[key];
      return result === HASH_UNDEFINED ? void 0 : result;
    }
    return hasOwnProperty.call(data, key) ? data[key] : void 0;
  }
  function hashHas(key) {
    var data = this.__data__;
    return nativeCreate ? data[key] !== void 0 : hasOwnProperty.call(data, key);
  }
  function hashSet(key, value) {
    var data = this.__data__;
    data[key] = nativeCreate && value === void 0 ? HASH_UNDEFINED : value;
    return this;
  }
  Hash.prototype.clear = hashClear;
  Hash.prototype["delete"] = hashDelete;
  Hash.prototype.get = hashGet;
  Hash.prototype.has = hashHas;
  Hash.prototype.set = hashSet;
  function ListCache(entries) {
    var index = -1, length = entries ? entries.length : 0;
    this.clear();
    while (++index < length) {
      var entry = entries[index];
      this.set(entry[0], entry[1]);
    }
  }
  function listCacheClear() {
    this.__data__ = [];
  }
  function listCacheDelete(key) {
    var data = this.__data__, index = assocIndexOf(data, key);
    if (index < 0) {
      return false;
    }
    var lastIndex = data.length - 1;
    if (index == lastIndex) {
      data.pop();
    } else {
      splice.call(data, index, 1);
    }
    return true;
  }
  function listCacheGet(key) {
    var data = this.__data__, index = assocIndexOf(data, key);
    return index < 0 ? void 0 : data[index][1];
  }
  function listCacheHas(key) {
    return assocIndexOf(this.__data__, key) > -1;
  }
  function listCacheSet(key, value) {
    var data = this.__data__, index = assocIndexOf(data, key);
    if (index < 0) {
      data.push([key, value]);
    } else {
      data[index][1] = value;
    }
    return this;
  }
  ListCache.prototype.clear = listCacheClear;
  ListCache.prototype["delete"] = listCacheDelete;
  ListCache.prototype.get = listCacheGet;
  ListCache.prototype.has = listCacheHas;
  ListCache.prototype.set = listCacheSet;
  function MapCache(entries) {
    var index = -1, length = entries ? entries.length : 0;
    this.clear();
    while (++index < length) {
      var entry = entries[index];
      this.set(entry[0], entry[1]);
    }
  }
  function mapCacheClear() {
    this.__data__ = {
      hash: new Hash(),
      map: new (Map2 || ListCache)(),
      string: new Hash()
    };
  }
  function mapCacheDelete(key) {
    return getMapData(this, key)["delete"](key);
  }
  function mapCacheGet(key) {
    return getMapData(this, key).get(key);
  }
  function mapCacheHas(key) {
    return getMapData(this, key).has(key);
  }
  function mapCacheSet(key, value) {
    getMapData(this, key).set(key, value);
    return this;
  }
  MapCache.prototype.clear = mapCacheClear;
  MapCache.prototype["delete"] = mapCacheDelete;
  MapCache.prototype.get = mapCacheGet;
  MapCache.prototype.has = mapCacheHas;
  MapCache.prototype.set = mapCacheSet;
  function SetCache(values) {
    var index = -1, length = values ? values.length : 0;
    this.__data__ = new MapCache();
    while (++index < length) {
      this.add(values[index]);
    }
  }
  function setCacheAdd(value) {
    this.__data__.set(value, HASH_UNDEFINED);
    return this;
  }
  function setCacheHas(value) {
    return this.__data__.has(value);
  }
  SetCache.prototype.add = SetCache.prototype.push = setCacheAdd;
  SetCache.prototype.has = setCacheHas;
  function Stack(entries) {
    this.__data__ = new ListCache(entries);
  }
  function stackClear() {
    this.__data__ = new ListCache();
  }
  function stackDelete(key) {
    return this.__data__["delete"](key);
  }
  function stackGet(key) {
    return this.__data__.get(key);
  }
  function stackHas(key) {
    return this.__data__.has(key);
  }
  function stackSet(key, value) {
    var cache = this.__data__;
    if (cache instanceof ListCache) {
      var pairs = cache.__data__;
      if (!Map2 || pairs.length < LARGE_ARRAY_SIZE - 1) {
        pairs.push([key, value]);
        return this;
      }
      cache = this.__data__ = new MapCache(pairs);
    }
    cache.set(key, value);
    return this;
  }
  Stack.prototype.clear = stackClear;
  Stack.prototype["delete"] = stackDelete;
  Stack.prototype.get = stackGet;
  Stack.prototype.has = stackHas;
  Stack.prototype.set = stackSet;
  function arrayLikeKeys(value, inherited) {
    var result = isArray(value) || isArguments(value) ? baseTimes(value.length, String) : [];
    var length = result.length, skipIndexes = !!length;
    for (var key in value) {
      if ((inherited || hasOwnProperty.call(value, key)) && !(skipIndexes && (key == "length" || isIndex(key, length)))) {
        result.push(key);
      }
    }
    return result;
  }
  function assocIndexOf(array, key) {
    var length = array.length;
    while (length--) {
      if (eq(array[length][0], key)) {
        return length;
      }
    }
    return -1;
  }
  function baseAggregator(collection, setter, iteratee, accumulator) {
    baseEach(collection, function(value, key, collection2) {
      setter(accumulator, value, iteratee(value), collection2);
    });
    return accumulator;
  }
  var baseEach = createBaseEach(baseForOwn);
  var baseFor = createBaseFor();
  function baseForOwn(object2, iteratee) {
    return object2 && baseFor(object2, iteratee, keys);
  }
  function baseGet(object2, path) {
    path = isKey(path, object2) ? [path] : castPath(path);
    var index = 0, length = path.length;
    while (object2 != null && index < length) {
      object2 = object2[toKey(path[index++])];
    }
    return index && index == length ? object2 : void 0;
  }
  function baseGetTag(value) {
    return objectToString.call(value);
  }
  function baseHasIn(object2, key) {
    return object2 != null && key in Object(object2);
  }
  function baseIsEqual(value, other, customizer, bitmask, stack) {
    if (value === other) {
      return true;
    }
    if (value == null || other == null || !isObject(value) && !isObjectLike(other)) {
      return value !== value && other !== other;
    }
    return baseIsEqualDeep(value, other, baseIsEqual, customizer, bitmask, stack);
  }
  function baseIsEqualDeep(object2, other, equalFunc, customizer, bitmask, stack) {
    var objIsArr = isArray(object2), othIsArr = isArray(other), objTag = arrayTag, othTag = arrayTag;
    if (!objIsArr) {
      objTag = getTag(object2);
      objTag = objTag == argsTag ? objectTag : objTag;
    }
    if (!othIsArr) {
      othTag = getTag(other);
      othTag = othTag == argsTag ? objectTag : othTag;
    }
    var objIsObj = objTag == objectTag && !isHostObject(object2), othIsObj = othTag == objectTag && !isHostObject(other), isSameTag = objTag == othTag;
    if (isSameTag && !objIsObj) {
      stack || (stack = new Stack());
      return objIsArr || isTypedArray(object2) ? equalArrays(object2, other, equalFunc, customizer, bitmask, stack) : equalByTag(object2, other, objTag, equalFunc, customizer, bitmask, stack);
    }
    if (!(bitmask & PARTIAL_COMPARE_FLAG)) {
      var objIsWrapped = objIsObj && hasOwnProperty.call(object2, "__wrapped__"), othIsWrapped = othIsObj && hasOwnProperty.call(other, "__wrapped__");
      if (objIsWrapped || othIsWrapped) {
        var objUnwrapped = objIsWrapped ? object2.value() : object2, othUnwrapped = othIsWrapped ? other.value() : other;
        stack || (stack = new Stack());
        return equalFunc(objUnwrapped, othUnwrapped, customizer, bitmask, stack);
      }
    }
    if (!isSameTag) {
      return false;
    }
    stack || (stack = new Stack());
    return equalObjects(object2, other, equalFunc, customizer, bitmask, stack);
  }
  function baseIsMatch(object2, source, matchData, customizer) {
    var index = matchData.length, length = index, noCustomizer = !customizer;
    if (object2 == null) {
      return !length;
    }
    object2 = Object(object2);
    while (index--) {
      var data = matchData[index];
      if (noCustomizer && data[2] ? data[1] !== object2[data[0]] : !(data[0] in object2)) {
        return false;
      }
    }
    while (++index < length) {
      data = matchData[index];
      var key = data[0], objValue = object2[key], srcValue = data[1];
      if (noCustomizer && data[2]) {
        if (objValue === void 0 && !(key in object2)) {
          return false;
        }
      } else {
        var stack = new Stack();
        if (customizer) {
          var result = customizer(objValue, srcValue, key, object2, source, stack);
        }
        if (!(result === void 0 ? baseIsEqual(srcValue, objValue, customizer, UNORDERED_COMPARE_FLAG | PARTIAL_COMPARE_FLAG, stack) : result)) {
          return false;
        }
      }
    }
    return true;
  }
  function baseIsNative(value) {
    if (!isObject(value) || isMasked(value)) {
      return false;
    }
    var pattern = isFunction(value) || isHostObject(value) ? reIsNative : reIsHostCtor;
    return pattern.test(toSource(value));
  }
  function baseIsTypedArray(value) {
    return isObjectLike(value) && isLength(value.length) && !!typedArrayTags[objectToString.call(value)];
  }
  function baseIteratee(value) {
    if (typeof value == "function") {
      return value;
    }
    if (value == null) {
      return identity;
    }
    if (typeof value == "object") {
      return isArray(value) ? baseMatchesProperty(value[0], value[1]) : baseMatches(value);
    }
    return property(value);
  }
  function baseKeys(object2) {
    if (!isPrototype(object2)) {
      return nativeKeys(object2);
    }
    var result = [];
    for (var key in Object(object2)) {
      if (hasOwnProperty.call(object2, key) && key != "constructor") {
        result.push(key);
      }
    }
    return result;
  }
  function baseMatches(source) {
    var matchData = getMatchData(source);
    if (matchData.length == 1 && matchData[0][2]) {
      return matchesStrictComparable(matchData[0][0], matchData[0][1]);
    }
    return function(object2) {
      return object2 === source || baseIsMatch(object2, source, matchData);
    };
  }
  function baseMatchesProperty(path, srcValue) {
    if (isKey(path) && isStrictComparable(srcValue)) {
      return matchesStrictComparable(toKey(path), srcValue);
    }
    return function(object2) {
      var objValue = get(object2, path);
      return objValue === void 0 && objValue === srcValue ? hasIn(object2, path) : baseIsEqual(srcValue, objValue, void 0, UNORDERED_COMPARE_FLAG | PARTIAL_COMPARE_FLAG);
    };
  }
  function basePropertyDeep(path) {
    return function(object2) {
      return baseGet(object2, path);
    };
  }
  function baseToString(value) {
    if (typeof value == "string") {
      return value;
    }
    if (isSymbol(value)) {
      return symbolToString ? symbolToString.call(value) : "";
    }
    var result = value + "";
    return result == "0" && 1 / value == -INFINITY ? "-0" : result;
  }
  function castPath(value) {
    return isArray(value) ? value : stringToPath(value);
  }
  function createAggregator(setter, initializer) {
    return function(collection, iteratee) {
      var func2 = isArray(collection) ? arrayAggregator : baseAggregator, accumulator = initializer ? initializer() : {};
      return func2(collection, setter, baseIteratee(iteratee, 2), accumulator);
    };
  }
  function createBaseEach(eachFunc, fromRight) {
    return function(collection, iteratee) {
      if (collection == null) {
        return collection;
      }
      if (!isArrayLike(collection)) {
        return eachFunc(collection, iteratee);
      }
      var length = collection.length, index = fromRight ? length : -1, iterable = Object(collection);
      while (fromRight ? index-- : ++index < length) {
        if (iteratee(iterable[index], index, iterable) === false) {
          break;
        }
      }
      return collection;
    };
  }
  function createBaseFor(fromRight) {
    return function(object2, iteratee, keysFunc) {
      var index = -1, iterable = Object(object2), props2 = keysFunc(object2), length = props2.length;
      while (length--) {
        var key = props2[fromRight ? length : ++index];
        if (iteratee(iterable[key], key, iterable) === false) {
          break;
        }
      }
      return object2;
    };
  }
  function equalArrays(array, other, equalFunc, customizer, bitmask, stack) {
    var isPartial = bitmask & PARTIAL_COMPARE_FLAG, arrLength = array.length, othLength = other.length;
    if (arrLength != othLength && !(isPartial && othLength > arrLength)) {
      return false;
    }
    var stacked = stack.get(array);
    if (stacked && stack.get(other)) {
      return stacked == other;
    }
    var index = -1, result = true, seen = bitmask & UNORDERED_COMPARE_FLAG ? new SetCache() : void 0;
    stack.set(array, other);
    stack.set(other, array);
    while (++index < arrLength) {
      var arrValue = array[index], othValue = other[index];
      if (customizer) {
        var compared = isPartial ? customizer(othValue, arrValue, index, other, array, stack) : customizer(arrValue, othValue, index, array, other, stack);
      }
      if (compared !== void 0) {
        if (compared) {
          continue;
        }
        result = false;
        break;
      }
      if (seen) {
        if (!arraySome(other, function(othValue2, othIndex) {
          if (!seen.has(othIndex) && (arrValue === othValue2 || equalFunc(arrValue, othValue2, customizer, bitmask, stack))) {
            return seen.add(othIndex);
          }
        })) {
          result = false;
          break;
        }
      } else if (!(arrValue === othValue || equalFunc(arrValue, othValue, customizer, bitmask, stack))) {
        result = false;
        break;
      }
    }
    stack["delete"](array);
    stack["delete"](other);
    return result;
  }
  function equalByTag(object2, other, tag, equalFunc, customizer, bitmask, stack) {
    switch (tag) {
      case dataViewTag:
        if (object2.byteLength != other.byteLength || object2.byteOffset != other.byteOffset) {
          return false;
        }
        object2 = object2.buffer;
        other = other.buffer;
      case arrayBufferTag:
        if (object2.byteLength != other.byteLength || !equalFunc(new Uint8Array2(object2), new Uint8Array2(other))) {
          return false;
        }
        return true;
      case boolTag:
      case dateTag:
      case numberTag:
        return eq(+object2, +other);
      case errorTag:
        return object2.name == other.name && object2.message == other.message;
      case regexpTag:
      case stringTag:
        return object2 == other + "";
      case mapTag:
        var convert = mapToArray;
      case setTag:
        var isPartial = bitmask & PARTIAL_COMPARE_FLAG;
        convert || (convert = setToArray);
        if (object2.size != other.size && !isPartial) {
          return false;
        }
        var stacked = stack.get(object2);
        if (stacked) {
          return stacked == other;
        }
        bitmask |= UNORDERED_COMPARE_FLAG;
        stack.set(object2, other);
        var result = equalArrays(convert(object2), convert(other), equalFunc, customizer, bitmask, stack);
        stack["delete"](object2);
        return result;
      case symbolTag:
        if (symbolValueOf) {
          return symbolValueOf.call(object2) == symbolValueOf.call(other);
        }
    }
    return false;
  }
  function equalObjects(object2, other, equalFunc, customizer, bitmask, stack) {
    var isPartial = bitmask & PARTIAL_COMPARE_FLAG, objProps = keys(object2), objLength = objProps.length, othProps = keys(other), othLength = othProps.length;
    if (objLength != othLength && !isPartial) {
      return false;
    }
    var index = objLength;
    while (index--) {
      var key = objProps[index];
      if (!(isPartial ? key in other : hasOwnProperty.call(other, key))) {
        return false;
      }
    }
    var stacked = stack.get(object2);
    if (stacked && stack.get(other)) {
      return stacked == other;
    }
    var result = true;
    stack.set(object2, other);
    stack.set(other, object2);
    var skipCtor = isPartial;
    while (++index < objLength) {
      key = objProps[index];
      var objValue = object2[key], othValue = other[key];
      if (customizer) {
        var compared = isPartial ? customizer(othValue, objValue, key, other, object2, stack) : customizer(objValue, othValue, key, object2, other, stack);
      }
      if (!(compared === void 0 ? objValue === othValue || equalFunc(objValue, othValue, customizer, bitmask, stack) : compared)) {
        result = false;
        break;
      }
      skipCtor || (skipCtor = key == "constructor");
    }
    if (result && !skipCtor) {
      var objCtor = object2.constructor, othCtor = other.constructor;
      if (objCtor != othCtor && ("constructor" in object2 && "constructor" in other) && !(typeof objCtor == "function" && objCtor instanceof objCtor && typeof othCtor == "function" && othCtor instanceof othCtor)) {
        result = false;
      }
    }
    stack["delete"](object2);
    stack["delete"](other);
    return result;
  }
  function getMapData(map, key) {
    var data = map.__data__;
    return isKeyable(key) ? data[typeof key == "string" ? "string" : "hash"] : data.map;
  }
  function getMatchData(object2) {
    var result = keys(object2), length = result.length;
    while (length--) {
      var key = result[length], value = object2[key];
      result[length] = [key, value, isStrictComparable(value)];
    }
    return result;
  }
  function getNative(object2, key) {
    var value = getValue(object2, key);
    return baseIsNative(value) ? value : void 0;
  }
  var getTag = baseGetTag;
  if (DataView && getTag(new DataView(new ArrayBuffer(1))) != dataViewTag || Map2 && getTag(new Map2()) != mapTag || Promise2 && getTag(Promise2.resolve()) != promiseTag || Set && getTag(new Set()) != setTag || WeakMap && getTag(new WeakMap()) != weakMapTag) {
    getTag = function(value) {
      var result = objectToString.call(value), Ctor = result == objectTag ? value.constructor : void 0, ctorString = Ctor ? toSource(Ctor) : void 0;
      if (ctorString) {
        switch (ctorString) {
          case dataViewCtorString:
            return dataViewTag;
          case mapCtorString:
            return mapTag;
          case promiseCtorString:
            return promiseTag;
          case setCtorString:
            return setTag;
          case weakMapCtorString:
            return weakMapTag;
        }
      }
      return result;
    };
  }
  function hasPath(object2, path, hasFunc) {
    path = isKey(path, object2) ? [path] : castPath(path);
    var result, index = -1, length = path.length;
    while (++index < length) {
      var key = toKey(path[index]);
      if (!(result = object2 != null && hasFunc(object2, key))) {
        break;
      }
      object2 = object2[key];
    }
    if (result) {
      return result;
    }
    var length = object2 ? object2.length : 0;
    return !!length && isLength(length) && isIndex(key, length) && (isArray(object2) || isArguments(object2));
  }
  function isIndex(value, length) {
    length = length == null ? MAX_SAFE_INTEGER : length;
    return !!length && (typeof value == "number" || reIsUint.test(value)) && (value > -1 && value % 1 == 0 && value < length);
  }
  function isKey(value, object2) {
    if (isArray(value)) {
      return false;
    }
    var type = typeof value;
    if (type == "number" || type == "symbol" || type == "boolean" || value == null || isSymbol(value)) {
      return true;
    }
    return reIsPlainProp.test(value) || !reIsDeepProp.test(value) || object2 != null && value in Object(object2);
  }
  function isKeyable(value) {
    var type = typeof value;
    return type == "string" || type == "number" || type == "symbol" || type == "boolean" ? value !== "__proto__" : value === null;
  }
  function isMasked(func2) {
    return !!maskSrcKey && maskSrcKey in func2;
  }
  function isPrototype(value) {
    var Ctor = value && value.constructor, proto = typeof Ctor == "function" && Ctor.prototype || objectProto;
    return value === proto;
  }
  function isStrictComparable(value) {
    return value === value && !isObject(value);
  }
  function matchesStrictComparable(key, srcValue) {
    return function(object2) {
      if (object2 == null) {
        return false;
      }
      return object2[key] === srcValue && (srcValue !== void 0 || key in Object(object2));
    };
  }
  var stringToPath = memoize(function(string3) {
    string3 = toString(string3);
    var result = [];
    if (reLeadingDot.test(string3)) {
      result.push("");
    }
    string3.replace(rePropName, function(match, number, quote, string4) {
      result.push(quote ? string4.replace(reEscapeChar, "$1") : number || match);
    });
    return result;
  });
  function toKey(value) {
    if (typeof value == "string" || isSymbol(value)) {
      return value;
    }
    var result = value + "";
    return result == "0" && 1 / value == -INFINITY ? "-0" : result;
  }
  function toSource(func2) {
    if (func2 != null) {
      try {
        return funcToString.call(func2);
      } catch (e) {
      }
      try {
        return func2 + "";
      } catch (e) {
      }
    }
    return "";
  }
  var groupBy2 = createAggregator(function(result, value, key) {
    if (hasOwnProperty.call(result, key)) {
      result[key].push(value);
    } else {
      result[key] = [value];
    }
  });
  function memoize(func2, resolver) {
    if (typeof func2 != "function" || resolver && typeof resolver != "function") {
      throw new TypeError(FUNC_ERROR_TEXT);
    }
    var memoized = function() {
      var args = arguments, key = resolver ? resolver.apply(this, args) : args[0], cache = memoized.cache;
      if (cache.has(key)) {
        return cache.get(key);
      }
      var result = func2.apply(this, args);
      memoized.cache = cache.set(key, result);
      return result;
    };
    memoized.cache = new (memoize.Cache || MapCache)();
    return memoized;
  }
  memoize.Cache = MapCache;
  function eq(value, other) {
    return value === other || value !== value && other !== other;
  }
  function isArguments(value) {
    return isArrayLikeObject(value) && hasOwnProperty.call(value, "callee") && (!propertyIsEnumerable.call(value, "callee") || objectToString.call(value) == argsTag);
  }
  var isArray = Array.isArray;
  function isArrayLike(value) {
    return value != null && isLength(value.length) && !isFunction(value);
  }
  function isArrayLikeObject(value) {
    return isObjectLike(value) && isArrayLike(value);
  }
  function isFunction(value) {
    var tag = isObject(value) ? objectToString.call(value) : "";
    return tag == funcTag || tag == genTag;
  }
  function isLength(value) {
    return typeof value == "number" && value > -1 && value % 1 == 0 && value <= MAX_SAFE_INTEGER;
  }
  function isObject(value) {
    var type = typeof value;
    return !!value && (type == "object" || type == "function");
  }
  function isObjectLike(value) {
    return !!value && typeof value == "object";
  }
  function isSymbol(value) {
    return typeof value == "symbol" || isObjectLike(value) && objectToString.call(value) == symbolTag;
  }
  var isTypedArray = nodeIsTypedArray ? baseUnary(nodeIsTypedArray) : baseIsTypedArray;
  function toString(value) {
    return value == null ? "" : baseToString(value);
  }
  function get(object2, path, defaultValue) {
    var result = object2 == null ? void 0 : baseGet(object2, path);
    return result === void 0 ? defaultValue : result;
  }
  function hasIn(object2, path) {
    return object2 != null && hasPath(object2, path, baseHasIn);
  }
  function keys(object2) {
    return isArrayLike(object2) ? arrayLikeKeys(object2) : baseKeys(object2);
  }
  function identity(value) {
    return value;
  }
  function property(path) {
    return isKey(path) ? baseProperty(toKey(path)) : basePropertyDeep(path);
  }
  module.exports = groupBy2;
});

// src/index.tsx
import {useComboboxPrimitive as useComboboxPrimitive2} from "@twilio-paste/combobox-primitive";

// src/Combobox.tsx
import {
  createElement as createElement6,
  forwardRef as forwardRef5,
  useCallback,
  useRef
} from "react";
import {
  any,
  arrayOf,
  bool as bool2,
  func,
  node as node5,
  object,
  oneOfType,
  string as string2
} from "prop-types";

// ../../../../node_modules/react-virtual/dist/react-virtual.mjs
import React from "react";
function _extends() {
  _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };
  return _extends.apply(this, arguments);
}
function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null)
    return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;
  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0)
      continue;
    target[key] = source[key];
  }
  return target;
}
var props = ["bottom", "height", "left", "right", "top", "width"];
var rectChanged = function rectChanged2(a, b) {
  if (a === void 0) {
    a = {};
  }
  if (b === void 0) {
    b = {};
  }
  return props.some(function(prop) {
    return a[prop] !== b[prop];
  });
};
var observedNodes = /* @__PURE__ */ new Map();
var rafId;
var run = function run2() {
  var changedStates = [];
  observedNodes.forEach(function(state, node6) {
    var newRect = node6.getBoundingClientRect();
    if (rectChanged(newRect, state.rect)) {
      state.rect = newRect;
      changedStates.push(state);
    }
  });
  changedStates.forEach(function(state) {
    state.callbacks.forEach(function(cb) {
      return cb(state.rect);
    });
  });
  rafId = window.requestAnimationFrame(run2);
};
function observeRect(node6, cb) {
  return {
    observe: function observe() {
      var wasEmpty = observedNodes.size === 0;
      if (observedNodes.has(node6)) {
        observedNodes.get(node6).callbacks.push(cb);
      } else {
        observedNodes.set(node6, {
          rect: void 0,
          hasRectChanged: false,
          callbacks: [cb]
        });
      }
      if (wasEmpty)
        run();
    },
    unobserve: function unobserve() {
      var state = observedNodes.get(node6);
      if (state) {
        var index = state.callbacks.indexOf(cb);
        if (index >= 0)
          state.callbacks.splice(index, 1);
        if (!state.callbacks.length)
          observedNodes["delete"](node6);
        if (!observedNodes.size)
          cancelAnimationFrame(rafId);
      }
    }
  };
}
var useIsomorphicLayoutEffect = typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;
function useRect(nodeRef) {
  var _React$useState = React.useState(nodeRef.current), element = _React$useState[0], setElement = _React$useState[1];
  var _React$useReducer = React.useReducer(rectReducer, null), rect = _React$useReducer[0], dispatch = _React$useReducer[1];
  var initialRectSet = React.useRef(false);
  useIsomorphicLayoutEffect(function() {
    if (nodeRef.current !== element) {
      setElement(nodeRef.current);
    }
  });
  useIsomorphicLayoutEffect(function() {
    if (element && !initialRectSet.current) {
      initialRectSet.current = true;
      var _rect = element.getBoundingClientRect();
      dispatch({
        rect: _rect
      });
    }
  }, [element]);
  React.useEffect(function() {
    if (!element) {
      return;
    }
    var observer = observeRect(element, function(rect2) {
      dispatch({
        rect: rect2
      });
    });
    observer.observe();
    return function() {
      observer.unobserve();
    };
  }, [element]);
  return rect;
}
function rectReducer(state, action) {
  var rect = action.rect;
  if (!state || state.height !== rect.height || state.width !== rect.width) {
    return rect;
  }
  return state;
}
var defaultEstimateSize = function defaultEstimateSize2() {
  return 50;
};
function useVirtual(_ref) {
  var _ref3, _measurements;
  var _ref$size = _ref.size, size = _ref$size === void 0 ? 0 : _ref$size, _ref$estimateSize = _ref.estimateSize, estimateSize = _ref$estimateSize === void 0 ? defaultEstimateSize : _ref$estimateSize, _ref$overscan = _ref.overscan, overscan = _ref$overscan === void 0 ? 0 : _ref$overscan, _ref$paddingStart = _ref.paddingStart, paddingStart = _ref$paddingStart === void 0 ? 0 : _ref$paddingStart, _ref$paddingEnd = _ref.paddingEnd, paddingEnd = _ref$paddingEnd === void 0 ? 0 : _ref$paddingEnd, parentRef = _ref.parentRef, horizontal = _ref.horizontal, scrollToFn = _ref.scrollToFn, useObserver = _ref.useObserver;
  var sizeKey = horizontal ? "width" : "height";
  var scrollKey = horizontal ? "scrollLeft" : "scrollTop";
  var latestRef = React.useRef({});
  var useMeasureParent = useObserver || useRect;
  var _ref2 = useMeasureParent(parentRef) || (_ref3 = {}, _ref3[sizeKey] = 0, _ref3), outerSize = _ref2[sizeKey];
  var defaultScrollToFn = React.useCallback(function(offset) {
    if (parentRef.current) {
      parentRef.current[scrollKey] = offset;
    }
  }, [parentRef, scrollKey]);
  var resolvedScrollToFn = scrollToFn || defaultScrollToFn;
  scrollToFn = React.useCallback(function(offset) {
    resolvedScrollToFn(offset, defaultScrollToFn);
  }, [defaultScrollToFn, resolvedScrollToFn]);
  var _React$useState = React.useState({}), measuredCache = _React$useState[0], setMeasuredCache = _React$useState[1];
  var measurements = React.useMemo(function() {
    var measurements2 = [];
    for (var i = 0; i < size; i++) {
      var measuredSize = measuredCache[i];
      var start = measurements2[i - 1] ? measurements2[i - 1].end : paddingStart;
      var _size = typeof measuredSize === "number" ? measuredSize : estimateSize(i);
      var end = start + _size;
      measurements2[i] = {
        index: i,
        start,
        size: _size,
        end
      };
    }
    return measurements2;
  }, [estimateSize, measuredCache, paddingStart, size]);
  var totalSize = (((_measurements = measurements[size - 1]) == null ? void 0 : _measurements.end) || 0) + paddingEnd;
  Object.assign(latestRef.current, {
    overscan,
    measurements,
    outerSize,
    totalSize
  });
  var _React$useState2 = React.useState({
    start: 0,
    end: 0
  }), range = _React$useState2[0], setRange = _React$useState2[1];
  useIsomorphicLayoutEffect(function() {
    var element = parentRef.current;
    if (!element) {
      return;
    }
    var onScroll = function onScroll2() {
      var scrollOffset = element[scrollKey];
      latestRef.current.scrollOffset = scrollOffset;
      setRange(function(prevRange) {
        return calculateRange(latestRef.current, prevRange);
      });
    };
    onScroll();
    element.addEventListener("scroll", onScroll, {
      capture: false,
      passive: true
    });
    return function() {
      element.removeEventListener("scroll", onScroll);
    };
  }, [
    parentRef.current,
    scrollKey,
    size,
    outerSize
  ]);
  var virtualItems = React.useMemo(function() {
    var virtualItems2 = [];
    var end = Math.min(range.end, measurements.length - 1);
    var _loop = function _loop2(i2) {
      var measurement = measurements[i2];
      var item = _extends(_extends({}, measurement), {}, {
        measureRef: function measureRef(el) {
          var scrollOffset = latestRef.current.scrollOffset;
          if (el) {
            var _el$getBoundingClient = el.getBoundingClientRect(), measuredSize = _el$getBoundingClient[sizeKey];
            if (measuredSize !== item.size) {
              if (item.start < scrollOffset) {
                defaultScrollToFn(scrollOffset + (measuredSize - item.size));
              }
              setMeasuredCache(function(old) {
                var _extends2;
                return _extends(_extends({}, old), {}, (_extends2 = {}, _extends2[i2] = measuredSize, _extends2));
              });
            }
          }
        }
      });
      virtualItems2.push(item);
    };
    for (var i = range.start; i <= end; i++) {
      _loop(i);
    }
    return virtualItems2;
  }, [range.start, range.end, measurements, sizeKey, defaultScrollToFn]);
  var mountedRef = React.useRef();
  useIsomorphicLayoutEffect(function() {
    if (mountedRef.current) {
      if (estimateSize || size)
        setMeasuredCache({});
    }
    mountedRef.current = true;
  }, [estimateSize, size]);
  var scrollToOffset = React.useCallback(function(toOffset, _temp) {
    var _ref4 = _temp === void 0 ? {} : _temp, _ref4$align = _ref4.align, align = _ref4$align === void 0 ? "start" : _ref4$align;
    var _latestRef$current = latestRef.current, scrollOffset = _latestRef$current.scrollOffset, outerSize2 = _latestRef$current.outerSize;
    if (align === "auto") {
      if (toOffset <= scrollOffset) {
        align = "start";
      } else if (scrollOffset >= scrollOffset + outerSize2) {
        align = "end";
      } else {
        align = "start";
      }
    }
    if (align === "start") {
      scrollToFn(toOffset);
    } else if (align === "end") {
      scrollToFn(toOffset - outerSize2);
    } else if (align === "center") {
      scrollToFn(toOffset - outerSize2 / 2);
    }
  }, [scrollToFn]);
  var tryScrollToIndex = React.useCallback(function(index, _temp2) {
    var _ref5 = _temp2 === void 0 ? {} : _temp2, _ref5$align = _ref5.align, align = _ref5$align === void 0 ? "auto" : _ref5$align, rest = _objectWithoutPropertiesLoose(_ref5, ["align"]);
    var _latestRef$current2 = latestRef.current, measurements2 = _latestRef$current2.measurements, scrollOffset = _latestRef$current2.scrollOffset, outerSize2 = _latestRef$current2.outerSize;
    var measurement = measurements2[Math.max(0, Math.min(index, size - 1))];
    if (!measurement) {
      return;
    }
    if (align === "auto") {
      if (measurement.end >= scrollOffset + outerSize2) {
        align = "end";
      } else if (measurement.start <= scrollOffset) {
        align = "start";
      } else {
        return;
      }
    }
    var toOffset = align === "center" ? measurement.start + measurement.size / 2 : align === "end" ? measurement.end : measurement.start;
    scrollToOffset(toOffset, _extends({
      align
    }, rest));
  }, [scrollToOffset, size]);
  var scrollToIndex = React.useCallback(function() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    tryScrollToIndex.apply(void 0, args);
    requestAnimationFrame(function() {
      tryScrollToIndex.apply(void 0, args);
    });
  }, [tryScrollToIndex]);
  return {
    virtualItems,
    totalSize,
    scrollToOffset,
    scrollToIndex
  };
}
function calculateRange(_ref6, prevRange) {
  var overscan = _ref6.overscan, measurements = _ref6.measurements, outerSize = _ref6.outerSize, scrollOffset = _ref6.scrollOffset;
  var total = measurements.length;
  var start = total - 1;
  while (start > 0 && measurements[start].end >= scrollOffset) {
    start -= 1;
  }
  var end = 0;
  while (end < total - 1 && measurements[end].start <= scrollOffset + outerSize) {
    end += 1;
  }
  start = Math.max(start - overscan, 0);
  end = Math.min(end + overscan, total - 1);
  if (!prevRange || prevRange.start !== start || prevRange.end !== end) {
    return {
      start,
      end
    };
  }
  return prevRange;
}

// src/Combobox.tsx
import {useTheme, remToPx} from "@twilio-paste/theme";
import {useUID} from "@twilio-paste/uid-library";
import {useComboboxPrimitive} from "@twilio-paste/combobox-primitive";
import {ChevronDownIcon} from "@twilio-paste/icons/esm/ChevronDownIcon";
import {Box as Box5} from "@twilio-paste/box";
import {InputBox, InputChevronWrapper} from "@twilio-paste/input-box";
import {Label} from "@twilio-paste/label";
import {HelpText} from "@twilio-paste/help-text";

// src/styles/ComboboxInputSelect.tsx
import {InputElement} from "@twilio-paste/input";
import {styled, css} from "@twilio-paste/styling-library";
var ComboboxInputSelect = styled(InputElement)((props2) => css({
  paddingRight: !props2.autocomplete ? "space100" : null
}));

// src/styles/ComboboxInputWrapper.tsx
import {
  createElement,
  forwardRef
} from "react";
import {
  node
} from "prop-types";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
var ComboboxInputWrapper = forwardRef((_a, ref) => {
  var {children} = _a, props2 = __rest(_a, ["children"]);
  return /* @__PURE__ */ createElement(Box, __assign(__assign({}, safelySpreadBoxProps(props2)), {
    display: "flex",
    position: "relative",
    width: "100%",
    ref
  }), children);
});
ComboboxInputWrapper.displayName = "ComboboxInputWrapper";
if (true) {
  ComboboxInputWrapper.propTypes = {
    children: node.isRequired
  };
}

// src/styles/ComboboxListbox.tsx
import {
  createElement as createElement2,
  forwardRef as forwardRef2
} from "react";
import {
  node as node2
} from "prop-types";
import {Box as Box2, safelySpreadBoxProps as safelySpreadBoxProps2} from "@twilio-paste/box";
var ComboboxListbox = forwardRef2((_a, ref) => {
  var {children} = _a, props2 = __rest(_a, ["children"]);
  return /* @__PURE__ */ createElement2(Box2, __assign(__assign({}, safelySpreadBoxProps2(props2)), {
    as: "div",
    backgroundColor: "colorBackgroundBody",
    borderRadius: "borderRadius20",
    borderColor: "colorBorderLight",
    borderWidth: "borderWidth10",
    borderStyle: "solid",
    boxShadow: "shadow",
    listStyleType: "none",
    margin: "space0",
    overflowY: "auto",
    padding: "space0",
    paddingY: "space30",
    position: "absolute",
    width: "100%",
    maxHeight: "size30",
    zIndex: "zIndex20",
    marginTop: "space20",
    ref
  }), children);
});
ComboboxListbox.displayName = "ComboboxListbox";
if (true) {
  ComboboxListbox.propTypes = {
    children: node2.isRequired
  };
}

// src/ComboboxItems.tsx
import {
  Fragment,
  createElement as createElement5
} from "react";
import {useUIDSeed} from "@twilio-paste/uid-library";

// src/styles/ComboboxListboxOption.tsx
import {
  createElement as createElement3,
  forwardRef as forwardRef3
} from "react";
import {
  bool,
  node as node3,
  oneOf
} from "prop-types";
import {Box as Box3, safelySpreadBoxProps as safelySpreadBoxProps3} from "@twilio-paste/box";
import {Text} from "@twilio-paste/text";
var VariantStyles = {
  groupOption: {
    paddingLeft: "space90",
    paddingRight: "space90"
  },
  default: {
    paddingLeft: "space70",
    paddingRight: "space70"
  }
};
var ComboboxListboxOption = forwardRef3((_a, ref) => {
  var {children, highlighted, variant = "default", virtualItem} = _a, props2 = __rest(_a, ["children", "highlighted", "variant", "virtualItem"]);
  const virtualItemStyles = virtualItem ? {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    transform: `translateY(${virtualItem.start}px)`
  } : {};
  return /* @__PURE__ */ createElement3(Box3, __assign(__assign(__assign(__assign({}, safelySpreadBoxProps3(props2)), {
    as: "li",
    backgroundColor: highlighted ? "colorBackgroundPrimaryLightest" : "colorBackgroundBody",
    padding: "space30",
    cursor: "pointer",
    ref
  }), virtualItemStyles), VariantStyles[variant]), /* @__PURE__ */ createElement3(Text, {
    as: "span",
    color: highlighted ? "colorTextLink" : "colorText",
    textDecoration: highlighted ? "underline" : null
  }, children));
});
ComboboxListboxOption.displayName = "ComboboxListboxOption";
if (true) {
  ComboboxListboxOption.propTypes = {
    children: node3.isRequired,
    highlighted: bool,
    variant: oneOf(["default", "groupOption"]).isRequired
  };
}

// src/styles/ComboboxListboxGroup.tsx
import {
  createElement as createElement4,
  forwardRef as forwardRef4
} from "react";
import {
  node as node4,
  string
} from "prop-types";
import {Box as Box4} from "@twilio-paste/box";
import {Text as Text2} from "@twilio-paste/text";
var ComboboxListboxGroup = forwardRef4(({children, groupName, groupLabelTemplate}, ref) => {
  return /* @__PURE__ */ createElement4(Box4, {
    as: "ul",
    role: !groupName ? "presentation" : "group",
    "aria-label": groupName,
    ref,
    margin: "space0",
    padding: "space0",
    listStyleType: "none"
  }, groupName ? /* @__PURE__ */ createElement4(Box4, {
    as: "li",
    role: "presentation",
    paddingY: "space30",
    paddingX: "space70"
  }, /* @__PURE__ */ createElement4(Text2, {
    as: "span",
    fontWeight: "fontWeightBold"
  }, groupLabelTemplate ? groupLabelTemplate(groupName) : groupName)) : null, children);
});
ComboboxListboxGroup.displayName = "ComboboxListboxGroup";
ComboboxListboxGroup.propTypes = {
  children: node4.isRequired,
  groupName: string
};

// src/helpers.tsx
var groupBy = require_lodash();
var getIndexedItems = (items) => {
  return items.map((item, index) => {
    if (typeof item === "string") {
      return {label: item, index};
    }
    return __assign(__assign({}, item), {index});
  });
};
var getGroupedItems = (items, groupItemsBy) => {
  return groupBy(items, (item) => typeof item === "string" ? "Uncategorized" : item[groupItemsBy]);
};

// src/ComboboxItems.tsx
var ComboboxItems = ({
  items,
  getItemProps,
  highlightedIndex,
  optionTemplate,
  groupLabelTemplate,
  groupItemsBy,
  rowVirtualizer
}) => {
  const UIDSeed = useUIDSeed();
  if (groupItemsBy == null) {
    return optionTemplate != null ? /* @__PURE__ */ createElement5(ComboboxListboxGroup, null, items.map((item, index) => /* @__PURE__ */ createElement5(ComboboxListboxOption, __assign(__assign({}, getItemProps({item, index})), {
      highlighted: highlightedIndex === index,
      key: UIDSeed(`item-${index}`),
      variant: "default"
    }), optionTemplate(item)))) : /* @__PURE__ */ createElement5(ComboboxListboxGroup, null, /* @__PURE__ */ createElement5("li", {
      role: "presentation",
      key: "total-size",
      style: {height: rowVirtualizer.totalSize}
    }), rowVirtualizer.virtualItems.map((virtualItem) => {
      const itemIndex = virtualItem.index;
      const item = items[itemIndex];
      return /* @__PURE__ */ createElement5(ComboboxListboxOption, __assign(__assign({
        ref: (element) => virtualItem.measureRef(element)
      }, getItemProps({item, index: itemIndex})), {
        highlighted: highlightedIndex === itemIndex,
        key: UIDSeed(`item-${itemIndex}`),
        variant: "default",
        virtualItem,
        "aria-setsize": items.length,
        "aria-posinset": items.indexOf(item)
      }), item);
    }));
  }
  const indexedItems = getIndexedItems(items);
  const groupedItems = getGroupedItems(indexedItems, groupItemsBy);
  const groupedItemKeys = Object.keys(groupedItems);
  return /* @__PURE__ */ createElement5(Fragment, null, groupedItemKeys.map((groupedItemKey) => {
    const isUncategorized = groupedItemKey === "undefined";
    const groupKey = isUncategorized ? "$$$Uncategorized$$$" : groupedItemKey;
    return /* @__PURE__ */ createElement5(ComboboxListboxGroup, {
      key: UIDSeed(groupKey),
      groupName: isUncategorized ? void 0 : groupKey,
      groupLabelTemplate
    }, groupedItems[groupedItemKey].map((item) => /* @__PURE__ */ createElement5(ComboboxListboxOption, __assign(__assign({}, getItemProps({item, index: item.index})), {
      highlighted: highlightedIndex === item.index,
      key: UIDSeed(`${groupKey}-${item.index}`),
      variant: isUncategorized ? "default" : "groupOption"
    }), optionTemplate ? optionTemplate(item) : item)));
  }));
};

// src/Combobox.tsx
var getHelpTextVariant = (variant, hasError) => {
  if (hasError && variant === "inverse") {
    return "error_inverse";
  }
  if (hasError) {
    return "error";
  }
  if (variant === "inverse") {
    return "inverse";
  }
  return "default";
};
var Combobox = forwardRef5((_a, ref) => {
  var {
    autocomplete,
    disabled,
    hasError,
    helpText,
    initialSelectedItem,
    initialIsOpen,
    inputValue,
    insertAfter,
    insertBefore,
    items,
    itemToString,
    labelText,
    onInputValueChange,
    onSelectedItemChange,
    optionTemplate,
    onHighlightedIndexChange,
    onIsOpenChange,
    required,
    selectedItem,
    groupItemsBy,
    groupLabelTemplate,
    variant = "default",
    state
  } = _a, props2 = __rest(_a, [
    "autocomplete",
    "disabled",
    "hasError",
    "helpText",
    "initialSelectedItem",
    "initialIsOpen",
    "inputValue",
    "insertAfter",
    "insertBefore",
    "items",
    "itemToString",
    "labelText",
    "onInputValueChange",
    "onSelectedItemChange",
    "optionTemplate",
    "onHighlightedIndexChange",
    "onIsOpenChange",
    "required",
    "selectedItem",
    "groupItemsBy",
    "groupLabelTemplate",
    "variant",
    "state"
  ]);
  const theme = useTheme();
  const parentRef = useRef(null);
  const helpTextId = useUID();
  const rowVirtualizer = !groupItemsBy && !optionTemplate ? useVirtual({
    size: items.length,
    parentRef,
    estimateSize: useCallback(() => 36, []),
    overscan: 4,
    paddingStart: remToPx(theme.space.space30, "number")
  }) : void 0;
  const {
    getComboboxProps,
    getInputProps,
    getItemProps,
    getLabelProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen
  } = state || useComboboxPrimitive(__assign(__assign(__assign(__assign({
    initialSelectedItem,
    items,
    onHighlightedIndexChange: (changes) => {
      var _a2;
      const currentHighlight = highlightedIndex != null ? highlightedIndex : 0;
      const newHighlight = (_a2 = changes.highlightedIndex) != null ? _a2 : currentHighlight;
      if (rowVirtualizer) {
        rowVirtualizer.scrollToIndex(newHighlight);
      }
      if (onHighlightedIndexChange) {
        onHighlightedIndexChange(changes);
      }
    },
    onInputValueChange,
    onIsOpenChange,
    onSelectedItemChange
  }, itemToString != null && {itemToString}), initialIsOpen != null && {initialIsOpen}), inputValue != null && {inputValue}), selectedItem != null && {selectedItem}));
  if (getComboboxProps === void 0 || getInputProps === void 0 || getItemProps === void 0 || getLabelProps === void 0 || getMenuProps === void 0 || getToggleButtonProps === void 0 || highlightedIndex === void 0 || isOpen === void 0) {
    throw new Error("[Combobox]: One of getComboboxProps, getInputProps, getItemProps, getLabelProps, getMenuProps, getToggleButtonProps, highlightedIndex or isOpen is missing from the state object. Please make sure this is provided.");
  }
  let iconColor = "colorTextIcon";
  if (disabled) {
    iconColor = "colorTextWeaker";
  } else if (variant === "inverse") {
    iconColor = "colorTextInverseWeak";
  }
  return /* @__PURE__ */ createElement6(Box5, {
    position: "relative"
  }, /* @__PURE__ */ createElement6(Label, __assign({
    disabled,
    required,
    variant
  }, getLabelProps()), labelText), /* @__PURE__ */ createElement6(InputBox, {
    disabled,
    hasError,
    insertBefore,
    insertAfter,
    variant
  }, /* @__PURE__ */ createElement6(ComboboxInputWrapper, __assign({}, getComboboxProps({role: "combobox"})), /* @__PURE__ */ createElement6(ComboboxInputSelect, __assign(__assign(__assign(__assign(__assign(__assign({}, getToggleButtonProps({tabIndex: 0})), getInputProps({disabled, ref})), !autocomplete ? {onChange: (event) => event.preventDefault()} : void 0), {
    autocomplete,
    "aria-describedby": helpTextId
  }), props2), {
    type: "text"
  })), !autocomplete && /* @__PURE__ */ createElement6(InputChevronWrapper, null, /* @__PURE__ */ createElement6(ChevronDownIcon, {
    "aria-hidden": "true",
    decorative: true,
    color: iconColor,
    size: "sizeIcon30"
  })))), /* @__PURE__ */ createElement6(ComboboxListbox, __assign({
    hidden: !isOpen
  }, getMenuProps({ref: parentRef})), /* @__PURE__ */ createElement6(ComboboxItems, {
    items,
    rowVirtualizer,
    getItemProps,
    highlightedIndex,
    optionTemplate,
    groupItemsBy,
    groupLabelTemplate
  })), helpText && /* @__PURE__ */ createElement6(HelpText, {
    id: helpTextId,
    variant: getHelpTextVariant(variant, hasError)
  }, helpText));
});
Combobox.displayName = "Combobox";
if (true) {
  Combobox.propTypes = {
    autocomplete: bool2,
    groupItemsBy: string2,
    helpText: oneOfType([string2, node5]),
    initialIsOpen: bool2,
    initialSelectedItem: oneOfType([string2, object]),
    items: arrayOf(any).isRequired,
    itemToString: func,
    labelText: oneOfType([string2, node5]).isRequired,
    onHighlightedIndexChange: func,
    onInputValueChange: func,
    onIsOpenChange: func,
    onSelectedItemChange: func,
    optionTemplate: func,
    selectedItem: oneOfType([string2, object])
  };
}
export {
  Combobox,
  ComboboxInputWrapper,
  ComboboxListbox,
  ComboboxListboxGroup,
  ComboboxListboxOption,
  useComboboxPrimitive2 as useCombobox
};
