var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/PopoverContainer.tsx
import {
  createElement,
  useMemo
} from "react";
import {
  useNonModalDialogPrimitiveState
} from "@twilio-paste/non-modal-dialog-primitive";

// src/PopoverContext.tsx
import {
  createContext
} from "react";
var PopoverContext = createContext({});

// src/PopoverContainer.tsx
var PopoverContainer = (_a) => {
  var {children, placement, gutter} = _a, initialState = __rest(_a, ["children", "placement", "gutter"]);
  const popover = useNonModalDialogPrimitiveState(__assign({placement, gutter, modal: true}, initialState));
  const value = useMemo(() => popover, Object.values(popover));
  return /* @__PURE__ */ createElement(PopoverContext.Provider, {
    value
  }, children);
};
PopoverContainer.displayName = "PopoverContainer";

// src/Popover.tsx
import {
  createElement as createElement3,
  forwardRef,
  useContext
} from "react";
import {
  node,
  string
} from "prop-types";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
import {Button} from "@twilio-paste/button";
import {CloseIcon} from "@twilio-paste/icons/esm/CloseIcon";
import {StyledBase} from "@twilio-paste/theme";
import {NonModalDialogPrimitive} from "@twilio-paste/non-modal-dialog-primitive";

// src/PopoverArrow.tsx
import {
  createElement as createElement2
} from "react";
import {
  NonModalDialogArrowPrimitive
} from "@twilio-paste/non-modal-dialog-primitive";
import {useTheme} from "@twilio-paste/theme";
var PopoverArrow = (props) => {
  const theme = useTheme();
  return /* @__PURE__ */ createElement2(NonModalDialogArrowPrimitive, __assign(__assign({}, props), {
    size: theme.fontSizes.fontSize70,
    stroke: theme.borderColors.colorBorderLighter,
    fill: theme.backgroundColors.colorBackgroundBody
  }));
};
PopoverArrow.displayName = "PopoverArrow";

// src/Popover.tsx
var StyledPopover = forwardRef((_a, ref) => {
  var {style} = _a, props = __rest(_a, ["style"]);
  return /* @__PURE__ */ createElement3(Box, __assign(__assign({}, safelySpreadBoxProps(props)), {
    backgroundColor: "colorBackgroundBody",
    borderStyle: "solid",
    borderWidth: "borderWidth10",
    borderColor: "colorBorderLighter",
    borderRadius: "borderRadius20",
    boxShadow: "shadowCard",
    maxWidth: "size30",
    zIndex: "zIndex80",
    _focus: {outline: "none"},
    style,
    ref
  }));
});
var Popover = forwardRef((_a, ref) => {
  var {children} = _a, props = __rest(_a, ["children"]);
  const popover = useContext(PopoverContext);
  return /* @__PURE__ */ createElement3(NonModalDialogPrimitive, __assign(__assign(__assign({}, popover), props), {
    as: StyledPopover,
    ref,
    preventBodyScroll: false
  }), /* @__PURE__ */ createElement3(StyledBase, null, /* @__PURE__ */ createElement3(PopoverArrow, __assign({}, popover)), /* @__PURE__ */ createElement3(Box, {
    padding: "space50",
    paddingLeft: "space70",
    paddingRight: "space70"
  }, children, /* @__PURE__ */ createElement3(Box, {
    position: "absolute",
    right: 8,
    top: 8
  }, /* @__PURE__ */ createElement3(Button, {
    variant: "reset",
    size: "reset",
    onClick: popover.hide
  }, /* @__PURE__ */ createElement3(CloseIcon, {
    decorative: false,
    color: "colorTextWeak",
    size: "sizeIcon30",
    title: "Close popover"
  }))))));
});
if (true) {
  Popover.propTypes = {
    "aria-label": string.isRequired,
    children: node.isRequired
  };
}
Popover.displayName = "Popover";

// src/PopoverButton.tsx
import {
  createElement as createElement4,
  forwardRef as forwardRef2,
  useContext as useContext2
} from "react";
import {
  func,
  node as node2,
  string as string2
} from "prop-types";
import {Button as Button2} from "@twilio-paste/button";
import {NonModalDialogDisclosurePrimitive} from "@twilio-paste/non-modal-dialog-primitive";
var PopoverButton = forwardRef2((_a, ref) => {
  var {children} = _a, popoverButtonProps = __rest(_a, ["children"]);
  const popover = useContext2(PopoverContext);
  return /* @__PURE__ */ createElement4(NonModalDialogDisclosurePrimitive, __assign(__assign(__assign({}, popover), popoverButtonProps), {
    as: Button2,
    ref
  }), children);
});
if (true) {
  PopoverButton.propTypes = {
    id: string2,
    children: node2.isRequired,
    toggle: func
  };
}
PopoverButton.displayName = "PopoverButton";
export {
  Popover,
  PopoverButton,
  PopoverContainer
};
