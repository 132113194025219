var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/index.tsx
import {
  createElement,
  forwardRef
} from "react";
import {
  func,
  string
} from "prop-types";
import {Text} from "@twilio-paste/text";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
import {isIconSizeTokenProp} from "@twilio-paste/style-props";

// src/utils.ts
var getInitialsFromName = (fullname) => {
  return fullname.split(" ").map((name) => name.slice(0, 1)).reduce((previous, currentInitial, index, initials) => {
    if (index === 0 || index === initials.length - 1) {
      return `${previous}${currentInitial}`;
    }
    return previous;
  }, "");
};
var getCorrespondingLineHeightFromSizeToken = (size) => {
  if (typeof size === "string" && size.includes("sizeIcon")) {
    return size.replace("sizeIcon", "lineHeight");
  }
  throw new Error("[Avatar]: size must be of type IconSizeOptions.");
};
var getCorrespondingFontSizeFromSizeToken = (size) => {
  if (typeof size === "string" && size.includes("sizeIcon")) {
    switch (size) {
      case "sizeIcon10":
      case "sizeIcon20":
      case "sizeIcon30":
      case "sizeIcon40":
      case "sizeIcon50":
      case "sizeIcon60":
      default:
        return "fontSize10";
      case "sizeIcon70":
        return "fontSize20";
      case "sizeIcon80":
        return "fontSize30";
      case "sizeIcon90":
        return "fontSize40";
      case "sizeIcon100":
        return "fontSize60";
      case "sizeIcon110":
        return "fontSize70";
    }
  }
  throw new Error("[Avatar]: size must be of type IconSizeOptions.");
};
var getCorrespondingIconSizeFromSizeToken = (size) => {
  if (typeof size === "string" && size.includes("sizeIcon")) {
    switch (size) {
      case "sizeIcon10":
      case "sizeIcon20":
      case "sizeIcon30":
      case "sizeIcon40":
      default:
        return "sizeIcon10";
      case "sizeIcon50":
      case "sizeIcon60":
        return "sizeIcon20";
      case "sizeIcon70":
        return "sizeIcon30";
      case "sizeIcon80":
        return "sizeIcon40";
      case "sizeIcon90":
        return "sizeIcon50";
      case "sizeIcon100":
        return "sizeIcon70";
      case "sizeIcon110":
        return "sizeIcon80";
    }
  }
  throw new Error("[Avatar]: size must be of type IconSizeOptions.");
};
var getComputedTokenNames = (size) => {
  if (typeof size === "string") {
    return {
      lineHeight: getCorrespondingLineHeightFromSizeToken(size),
      fontSize: getCorrespondingFontSizeFromSizeToken(size),
      iconSize: getCorrespondingIconSizeFromSizeToken(size)
    };
  }
  if (Array.isArray(size)) {
    return {
      lineHeight: size.map((s) => {
        if (s != null) {
          return getCorrespondingLineHeightFromSizeToken(s);
        }
        return null;
      }),
      fontSize: size.map((s) => {
        if (s != null) {
          return getCorrespondingFontSizeFromSizeToken(s);
        }
        return null;
      }),
      iconSize: size.map((s) => {
        if (s != null) {
          return getCorrespondingIconSizeFromSizeToken(s);
        }
        return null;
      })
    };
  }
  throw new Error("[Avatar]: size must be a string or an array");
};

// src/index.tsx
var DEFAULT_SIZE = "sizeIcon70";
var AvatarContents = ({name, size = DEFAULT_SIZE, src, icon: Icon}) => {
  const computedTokenNames = getComputedTokenNames(size);
  if (Icon != null) {
    if (typeof Icon !== "function" || typeof Icon.displayName !== "string" || !Icon.displayName.includes("Icon")) {
      throw new Error("[Paste Avatar]: icon prop expected to be a Paste icon only.");
    }
    return /* @__PURE__ */ createElement(Box, {
      maxWidth: "100%",
      size,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }, /* @__PURE__ */ createElement(Icon, {
      decorative: false,
      title: name,
      size: computedTokenNames.iconSize,
      color: "colorText"
    }));
  }
  if (src != null) {
    return /* @__PURE__ */ createElement(Box, {
      as: "img",
      alt: name,
      maxWidth: "100%",
      src,
      size,
      title: name
    });
  }
  return /* @__PURE__ */ createElement(Text, {
    as: "abbr",
    display: "block",
    fontSize: computedTokenNames.fontSize,
    fontWeight: "fontWeightBold",
    lineHeight: computedTokenNames.lineHeight,
    textAlign: "center",
    textDecoration: "none",
    title: name
  }, getInitialsFromName(name));
};
var Avatar = forwardRef((_a, ref) => {
  var {name, children, size = DEFAULT_SIZE, src, icon} = _a, props = __rest(_a, ["name", "children", "size", "src", "icon"]);
  if (name === void 0) {
    console.error("[Paste Avatar]: name prop is required");
  }
  return /* @__PURE__ */ createElement(Box, __assign(__assign({}, safelySpreadBoxProps(props)), {
    as: "div",
    backgroundColor: "colorBackgroundUser",
    borderRadius: "borderRadiusCircle",
    overflow: "hidden",
    ref,
    size
  }), /* @__PURE__ */ createElement(AvatarContents, __assign({
    name,
    size,
    src,
    icon
  }, props)));
});
Avatar.displayName = "Avatar";
Avatar.propTypes = {
  size: isIconSizeTokenProp,
  src: string,
  name: string.isRequired,
  icon: func
};
export {
  Avatar
};
