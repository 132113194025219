var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Modal.tsx
import {
  createElement,
  forwardRef,
  useEffect
} from "react";
import {
  bool,
  func,
  node,
  object,
  oneOf,
  string
} from "prop-types";
import {styled, css} from "@twilio-paste/styling-library";
import {useTransition, animated} from "@twilio-paste/animation-library";
import {pasteBaseStyles} from "@twilio-paste/theme";
import {ModalDialogPrimitiveOverlay, ModalDialogPrimitiveContent} from "@twilio-paste/modal-dialog-primitive";

// src/ModalContext.tsx
import {
  createContext,
  useContext
} from "react";
var ModalContext = createContext(null);
var useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModalContext must be used with ModalContextProvider");
  }
  return context;
};

// src/utils/consoleUtils.ts
var MARGIN_AMOUNT_BASED_ON_CALC_OFFSET = "80px";
function addConsoleHeightPatch() {
  if (document == null) {
    return;
  }
  const CONTENT_WRAPPER = document.querySelector("#content");
  const SIDEBAR_WRAPPER = document.querySelector("#sidebar-wrapper");
  if (CONTENT_WRAPPER != null) {
    CONTENT_WRAPPER.style.marginTop = MARGIN_AMOUNT_BASED_ON_CALC_OFFSET;
  }
  if (SIDEBAR_WRAPPER != null) {
    SIDEBAR_WRAPPER.style.marginTop = MARGIN_AMOUNT_BASED_ON_CALC_OFFSET;
  }
}
function removeConsoleHeightPatch() {
  if (document == null) {
    return;
  }
  const CONTENT_WRAPPER = document.querySelector("#content");
  const SIDEBAR_WRAPPER = document.querySelector("#sidebar-wrapper");
  if (CONTENT_WRAPPER != null) {
    CONTENT_WRAPPER.style.removeProperty("margin-top");
  }
  if (SIDEBAR_WRAPPER != null) {
    SIDEBAR_WRAPPER.style.removeProperty("margin-top");
  }
}

// src/Modal.tsx
var ModalDialogOverlay = animated(styled(ModalDialogPrimitiveOverlay)(css({
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  backgroundColor: "colorBackgroundOverlay",
  zIndex: "zIndex80"
}), pasteBaseStyles));
var ModalDialogContent = animated(styled(ModalDialogPrimitiveContent)(({size}) => css({
  width: "100%",
  maxWidth: size === "wide" ? "size80" : "size60",
  maxHeight: "90%",
  minHeight: "170px",
  backgroundColor: "colorBackgroundBody",
  borderRadius: "borderRadius20",
  boxShadow: "shadowCard",
  display: "flex",
  flexDirection: "column"
})));
var getAnimationStates = (isConsole) => ({
  from: {opacity: 0, transform: `scale(0.675)`},
  enter: {opacity: 1, transform: `scale(1)`},
  leave: isConsole ? null : {opacity: 0, transform: `scale(0.675)`},
  config: {
    mass: 0.5,
    tension: 370,
    friction: 26
  }
});
var Modal = forwardRef((_a, ref) => {
  var {
    children,
    isOpen,
    onDismiss,
    allowPinchZoom = true,
    initialFocusRef,
    ariaLabelledby,
    size,
    __console_patch = false
  } = _a, props = __rest(_a, [
    "children",
    "isOpen",
    "onDismiss",
    "allowPinchZoom",
    "initialFocusRef",
    "ariaLabelledby",
    "size",
    "__console_patch"
  ]);
  const transitions = useTransition(isOpen, getAnimationStates(__console_patch));
  useEffect(() => {
    if (__console_patch && isOpen) {
      addConsoleHeightPatch();
    }
    return () => {
      if (__console_patch) {
        removeConsoleHeightPatch();
      }
    };
  }, [isOpen, __console_patch]);
  return /* @__PURE__ */ createElement(ModalContext.Provider, {
    value: {onDismiss}
  }, transitions((styles, item) => item && /* @__PURE__ */ createElement(ModalDialogOverlay, {
    onDismiss,
    allowPinchZoom,
    initialFocusRef,
    style: {opacity: styles.opacity}
  }, /* @__PURE__ */ createElement(ModalDialogContent, __assign(__assign({
    "aria-labelledby": ariaLabelledby
  }, props), {
    className: null,
    ref,
    style: styles,
    size
  }), children))));
});
Modal.displayName = "Modal";
if (true) {
  Modal.propTypes = {
    children: node.isRequired,
    isOpen: bool.isRequired,
    onDismiss: func.isRequired,
    allowPinchZoom: bool,
    size: oneOf(["default", "wide"]).isRequired,
    initialFocusRef: object,
    ariaLabelledby: string.isRequired
  };
}

// src/ModalHeader.tsx
import {
  createElement as createElement2,
  forwardRef as forwardRef2
} from "react";
import {
  node as node2
} from "prop-types";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
import {Button} from "@twilio-paste/button";
import {Flex} from "@twilio-paste/flex";
import {CloseIcon} from "@twilio-paste/icons/esm/CloseIcon";
var ModalHeader = forwardRef2((_a, ref) => {
  var {children} = _a, props = __rest(_a, ["children"]);
  const {onDismiss} = useModalContext();
  return /* @__PURE__ */ createElement2(Box, __assign(__assign({}, safelySpreadBoxProps(props)), {
    as: "header",
    borderBottomStyle: "solid",
    borderBottomWidth: "borderWidth10",
    borderBottomColor: "colorBorderLighter",
    padding: "space50",
    margin: "space0",
    flexShrink: 0,
    ref
  }), /* @__PURE__ */ createElement2(Flex, {
    hAlignContent: "between"
  }, /* @__PURE__ */ createElement2(Flex, {
    vAlignContent: "center",
    grow: 1,
    marginRight: "space70"
  }, children), /* @__PURE__ */ createElement2(Button, {
    variant: "reset",
    size: "reset",
    onClick: () => onDismiss()
  }, /* @__PURE__ */ createElement2(CloseIcon, {
    decorative: false,
    color: "colorTextWeak",
    size: "sizeIcon60",
    title: "Close modal"
  }))));
});
ModalHeader.displayName = "ModalHeader";
if (true) {
  ModalHeader.propTypes = {
    children: node2.isRequired
  };
}

// src/ModalHeading.tsx
import {
  createElement as createElement3,
  forwardRef as forwardRef3
} from "react";
import {
  node as node3,
  oneOf as oneOf2
} from "prop-types";
import {Heading} from "@twilio-paste/heading";
var ModalHeading = forwardRef3((_a, ref) => {
  var {children, as = "h2"} = _a, props = __rest(_a, ["children", "as"]);
  return /* @__PURE__ */ createElement3(Heading, __assign(__assign({}, props), {
    as,
    marginBottom: "space0",
    variant: "heading30",
    ref
  }), children);
});
ModalHeading.displayName = "ModalHeading";
if (true) {
  ModalHeading.propTypes = {
    children: node3.isRequired,
    as: oneOf2(["h1", "h2", "h3", "h4", "h5", "h6"])
  };
}

// src/ModalBody.tsx
import {
  createElement as createElement4,
  forwardRef as forwardRef4
} from "react";
import {
  node as node4
} from "prop-types";
import {Box as Box2, safelySpreadBoxProps as safelySpreadBoxProps2} from "@twilio-paste/box";
var ModalBody = forwardRef4((_a, ref) => {
  var {children} = _a, props = __rest(_a, ["children"]);
  return /* @__PURE__ */ createElement4(Box2, __assign(__assign({}, safelySpreadBoxProps2(props)), {
    as: "div",
    overflowY: "auto",
    padding: "space50",
    paddingBottom: "space60",
    ref
  }), children);
});
ModalBody.displayName = "ModalBody";
if (true) {
  ModalBody.propTypes = {
    children: node4.isRequired
  };
}

// src/ModalFooter.tsx
import {
  createElement as createElement5,
  forwardRef as forwardRef5
} from "react";
import {
  node as node5
} from "prop-types";
import {Box as Box3, safelySpreadBoxProps as safelySpreadBoxProps3} from "@twilio-paste/box";
var ModalFooter = forwardRef5((_a, ref) => {
  var {children} = _a, props = __rest(_a, ["children"]);
  return /* @__PURE__ */ createElement5(Box3, __assign(__assign({}, safelySpreadBoxProps3(props)), {
    as: "footer",
    borderTopStyle: "solid",
    borderTopWidth: "borderWidth10",
    borderTopColor: "colorBorderLighter",
    padding: "space50",
    display: "flex",
    flexShrink: 0,
    ref
  }), children);
});
ModalFooter.displayName = "ModalFooter";
if (true) {
  ModalFooter.propTypes = {
    children: node5.isRequired
  };
}

// src/ModalFooterActions.tsx
import {
  Children,
  createElement as createElement6,
  forwardRef as forwardRef6
} from "react";
import {
  node as node6,
  oneOf as oneOf3
} from "prop-types";
import {Box as Box4} from "@twilio-paste/box";
var ModalFooterActions = forwardRef6(({children, justify}, ref) => {
  const count = Children.count(children);
  return /* @__PURE__ */ createElement6(Box4, {
    display: "flex",
    justifyContent: justify === "start" ? "flex-start" : "flex-end",
    flexShrink: justify === "start" ? null : 0,
    flexWrap: "wrap",
    flexGrow: 1,
    alignItems: "center",
    ref
  }, Children.map(children, (child, index) => {
    return /* @__PURE__ */ createElement6(Box4, {
      marginRight: count !== index + 1 ? "space50" : null
    }, child);
  }));
});
ModalFooterActions.displayName = "ModalFooterActions";
if (true) {
  ModalFooterActions.propTypes = {
    children: node6.isRequired,
    justify: oneOf3(["start", "end"])
  };
}
export {
  Modal,
  ModalBody,
  ModalFooter,
  ModalFooterActions,
  ModalHeader,
  ModalHeading
};
