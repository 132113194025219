// src/index.tsx
import {
  createElement,
  forwardRef,
  useEffect,
  useState
} from "react";
import {
  number,
  string
} from "prop-types";
import {styled, keyframes, css, size as sizeFn} from "@twilio-paste/styling-library";
import {LoadingIcon} from "@twilio-paste/icons/esm/LoadingIcon";
import {isIconSizeTokenProp} from "@twilio-paste/style-props";
var rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
`;
var SpinningWrapper = styled.div(sizeFn, (props) => css({
  display: "inline-block",
  opacity: props.show ? 1 : 0,
  transition: "opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1)",
  animation: `${rotate} 2s linear infinite`,
  "@media screen and (prefers-reduced-motion: reduce)": {
    animation: "none"
  }
}));
var Spinner = forwardRef(({as, size, color, decorative, title, delay = 250}, ref) => {
  const [show, setShow] = useState(delay === 0);
  useEffect(() => {
    if (delay === 0)
      return;
    const showTimer = setTimeout(() => setShow(true), delay);
    return () => clearTimeout(showTimer);
  }, [delay]);
  return /* @__PURE__ */ createElement(SpinningWrapper, {
    size,
    show,
    ref
  }, /* @__PURE__ */ createElement(LoadingIcon, {
    as,
    size,
    color,
    decorative,
    title
  }));
});
Spinner.defaultProps = {
  size: "sizeIcon20"
};
if (true) {
  Spinner.propTypes = {
    title: string.isRequired,
    delay: number,
    size: isIconSizeTokenProp
  };
}
Spinner.displayName = "Spinner";
export {
  Spinner
};
