import * as React from "react";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
const IconWrapper = ({display, color, size, ...props}) => {
  return /* @__PURE__ */ React.createElement(Box, {
    ...safelySpreadBoxProps(props),
    lineHeight: "lineHeight0",
    display,
    color,
    size
  });
};
IconWrapper.defaultProps = {
  as: "span",
  display: "block",
  color: "currentColor",
  size: "sizeIcon30"
};
IconWrapper.displayName = "IconWrapper";
export {
  IconWrapper
};
