import * as React from "react";
import {useUID} from "@twilio-paste/uid-library";
import {IconWrapper} from "./helpers/IconWrapper";
const ProductAuthyIcon = ({as, display, size, color, title, decorative}) => {
  const titleId = `ProductAuthyIcon-${useUID()}`;
  if (!decorative && title == null) {
    throw new Error("[ProductAuthyIcon]: Missing a title for non-decorative icon.");
  }
  return /* @__PURE__ */ React.createElement(IconWrapper, {
    as,
    display,
    size,
    color
  }, /* @__PURE__ */ React.createElement("svg", {
    role: "img",
    "aria-hidden": decorative,
    width: "100%",
    height: "100%",
    viewBox: "0 0 20 20",
    "aria-labelledby": titleId
  }, title ? /* @__PURE__ */ React.createElement("title", {
    id: titleId
  }, title) : null, /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    fillRule: "evenodd",
    d: "M10.722 6.547l2.89 2.762a.947.947 0 01.265.943 1 1 0 01-.723.69 1.054 1.054 0 01-.987-.252l-2.89-2.762c-1.198-1.14-3.135-1.137-4.33.005a2.838 2.838 0 00-.006 4.138l2.89 2.762c.293.28.38.7.222 1.064-.158.365-.53.603-.944.603-.27 0-.53-.103-.722-.286l-2.89-2.762c-1.996-1.907-1.996-4.998 0-6.905 1.995-1.907 5.23-1.907 7.225 0zm2.89-2.762l2.89 2.762c1.462 1.396 1.9 3.496 1.109 5.32-.791 1.825-2.655 3.014-4.721 3.013a5.216 5.216 0 01-3.613-1.428l-2.89-2.762a.947.947 0 01-.266-.944 1 1 0 01.723-.691c.353-.09.73.007.988.254l2.89 2.762c1.198 1.14 3.135 1.137 4.33-.005a2.838 2.838 0 00.006-4.138l-2.89-2.762a.946.946 0 010-1.38 1.056 1.056 0 011.444-.001z"
  })));
};
ProductAuthyIcon.displayName = "ProductAuthyIcon";
export {
  ProductAuthyIcon
};
