import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/ChangelogLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Change Log`}</h1>
    <h2>{`4.0.3`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0eded1fd63f081ba9aeab5b5946218e1c5b9b316"
        }}><inlineCode parentName="a">{`0eded1fd`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1319"
        }}>{`#1319`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/SiTaggart"
        }}>{`@SiTaggart`}</a>{`! - Change internal dependencies to have minor range matching on version numbers`}</li>
    </ul>
    <h2>{`4.0.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/514bd5aa9fed6581bbc4c1de649457bcc8e87b3c"
        }}><inlineCode parentName="a">{`514bd5aa`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.1"
            }}>{`theme@5.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.2"
            }}>{`box@4.0.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.1"
            }}>{`style-props@3.0.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`4.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/509eba7a95325dd6f8adc3e905e22f92b7f004a9"
        }}><inlineCode parentName="a">{`509eba7a`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.1"
            }}>{`box@4.0.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`4.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4c9ed5cac36ada218824d3e24bf45d4a03a12272"
        }}><inlineCode parentName="a">{`4c9ed5ca`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/26c828d8681e0e671f28b5f2856cd1803f13953f"
        }}><inlineCode parentName="a">{`26c828d8`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.6.0"
            }}>{`design-tokens@6.6.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.0"
            }}>{`theme@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@4.0.0"
            }}>{`box@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.0"
            }}>{`style-props@3.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`3.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/944c340790b932f4714b0e6075c5641ecdbee9d6"
        }}><inlineCode parentName="a">{`944c3407`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.5.2"
            }}>{`design-tokens@6.5.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@3.0.1"
            }}>{`box@3.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.1"
            }}>{`style-props@2.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.1"
            }}>{`theme@4.3.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`3.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f1675586933bcce71a6b5c5fec7d939735763a73"
        }}><inlineCode parentName="a">{`f1675586`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.0"
            }}>{`theme@4.3.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@3.0.0"
            }}>{`box@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.0"
            }}>{`style-props@2.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`2.1.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{` `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/pull/1158"
          }}>{`#1158`}</a>{` Thanks `}<a parentName="p" {...{
            "href": "https://github.com/richbachman"
          }}>{`@richbachman`}</a>{`! - Pinned all twilio-paste package versions in order to keep them in sync with core when they are updated by changesets.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Updated dependencies [`}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`]:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.2.2"
            }}>{`theme@4.2.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@1.9.2"
            }}>{`style-props@1.9.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:box@2.13.2"
            }}>{`box@2.13.2`}</a></li>
        </ul>
      </li>
    </ul>
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@2.1.0...@twilio-paste/media-object@2.1.1"
      }}>{`2.1.1`}</a>{` (2021-01-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@2.0.3...@twilio-paste/media-object@2.1.0"
      }}>{`2.1.0`}</a>{` (2021-01-21)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ESBuild for builds (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1084"
        }}>{`#1084`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/053646011508be10477d5b732269cdb0419235d7"
        }}>{`0536460`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@2.0.2...@twilio-paste/media-object@2.0.3"
      }}>{`2.0.3`}</a>{` (2021-01-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@2.0.1...@twilio-paste/media-object@2.0.2"
      }}>{`2.0.2`}</a>{` (2021-01-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@2.0.0...@twilio-paste/media-object@2.0.1"
      }}>{`2.0.1`}</a>{` (2020-12-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.75...@twilio-paste/media-object@2.0.0"
      }}>{`2.0.0`}</a>{` (2020-12-17)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`media-object:`}</strong>{` add forwardRef (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/6bcac357000269a4f1a881373751068f675ceffe"
        }}>{`6bcac35`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`media-object:`}</strong>{` component is now using fowardRef`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.74...@twilio-paste/media-object@1.2.75"
      }}>{`1.2.75`}</a>{` (2020-12-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.73...@twilio-paste/media-object@1.2.74"
      }}>{`1.2.74`}</a>{` (2020-12-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.72...@twilio-paste/media-object@1.2.73"
      }}>{`1.2.73`}</a>{` (2020-12-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.71...@twilio-paste/media-object@1.2.72"
      }}>{`1.2.72`}</a>{` (2020-12-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.70...@twilio-paste/media-object@1.2.71"
      }}>{`1.2.71`}</a>{` (2020-12-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.69...@twilio-paste/media-object@1.2.70"
      }}>{`1.2.70`}</a>{` (2020-11-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.68...@twilio-paste/media-object@1.2.69"
      }}>{`1.2.69`}</a>{` (2020-11-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.67...@twilio-paste/media-object@1.2.68"
      }}>{`1.2.68`}</a>{` (2020-11-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.66...@twilio-paste/media-object@1.2.67"
      }}>{`1.2.67`}</a>{` (2020-10-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.65...@twilio-paste/media-object@1.2.66"
      }}>{`1.2.66`}</a>{` (2020-10-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.64...@twilio-paste/media-object@1.2.65"
      }}>{`1.2.65`}</a>{` (2020-10-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.63...@twilio-paste/media-object@1.2.64"
      }}>{`1.2.64`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.62...@twilio-paste/media-object@1.2.63"
      }}>{`1.2.63`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.61...@twilio-paste/media-object@1.2.62"
      }}>{`1.2.62`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.60...@twilio-paste/media-object@1.2.61"
      }}>{`1.2.61`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.59...@twilio-paste/media-object@1.2.60"
      }}>{`1.2.60`}</a>{` (2020-09-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.58...@twilio-paste/media-object@1.2.59"
      }}>{`1.2.59`}</a>{` (2020-09-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.57...@twilio-paste/media-object@1.2.58"
      }}>{`1.2.58`}</a>{` (2020-09-21)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`media-object:`}</strong>{` set to be production (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/08dcd7abc74de1b54987cd77ff3986bf56b690e7"
        }}>{`08dcd7a`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.56...@twilio-paste/media-object@1.2.57"
      }}>{`1.2.57`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.55...@twilio-paste/media-object@1.2.56"
      }}>{`1.2.56`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.54...@twilio-paste/media-object@1.2.55"
      }}>{`1.2.55`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.53...@twilio-paste/media-object@1.2.54"
      }}>{`1.2.54`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.52...@twilio-paste/media-object@1.2.53"
      }}>{`1.2.53`}</a>{` (2020-09-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.51...@twilio-paste/media-object@1.2.52"
      }}>{`1.2.52`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.50...@twilio-paste/media-object@1.2.51"
      }}>{`1.2.51`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.49...@twilio-paste/media-object@1.2.50"
      }}>{`1.2.50`}</a>{` (2020-08-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.48...@twilio-paste/media-object@1.2.49"
      }}>{`1.2.49`}</a>{` (2020-08-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.47...@twilio-paste/media-object@1.2.48"
      }}>{`1.2.48`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.46...@twilio-paste/media-object@1.2.47"
      }}>{`1.2.47`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.45...@twilio-paste/media-object@1.2.46"
      }}>{`1.2.46`}</a>{` (2020-08-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.44...@twilio-paste/media-object@1.2.45"
      }}>{`1.2.45`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.43...@twilio-paste/media-object@1.2.44"
      }}>{`1.2.44`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.42...@twilio-paste/media-object@1.2.43"
      }}>{`1.2.43`}</a>{` (2020-07-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.41...@twilio-paste/media-object@1.2.42"
      }}>{`1.2.42`}</a>{` (2020-07-30)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.40...@twilio-paste/media-object@1.2.41"
      }}>{`1.2.41`}</a>{` (2020-07-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.39...@twilio-paste/media-object@1.2.40"
      }}>{`1.2.40`}</a>{` (2020-07-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.38...@twilio-paste/media-object@1.2.39"
      }}>{`1.2.39`}</a>{` (2020-07-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.37...@twilio-paste/media-object@1.2.38"
      }}>{`1.2.38`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.36...@twilio-paste/media-object@1.2.37"
      }}>{`1.2.37`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.35...@twilio-paste/media-object@1.2.36"
      }}>{`1.2.36`}</a>{` (2020-07-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.34...@twilio-paste/media-object@1.2.35"
      }}>{`1.2.35`}</a>{` (2020-06-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.33...@twilio-paste/media-object@1.2.34"
      }}>{`1.2.34`}</a>{` (2020-06-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.32...@twilio-paste/media-object@1.2.33"
      }}>{`1.2.33`}</a>{` (2020-06-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.31...@twilio-paste/media-object@1.2.32"
      }}>{`1.2.32`}</a>{` (2020-06-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.30...@twilio-paste/media-object@1.2.31"
      }}>{`1.2.31`}</a>{` (2020-06-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.29...@twilio-paste/media-object@1.2.30"
      }}>{`1.2.30`}</a>{` (2020-06-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.28...@twilio-paste/media-object@1.2.29"
      }}>{`1.2.29`}</a>{` (2020-06-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.27...@twilio-paste/media-object@1.2.28"
      }}>{`1.2.28`}</a>{` (2020-06-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.26...@twilio-paste/media-object@1.2.27"
      }}>{`1.2.27`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.25...@twilio-paste/media-object@1.2.26"
      }}>{`1.2.26`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.24...@twilio-paste/media-object@1.2.25"
      }}>{`1.2.25`}</a>{` (2020-05-27)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.23...@twilio-paste/media-object@1.2.24"
      }}>{`1.2.24`}</a>{` (2020-05-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.22...@twilio-paste/media-object@1.2.23"
      }}>{`1.2.23`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.21...@twilio-paste/media-object@1.2.22"
      }}>{`1.2.22`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.20...@twilio-paste/media-object@1.2.21"
      }}>{`1.2.21`}</a>{` (2020-05-04)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`media-object:`}</strong>{` switch to new spacing prop validators (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/d587c498cb060980f6484f0d89940a956bbdd909"
        }}>{`d587c49`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.19...@twilio-paste/media-object@1.2.20"
      }}>{`1.2.20`}</a>{` (2020-05-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.18...@twilio-paste/media-object@1.2.19"
      }}>{`1.2.19`}</a>{` (2020-04-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.17...@twilio-paste/media-object@1.2.18"
      }}>{`1.2.18`}</a>{` (2020-04-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.16...@twilio-paste/media-object@1.2.17"
      }}>{`1.2.17`}</a>{` (2020-04-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.15...@twilio-paste/media-object@1.2.16"
      }}>{`1.2.16`}</a>{` (2020-04-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.14...@twilio-paste/media-object@1.2.15"
      }}>{`1.2.15`}</a>{` (2020-04-08)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`media-object:`}</strong>{` package dependencies updated to be correct (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/02b4640897f78aed7d898247a16d5e7133c15b49"
        }}>{`02b4640`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.13...@twilio-paste/media-object@1.2.14"
      }}>{`1.2.14`}</a>{` (2020-04-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.12...@twilio-paste/media-object@1.2.13"
      }}>{`1.2.13`}</a>{` (2020-04-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.11...@twilio-paste/media-object@1.2.12"
      }}>{`1.2.12`}</a>{` (2020-04-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.10...@twilio-paste/media-object@1.2.11"
      }}>{`1.2.11`}</a>{` (2020-03-28)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`media-object:`}</strong>{` add min-width to body (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/5281863ac0cf2ee94fb5cabc1b2ca4719adac8f9"
        }}>{`5281863`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.9...@twilio-paste/media-object@1.2.10"
      }}>{`1.2.10`}</a>{` (2020-03-24)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`media-object:`}</strong>{` switch from theme-tokens to theme package dep (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/399088bf5fc80c0398daaff0a6551673a7484807"
        }}>{`399088b`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.8...@twilio-paste/media-object@1.2.9"
      }}>{`1.2.9`}</a>{` (2020-03-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.7...@twilio-paste/media-object@1.2.8"
      }}>{`1.2.8`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.6...@twilio-paste/media-object@1.2.7"
      }}>{`1.2.7`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.5...@twilio-paste/media-object@1.2.6"
      }}>{`1.2.6`}</a>{` (2020-03-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.4...@twilio-paste/media-object@1.2.5"
      }}>{`1.2.5`}</a>{` (2020-03-06)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`add missing transitive peerDep on '@styled-system/css' (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/fdbb81370cda71037348742a680299ce481eabfd"
        }}>{`fdbb813`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.3...@twilio-paste/media-object@1.2.4"
      }}>{`1.2.4`}</a>{` (2020-03-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.2...@twilio-paste/media-object@1.2.3"
      }}>{`1.2.3`}</a>{` (2020-02-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.1...@twilio-paste/media-object@1.2.2"
      }}>{`1.2.2`}</a>{` (2020-02-26)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`package dependencies and deprecation warnings (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/334"
        }}>{`#334`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0e88338511e6835a79eb0a9cea8d5b3a1cdf0a88"
        }}>{`0e88338`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.2.0...@twilio-paste/media-object@1.2.1"
      }}>{`1.2.1`}</a>{` (2020-02-14)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`media-object:`}</strong>{` add missing peer deps (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/34ba7a4b7a082d64c15fbcd0b7245c0c8c7b1b36"
        }}>{`34ba7a4`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.1.6...@twilio-paste/media-object@1.2.0"
      }}>{`1.2.0`}</a>{` (2020-02-13)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`media-object:`}</strong>{` add support for setting margin top and bottom (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7adc1ed071e8299eb396afbfa2b85b8c35af988d"
        }}>{`7adc1ed`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`media-object:`}</strong>{` add support for the as prop (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/3167ba1377e9f4b0fdc22f4df3488f81e75a74e8"
        }}>{`3167ba1`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.1.5...@twilio-paste/media-object@1.1.6"
      }}>{`1.1.6`}</a>{` (2020-02-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.1.4...@twilio-paste/media-object@1.1.5"
      }}>{`1.1.5`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.1.3...@twilio-paste/media-object@1.1.4"
      }}>{`1.1.4`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.1.2...@twilio-paste/media-object@1.1.3"
      }}>{`1.1.3`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.1.1...@twilio-paste/media-object@1.1.2"
      }}>{`1.1.2`}</a>{` (2020-02-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.1.0...@twilio-paste/media-object@1.1.1"
      }}>{`1.1.1`}</a>{` (2020-01-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.0.6...@twilio-paste/media-object@1.1.0"
      }}>{`1.1.0`}</a>{` (2020-01-17)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`media-object:`}</strong>{` amend missing deps for successful build (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b420ba0e3763e990779ba98a3e5d7fd9e760f2f1"
        }}>{`b420ba0`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`media-object:`}</strong>{` update implementation of media-object to use box (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1c999cc1cd2cfccfb88f2f38de8aa880a951ccd2"
        }}>{`1c999cc`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.0.5...@twilio-paste/media-object@1.0.6"
      }}>{`1.0.6`}</a>{` (2019-12-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.0.4...@twilio-paste/media-object@1.0.5"
      }}>{`1.0.5`}</a>{` (2019-12-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.0.3...@twilio-paste/media-object@1.0.4"
      }}>{`1.0.4`}</a>{` (2019-12-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.0.2...@twilio-paste/media-object@1.0.3"
      }}>{`1.0.3`}</a>{` (2019-11-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.0.1...@twilio-paste/media-object@1.0.2"
      }}>{`1.0.2`}</a>{` (2019-11-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@1.0.0...@twilio-paste/media-object@1.0.1"
      }}>{`1.0.1`}</a>{` (2019-11-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@0.5.0...@twilio-paste/media-object@1.0.0"
      }}>{`1.0.0`}</a>{` (2019-11-11)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`icons:`}</strong>{` delete old icons, add new streamline icons (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/129"
        }}>{`#129`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/571791ded8ee4c55bb5a3dbcebee4b17b2c7c826"
        }}>{`571791d`}</a>{`)`}</li>
    </ul>
    <h3>{`BREAKING CHANGES`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`icons:`}</strong>{` removed all the inherited icons since we're moving to a new system`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(icons): update icon list for storybook`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): use new icon`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(storybook): use new icon for story`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(story): button icons should be 24px`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix: adjust icon size tokens`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat: add iconSizes as separate key in theme-tokens`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat(icons): icons package to use tokens and update icons`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): update to use tokens and new icons`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(button): use correct spinner size`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(icons): major icons package fixes`}</p>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`huge overhaul to build process`}</li>
      <li parentName="ul">{`now uses rollup`}</li>
      <li parentName="ul">{`builds into cjs and esm directories`}</li>
      <li parentName="ul">{`made the package publishable to npm`}</li>
      <li parentName="ul">{`moved svg folder out of src, now src only holds react stuff`}</li>
      <li parentName="ul">{`updated scripts to use new paths and cleaned up the code`}</li>
      <li parentName="ul">{`programmatically generates rollup config from the icon-list command`}</li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`chore: add new icons dist folders to gitignore`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix: spinner and button icon usage`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`feat(icons): add rollup icon list cache file`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(core-bundle): sort packages for consistent builds`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore: use `}<inlineCode parentName="p">{`esm`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`es`}</inlineCode>{` in rollup for correctness`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore: yarn.lock`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): lint error`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(icons): move rollup icon list`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`chore(spinner): use types package in story`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`fix(spinner): swap out destructured props for explicit props`}</p>
      </li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@0.4.0...@twilio-paste/media-object@0.5.0"
      }}>{`0.5.0`}</a>{` (2019-10-31)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`update types package to styled-system v5 format (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/154"
        }}>{`#154`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/117c41e463b11440582e740c28cc067c06d16e67"
        }}>{`117c41e`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@0.3.1...@twilio-paste/media-object@0.4.0"
      }}>{`0.4.0`}</a>{` (2019-10-29)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`finalise the Text component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/151"
        }}>{`#151`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/b51c272cf420380f73c31c3474ebdf23cb7cad3a"
        }}>{`b51c272`}</a>{`)`}</li>
      <li parentName="ul">{`upgrading to styled-system v5 (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/18"
        }}>{`#18`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/2b3ebd8b2f701a0c6e8b75ab6978ba936814f455"
        }}>{`2b3ebd8`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/media-object@0.3.0...@twilio-paste/media-object@0.3.1"
      }}>{`0.3.1`}</a>{` (2019-09-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/media-object`}</p>
    <h1>{`0.3.0 (2019-08-15)`}</h1>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`change `}<a parentName="li" {...{
          "href": "https://github.com/paste"
        }}>{`@paste`}</a>{` scope to `}<a parentName="li" {...{
          "href": "https://github.com/twilio-paste"
        }}>{`@twilio-paste`}</a>{` (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/2"
        }}>{`#2`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1d8d2ff"
        }}>{`1d8d2ff`}</a>{`)`}</li>
    </ul>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`website:`}</strong>{` Add the tokens page (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/19"
        }}>{`#19`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/878d3c5"
        }}>{`878d3c5`}</a>{`)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      