var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/index.tsx
import {useMenuPrimitiveState} from "@twilio-paste/menu-primitive";

// src/Menu.tsx
import {
  createElement,
  forwardRef
} from "react";
import {safelySpreadBoxProps, Box} from "@twilio-paste/box";
import {MenuPrimitive} from "@twilio-paste/menu-primitive";
var StyledMenu = forwardRef((_a, ref) => {
  var {style} = _a, props = __rest(_a, ["style"]);
  return /* @__PURE__ */ createElement(Box, __assign(__assign({}, safelySpreadBoxProps(props)), {
    backgroundColor: "colorBackgroundBody",
    borderStyle: "solid",
    borderWidth: "borderWidth10",
    borderColor: "colorBorderLight",
    borderRadius: "borderRadius20",
    boxShadow: "shadow",
    maxWidth: "size30",
    minWidth: "size20",
    zIndex: "zIndex20",
    paddingY: "space30",
    _focus: {outline: "none"},
    style,
    ref
  }));
});
var Menu = forwardRef((props, ref) => {
  return /* @__PURE__ */ createElement(MenuPrimitive, __assign(__assign({}, props), {
    as: StyledMenu,
    ref
  }));
});
Menu.displayName = "Menu";

// src/MenuButton.tsx
import {
  createElement as createElement2,
  forwardRef as forwardRef2
} from "react";
import {MenuPrimitiveButton} from "@twilio-paste/menu-primitive";
import {Button} from "@twilio-paste/button";
var MenuButton = forwardRef2((props, ref) => {
  return /* @__PURE__ */ createElement2(MenuPrimitiveButton, __assign(__assign({}, props), {
    as: Button,
    ref
  }), props.children);
});
MenuButton.displayName = "MenuButton";

// src/MenuGroup.tsx
import {
  cloneElement,
  createContext,
  createElement as createElement3,
  forwardRef as forwardRef3,
  isValidElement
} from "react";
import {safelySpreadBoxProps as safelySpreadBoxProps2, Box as Box2} from "@twilio-paste/box";
import {Text} from "@twilio-paste/text";

// src/constants.ts
var MenuItemVariants = {
  DEFAULT: "default",
  GROUP_ITEM: "group_item"
};

// src/MenuGroup.tsx
var MenuGroupContext = createContext(MenuItemVariants.DEFAULT);
var MenuGroup = forwardRef3((_a, ref) => {
  var {label, icon, children} = _a, props = __rest(_a, ["label", "icon", "children"]);
  return /* @__PURE__ */ createElement3(MenuGroupContext.Provider, {
    value: MenuItemVariants.GROUP_ITEM
  }, /* @__PURE__ */ createElement3(Box2, __assign(__assign({}, safelySpreadBoxProps2(props)), {
    role: "presentation",
    "aria-label": label,
    textDecoration: "none",
    ref
  }), /* @__PURE__ */ createElement3(Box2, {
    display: "flex",
    alignItems: "center",
    paddingX: "space70",
    paddingY: "space30",
    cursor: "default"
  }, isValidElement(icon) ? /* @__PURE__ */ createElement3(Box2, {
    flexShrink: 0,
    size: "sizeIcon30"
  }, cloneElement(icon, {color: "colorTextIcon"})) : null, /* @__PURE__ */ createElement3(Text, {
    as: "span",
    color: "colorText",
    role: "presentation",
    fontWeight: "fontWeightBold",
    paddingLeft: icon != null ? "space20" : void 0
  }, label)), children));
});
MenuGroup.displayName = "MenuGroup";

// src/MenuItem.tsx
import {
  createElement as createElement4,
  forwardRef as forwardRef4,
  useContext
} from "react";
import {secureExternalLink} from "@twilio-paste/anchor";
import {Box as Box3, safelySpreadBoxProps as safelySpreadBoxProps3} from "@twilio-paste/box";
import {MenuPrimitiveItem} from "@twilio-paste/menu-primitive";
var StyledMenuItem = forwardRef4((props, ref) => {
  return /* @__PURE__ */ createElement4(Box3, __assign(__assign(__assign({}, props.href && secureExternalLink(props.href)), safelySpreadBoxProps3(props)), {
    as: "a",
    color: props["aria-disabled"] ? "colorTextWeaker" : "colorText",
    display: "block",
    paddingY: "space30",
    paddingX: props.variant === MenuItemVariants.GROUP_ITEM ? "space90" : "space70",
    textDecoration: props.tabIndex === 0 ? "underline" : "none",
    _hover: {
      cursor: "pointer"
    },
    _focus: {
      color: "colorTextLink",
      outline: "none"
    },
    _disabled: {cursor: "not-allowed"},
    ref
  }), props.children);
});
var MenuItem = forwardRef4((_a, ref) => {
  var {as = StyledMenuItem, variant: _variant} = _a, props = __rest(_a, ["as", "variant"]);
  const variant = _variant || useContext(MenuGroupContext);
  return /* @__PURE__ */ createElement4(MenuPrimitiveItem, __assign(__assign({}, props), {
    variant,
    as,
    ref
  }));
});
MenuItem.displayName = "MenuItem";

// src/MenuSeparator.tsx
import {
  createElement as createElement5,
  forwardRef as forwardRef5
} from "react";
import {Separator} from "@twilio-paste/separator";
import {MenuPrimitiveSeparator} from "@twilio-paste/menu-primitive";
var StyledMenuSeparator = forwardRef5((props, ref) => {
  return /* @__PURE__ */ createElement5(Separator, __assign(__assign({}, props), {
    orientation: "horizontal",
    verticalSpacing: "space40",
    ref
  }));
});
var MenuSeparator = forwardRef5((props, ref) => {
  return /* @__PURE__ */ createElement5(MenuPrimitiveSeparator, __assign(__assign({}, props), {
    as: StyledMenuSeparator,
    ref
  }));
});
MenuSeparator.displayName = "MenuSeparator";

// src/SubMenuButton.tsx
import {
  createElement as createElement6,
  forwardRef as forwardRef6
} from "react";
import {MenuPrimitiveButton as MenuPrimitiveButton2} from "@twilio-paste/menu-primitive";
import {MediaObject, MediaBody, MediaFigure} from "@twilio-paste/media-object";
import {ChevronRightIcon} from "@twilio-paste/icons/esm/ChevronRightIcon";
var SubMenuButton = forwardRef6((props, ref) => {
  return /* @__PURE__ */ createElement6(MenuPrimitiveButton2, __assign(__assign({}, props), {
    as: StyledMenuItem,
    ref
  }), /* @__PURE__ */ createElement6(MediaObject, {
    as: "span",
    verticalAlign: "center"
  }, props.children && /* @__PURE__ */ createElement6(MediaBody, {
    as: "span"
  }, props.children), /* @__PURE__ */ createElement6(MediaFigure, {
    as: "span",
    align: "end",
    spacing: "space20"
  }, /* @__PURE__ */ createElement6(ChevronRightIcon, {
    decorative: true,
    size: "sizeIcon30"
  }))));
});
SubMenuButton.displayName = "SubMenuButton";
export {
  Menu,
  MenuButton,
  MenuGroup,
  MenuGroupContext,
  MenuItem,
  MenuSeparator,
  StyledMenuItem,
  SubMenuButton,
  useMenuPrimitiveState as useMenuState
};
