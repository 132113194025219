var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/index.tsx
import {
  createElement as createElement3,
  forwardRef as forwardRef3
} from "react";
import {Box as Box3, safelySpreadBoxProps} from "@twilio-paste/box";
import {LinkExternalIcon} from "@twilio-paste/icons/esm/LinkExternalIcon";

// src/DefaultAnchor.tsx
import {
  createElement,
  forwardRef
} from "react";
import {Box} from "@twilio-paste/box";

// src/types.ts
import {
  bool,
  node,
  oneOf,
  string
} from "prop-types";
var AnchorPropTypes = {
  children: node.isRequired,
  href: string.isRequired,
  rel: string,
  showExternal: bool,
  tabIndex: oneOf([0, -1]),
  target: oneOf(["_self", "_blank", "_parent", "_top"]),
  variant: oneOf(["default", "inverse"])
};

// src/DefaultAnchor.tsx
var DefaultAnchor = forwardRef((props, ref) => /* @__PURE__ */ createElement(Box, __assign(__assign({}, props), {
  as: "a",
  color: "colorTextLink",
  fontSize: "inherit",
  fontWeight: "inherit",
  lineHeight: "inherit",
  outline: "none",
  ref,
  textDecoration: "underline",
  _active: {
    color: "colorTextLinkDarker",
    textDecoration: "none"
  },
  _focus: {
    boxShadow: "shadowFocus",
    color: "colorTextLinkDarker",
    textDecoration: "none"
  },
  _hover: {
    color: "colorTextLinkDarker",
    textDecoration: "none"
  }
}), props.children));
DefaultAnchor.displayName = "DefaultAnchor";
if (true) {
  DefaultAnchor.propTypes = AnchorPropTypes;
}

// src/InverseAnchor.tsx
import {
  createElement as createElement2,
  forwardRef as forwardRef2
} from "react";
import {Box as Box2} from "@twilio-paste/box";
var InverseAnchor = forwardRef2((props, ref) => /* @__PURE__ */ createElement2(Box2, __assign(__assign({}, props), {
  as: "a",
  color: "colorTextInverse",
  fontSize: "inherit",
  fontWeight: "inherit",
  lineHeight: "inherit",
  outline: "none",
  ref,
  textDecoration: "underline",
  _active: {
    color: "colorTextInverse",
    textDecoration: "none"
  },
  _focus: {
    boxShadow: "shadowFocusInverse",
    color: "colorTextInverse",
    textDecoration: "none"
  },
  _hover: {
    color: "colorTextInverse",
    textDecoration: "none"
  }
}), props.children));
InverseAnchor.displayName = "InverseAnchor";
if (true) {
  InverseAnchor.propTypes = AnchorPropTypes;
}

// src/index.tsx
var AnchorVariants = {
  inverse: InverseAnchor,
  default: DefaultAnchor
};
var EXTERNAL_URL_REGEX = /^(https?:)\S*$/;
var EXTERNAL_TARGET_DEFAULT = "_blank";
var EXTERNAL_REL_DEFAULT = "noreferrer noopener";
var isExternalUrl = (url) => EXTERNAL_URL_REGEX.test(url);
var secureExternalLink = (href) => {
  if (!isExternalUrl(href))
    return;
  return {
    rel: EXTERNAL_REL_DEFAULT,
    target: EXTERNAL_TARGET_DEFAULT
  };
};
var Anchor = forwardRef3((_a, ref) => {
  var {
    display,
    height,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    marginX = null,
    marginY = null,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingX = null,
    paddingY = null,
    showExternal,
    size,
    variant = "default",
    verticalAlign,
    width
  } = _a, props = __rest(_a, [
    "display",
    "height",
    "margin",
    "marginBottom",
    "marginLeft",
    "marginRight",
    "marginTop",
    "marginX",
    "marginY",
    "maxHeight",
    "maxWidth",
    "minHeight",
    "minWidth",
    "padding",
    "paddingBottom",
    "paddingLeft",
    "paddingRight",
    "paddingTop",
    "paddingX",
    "paddingY",
    "showExternal",
    "size",
    "variant",
    "verticalAlign",
    "width"
  ]);
  const AnchorComponent = AnchorVariants[variant];
  return /* @__PURE__ */ createElement3(AnchorComponent, __assign(__assign(__assign({
    href: props.href,
    ref,
    variant
  }, secureExternalLink(props.href)), safelySpreadBoxProps(props)), {
    display,
    height,
    margin,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    marginX,
    marginY,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingX,
    paddingY,
    size,
    verticalAlign,
    width
  }), showExternal ? /* @__PURE__ */ createElement3(Box3, {
    as: "span",
    display: "inline-flex",
    alignItems: "center"
  }, props.children, /* @__PURE__ */ createElement3(LinkExternalIcon, {
    decorative: false,
    title: "link takes you to an external page"
  })) : props.children);
});
Anchor.displayName = "Anchor";
if (true) {
  Anchor.propTypes = AnchorPropTypes;
}
export {
  Anchor,
  AnchorPropTypes,
  isExternalUrl,
  secureExternalLink
};
