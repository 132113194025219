var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Grid.tsx
import {
  Children,
  cloneElement,
  createElement,
  forwardRef,
  isValidElement,
  useMemo
} from "react";
import {
  bool,
  node,
  string
} from "prop-types";
import {ResponsiveProp} from "@twilio-paste/style-props";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";

// src/utils.tsx
var getOuterGutterPull = (gutter) => {
  if (Array.isArray(gutter)) {
    return gutter.map((value) => {
      if (value !== "space0") {
        return value.replace("space", "spaceNegative");
      }
      if (value === "space0") {
        return "space0";
      }
      return null;
    });
  }
  if (gutter === "space0") {
    return "space0";
  }
  if (gutter && typeof gutter === "string") {
    return gutter.replace("space", "spaceNegative");
  }
  return "auto";
};
var getStackedColumns = (vertical) => {
  if (Array.isArray(vertical)) {
    return vertical.map((value) => {
      if (typeof value === "boolean") {
        return value === true ? "100%" : "0";
      }
      return null;
    });
  }
  if (vertical) {
    return "100%";
  }
  return "0";
};
var getColumnSpan = ({count, span}) => {
  if (Array.isArray(span) && count) {
    return span.map((value) => {
      return `${value / 12 * 100}%`;
    });
  }
  if (typeof span === "number" && count && count <= 12) {
    return `${span / 12 * 100}%`;
  }
  if (count !== void 0) {
    return `${1 / count * 100}%`;
  }
  return `${1 / 12 * 100}%`;
};
var getColumnOffset = (offset) => {
  if (Array.isArray(offset)) {
    return offset.map((value) => {
      return `${value / 12 * 100}%`;
    });
  }
  return `${offset / 12 * 100}%`;
};

// src/Grid.tsx
var getGutterStyles = (gutter) => {
  const marginStyles = {
    marginRight: getOuterGutterPull(gutter),
    marginLeft: getOuterGutterPull(gutter)
  };
  return marginStyles;
};
var getFlexDirection = (vertical) => {
  if (Array.isArray(vertical)) {
    return vertical.map((value) => {
      if (typeof value === "boolean") {
        return value === true ? "column" : "row";
      }
      return "row";
    });
  }
  if (vertical) {
    return "column";
  }
  return "row";
};
var Grid = forwardRef((_a, ref) => {
  var {as, children, equalColumnHeights, gutter, marginTop, marginBottom, vertical} = _a, props = __rest(_a, ["as", "children", "equalColumnHeights", "gutter", "marginTop", "marginBottom", "vertical"]);
  const GridColumns = useMemo(() => Children.map(children, (child) => isValidElement(child) ? cloneElement(child, {
    count: Children.count(children),
    gutter,
    vertical,
    stretchColumnContent: equalColumnHeights
  }) : child), [children]);
  const gutterStyles = useMemo(() => getGutterStyles(gutter), [gutter]);
  const flexDirection = useMemo(() => getFlexDirection(vertical), [vertical]);
  return /* @__PURE__ */ createElement(Box, __assign(__assign(__assign(__assign({}, safelySpreadBoxProps(props)), {
    ref,
    as,
    alignItems: equalColumnHeights ? "stretch" : null,
    flexDirection,
    flexWrap: "wrap",
    display: "flex",
    marginTop,
    marginBottom
  }), gutterStyles), {
    minWidth: "size0"
  }), GridColumns);
});
Grid.displayName = "Grid";
Grid.defaultProps = {
  vertical: false
};
if (true) {
  Grid.propTypes = {
    as: string,
    children: node.isRequired,
    vertical: ResponsiveProp(bool),
    equalColumnHeights: bool
  };
}

// src/Column.tsx
import {
  createElement as createElement2,
  forwardRef as forwardRef2,
  useMemo as useMemo2
} from "react";
import {
  number,
  string as string2
} from "prop-types";
import {styled, compose, flexbox, layout, space} from "@twilio-paste/styling-library";
import {ResponsiveProp as ResponsiveProp2} from "@twilio-paste/style-props";
var getColumnStyles = (props) => {
  const columnStyles = {
    width: getColumnSpan(props)
  };
  if (props.gutter) {
    columnStyles.paddingLeft = props.gutter;
    columnStyles.paddingRight = props.gutter;
  }
  if (props.offset) {
    columnStyles.marginLeft = getColumnOffset(props.offset);
  }
  if (props.vertical && !props.offset) {
    columnStyles.minWidth = getStackedColumns(props.vertical);
    columnStyles.marginLeft = "space0";
  }
  if (props.stretchColumnContent) {
    columnStyles.alignContent = "stretch";
    columnStyles.display = "flex";
  }
  return columnStyles;
};
var StyledColumn = styled.div(compose(space, flexbox, layout));
var Column = forwardRef2(({as, children, count, gutter, offset, span, stretchColumnContent, vertical}, ref) => {
  const ColumnStyles = useMemo2(() => getColumnStyles({count, gutter, offset, span, stretchColumnContent, vertical}), [count, gutter, offset, span, stretchColumnContent, vertical]);
  return /* @__PURE__ */ createElement2(StyledColumn, __assign(__assign({}, ColumnStyles), {
    as,
    ref
  }), children);
});
Column.displayName = "Column";
if (true) {
  Column.propTypes = {
    as: string2,
    offset: ResponsiveProp2(number),
    span: ResponsiveProp2(number)
  };
}
export {
  Column,
  Grid
};
