var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Label.tsx
import {
  createElement,
  forwardRef
} from "react";
import {
  bool,
  oneOf,
  string
} from "prop-types";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
import {MediaObject, MediaFigure, MediaBody} from "@twilio-paste/media-object";
import {Text} from "@twilio-paste/text";
import {ScreenReaderOnly} from "@twilio-paste/screen-reader-only";
var RequiredDot = (props) => {
  return /* @__PURE__ */ createElement(Box, __assign(__assign({}, props), {
    alignItems: "center",
    justifyContent: "center",
    as: "span",
    cursor: "pointer",
    display: "flex",
    height: "sizeIcon30"
  }), /* @__PURE__ */ createElement(Box, {
    as: "span",
    backgroundColor: "colorBackgroundRequired",
    borderRadius: "borderRadiusCircle",
    display: "block",
    height: "4px",
    width: "4px"
  }, /* @__PURE__ */ createElement(ScreenReaderOnly, null, "Required: ")));
};
var Label = forwardRef((_a, ref) => {
  var {as, marginBottom, required, disabled, children, variant} = _a, props = __rest(_a, ["as", "marginBottom", "required", "disabled", "children", "variant"]);
  let textColor = "colorText";
  if (disabled && variant === "inverse") {
    textColor = "colorTextInverseWeak";
  } else if (disabled) {
    textColor = "colorTextWeak";
  } else if (variant === "inverse") {
    textColor = "colorTextInverse";
  }
  return /* @__PURE__ */ createElement(Box, __assign(__assign({}, safelySpreadBoxProps(props)), {
    as,
    borderBottomWidth: "borderWidth0",
    display: "block",
    marginBottom: marginBottom || "space20",
    paddingLeft: "space0",
    paddingRight: "space0",
    textTransform: "none",
    ref
  }), /* @__PURE__ */ createElement(MediaObject, {
    verticalAlign: "top"
  }, required && /* @__PURE__ */ createElement(MediaFigure, {
    spacing: "space20"
  }, /* @__PURE__ */ createElement(RequiredDot, null)), /* @__PURE__ */ createElement(MediaBody, null, /* @__PURE__ */ createElement(Text, {
    as: "span",
    fontSize: "fontSize30",
    fontWeight: "fontWeightSemibold",
    lineHeight: "lineHeight30",
    color: textColor,
    cursor: disabled ? "not-allowed" : "pointer"
  }, children))));
});
Label.displayName = "Label";
Label.defaultProps = {
  as: "label"
};
if (true) {
  Label.propTypes = {
    as: oneOf(["label", "legend"]),
    disabled: bool,
    htmlFor: string,
    marginBottom: oneOf(["space0"]),
    required: bool,
    variant: oneOf(["default", "inverse"])
  };
}
export {
  Label,
  RequiredDot
};
