import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Button } from '@twilio-paste/button';
import { Paragraph } from '@twilio-paste/paragraph';
import { Table, THead, TBody, Td, Th, Tr } from '@twilio-paste/table';
import { useTabState, Tabs, Tab, TabList, TabPanels, TabPanel } from '@twilio-paste/tabs';
import Changelog from '@twilio-paste/tabs/CHANGELOG.md';
import { MediaObject, MediaFigure, MediaBody } from '@twilio-paste/media-object';
import { InformationIcon } from '@twilio-paste/icons/esm/InformationIcon';
import { LinkExternalIcon } from '@twilio-paste/icons/esm/LinkExternalIcon';
import { Text } from '@twilio-paste/text';
import { useUID } from '@twilio-paste/uid-library';
import { UnorderedList, ListItem } from '@twilio-paste/list';
import { Callout, CalloutTitle, CalloutText } from "../../../../src/components/callout";
import { SidebarCategoryRoutes } from "../../../../src/constants";
import { HorizontalTabsExample, FittedTabsExample, StateHookTabsExample } from "../../../../src/component-examples/TabsExamples";
import * as React from 'react';
export default {
  graphql,
  Anchor,
  Box,
  Button,
  Paragraph,
  Table,
  THead,
  TBody,
  Td,
  Th,
  Tr,
  useTabState,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Changelog,
  MediaObject,
  MediaFigure,
  MediaBody,
  InformationIcon,
  LinkExternalIcon,
  Text,
  useUID,
  UnorderedList,
  ListItem,
  Callout,
  CalloutTitle,
  CalloutText,
  SidebarCategoryRoutes,
  HorizontalTabsExample,
  FittedTabsExample,
  StateHookTabsExample,
  React
};