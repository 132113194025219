// src/index.tsx
import {
  boxShadow,
  compose,
  css,
  display,
  overflow,
  position,
  space,
  styled,
  system,
  typography,
  verticalAlign
} from "@twilio-paste/styling-library";

// src/PseudoPropStyles.ts
var PseudoPropStyles = {
  _hover: "&:hover",
  _active: "&:active, &[data-active=true]",
  _focus: "&:focus",
  _visited: "&:visited",
  _even: "&:nth-of-type(even)",
  _odd: "&:nth-of-type(odd)",
  _disabled: "&:disabled, &:disabled:focus, &:disabled:hover, &[aria-disabled=true], &[aria-disabled=true]:focus, &[aria-disabled=true]:hover",
  _checked: "&:checked, &[aria-checked=true]",
  _mixed: "&:indeterminate, &[aria-checked=mixed]",
  _selected: "&[aria-selected=true]",
  _invalid: "&:invalid, &[aria-invalid=true]",
  _pressed: "&[aria-pressed=true]",
  _readOnly: "&[aria-readonly=true], &[readonly]",
  _first: "&:first-of-type",
  _last: "&:last-of-type",
  _expanded: "&[aria-expanded=true]",
  _grabbed: "&[aria-grabbed=true]",
  _notFirst: "&:not(:first-of-type)",
  _notLast: "&:not(:last-of-type)",
  _before: "&:before",
  _after: "&:after",
  _focusWithin: "&:focus-within"
};

// src/SafelySpreadProps.ts
import {SHADOW_PROPS, SPACE_PROPS, TYPOGRAPHY_PROPS, OVERFLOW_PROPS} from "@twilio-paste/style-props";
var TEXT_PROPS_TO_BLOCK = [
  ...SHADOW_PROPS,
  ...SPACE_PROPS,
  ...TYPOGRAPHY_PROPS,
  ...OVERFLOW_PROPS,
  ...Object.keys(PseudoPropStyles),
  "display",
  "color",
  "verticalAlign",
  "className",
  "style"
];
var safelySpreadTextProps = (props) => {
  return Object.keys(props).reduce((newProps, key) => {
    if (!TEXT_PROPS_TO_BLOCK.includes(key)) {
      newProps[key] = props[key];
    }
    return newProps;
  }, {});
};

// src/index.tsx
var extraConfig = system({
  color: {
    property: "color",
    scale: "textColors"
  },
  cursor: true,
  outline: true,
  transition: true,
  textTransform: true,
  wordBreak: true,
  wordWrap: true
});
var textDecoration = system({textDecoration: true});
var getPseudoStyles = (props) => {
  const pseudoProps = Object.keys(props).filter((propName) => propName.startsWith("_"));
  if (pseudoProps.length === 0) {
    return {};
  }
  const pseudoStyles = {};
  pseudoProps.forEach((pseudoProp) => {
    pseudoStyles[PseudoPropStyles[pseudoProp]] = props[pseudoProp];
  });
  return css(pseudoStyles);
};
var Text = styled.span({
  margin: 0,
  padding: 0
}, compose(boxShadow, display, overflow, position, space, textDecoration, typography, verticalAlign, extraConfig), getPseudoStyles);
Text.displayName = "Text";
Text.defaultProps = {
  color: "colorText",
  fontSize: "fontSize30",
  lineHeight: "lineHeight30"
};
export {
  TEXT_PROPS_TO_BLOCK,
  Text,
  safelySpreadTextProps
};
