var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
var __commonJS = (callback, module) => () => {
  if (!module) {
    module = {exports: {}};
    callback(module.exports, module);
  }
  return module.exports;
};

// ../../../../node_modules/lodash.merge/index.js
var require_lodash = __commonJS((exports, module) => {
  var LARGE_ARRAY_SIZE = 200;
  var HASH_UNDEFINED = "__lodash_hash_undefined__";
  var HOT_COUNT = 800;
  var HOT_SPAN = 16;
  var MAX_SAFE_INTEGER = 9007199254740991;
  var argsTag = "[object Arguments]";
  var arrayTag = "[object Array]";
  var asyncTag = "[object AsyncFunction]";
  var boolTag = "[object Boolean]";
  var dateTag = "[object Date]";
  var errorTag = "[object Error]";
  var funcTag = "[object Function]";
  var genTag = "[object GeneratorFunction]";
  var mapTag = "[object Map]";
  var numberTag = "[object Number]";
  var nullTag = "[object Null]";
  var objectTag = "[object Object]";
  var proxyTag = "[object Proxy]";
  var regexpTag = "[object RegExp]";
  var setTag = "[object Set]";
  var stringTag = "[object String]";
  var undefinedTag = "[object Undefined]";
  var weakMapTag = "[object WeakMap]";
  var arrayBufferTag = "[object ArrayBuffer]";
  var dataViewTag = "[object DataView]";
  var float32Tag = "[object Float32Array]";
  var float64Tag = "[object Float64Array]";
  var int8Tag = "[object Int8Array]";
  var int16Tag = "[object Int16Array]";
  var int32Tag = "[object Int32Array]";
  var uint8Tag = "[object Uint8Array]";
  var uint8ClampedTag = "[object Uint8ClampedArray]";
  var uint16Tag = "[object Uint16Array]";
  var uint32Tag = "[object Uint32Array]";
  var reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
  var reIsHostCtor = /^\[object .+?Constructor\]$/;
  var reIsUint = /^(?:0|[1-9]\d*)$/;
  var typedArrayTags = {};
  typedArrayTags[float32Tag] = typedArrayTags[float64Tag] = typedArrayTags[int8Tag] = typedArrayTags[int16Tag] = typedArrayTags[int32Tag] = typedArrayTags[uint8Tag] = typedArrayTags[uint8ClampedTag] = typedArrayTags[uint16Tag] = typedArrayTags[uint32Tag] = true;
  typedArrayTags[argsTag] = typedArrayTags[arrayTag] = typedArrayTags[arrayBufferTag] = typedArrayTags[boolTag] = typedArrayTags[dataViewTag] = typedArrayTags[dateTag] = typedArrayTags[errorTag] = typedArrayTags[funcTag] = typedArrayTags[mapTag] = typedArrayTags[numberTag] = typedArrayTags[objectTag] = typedArrayTags[regexpTag] = typedArrayTags[setTag] = typedArrayTags[stringTag] = typedArrayTags[weakMapTag] = false;
  var freeGlobal = typeof global == "object" && global && global.Object === Object && global;
  var freeSelf = typeof self == "object" && self && self.Object === Object && self;
  var root = freeGlobal || freeSelf || Function("return this")();
  var freeExports = typeof exports == "object" && exports && !exports.nodeType && exports;
  var freeModule = freeExports && typeof module == "object" && module && !module.nodeType && module;
  var moduleExports = freeModule && freeModule.exports === freeExports;
  var freeProcess = moduleExports && freeGlobal.process;
  var nodeUtil = function() {
    try {
      var types = freeModule && freeModule.require && freeModule.require("util").types;
      if (types) {
        return types;
      }
      return freeProcess && freeProcess.binding && freeProcess.binding("util");
    } catch (e) {
    }
  }();
  var nodeIsTypedArray = nodeUtil && nodeUtil.isTypedArray;
  function apply(func, thisArg, args) {
    switch (args.length) {
      case 0:
        return func.call(thisArg);
      case 1:
        return func.call(thisArg, args[0]);
      case 2:
        return func.call(thisArg, args[0], args[1]);
      case 3:
        return func.call(thisArg, args[0], args[1], args[2]);
    }
    return func.apply(thisArg, args);
  }
  function baseTimes(n, iteratee) {
    var index = -1, result = Array(n);
    while (++index < n) {
      result[index] = iteratee(index);
    }
    return result;
  }
  function baseUnary(func) {
    return function(value) {
      return func(value);
    };
  }
  function getValue(object, key) {
    return object == null ? void 0 : object[key];
  }
  function overArg(func, transform) {
    return function(arg) {
      return func(transform(arg));
    };
  }
  var arrayProto = Array.prototype;
  var funcProto = Function.prototype;
  var objectProto = Object.prototype;
  var coreJsData = root["__core-js_shared__"];
  var funcToString = funcProto.toString;
  var hasOwnProperty = objectProto.hasOwnProperty;
  var maskSrcKey = function() {
    var uid = /[^.]+$/.exec(coreJsData && coreJsData.keys && coreJsData.keys.IE_PROTO || "");
    return uid ? "Symbol(src)_1." + uid : "";
  }();
  var nativeObjectToString = objectProto.toString;
  var objectCtorString = funcToString.call(Object);
  var reIsNative = RegExp("^" + funcToString.call(hasOwnProperty).replace(reRegExpChar, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$");
  var Buffer2 = moduleExports ? root.Buffer : void 0;
  var Symbol = root.Symbol;
  var Uint8Array2 = root.Uint8Array;
  var allocUnsafe = Buffer2 ? Buffer2.allocUnsafe : void 0;
  var getPrototype = overArg(Object.getPrototypeOf, Object);
  var objectCreate = Object.create;
  var propertyIsEnumerable = objectProto.propertyIsEnumerable;
  var splice = arrayProto.splice;
  var symToStringTag = Symbol ? Symbol.toStringTag : void 0;
  var defineProperty = function() {
    try {
      var func = getNative(Object, "defineProperty");
      func({}, "", {});
      return func;
    } catch (e) {
    }
  }();
  var nativeIsBuffer = Buffer2 ? Buffer2.isBuffer : void 0;
  var nativeMax = Math.max;
  var nativeNow = Date.now;
  var Map = getNative(root, "Map");
  var nativeCreate = getNative(Object, "create");
  var baseCreate = function() {
    function object() {
    }
    return function(proto) {
      if (!isObject(proto)) {
        return {};
      }
      if (objectCreate) {
        return objectCreate(proto);
      }
      object.prototype = proto;
      var result = new object();
      object.prototype = void 0;
      return result;
    };
  }();
  function Hash(entries) {
    var index = -1, length = entries == null ? 0 : entries.length;
    this.clear();
    while (++index < length) {
      var entry = entries[index];
      this.set(entry[0], entry[1]);
    }
  }
  function hashClear() {
    this.__data__ = nativeCreate ? nativeCreate(null) : {};
    this.size = 0;
  }
  function hashDelete(key) {
    var result = this.has(key) && delete this.__data__[key];
    this.size -= result ? 1 : 0;
    return result;
  }
  function hashGet(key) {
    var data = this.__data__;
    if (nativeCreate) {
      var result = data[key];
      return result === HASH_UNDEFINED ? void 0 : result;
    }
    return hasOwnProperty.call(data, key) ? data[key] : void 0;
  }
  function hashHas(key) {
    var data = this.__data__;
    return nativeCreate ? data[key] !== void 0 : hasOwnProperty.call(data, key);
  }
  function hashSet(key, value) {
    var data = this.__data__;
    this.size += this.has(key) ? 0 : 1;
    data[key] = nativeCreate && value === void 0 ? HASH_UNDEFINED : value;
    return this;
  }
  Hash.prototype.clear = hashClear;
  Hash.prototype["delete"] = hashDelete;
  Hash.prototype.get = hashGet;
  Hash.prototype.has = hashHas;
  Hash.prototype.set = hashSet;
  function ListCache(entries) {
    var index = -1, length = entries == null ? 0 : entries.length;
    this.clear();
    while (++index < length) {
      var entry = entries[index];
      this.set(entry[0], entry[1]);
    }
  }
  function listCacheClear() {
    this.__data__ = [];
    this.size = 0;
  }
  function listCacheDelete(key) {
    var data = this.__data__, index = assocIndexOf(data, key);
    if (index < 0) {
      return false;
    }
    var lastIndex = data.length - 1;
    if (index == lastIndex) {
      data.pop();
    } else {
      splice.call(data, index, 1);
    }
    --this.size;
    return true;
  }
  function listCacheGet(key) {
    var data = this.__data__, index = assocIndexOf(data, key);
    return index < 0 ? void 0 : data[index][1];
  }
  function listCacheHas(key) {
    return assocIndexOf(this.__data__, key) > -1;
  }
  function listCacheSet(key, value) {
    var data = this.__data__, index = assocIndexOf(data, key);
    if (index < 0) {
      ++this.size;
      data.push([key, value]);
    } else {
      data[index][1] = value;
    }
    return this;
  }
  ListCache.prototype.clear = listCacheClear;
  ListCache.prototype["delete"] = listCacheDelete;
  ListCache.prototype.get = listCacheGet;
  ListCache.prototype.has = listCacheHas;
  ListCache.prototype.set = listCacheSet;
  function MapCache(entries) {
    var index = -1, length = entries == null ? 0 : entries.length;
    this.clear();
    while (++index < length) {
      var entry = entries[index];
      this.set(entry[0], entry[1]);
    }
  }
  function mapCacheClear() {
    this.size = 0;
    this.__data__ = {
      hash: new Hash(),
      map: new (Map || ListCache)(),
      string: new Hash()
    };
  }
  function mapCacheDelete(key) {
    var result = getMapData(this, key)["delete"](key);
    this.size -= result ? 1 : 0;
    return result;
  }
  function mapCacheGet(key) {
    return getMapData(this, key).get(key);
  }
  function mapCacheHas(key) {
    return getMapData(this, key).has(key);
  }
  function mapCacheSet(key, value) {
    var data = getMapData(this, key), size = data.size;
    data.set(key, value);
    this.size += data.size == size ? 0 : 1;
    return this;
  }
  MapCache.prototype.clear = mapCacheClear;
  MapCache.prototype["delete"] = mapCacheDelete;
  MapCache.prototype.get = mapCacheGet;
  MapCache.prototype.has = mapCacheHas;
  MapCache.prototype.set = mapCacheSet;
  function Stack2(entries) {
    var data = this.__data__ = new ListCache(entries);
    this.size = data.size;
  }
  function stackClear() {
    this.__data__ = new ListCache();
    this.size = 0;
  }
  function stackDelete(key) {
    var data = this.__data__, result = data["delete"](key);
    this.size = data.size;
    return result;
  }
  function stackGet(key) {
    return this.__data__.get(key);
  }
  function stackHas(key) {
    return this.__data__.has(key);
  }
  function stackSet(key, value) {
    var data = this.__data__;
    if (data instanceof ListCache) {
      var pairs = data.__data__;
      if (!Map || pairs.length < LARGE_ARRAY_SIZE - 1) {
        pairs.push([key, value]);
        this.size = ++data.size;
        return this;
      }
      data = this.__data__ = new MapCache(pairs);
    }
    data.set(key, value);
    this.size = data.size;
    return this;
  }
  Stack2.prototype.clear = stackClear;
  Stack2.prototype["delete"] = stackDelete;
  Stack2.prototype.get = stackGet;
  Stack2.prototype.has = stackHas;
  Stack2.prototype.set = stackSet;
  function arrayLikeKeys(value, inherited) {
    var isArr = isArray(value), isArg = !isArr && isArguments(value), isBuff = !isArr && !isArg && isBuffer(value), isType = !isArr && !isArg && !isBuff && isTypedArray(value), skipIndexes = isArr || isArg || isBuff || isType, result = skipIndexes ? baseTimes(value.length, String) : [], length = result.length;
    for (var key in value) {
      if ((inherited || hasOwnProperty.call(value, key)) && !(skipIndexes && (key == "length" || isBuff && (key == "offset" || key == "parent") || isType && (key == "buffer" || key == "byteLength" || key == "byteOffset") || isIndex(key, length)))) {
        result.push(key);
      }
    }
    return result;
  }
  function assignMergeValue(object, key, value) {
    if (value !== void 0 && !eq(object[key], value) || value === void 0 && !(key in object)) {
      baseAssignValue(object, key, value);
    }
  }
  function assignValue(object, key, value) {
    var objValue = object[key];
    if (!(hasOwnProperty.call(object, key) && eq(objValue, value)) || value === void 0 && !(key in object)) {
      baseAssignValue(object, key, value);
    }
  }
  function assocIndexOf(array, key) {
    var length = array.length;
    while (length--) {
      if (eq(array[length][0], key)) {
        return length;
      }
    }
    return -1;
  }
  function baseAssignValue(object, key, value) {
    if (key == "__proto__" && defineProperty) {
      defineProperty(object, key, {
        configurable: true,
        enumerable: true,
        value,
        writable: true
      });
    } else {
      object[key] = value;
    }
  }
  var baseFor = createBaseFor();
  function baseGetTag(value) {
    if (value == null) {
      return value === void 0 ? undefinedTag : nullTag;
    }
    return symToStringTag && symToStringTag in Object(value) ? getRawTag(value) : objectToString(value);
  }
  function baseIsArguments(value) {
    return isObjectLike(value) && baseGetTag(value) == argsTag;
  }
  function baseIsNative(value) {
    if (!isObject(value) || isMasked(value)) {
      return false;
    }
    var pattern = isFunction(value) ? reIsNative : reIsHostCtor;
    return pattern.test(toSource(value));
  }
  function baseIsTypedArray(value) {
    return isObjectLike(value) && isLength(value.length) && !!typedArrayTags[baseGetTag(value)];
  }
  function baseKeysIn(object) {
    if (!isObject(object)) {
      return nativeKeysIn(object);
    }
    var isProto = isPrototype(object), result = [];
    for (var key in object) {
      if (!(key == "constructor" && (isProto || !hasOwnProperty.call(object, key)))) {
        result.push(key);
      }
    }
    return result;
  }
  function baseMerge(object, source, srcIndex, customizer, stack) {
    if (object === source) {
      return;
    }
    baseFor(source, function(srcValue, key) {
      stack || (stack = new Stack2());
      if (isObject(srcValue)) {
        baseMergeDeep(object, source, key, srcIndex, baseMerge, customizer, stack);
      } else {
        var newValue = customizer ? customizer(safeGet(object, key), srcValue, key + "", object, source, stack) : void 0;
        if (newValue === void 0) {
          newValue = srcValue;
        }
        assignMergeValue(object, key, newValue);
      }
    }, keysIn);
  }
  function baseMergeDeep(object, source, key, srcIndex, mergeFunc, customizer, stack) {
    var objValue = safeGet(object, key), srcValue = safeGet(source, key), stacked = stack.get(srcValue);
    if (stacked) {
      assignMergeValue(object, key, stacked);
      return;
    }
    var newValue = customizer ? customizer(objValue, srcValue, key + "", object, source, stack) : void 0;
    var isCommon = newValue === void 0;
    if (isCommon) {
      var isArr = isArray(srcValue), isBuff = !isArr && isBuffer(srcValue), isTyped = !isArr && !isBuff && isTypedArray(srcValue);
      newValue = srcValue;
      if (isArr || isBuff || isTyped) {
        if (isArray(objValue)) {
          newValue = objValue;
        } else if (isArrayLikeObject(objValue)) {
          newValue = copyArray(objValue);
        } else if (isBuff) {
          isCommon = false;
          newValue = cloneBuffer(srcValue, true);
        } else if (isTyped) {
          isCommon = false;
          newValue = cloneTypedArray(srcValue, true);
        } else {
          newValue = [];
        }
      } else if (isPlainObject(srcValue) || isArguments(srcValue)) {
        newValue = objValue;
        if (isArguments(objValue)) {
          newValue = toPlainObject(objValue);
        } else if (!isObject(objValue) || isFunction(objValue)) {
          newValue = initCloneObject(srcValue);
        }
      } else {
        isCommon = false;
      }
    }
    if (isCommon) {
      stack.set(srcValue, newValue);
      mergeFunc(newValue, srcValue, srcIndex, customizer, stack);
      stack["delete"](srcValue);
    }
    assignMergeValue(object, key, newValue);
  }
  function baseRest(func, start) {
    return setToString(overRest(func, start, identity), func + "");
  }
  var baseSetToString = !defineProperty ? identity : function(func, string2) {
    return defineProperty(func, "toString", {
      configurable: true,
      enumerable: false,
      value: constant(string2),
      writable: true
    });
  };
  function cloneBuffer(buffer, isDeep) {
    if (isDeep) {
      return buffer.slice();
    }
    var length = buffer.length, result = allocUnsafe ? allocUnsafe(length) : new buffer.constructor(length);
    buffer.copy(result);
    return result;
  }
  function cloneArrayBuffer(arrayBuffer) {
    var result = new arrayBuffer.constructor(arrayBuffer.byteLength);
    new Uint8Array2(result).set(new Uint8Array2(arrayBuffer));
    return result;
  }
  function cloneTypedArray(typedArray, isDeep) {
    var buffer = isDeep ? cloneArrayBuffer(typedArray.buffer) : typedArray.buffer;
    return new typedArray.constructor(buffer, typedArray.byteOffset, typedArray.length);
  }
  function copyArray(source, array) {
    var index = -1, length = source.length;
    array || (array = Array(length));
    while (++index < length) {
      array[index] = source[index];
    }
    return array;
  }
  function copyObject(source, props, object, customizer) {
    var isNew = !object;
    object || (object = {});
    var index = -1, length = props.length;
    while (++index < length) {
      var key = props[index];
      var newValue = customizer ? customizer(object[key], source[key], key, object, source) : void 0;
      if (newValue === void 0) {
        newValue = source[key];
      }
      if (isNew) {
        baseAssignValue(object, key, newValue);
      } else {
        assignValue(object, key, newValue);
      }
    }
    return object;
  }
  function createAssigner(assigner) {
    return baseRest(function(object, sources) {
      var index = -1, length = sources.length, customizer = length > 1 ? sources[length - 1] : void 0, guard = length > 2 ? sources[2] : void 0;
      customizer = assigner.length > 3 && typeof customizer == "function" ? (length--, customizer) : void 0;
      if (guard && isIterateeCall(sources[0], sources[1], guard)) {
        customizer = length < 3 ? void 0 : customizer;
        length = 1;
      }
      object = Object(object);
      while (++index < length) {
        var source = sources[index];
        if (source) {
          assigner(object, source, index, customizer);
        }
      }
      return object;
    });
  }
  function createBaseFor(fromRight) {
    return function(object, iteratee, keysFunc) {
      var index = -1, iterable = Object(object), props = keysFunc(object), length = props.length;
      while (length--) {
        var key = props[fromRight ? length : ++index];
        if (iteratee(iterable[key], key, iterable) === false) {
          break;
        }
      }
      return object;
    };
  }
  function getMapData(map, key) {
    var data = map.__data__;
    return isKeyable(key) ? data[typeof key == "string" ? "string" : "hash"] : data.map;
  }
  function getNative(object, key) {
    var value = getValue(object, key);
    return baseIsNative(value) ? value : void 0;
  }
  function getRawTag(value) {
    var isOwn = hasOwnProperty.call(value, symToStringTag), tag = value[symToStringTag];
    try {
      value[symToStringTag] = void 0;
      var unmasked = true;
    } catch (e) {
    }
    var result = nativeObjectToString.call(value);
    if (unmasked) {
      if (isOwn) {
        value[symToStringTag] = tag;
      } else {
        delete value[symToStringTag];
      }
    }
    return result;
  }
  function initCloneObject(object) {
    return typeof object.constructor == "function" && !isPrototype(object) ? baseCreate(getPrototype(object)) : {};
  }
  function isIndex(value, length) {
    var type = typeof value;
    length = length == null ? MAX_SAFE_INTEGER : length;
    return !!length && (type == "number" || type != "symbol" && reIsUint.test(value)) && (value > -1 && value % 1 == 0 && value < length);
  }
  function isIterateeCall(value, index, object) {
    if (!isObject(object)) {
      return false;
    }
    var type = typeof index;
    if (type == "number" ? isArrayLike(object) && isIndex(index, object.length) : type == "string" && index in object) {
      return eq(object[index], value);
    }
    return false;
  }
  function isKeyable(value) {
    var type = typeof value;
    return type == "string" || type == "number" || type == "symbol" || type == "boolean" ? value !== "__proto__" : value === null;
  }
  function isMasked(func) {
    return !!maskSrcKey && maskSrcKey in func;
  }
  function isPrototype(value) {
    var Ctor = value && value.constructor, proto = typeof Ctor == "function" && Ctor.prototype || objectProto;
    return value === proto;
  }
  function nativeKeysIn(object) {
    var result = [];
    if (object != null) {
      for (var key in Object(object)) {
        result.push(key);
      }
    }
    return result;
  }
  function objectToString(value) {
    return nativeObjectToString.call(value);
  }
  function overRest(func, start, transform) {
    start = nativeMax(start === void 0 ? func.length - 1 : start, 0);
    return function() {
      var args = arguments, index = -1, length = nativeMax(args.length - start, 0), array = Array(length);
      while (++index < length) {
        array[index] = args[start + index];
      }
      index = -1;
      var otherArgs = Array(start + 1);
      while (++index < start) {
        otherArgs[index] = args[index];
      }
      otherArgs[start] = transform(array);
      return apply(func, this, otherArgs);
    };
  }
  function safeGet(object, key) {
    if (key === "constructor" && typeof object[key] === "function") {
      return;
    }
    if (key == "__proto__") {
      return;
    }
    return object[key];
  }
  var setToString = shortOut(baseSetToString);
  function shortOut(func) {
    var count = 0, lastCalled = 0;
    return function() {
      var stamp = nativeNow(), remaining = HOT_SPAN - (stamp - lastCalled);
      lastCalled = stamp;
      if (remaining > 0) {
        if (++count >= HOT_COUNT) {
          return arguments[0];
        }
      } else {
        count = 0;
      }
      return func.apply(void 0, arguments);
    };
  }
  function toSource(func) {
    if (func != null) {
      try {
        return funcToString.call(func);
      } catch (e) {
      }
      try {
        return func + "";
      } catch (e) {
      }
    }
    return "";
  }
  function eq(value, other) {
    return value === other || value !== value && other !== other;
  }
  var isArguments = baseIsArguments(function() {
    return arguments;
  }()) ? baseIsArguments : function(value) {
    return isObjectLike(value) && hasOwnProperty.call(value, "callee") && !propertyIsEnumerable.call(value, "callee");
  };
  var isArray = Array.isArray;
  function isArrayLike(value) {
    return value != null && isLength(value.length) && !isFunction(value);
  }
  function isArrayLikeObject(value) {
    return isObjectLike(value) && isArrayLike(value);
  }
  var isBuffer = nativeIsBuffer || stubFalse;
  function isFunction(value) {
    if (!isObject(value)) {
      return false;
    }
    var tag = baseGetTag(value);
    return tag == funcTag || tag == genTag || tag == asyncTag || tag == proxyTag;
  }
  function isLength(value) {
    return typeof value == "number" && value > -1 && value % 1 == 0 && value <= MAX_SAFE_INTEGER;
  }
  function isObject(value) {
    var type = typeof value;
    return value != null && (type == "object" || type == "function");
  }
  function isObjectLike(value) {
    return value != null && typeof value == "object";
  }
  function isPlainObject(value) {
    if (!isObjectLike(value) || baseGetTag(value) != objectTag) {
      return false;
    }
    var proto = getPrototype(value);
    if (proto === null) {
      return true;
    }
    var Ctor = hasOwnProperty.call(proto, "constructor") && proto.constructor;
    return typeof Ctor == "function" && Ctor instanceof Ctor && funcToString.call(Ctor) == objectCtorString;
  }
  var isTypedArray = nodeIsTypedArray ? baseUnary(nodeIsTypedArray) : baseIsTypedArray;
  function toPlainObject(value) {
    return copyObject(value, keysIn(value));
  }
  function keysIn(object) {
    return isArrayLike(object) ? arrayLikeKeys(object, true) : baseKeysIn(object);
  }
  var merge11 = createAssigner(function(object, source, srcIndex) {
    baseMerge(object, source, srcIndex);
  });
  function constant(value) {
    return function() {
      return value;
    };
  }
  function identity(value) {
    return value;
  }
  function stubFalse() {
    return false;
  }
  module.exports = merge11;
});

// src/index.tsx
import {
  Children,
  Fragment,
  createElement as createElement10,
  forwardRef as forwardRef10,
  isValidElement,
  useMemo,
  useState
} from "react";
import {Box as Box10} from "@twilio-paste/box";
import {Stack} from "@twilio-paste/stack";
import {Spinner} from "@twilio-paste/spinner";
import {secureExternalLink} from "@twilio-paste/anchor";
import {useSpring, animated} from "@twilio-paste/animation-library";
import {ArrowForwardIcon} from "@twilio-paste/icons/esm/ArrowForwardIcon";

// src/proptypes.ts
import {
  bool,
  oneOf,
  string
} from "prop-types";
var DirectButtonPropTypes = {
  as: string,
  fullWidth: bool,
  href: string,
  size: oneOf(["small", "default", "icon", "icon_small", "reset"]).isRequired,
  tabIndex: oneOf([0, -1]),
  type: oneOf(["submit", "button", "reset"]),
  disabled: bool,
  buttonState: oneOf(["disabled", "loading", "default"]).isRequired,
  variant: oneOf([
    "primary",
    "secondary",
    "destructive",
    "destructive_link",
    "destructive_secondary",
    "link",
    "inverse_link",
    "inverse",
    "reset"
  ])
};
var ButtonPropTypes = {
  as: string,
  fullWidth: bool,
  href: string,
  size: oneOf(["small", "default", "icon", "icon_small", "reset"]),
  tabIndex: oneOf([0, -1]),
  type: oneOf(["submit", "button", "reset"]),
  disabled: bool,
  loading: bool,
  variant: oneOf([
    "primary",
    "secondary",
    "destructive",
    "destructive_link",
    "destructive_secondary",
    "link",
    "inverse_link",
    "inverse",
    "reset"
  ]).isRequired
};

// src/PrimaryButton.tsx
import {
  createElement,
  forwardRef
} from "react";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";

// src/styles.ts
var merge = require_lodash();
var ResetStyles = {
  appearance: "none",
  background: "none",
  display: "inline-block",
  border: "none",
  outline: "none",
  transition: "background-color 100ms ease-in, box-shadow 100ms ease-in, color 100ms ease-in",
  fontFamily: "fontFamilyText",
  fontWeight: "fontWeightSemibold",
  textDecoration: "none",
  position: "relative",
  _hover: {textDecoration: "none"},
  _focus: {textDecoration: "none", boxShadow: "shadowFocus"},
  _active: {textDecoration: "none"}
};
var BaseStyles = {
  default: merge({}, ResetStyles, {
    cursor: "pointer",
    _active: {boxShadow: "none"}
  }),
  disabled: merge({}, ResetStyles, {
    cursor: "not-allowed"
  }),
  loading: merge({}, ResetStyles, {
    cursor: "wait"
  })
};
var SizeStyles = {
  default: {
    paddingTop: "space30",
    paddingBottom: "space30",
    paddingLeft: "space40",
    paddingRight: "space40",
    borderRadius: "borderRadius20",
    fontSize: "fontSize30",
    lineHeight: "lineHeight20"
  },
  small: {
    paddingTop: "space20",
    paddingBottom: "space20",
    paddingLeft: "space30",
    paddingRight: "space30",
    borderRadius: "borderRadius10",
    fontSize: "fontSize30",
    lineHeight: "lineHeight20"
  },
  icon: {
    padding: "space30",
    borderRadius: "borderRadius20"
  },
  icon_small: {
    padding: "space20",
    borderRadius: "borderRadius20"
  },
  reset: {
    paddingTop: "space0",
    paddingRight: "space0",
    paddingBottom: "space0",
    paddingLeft: "space0",
    borderWidth: "borderWidth0"
  }
};

// src/PrimaryButton.tsx
var merge2 = require_lodash();
var defaultStyles = merge2({}, BaseStyles.default, {
  color: "colorTextInverse",
  backgroundColor: "colorBackgroundPrimary",
  boxShadow: "shadowBorderPrimary",
  _hover: {
    color: "colorTextInverse",
    backgroundColor: "colorBackgroundPrimaryDarker",
    boxShadow: "shadowBorderPrimaryDarker"
  },
  _focus: {
    color: "colorTextInverse",
    backgroundColor: "colorBackgroundPrimaryDarker",
    boxShadow: "shadowFocus"
  },
  _active: {
    color: "colorTextInverse",
    backgroundColor: "colorBackgroundPrimaryDark",
    boxShadow: "shadowBorderPrimaryDarker"
  }
});
var loadingStyles = merge2({}, BaseStyles.loading, {
  color: "colorTextInverse",
  backgroundColor: "colorBackgroundPrimaryDarker",
  boxShadow: "shadowBorderPrimaryDarker"
});
var disabledStyles = merge2({}, BaseStyles.disabled, {
  color: "colorTextInverse",
  backgroundColor: "colorBackgroundPrimaryLight",
  boxShadow: "shadowBorderPrimaryLight"
});
var ButtonStyleMapping = {
  default: defaultStyles,
  loading: loadingStyles,
  disabled: disabledStyles
};
var PrimaryButton = forwardRef((_a, ref) => {
  var {size, buttonState, fullWidth} = _a, props = __rest(_a, ["size", "buttonState", "fullWidth"]);
  return /* @__PURE__ */ createElement(Box, __assign(__assign(__assign({
    ref,
    width: fullWidth ? "100%" : "auto"
  }, safelySpreadBoxProps(props)), ButtonStyleMapping[buttonState]), SizeStyles[size]));
});
PrimaryButton.defaultProps = {
  as: "button"
};
if (true) {
  PrimaryButton.propTypes = DirectButtonPropTypes;
}
PrimaryButton.displayName = "PrimaryButton";

// src/SecondaryButton.tsx
import {
  createElement as createElement2,
  forwardRef as forwardRef2
} from "react";
import {Box as Box2, safelySpreadBoxProps as safelySpreadBoxProps2} from "@twilio-paste/box";
var merge3 = require_lodash();
var defaultStyles2 = merge3({}, BaseStyles.default, {
  color: "colorTextLink",
  backgroundColor: "colorBackgroundBody",
  boxShadow: "shadowBorderPrimary",
  _hover: {
    color: "colorTextLinkDarker",
    backgroundColor: "colorBackgroundPrimaryLightest",
    boxShadow: "shadowBorderPrimaryDarker"
  },
  _focus: {
    color: "colorTextLinkDarker",
    backgroundColor: "colorBackgroundPrimaryLightest",
    boxShadow: "shadowFocus"
  },
  _active: {
    color: "colorTextLinkDarker",
    backgroundColor: "colorBackgroundPrimaryLighter",
    boxShadow: "shadowBorderPrimaryDarker"
  }
});
var baseLoadingStyles = {
  color: "colorTextLinkDarker",
  backgroundColor: "colorBackgroundPrimaryLighter",
  boxShadow: "shadowBorderPrimaryLighter"
};
var loadingStyles2 = merge3({}, BaseStyles.loading, __assign(__assign({}, baseLoadingStyles), {
  _hover: baseLoadingStyles,
  _active: baseLoadingStyles,
  _focus: baseLoadingStyles
}));
var baseDisabledStyles = {
  color: "colorTextLinkLight",
  backgroundColor: "colorBackgroundBody",
  boxShadow: "shadowBorderPrimaryLight"
};
var disabledStyles2 = merge3({}, BaseStyles.disabled, __assign(__assign({}, baseDisabledStyles), {
  _hover: baseDisabledStyles,
  _active: baseDisabledStyles,
  _focus: baseDisabledStyles
}));
var ButtonStyleMapping2 = {
  default: defaultStyles2,
  loading: loadingStyles2,
  disabled: disabledStyles2
};
var SecondaryButton = forwardRef2((_a, ref) => {
  var {size, buttonState, fullWidth} = _a, props = __rest(_a, ["size", "buttonState", "fullWidth"]);
  return /* @__PURE__ */ createElement2(Box2, __assign(__assign(__assign({
    ref,
    width: fullWidth ? "100%" : "auto"
  }, safelySpreadBoxProps2(props)), ButtonStyleMapping2[buttonState]), SizeStyles[size]));
});
SecondaryButton.defaultProps = {
  as: "button"
};
if (true) {
  SecondaryButton.propTypes = DirectButtonPropTypes;
}
SecondaryButton.displayName = "SecondaryButton";

// src/DestructiveButton.tsx
import {
  createElement as createElement3,
  forwardRef as forwardRef3
} from "react";
import {Box as Box3, safelySpreadBoxProps as safelySpreadBoxProps3} from "@twilio-paste/box";
var merge4 = require_lodash();
var defaultStyles3 = merge4({}, BaseStyles.default, {
  color: "colorTextInverse",
  backgroundColor: "colorBackgroundDestructive",
  boxShadow: "shadowBorderDestructive",
  _hover: {
    color: "colorTextInverse",
    backgroundColor: "colorBackgroundDestructiveDarker",
    boxShadow: "shadowBorderDestructiveDarker"
  },
  _focus: {
    color: "colorTextInverse",
    backgroundColor: "colorBackgroundDestructiveDarker",
    boxShadow: "shadowFocus"
  },
  _active: {
    color: "colorTextInverse",
    backgroundColor: "colorBackgroundDestructiveDark",
    boxShadow: "shadowBorderDestructiveDarker"
  }
});
var loadingStyles3 = merge4({}, BaseStyles.loading, {
  color: "colorTextInverse",
  backgroundColor: "colorBackgroundDestructiveDarker",
  boxShadow: "shadowBorderDestructiveDarker"
});
var disabledStyles3 = merge4({}, BaseStyles.disabled, {
  color: "colorTextInverse",
  backgroundColor: "colorBackgroundDestructiveLight",
  boxShadow: "shadowBorderDestructiveLight"
});
var ButtonStyleMapping3 = {
  default: defaultStyles3,
  loading: loadingStyles3,
  disabled: disabledStyles3
};
var DestructiveButton = forwardRef3((_a, ref) => {
  var {size, buttonState, fullWidth} = _a, props = __rest(_a, ["size", "buttonState", "fullWidth"]);
  return /* @__PURE__ */ createElement3(Box3, __assign(__assign(__assign({
    ref,
    width: fullWidth ? "100%" : "auto"
  }, safelySpreadBoxProps3(props)), ButtonStyleMapping3[buttonState]), SizeStyles[size]));
});
DestructiveButton.defaultProps = {
  as: "button"
};
if (true) {
  DestructiveButton.propTypes = DirectButtonPropTypes;
}
DestructiveButton.displayName = "DestructiveButton";

// src/DestructiveLinkButton.tsx
import {
  createElement as createElement4,
  forwardRef as forwardRef4
} from "react";
import {Box as Box4, safelySpreadBoxProps as safelySpreadBoxProps4} from "@twilio-paste/box";
var merge5 = require_lodash();
var defaultStyles4 = merge5({}, BaseStyles.default, {
  color: "colorTextLinkDestructive",
  transition: "none",
  _hover: {color: "colorTextLinkDestructiveDark", textDecoration: "underline"},
  _active: {color: "colorTextLinkDestructiveDarker", textDecoration: "underline"},
  _focus: {color: "colorTextLinkDestructiveDark", textDecoration: "underline"}
});
var loadingStyles4 = merge5({}, BaseStyles.loading, {
  color: "colorTextLinkDestructiveDarker",
  _hover: {color: "colorTextLinkDestructiveDarker"},
  _active: {color: "colorTextLinkDestructiveDarker"},
  _focus: {color: "colorTextLinkDestructiveDarker"}
});
var disabledStyles4 = merge5({}, BaseStyles.disabled, {
  color: "colorTextLinkDestructiveLight",
  _hover: {color: "colorTextLinkDestructiveLight"},
  _active: {color: "colorTextLinkDestructiveLight"},
  _focus: {color: "colorTextLinkDestructiveLight"}
});
var ButtonStyleMapping4 = {
  default: defaultStyles4,
  loading: loadingStyles4,
  disabled: disabledStyles4
};
var DestructiveLinkButton = forwardRef4((_a, ref) => {
  var {size, buttonState, fullWidth} = _a, props = __rest(_a, ["size", "buttonState", "fullWidth"]);
  return /* @__PURE__ */ createElement4(Box4, __assign(__assign(__assign({
    ref,
    width: fullWidth ? "100%" : "auto"
  }, safelySpreadBoxProps4(props)), ButtonStyleMapping4[buttonState]), SizeStyles[size]));
});
DestructiveLinkButton.defaultProps = {
  as: "a"
};
if (true) {
  DestructiveLinkButton.propTypes = DirectButtonPropTypes;
}
DestructiveLinkButton.displayName = "DestructiveLinkButton";

// src/DestructiveSecondaryButton.tsx
import {
  createElement as createElement5,
  forwardRef as forwardRef5
} from "react";
import {Box as Box5, safelySpreadBoxProps as safelySpreadBoxProps5} from "@twilio-paste/box";
var merge6 = require_lodash();
var defaultStyles5 = merge6({}, BaseStyles.default, {
  color: "colorTextLinkDestructive",
  backgroundColor: "colorBackgroundBody",
  boxShadow: "shadowBorderDestructive",
  _hover: {
    color: "colorTextLinkDestructiveDarker",
    backgroundColor: "colorBackgroundDestructiveLightest",
    boxShadow: "shadowBorderDestructiveDarker"
  },
  _focus: {
    color: "colorTextLinkDestructiveDarker",
    backgroundColor: "colorBackgroundDestructiveLightest",
    boxShadow: "shadowFocus"
  },
  _active: {
    color: "colorTextLinkDestructiveDarker",
    backgroundColor: "colorBackgroundDestructiveLighter",
    boxShadow: "shadowBorderDestructiveDarker"
  }
});
var baseLoadingStyles2 = {
  color: "colorTextLinkDestructiveDarker",
  backgroundColor: "colorBackgroundDestructiveLighter",
  boxShadow: "shadowBorderDestructiveLighter"
};
var loadingStyles5 = merge6({}, BaseStyles.loading, __assign(__assign({}, baseLoadingStyles2), {
  _hover: baseLoadingStyles2,
  _active: baseLoadingStyles2,
  _focus: baseLoadingStyles2
}));
var baseDisabledStyles2 = {
  color: "colorTextLinkDestructiveLight",
  backgroundColor: "colorBackgroundBody",
  boxShadow: "shadowBorderDestructiveLight"
};
var disabledStyles5 = merge6({}, BaseStyles.disabled, __assign(__assign({}, baseDisabledStyles2), {
  _hover: baseDisabledStyles2,
  _active: baseDisabledStyles2,
  _focus: baseDisabledStyles2
}));
var ButtonStyleMapping5 = {
  default: defaultStyles5,
  loading: loadingStyles5,
  disabled: disabledStyles5
};
var DestructiveSecondaryButton = forwardRef5((_a, ref) => {
  var {size, buttonState, fullWidth} = _a, props = __rest(_a, ["size", "buttonState", "fullWidth"]);
  return /* @__PURE__ */ createElement5(Box5, __assign(__assign(__assign({
    ref,
    width: fullWidth ? "100%" : "auto"
  }, safelySpreadBoxProps5(props)), ButtonStyleMapping5[buttonState]), SizeStyles[size]));
});
DestructiveSecondaryButton.defaultProps = {
  as: "button"
};
if (true) {
  DestructiveSecondaryButton.propTypes = DirectButtonPropTypes;
}
DestructiveSecondaryButton.displayName = "DestructiveSecondaryButton";

// src/LinkButton.tsx
import {
  createElement as createElement6,
  forwardRef as forwardRef6
} from "react";
import {Box as Box6, safelySpreadBoxProps as safelySpreadBoxProps6} from "@twilio-paste/box";
var merge7 = require_lodash();
var defaultStyles6 = merge7({}, BaseStyles.default, {
  color: "colorTextLink",
  transition: "none",
  _hover: {color: "colorTextLinkDark", textDecoration: "underline"},
  _focus: {color: "colorTextLinkDark", textDecoration: "underline"},
  _active: {color: "colorTextLinkDarker", textDecoration: "underline"}
});
var loadingStyles6 = merge7({}, BaseStyles.loading, {
  color: "colorTextLinkDarker"
});
var disabledStyles6 = merge7({}, BaseStyles.disabled, {
  color: "colorTextLinkLight"
});
var ButtonStyleMapping6 = {
  default: defaultStyles6,
  loading: loadingStyles6,
  disabled: disabledStyles6
};
var LinkButton = forwardRef6((_a, ref) => {
  var {size, buttonState, fullWidth} = _a, props = __rest(_a, ["size", "buttonState", "fullWidth"]);
  return /* @__PURE__ */ createElement6(Box6, __assign(__assign(__assign({
    ref,
    width: fullWidth ? "100%" : "auto"
  }, safelySpreadBoxProps6(props)), ButtonStyleMapping6[buttonState]), SizeStyles[size]));
});
LinkButton.defaultProps = {
  as: "a"
};
if (true) {
  LinkButton.propTypes = DirectButtonPropTypes;
}
LinkButton.displayName = "LinkButton";

// src/InverseButton.tsx
import {
  createElement as createElement7,
  forwardRef as forwardRef7
} from "react";
import {Box as Box7, safelySpreadBoxProps as safelySpreadBoxProps7} from "@twilio-paste/box";
var merge8 = require_lodash();
var defaultStyles7 = merge8({}, BaseStyles.default, {
  color: "colorTextInverse",
  fontWeight: "fontWeightSemibold",
  backgroundColor: "colorBackgroundInverse",
  boxShadow: "shadowBorderInverse",
  _hover: {
    color: "colorTextInverse",
    fontWeight: "fontWeightSemibold",
    backgroundColor: "colorBackgroundInverseLight",
    boxShadow: "shadowBorderInverseLighter"
  },
  _focus: {
    color: "colorTextInverse",
    fontWeight: "fontWeightSemibold",
    backgroundColor: "colorBackgroundInverseLight",
    boxShadow: "shadowFocusInverse"
  },
  _active: {
    color: "colorTextInverse",
    fontWeight: "fontWeightSemibold",
    backgroundColor: "colorBackgroundInverseLight",
    boxShadow: "shadowBorderInverseLightest"
  }
});
var loadingStyles7 = merge8({}, BaseStyles.loading, {
  color: "colorTextInverse",
  fontWeight: "fontWeightSemibold",
  backgroundColor: "colorBackgroundInverseLight",
  boxShadow: "shadowBorderInverseDarker"
});
var disabledStyles7 = merge8({}, BaseStyles.disabled, {
  color: "colorTextInverseWeaker",
  fontWeight: "fontWeightSemibold",
  backgroundColor: "colorBackgroundInverse",
  boxShadow: "shadowBorderInverseDarker"
});
var ButtonStyleMapping7 = {
  default: defaultStyles7,
  loading: loadingStyles7,
  disabled: disabledStyles7
};
var InverseButton = forwardRef7((_a, ref) => {
  var {size, buttonState, fullWidth} = _a, props = __rest(_a, ["size", "buttonState", "fullWidth"]);
  return /* @__PURE__ */ createElement7(Box7, __assign(__assign(__assign({
    ref,
    width: fullWidth ? "100%" : "auto"
  }, safelySpreadBoxProps7(props)), ButtonStyleMapping7[buttonState]), SizeStyles[size]));
});
InverseButton.defaultProps = {
  as: "button"
};
if (true) {
  InverseButton.propTypes = DirectButtonPropTypes;
}
InverseButton.displayName = "InverseButton";

// src/InverseLinkButton.tsx
import {
  createElement as createElement8,
  forwardRef as forwardRef8
} from "react";
import {Box as Box8, safelySpreadBoxProps as safelySpreadBoxProps8} from "@twilio-paste/box";
var merge9 = require_lodash();
var defaultStyles8 = merge9({}, BaseStyles.default, {
  color: "colorTextInverse",
  transition: "none",
  _hover: {color: "colorTextInverseWeaker", textDecoration: "underline"},
  _focus: {color: "colorTextInverseWeaker", boxShadow: "shadowFocusInverse", textDecoration: "underline"},
  _active: {textDecoration: "underline"}
});
var loadingStyles8 = merge9({}, BaseStyles.loading, {
  color: "colorTextInverse"
});
var disabledStyles8 = merge9({}, BaseStyles.disabled, {
  color: "colorTextInverseWeaker"
});
var ButtonStyleMapping8 = {
  default: defaultStyles8,
  loading: loadingStyles8,
  disabled: disabledStyles8
};
var InverseLinkButton = forwardRef8((_a, ref) => {
  var {size, buttonState, fullWidth} = _a, props = __rest(_a, ["size", "buttonState", "fullWidth"]);
  return /* @__PURE__ */ createElement8(Box8, __assign(__assign(__assign({
    ref,
    width: fullWidth ? "100%" : "auto"
  }, safelySpreadBoxProps8(props)), ButtonStyleMapping8[buttonState]), SizeStyles[size]));
});
InverseLinkButton.defaultProps = {
  as: "a"
};
if (true) {
  InverseLinkButton.propTypes = DirectButtonPropTypes;
}
InverseLinkButton.displayName = "InverseLinkButton";

// src/ResetButton.tsx
import {
  createElement as createElement9,
  forwardRef as forwardRef9
} from "react";
import {Box as Box9, safelySpreadBoxProps as safelySpreadBoxProps9} from "@twilio-paste/box";
var merge10 = require_lodash();
var defaultStyles9 = merge10({}, BaseStyles.default, {
  fontSize: "inherit",
  fontWeight: "inherit",
  color: "inherit"
});
var loadingStyles9 = merge10({}, BaseStyles.loading, {fontSize: "inherit", fontWeight: "inherit"});
var disabledStyles9 = merge10({}, BaseStyles.disabled, {fontSize: "inherit", fontWeight: "inherit"});
var ButtonStyleMapping9 = {
  default: defaultStyles9,
  loading: loadingStyles9,
  disabled: disabledStyles9
};
var ResetButton = forwardRef9((_a, ref) => {
  var {size, buttonState, fullWidth} = _a, props = __rest(_a, ["size", "buttonState", "fullWidth"]);
  return /* @__PURE__ */ createElement9(Box9, __assign(__assign(__assign({
    ref,
    width: fullWidth ? "100%" : "auto"
  }, safelySpreadBoxProps9(props)), ButtonStyleMapping9[buttonState]), SizeStyles[size]));
});
ResetButton.defaultProps = {
  as: "button"
};
if (true) {
  ResetButton.propTypes = DirectButtonPropTypes;
}
ResetButton.displayName = "ResetButton";

// src/index.tsx
var AnimatedBox = animated(Box10);
var getButtonSize = (variant, children, size) => {
  let smartSize = "default";
  if (size != null) {
    smartSize = size;
  } else if (variant === "link" || variant === "destructive_link" || variant === "reset") {
    smartSize = "reset";
  } else if (Children.count(children) === 1) {
    Children.forEach(children, (child) => {
      if (isValidElement(child)) {
        if (typeof child.type.displayName === "string" && child.type.displayName.includes("Icon")) {
          smartSize = "icon";
        }
      }
    });
  }
  return smartSize;
};
var getButtonState = (disabled, loading) => {
  if (disabled) {
    return "disabled";
  }
  if (loading) {
    return "loading";
  }
  return "default";
};
var handlePropValidation = ({
  as,
  href,
  tabIndex,
  variant,
  size,
  fullWidth,
  children,
  disabled,
  loading
}) => {
  const hasHref = href != null && href !== "";
  const hasTabIndex = tabIndex != null;
  if (as !== "a" && hasHref) {
    throw new Error(`[Paste: Button] You cannot pass href into a button without the 'a' tag.  Use 'as="a"'.`);
  }
  if (as === "a") {
    if (!hasHref) {
      throw new Error(`[Paste: Button] Missing href prop for link button.`);
    }
    if (variant === "link" || variant === "inverse_link") {
      throw new Error(`[Paste: Button] Using Button component as an Anchor. Use the Paste Anchor component instead.`);
    }
    if (variant !== "primary" && variant !== "secondary" && variant !== "reset") {
      throw new Error(`[Paste: Button] <Button as="a"> only works with the following variants: primary or secondary.`);
    }
    if (disabled || loading) {
      throw new Error(`[Paste: Button] <Button as="a"> cannot be disabled or loading.`);
    }
  }
  if (variant === "reset" && size !== "reset") {
    throw new Error('[Paste: Button] The "RESET" variant can only be used with the "RESET" size.');
  }
  if ((size === "icon" || size === "icon_small") && fullWidth) {
    throw new Error("[Paste: Button] Icon buttons should not be fullWidth.");
  }
  if (children == null) {
    throw new Error(`[Paste: Button] Must have non-null children.`);
  }
  if (hasTabIndex && !(tabIndex === 0 || tabIndex === -1)) {
    throw new Error(`[Paste: Button] tabIndex must be 0 or -1.`);
  }
};
var ButtonContents = ({buttonState, children, showLoading, variant}) => {
  return /* @__PURE__ */ createElement10(Fragment, null, /* @__PURE__ */ createElement10(Box10, {
    as: "span",
    display: "flex",
    textDecoration: "inherit",
    opacity: buttonState === "loading" ? "0" : "1",
    "aria-hidden": buttonState === "loading" ? "true" : "false",
    justifyContent: variant === "reset" ? null : "center"
  }, Children.count(children) > 1 ? /* @__PURE__ */ createElement10(Stack, {
    as: "span",
    orientation: "horizontal",
    spacing: "space20"
  }, children) : children), showLoading ? /* @__PURE__ */ createElement10(Box10, {
    as: "span",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "lineHeight30"
  }, /* @__PURE__ */ createElement10(Spinner, {
    decorative: false,
    title: "Loading, please wait.",
    delay: 0
  })) : null);
};
var getButtonComponent = (variant) => {
  switch (variant) {
    case "secondary":
      return SecondaryButton;
    case "destructive":
      return DestructiveButton;
    case "destructive_secondary":
      return DestructiveSecondaryButton;
    case "link":
      return LinkButton;
    case "destructive_link":
      return DestructiveLinkButton;
    case "reset":
      return ResetButton;
    case "inverse":
      return InverseButton;
    case "inverse_link":
      return InverseLinkButton;
    case "primary":
    default:
      return PrimaryButton;
  }
};
var Button = forwardRef10((props, ref) => {
  const {size, variant, children, disabled, loading} = props, rest = __rest(props, ["size", "variant", "children", "disabled", "loading"]);
  const [hovered, setHovered] = useState(false);
  const arrowIconStyles = useSpring({
    translateX: hovered ? "4px" : "0px",
    config: {
      mass: 0.1,
      tension: 275,
      friction: 16
    }
  });
  const smartDefaultSize = useMemo(() => {
    return getButtonSize(variant, children, size);
  }, [size, variant, children]);
  handlePropValidation(__assign(__assign({}, props), {size: smartDefaultSize}));
  const buttonState = getButtonState(disabled, loading);
  const showLoading = buttonState === "loading";
  const showDisabled = buttonState !== "default";
  const ButtonComponent = getButtonComponent(variant);
  const injectIconChildren = props.as === "a" && props.href != null && typeof children === "string" && variant !== "reset" ? /* @__PURE__ */ createElement10(Fragment, null, children, /* @__PURE__ */ createElement10(AnimatedBox, {
    style: arrowIconStyles
  }, /* @__PURE__ */ createElement10(ArrowForwardIcon, {
    decorative: true
  }))) : children;
  return /* @__PURE__ */ createElement10(ButtonComponent, __assign(__assign(__assign({}, rest.href != null ? secureExternalLink(rest.href) : null), rest), {
    onMouseEnter: (event) => {
      if (typeof rest.onMouseEnter === "function") {
        rest.onMouseEnter(event);
      }
      setHovered(true);
    },
    onMouseLeave: (event) => {
      if (typeof rest.onMouseLeave === "function") {
        rest.onMouseLeave(event);
      }
      setHovered(false);
    },
    buttonState,
    disabled: showDisabled,
    size: smartDefaultSize,
    "aria-busy": buttonState === "loading" ? "true" : "false",
    className: void 0,
    style: void 0,
    ref
  }), /* @__PURE__ */ createElement10(ButtonContents, {
    buttonState,
    showLoading,
    variant
  }, injectIconChildren));
});
Button.defaultProps = {
  as: "button",
  fullWidth: false,
  disabled: false,
  loading: false,
  type: "button",
  variant: "primary"
};
if (true) {
  Button.propTypes = ButtonPropTypes;
}
Button.displayName = "Button";
export {
  Button
};
