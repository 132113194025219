var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/index.tsx
import {
  createElement,
  forwardRef
} from "react";
import {
  func,
  node,
  oneOf,
  string
} from "prop-types";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
import {MediaObject, MediaFigure, MediaBody} from "@twilio-paste/media-object";
import {Button} from "@twilio-paste/button";
import {CloseIcon} from "@twilio-paste/icons/esm/CloseIcon";
import {ErrorIcon} from "@twilio-paste/icons/esm/ErrorIcon";
import {NeutralIcon} from "@twilio-paste/icons/esm/NeutralIcon";
import {WarningIcon} from "@twilio-paste/icons/esm/WarningIcon";
var AlertRoles = {
  ERROR: "alert",
  NEUTRAL: "status",
  WARNING: "alert"
};
var AlertVariants = {
  ERROR: "error",
  NEUTRAL: "neutral",
  WARNING: "warning"
};
var AlertBackgroundColors = {
  ERROR: "colorBackgroundErrorLightest",
  NEUTRAL: "colorBackgroundPrimaryLightest",
  WARNING: "colorBackgroundWarningLightest"
};
var AlertBorderColors = {
  ERROR: "colorBorderErrorLight",
  NEUTRAL: "colorBorderNeutral",
  WARNING: "colorBorderWarningLight"
};
var renderAlertIcon = (variant) => {
  switch (variant) {
    case AlertVariants.ERROR:
      return /* @__PURE__ */ createElement(ErrorIcon, {
        color: "colorTextError",
        decorative: false,
        title: "error: ",
        size: "sizeIcon20"
      });
    case AlertVariants.WARNING:
      return /* @__PURE__ */ createElement(WarningIcon, {
        color: "colorTextWarning",
        decorative: false,
        title: "warning: ",
        size: "sizeIcon20"
      });
    case AlertVariants.NEUTRAL:
    default:
      return /* @__PURE__ */ createElement(NeutralIcon, {
        color: "colorTextNeutral",
        decorative: false,
        title: "information: ",
        size: "sizeIcon20"
      });
  }
};
var Alert = forwardRef((_a, ref) => {
  var {children, onDismiss, variant, role} = _a, props = __rest(_a, ["children", "onDismiss", "variant", "role"]);
  return /* @__PURE__ */ createElement(Box, __assign(__assign({}, safelySpreadBoxProps(props)), {
    backgroundColor: AlertBackgroundColors[variant.toUpperCase()],
    borderColor: AlertBorderColors[variant.toUpperCase()],
    borderBottomWidth: "borderWidth20",
    borderBottomStyle: "solid",
    paddingLeft: "space60",
    paddingRight: "space60",
    paddingTop: "space50",
    paddingBottom: "space50",
    ref,
    role: role != null ? role : AlertRoles[variant.toUpperCase()]
  }), /* @__PURE__ */ createElement(MediaObject, {
    as: "div"
  }, /* @__PURE__ */ createElement(MediaFigure, {
    as: "div",
    spacing: "space30"
  }, renderAlertIcon(variant)), /* @__PURE__ */ createElement(MediaBody, {
    as: "div"
  }, children), onDismiss && typeof onDismiss === "function" && /* @__PURE__ */ createElement(MediaFigure, {
    align: "end",
    spacing: "space60"
  }, /* @__PURE__ */ createElement(Button, {
    onClick: onDismiss,
    variant: "link",
    size: "reset"
  }, /* @__PURE__ */ createElement(CloseIcon, {
    color: "colorTextIcon",
    decorative: false,
    title: "dismiss this alert",
    size: "sizeIcon20"
  })))));
});
Alert.displayName = "Alert";
if (true) {
  Alert.propTypes = {
    children: node.isRequired,
    onDismiss: func,
    role: string,
    variant: oneOf(Object.values(AlertVariants)).isRequired
  };
}
export {
  Alert,
  AlertBackgroundColors,
  AlertBorderColors,
  AlertRoles,
  AlertVariants
};
