var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/index.tsx
import {
  createElement,
  forwardRef
} from "react";
import {
  node
} from "prop-types";
import {Box} from "@twilio-paste/box";
var Truncate = forwardRef((_a, ref) => {
  var {children} = _a, props = __rest(_a, ["children"]);
  return /* @__PURE__ */ createElement(Box, __assign(__assign({}, props), {
    as: "span",
    display: "inline-block",
    maxWidth: "100%",
    overflow: "hidden",
    ref,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  }), children);
});
Truncate.displayName = "Truncate";
if (true) {
  Truncate.propTypes = {
    children: node.isRequired
  };
}
export {
  Truncate
};
