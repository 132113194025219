import * as React from "react";
import {useUID} from "@twilio-paste/uid-library";
import {IconWrapper} from "./helpers/IconWrapper";
const ProductFrontlineIcon = ({as, display, size, color, title, decorative}) => {
  const titleId = `ProductFrontlineIcon-${useUID()}`;
  if (!decorative && title == null) {
    throw new Error("[ProductFrontlineIcon]: Missing a title for non-decorative icon.");
  }
  return /* @__PURE__ */ React.createElement(IconWrapper, {
    as,
    display,
    size,
    color
  }, /* @__PURE__ */ React.createElement("svg", {
    role: "img",
    "aria-hidden": decorative,
    width: "100%",
    height: "100%",
    viewBox: "0 0 20 20",
    "aria-labelledby": titleId
  }, title ? /* @__PURE__ */ React.createElement("title", {
    id: titleId
  }, title) : null, /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    fillRule: "evenodd",
    d: "M8.892 2.067a.507.507 0 01.504 0 .5.5 0 01.251.433v8.763a.5.5 0 01-.251.433l-4.762 2.728a.502.502 0 00-.203.219l-1.472 3.072A.505.505 0 012 17.5V6.304a.5.5 0 01.252-.433zm8.132.225a.507.507 0 01.57.045.498.498 0 01.16.545l-1.567 4.682a.497.497 0 00.035.395l1.718 3.166a.498.498 0 01-.192.67l-6.337 3.63a.507.507 0 01-.504 0 .5.5 0 01-.252-.433V6.229a.5.5 0 01.252-.433z"
  })));
};
ProductFrontlineIcon.displayName = "ProductFrontlineIcon";
export {
  ProductFrontlineIcon
};
