var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/index.tsx
import {
  createElement,
  forwardRef
} from "react";
import {Text, safelySpreadTextProps} from "@twilio-paste/text";
var Paragraph = forwardRef((_a, ref) => {
  var {children, marginBottom} = _a, props = __rest(_a, ["children", "marginBottom"]);
  return /* @__PURE__ */ createElement(Text, __assign(__assign({}, safelySpreadTextProps(props)), {
    as: "p",
    marginBottom: marginBottom || "space70",
    fontSize: "fontSize30",
    lineHeight: "lineHeight40",
    fontWeight: "fontWeightNormal",
    color: "colorText",
    ref
  }), children);
});
Paragraph.displayName = "Paragraph";
export {
  Paragraph
};
