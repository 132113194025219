var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/index.tsx
import {
  Children,
  Fragment,
  cloneElement,
  createElement as createElement2,
  forwardRef
} from "react";
import {
  element,
  string
} from "prop-types";
import {useUID} from "@twilio-paste/uid-library";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
import {StyledBase} from "@twilio-paste/theme";
import {Text} from "@twilio-paste/text";
import {
  useTooltipPrimitiveState,
  TooltipPrimitive,
  TooltipPrimitiveReference
} from "@twilio-paste/tooltip-primitive";

// src/TooltipArrow.tsx
import {
  createElement
} from "react";
import {TooltipPrimitiveArrow} from "@twilio-paste/tooltip-primitive";
import {useTheme} from "@twilio-paste/theme";
var TooltipArrow = (props) => {
  const theme = useTheme();
  return /* @__PURE__ */ createElement(TooltipPrimitiveArrow, __assign(__assign({}, props), {
    size: theme.fontSizes.fontSize50,
    stroke: theme.borderColors.colorBorderDark,
    fill: theme.backgroundColors.colorBackgroundBodyInverse
  }));
};
TooltipArrow.displayName = "TooltipArrow";

// src/index.tsx
var StyledTooltip = forwardRef((_a, ref) => {
  var {style} = _a, props = __rest(_a, ["style"]);
  return /* @__PURE__ */ createElement2(Box, __assign(__assign({}, safelySpreadBoxProps(props)), {
    backgroundColor: "colorBackgroundBodyInverse",
    borderColor: "colorBorderDark",
    borderRadius: "borderRadius20",
    borderStyle: "solid",
    borderWidth: "borderWidth10",
    boxShadow: "shadow",
    maxWidth: "size30",
    padding: "space30",
    paddingBottom: "space20",
    paddingTop: "space20",
    zIndex: "zIndex90",
    _focus: {outline: "none"},
    style,
    ref
  }));
});
var Tooltip = forwardRef((_a, ref) => {
  var {baseId, children, state, text} = _a, props = __rest(_a, ["baseId", "children", "state", "text"]);
  const tooltip = state || useTooltipPrimitiveState(__assign({baseId: `paste-tooltip-${useUID()}`}, props));
  return /* @__PURE__ */ createElement2(Fragment, null, Children.only(/* @__PURE__ */ createElement2(TooltipPrimitiveReference, __assign(__assign({}, tooltip), {
    ref
  }), (referenceProps) => cloneElement(children, referenceProps))), /* @__PURE__ */ createElement2(TooltipPrimitive, __assign(__assign(__assign({}, tooltip), props), {
    as: StyledTooltip
  }), /* @__PURE__ */ createElement2(StyledBase, null, /* @__PURE__ */ createElement2(TooltipArrow, __assign({}, tooltip)), /* @__PURE__ */ createElement2(Text, {
    as: "span",
    color: "colorTextInverse"
  }, text))));
});
if (true) {
  Tooltip.propTypes = {
    children: element.isRequired,
    text: string.isRequired
  };
}
Tooltip.displayName = "Tooltip";
export {
  Tooltip,
  useTooltipPrimitiveState as useTooltipState
};
