var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __assign = Object.assign;
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/index.tsx
import {
  createElement,
  forwardRef,
  useMemo
} from "react";
import {
  bool,
  number,
  oneOf,
  oneOfType,
  string
} from "prop-types";
import {Box, safelySpreadBoxProps} from "@twilio-paste/box";
import {ResponsiveProp} from "@twilio-paste/style-props";

// src/helpers.tsx
var getGrow = ({grow}) => {
  if (Array.isArray(grow)) {
    return grow.map((value) => {
      return Number(value);
    });
  }
  if (typeof grow === "number") {
    return grow;
  }
  if (grow) {
    return 1;
  }
  return 0;
};
var getShrink = ({shrink, basis}) => {
  if (Array.isArray(shrink)) {
    return shrink.map((value) => {
      return Number(value);
    });
  }
  if (typeof shrink === "number") {
    return shrink;
  }
  if (typeof shrink === "boolean") {
    return shrink ? 1 : 0;
  }
  if (basis && basis !== "auto") {
    return 0;
  }
  return 1;
};
var getSuffix = (item) => {
  const suffix = typeof item === "number" || String(Number.parseInt(item, 10)) === item ? "px" : "";
  return item + suffix;
};
var getBasis = ({basis}) => {
  if (Array.isArray(basis)) {
    return basis.map((value) => {
      return getSuffix(value);
    });
  }
  if (basis) {
    return getSuffix(basis);
  }
  return "auto";
};
var getVertical = ({vertical}) => {
  if (Array.isArray(vertical)) {
    return vertical.map((value) => {
      if (typeof value === "boolean") {
        return value === true ? "column" : "row";
      }
      return value;
    });
  }
  if (vertical) {
    return "column";
  }
  return "row";
};
var getWrap = ({wrap}) => {
  if (Array.isArray(wrap)) {
    return wrap.map((value) => {
      if (typeof value === "boolean") {
        return value === true ? "wrap" : "nowrap";
      }
      return value;
    });
  }
  if (wrap) {
    return "wrap";
  }
  return "nowrap";
};
var RemapedVerticalAlignments = {
  top: "flex-start",
  center: "center",
  bottom: "flex-end",
  stretch: "stretch"
};
var vAlignToProps = ({
  vAlignContent
}) => {
  if (Array.isArray(vAlignContent)) {
    return vAlignContent.map((value) => RemapedVerticalAlignments[value]);
  }
  if (vAlignContent) {
    return RemapedVerticalAlignments[vAlignContent];
  }
  return "flex-start";
};
var RemapedHorizontalAlignments = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
  around: "space-around",
  between: "space-between"
};
var hAlignToProps = ({hAlignContent}) => {
  if (Array.isArray(hAlignContent)) {
    return hAlignContent.map((value) => RemapedHorizontalAlignments[value]);
  }
  if (hAlignContent) {
    return RemapedHorizontalAlignments[hAlignContent];
  }
  return "flex-start";
};

// src/index.tsx
var getFlexStyles = (props) => {
  const styles = {
    justifyContent: props.vertical ? vAlignToProps(props) : hAlignToProps(props),
    alignItems: props.vertical ? hAlignToProps(props) : vAlignToProps(props)
  };
  if (props.grow || props.shrink || props.basis) {
    styles.flexGrow = getGrow(props);
    styles.flexShrink = getShrink(props);
    styles.flexBasis = getBasis(props);
  }
  if (props.vertical) {
    styles.flexDirection = getVertical(props);
  }
  if (props.wrap) {
    styles.flexWrap = getWrap(props);
  }
  return styles;
};
var Flex = forwardRef((_a, ref) => {
  var {
    as,
    basis,
    children,
    display,
    hAlignContent,
    grow,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    maxWidth,
    minWidth = "size0",
    shrink,
    vertical,
    vAlignContent,
    width,
    wrap
  } = _a, props = __rest(_a, [
    "as",
    "basis",
    "children",
    "display",
    "hAlignContent",
    "grow",
    "marginTop",
    "marginRight",
    "marginBottom",
    "marginLeft",
    "paddingTop",
    "paddingRight",
    "paddingBottom",
    "paddingLeft",
    "maxWidth",
    "minWidth",
    "shrink",
    "vertical",
    "vAlignContent",
    "width",
    "wrap"
  ]);
  const FlexStyles = useMemo(() => getFlexStyles({basis, hAlignContent, grow, shrink, vertical, vAlignContent, wrap}), [basis, hAlignContent, grow, shrink, vertical, vAlignContent, wrap]);
  return /* @__PURE__ */ createElement(Box, __assign(__assign(__assign({}, FlexStyles), safelySpreadBoxProps(props)), {
    ref,
    as,
    display,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    maxWidth,
    minWidth,
    width
  }), children);
});
Flex.displayName = "Flex";
Flex.defaultProps = {
  display: "flex"
};
if (true) {
  Flex.propTypes = {
    as: string,
    display: ResponsiveProp(oneOf(["flex", "inline-flex"])),
    vertical: ResponsiveProp(bool),
    vAlignContent: ResponsiveProp(oneOf(["top", "center", "bottom", "stretch"])),
    hAlignContent: ResponsiveProp(oneOf(["left", "center", "right", "around", "between"])),
    grow: ResponsiveProp(oneOfType([bool, number])),
    shrink: ResponsiveProp(oneOfType([bool, number])),
    basis: ResponsiveProp(oneOfType([string, number])),
    wrap: ResponsiveProp(bool)
  };
}
export {
  Flex
};
