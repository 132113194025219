// src/index.tsx
import {
  createElement,
  forwardRef
} from "react";
import {
  node,
  oneOf
} from "prop-types";
import {Box} from "@twilio-paste/box";
import {isMarginTokenProp} from "@twilio-paste/style-props";
var MediaObject = forwardRef(({as = "span", children, marginBottom, marginTop, verticalAlign = "top"}, ref) => {
  return /* @__PURE__ */ createElement(Box, {
    as,
    display: "flex",
    alignItems: verticalAlign === "top" ? "flex-start" : "center",
    marginBottom,
    marginTop,
    ref
  }, children);
});
MediaObject.displayName = "MediaObject";
if (true) {
  MediaObject.propTypes = {
    children: node.isRequired,
    verticalAlign: oneOf(["center", "top"])
  };
}
var MediaFigure = forwardRef(({as = "span", children, align, spacing}, ref) => {
  return /* @__PURE__ */ createElement(Box, {
    as,
    display: "flex",
    flexShrink: 0,
    marginLeft: align === "end" ? spacing : void 0,
    marginRight: align === "start" ? spacing : void 0,
    ref
  }, children);
});
MediaFigure.displayName = "MediaFigure";
MediaFigure.defaultProps = {
  align: "start",
  spacing: "space0"
};
if (true) {
  MediaFigure.propTypes = {
    align: oneOf(["start", "end"]),
    children: node.isRequired,
    spacing: isMarginTokenProp
  };
}
var MediaBody = forwardRef(({as = "span", children}, ref) => {
  return /* @__PURE__ */ createElement(Box, {
    as,
    flex: 1,
    minWidth: "size0",
    ref
  }, children);
});
MediaBody.displayName = "MediaBody";
if (true) {
  MediaBody.propTypes = {
    children: node.isRequired
  };
}
export {
  MediaBody,
  MediaFigure,
  MediaObject
};
