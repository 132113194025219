import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/ChangelogLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Change Log`}</h1>
    <h2>{`2.0.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0eded1fd63f081ba9aeab5b5946218e1c5b9b316"
        }}><inlineCode parentName="a">{`0eded1fd`}</inlineCode></a>{` `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/pull/1319"
        }}>{`#1319`}</a>{` Thanks `}<a parentName="li" {...{
          "href": "https://github.com/SiTaggart"
        }}>{`@SiTaggart`}</a>{`! - Change internal dependencies to have minor range matching on version numbers`}</li>
    </ul>
    <h2>{`2.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/514bd5aa9fed6581bbc4c1de649457bcc8e87b3c"
        }}><inlineCode parentName="a">{`514bd5aa`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.1"
            }}>{`theme@5.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:text@4.0.1"
            }}>{`text@4.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.1"
            }}>{`style-props@3.0.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`2.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4c9ed5cac36ada218824d3e24bf45d4a03a12272"
        }}><inlineCode parentName="a">{`4c9ed5ca`}</inlineCode></a>{`, `}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/26c828d8681e0e671f28b5f2856cd1803f13953f"
        }}><inlineCode parentName="a">{`26c828d8`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.6.0"
            }}>{`design-tokens@6.6.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@5.0.0"
            }}>{`theme@5.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:text@4.0.0"
            }}>{`text@4.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@3.0.0"
            }}>{`style-props@3.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`1.0.1`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/944c340790b932f4714b0e6075c5641ecdbee9d6"
        }}><inlineCode parentName="a">{`944c3407`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:design-tokens@6.5.2"
            }}>{`design-tokens@6.5.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:text@3.0.1"
            }}>{`text@3.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.1"
            }}>{`style-props@2.0.1`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.1"
            }}>{`theme@4.3.1`}</a></li>
        </ul></li>
    </ul>
    <h2>{`1.0.0`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">{`Updated dependencies [`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/f1675586933bcce71a6b5c5fec7d939735763a73"
        }}><inlineCode parentName="a">{`f1675586`}</inlineCode></a>{`]:`}<ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.3.0"
            }}>{`theme@4.3.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:text@3.0.0"
            }}>{`text@3.0.0`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@2.0.0"
            }}>{`style-props@2.0.0`}</a></li>
        </ul></li>
    </ul>
    <h2>{`0.3.2`}</h2>
    <h3>{`Patch Changes`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{` `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/pull/1158"
          }}>{`#1158`}</a>{` Thanks `}<a parentName="p" {...{
            "href": "https://github.com/richbachman"
          }}>{`@richbachman`}</a>{`! - Pinned all twilio-paste package versions in order to keep them in sync with core when they are updated by changesets.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Updated dependencies [`}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`, `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/paste/commit/a12acb61739c7c2f2984dfc71fe53b5b3812675f"
          }}><inlineCode parentName="a">{`a12acb61`}</inlineCode></a>{`]:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:theme@4.2.2"
            }}>{`theme@4.2.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:style-props@1.9.2"
            }}>{`style-props@1.9.2`}</a></li>
          <li parentName="ul">{`@twilio-paste/`}<a parentName="li" {...{
              "href": "mailto:text@2.5.2"
            }}>{`text@2.5.2`}</a></li>
        </ul>
      </li>
    </ul>
    <p>{`All notable changes to this project will be documented in this file.
See `}<a parentName="p" {...{
        "href": "https://conventionalcommits.org"
      }}>{`Conventional Commits`}</a>{` for commit guidelines.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.3.0...@twilio-paste/list@0.3.1"
      }}>{`0.3.1`}</a>{` (2021-01-25)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`list:`}</strong>{` missing type export (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/297039457033f7c4c4e5bf663880aa68f5a232f4"
        }}>{`2970394`}</a>{`)`}</li>
    </ul>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.60...@twilio-paste/list@0.3.0"
      }}>{`0.3.0`}</a>{` (2021-01-21)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul">{`ESBuild for builds (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/1084"
        }}>{`#1084`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/053646011508be10477d5b732269cdb0419235d7"
        }}>{`0536460`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.59...@twilio-paste/list@0.2.60"
      }}>{`0.2.60`}</a>{` (2021-01-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.58...@twilio-paste/list@0.2.59"
      }}>{`0.2.59`}</a>{` (2021-01-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.57...@twilio-paste/list@0.2.58"
      }}>{`0.2.58`}</a>{` (2020-12-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.56...@twilio-paste/list@0.2.57"
      }}>{`0.2.57`}</a>{` (2020-12-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.55...@twilio-paste/list@0.2.56"
      }}>{`0.2.56`}</a>{` (2020-12-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.54...@twilio-paste/list@0.2.55"
      }}>{`0.2.55`}</a>{` (2020-12-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.53...@twilio-paste/list@0.2.54"
      }}>{`0.2.54`}</a>{` (2020-12-09)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.52...@twilio-paste/list@0.2.53"
      }}>{`0.2.53`}</a>{` (2020-12-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.51...@twilio-paste/list@0.2.52"
      }}>{`0.2.52`}</a>{` (2020-11-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.50...@twilio-paste/list@0.2.51"
      }}>{`0.2.51`}</a>{` (2020-11-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.49...@twilio-paste/list@0.2.50"
      }}>{`0.2.50`}</a>{` (2020-11-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.48...@twilio-paste/list@0.2.49"
      }}>{`0.2.49`}</a>{` (2020-10-27)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`list:`}</strong>{` forward refs to component exports (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/886"
        }}>{`#886`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/7f4def0207189978f11fd2107d8be81b5ecd22a7"
        }}>{`7f4def0`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.47...@twilio-paste/list@0.2.48"
      }}>{`0.2.48`}</a>{` (2020-10-23)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.46...@twilio-paste/list@0.2.47"
      }}>{`0.2.47`}</a>{` (2020-10-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.45...@twilio-paste/list@0.2.46"
      }}>{`0.2.46`}</a>{` (2020-10-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.44...@twilio-paste/list@0.2.45"
      }}>{`0.2.45`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.43...@twilio-paste/list@0.2.44"
      }}>{`0.2.44`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.42...@twilio-paste/list@0.2.43"
      }}>{`0.2.43`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.41...@twilio-paste/list@0.2.42"
      }}>{`0.2.42`}</a>{` (2020-10-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.40...@twilio-paste/list@0.2.41"
      }}>{`0.2.41`}</a>{` (2020-09-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.39...@twilio-paste/list@0.2.40"
      }}>{`0.2.40`}</a>{` (2020-09-21)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.38...@twilio-paste/list@0.2.39"
      }}>{`0.2.39`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.37...@twilio-paste/list@0.2.38"
      }}>{`0.2.38`}</a>{` (2020-09-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.36...@twilio-paste/list@0.2.37"
      }}>{`0.2.37`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.35...@twilio-paste/list@0.2.36"
      }}>{`0.2.36`}</a>{` (2020-09-08)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.34...@twilio-paste/list@0.2.35"
      }}>{`0.2.35`}</a>{` (2020-09-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.33...@twilio-paste/list@0.2.34"
      }}>{`0.2.34`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.32...@twilio-paste/list@0.2.33"
      }}>{`0.2.33`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.31...@twilio-paste/list@0.2.32"
      }}>{`0.2.32`}</a>{` (2020-08-31)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.30...@twilio-paste/list@0.2.31"
      }}>{`0.2.31`}</a>{` (2020-08-24)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`list:`}</strong>{` bump line-height due to default line-height change (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/4006207838b2cd1b18da6ecdc029d4ea8047ae79"
        }}>{`4006207`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.29...@twilio-paste/list@0.2.30"
      }}>{`0.2.30`}</a>{` (2020-08-19)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.28...@twilio-paste/list@0.2.29"
      }}>{`0.2.29`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.27...@twilio-paste/list@0.2.28"
      }}>{`0.2.28`}</a>{` (2020-08-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.26...@twilio-paste/list@0.2.27"
      }}>{`0.2.27`}</a>{` (2020-08-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.25...@twilio-paste/list@0.2.26"
      }}>{`0.2.26`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.24...@twilio-paste/list@0.2.25"
      }}>{`0.2.25`}</a>{` (2020-08-04)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.23...@twilio-paste/list@0.2.24"
      }}>{`0.2.24`}</a>{` (2020-07-30)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.22...@twilio-paste/list@0.2.23"
      }}>{`0.2.23`}</a>{` (2020-07-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.21...@twilio-paste/list@0.2.22"
      }}>{`0.2.22`}</a>{` (2020-07-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.20...@twilio-paste/list@0.2.21"
      }}>{`0.2.21`}</a>{` (2020-07-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.19...@twilio-paste/list@0.2.20"
      }}>{`0.2.20`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.18...@twilio-paste/list@0.2.19"
      }}>{`0.2.19`}</a>{` (2020-07-14)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.17...@twilio-paste/list@0.2.18"
      }}>{`0.2.18`}</a>{` (2020-07-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.16...@twilio-paste/list@0.2.17"
      }}>{`0.2.17`}</a>{` (2020-06-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.15...@twilio-paste/list@0.2.16"
      }}>{`0.2.16`}</a>{` (2020-06-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.14...@twilio-paste/list@0.2.15"
      }}>{`0.2.15`}</a>{` (2020-06-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.13...@twilio-paste/list@0.2.14"
      }}>{`0.2.14`}</a>{` (2020-06-18)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.12...@twilio-paste/list@0.2.13"
      }}>{`0.2.13`}</a>{` (2020-06-16)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.11...@twilio-paste/list@0.2.12"
      }}>{`0.2.12`}</a>{` (2020-06-12)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.10...@twilio-paste/list@0.2.11"
      }}>{`0.2.11`}</a>{` (2020-06-10)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.9...@twilio-paste/list@0.2.10"
      }}>{`0.2.10`}</a>{` (2020-06-05)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.8...@twilio-paste/list@0.2.9"
      }}>{`0.2.9`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.7...@twilio-paste/list@0.2.8"
      }}>{`0.2.8`}</a>{` (2020-06-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.6...@twilio-paste/list@0.2.7"
      }}>{`0.2.7`}</a>{` (2020-05-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.5...@twilio-paste/list@0.2.6"
      }}>{`0.2.6`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.4...@twilio-paste/list@0.2.5"
      }}>{`0.2.5`}</a>{` (2020-05-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.3...@twilio-paste/list@0.2.4"
      }}>{`0.2.4`}</a>{` (2020-05-04)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`list:`}</strong>{` switch to new spacing prop validators (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/cf0824229ca9f2e1399c1084d51717ef019becb2"
        }}>{`cf08242`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.2...@twilio-paste/list@0.2.3"
      }}>{`0.2.3`}</a>{` (2020-05-01)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.1...@twilio-paste/list@0.2.2"
      }}>{`0.2.2`}</a>{` (2020-04-25)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.2.0...@twilio-paste/list@0.2.1"
      }}>{`0.2.1`}</a>{` (2020-04-22)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.24...@twilio-paste/list@0.2.0"
      }}>{`0.2.0`}</a>{` (2020-04-17)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`components:`}</strong>{` promote text components, spinner and primitive to prod (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/5605adc99021be20dafd3fedfeb745490a50c7e7"
        }}>{`5605adc`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.23...@twilio-paste/list@0.1.24"
      }}>{`0.1.24`}</a>{` (2020-04-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.22...@twilio-paste/list@0.1.23"
      }}>{`0.1.23`}</a>{` (2020-04-08)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`list:`}</strong>{` package dependencies updated to be correct (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/12eedeecbced943d97b56b50f72266f1442f2acb"
        }}>{`12eedee`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.21...@twilio-paste/list@0.1.22"
      }}>{`0.1.22`}</a>{` (2020-04-07)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.20...@twilio-paste/list@0.1.21"
      }}>{`0.1.21`}</a>{` (2020-04-07)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`list:`}</strong>{` use color prop, update tests and snapshots (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/48bbeaf42cb81921b67f2747596c587731779e83"
        }}>{`48bbeaf`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.19...@twilio-paste/list@0.1.20"
      }}>{`0.1.20`}</a>{` (2020-04-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.18...@twilio-paste/list@0.1.19"
      }}>{`0.1.19`}</a>{` (2020-03-24)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`list:`}</strong>{` switch from theme-tokens to theme package dep (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/1989a60c229efb4d4826b47235a088083d181697"
        }}>{`1989a60`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.17...@twilio-paste/list@0.1.18"
      }}>{`0.1.18`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.16...@twilio-paste/list@0.1.17"
      }}>{`0.1.17`}</a>{` (2020-03-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.15...@twilio-paste/list@0.1.16"
      }}>{`0.1.16`}</a>{` (2020-03-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.14...@twilio-paste/list@0.1.15"
      }}>{`0.1.15`}</a>{` (2020-03-06)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.13...@twilio-paste/list@0.1.14"
      }}>{`0.1.14`}</a>{` (2020-03-02)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.12...@twilio-paste/list@0.1.13"
      }}>{`0.1.13`}</a>{` (2020-02-28)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.11...@twilio-paste/list@0.1.12"
      }}>{`0.1.12`}</a>{` (2020-02-26)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.10...@twilio-paste/list@0.1.11"
      }}>{`0.1.11`}</a>{` (2020-02-26)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul">{`package dependencies and deprecation warnings (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/334"
        }}>{`#334`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/0e88338511e6835a79eb0a9cea8d5b3a1cdf0a88"
        }}>{`0e88338`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.9...@twilio-paste/list@0.1.10"
      }}>{`0.1.10`}</a>{` (2020-02-14)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`list:`}</strong>{` add missing peer deps (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/375c12968f895880199fe9454cc28676816e3ee3"
        }}>{`375c129`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.8...@twilio-paste/list@0.1.9"
      }}>{`0.1.9`}</a>{` (2020-02-13)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.7...@twilio-paste/list@0.1.8"
      }}>{`0.1.8`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.6...@twilio-paste/list@0.1.7"
      }}>{`0.1.7`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.5...@twilio-paste/list@0.1.6"
      }}>{`0.1.6`}</a>{` (2020-02-11)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.4...@twilio-paste/list@0.1.5"
      }}>{`0.1.5`}</a>{` (2020-02-10)`}</h2>
    <h3>{`Bug Fixes`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`list:`}</strong>{` allow marginTop and marginBottom props (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/issues/297"
        }}>{`#297`}</a>{`) (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/24ceb8d0b671aeb4df446a6bd5cf17695fa07dc9"
        }}>{`24ceb8d`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.3...@twilio-paste/list@0.1.4"
      }}>{`0.1.4`}</a>{` (2020-02-03)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.2...@twilio-paste/list@0.1.3"
      }}>{`0.1.3`}</a>{` (2020-01-24)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.1...@twilio-paste/list@0.1.2"
      }}>{`0.1.2`}</a>{` (2020-01-17)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.1.0...@twilio-paste/list@0.1.1"
      }}>{`0.1.1`}</a>{` (2019-12-20)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h1><a parentName="h1" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.0.2...@twilio-paste/list@0.1.0"
      }}>{`0.1.0`}</a>{` (2019-12-19)`}</h1>
    <h3>{`Features`}</h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`list:`}</strong>{` created the list component (`}<a parentName="li" {...{
          "href": "https://github.com/twilio-labs/paste/commit/bd6db2e7de6a72fda8d42455f12623ca51440e27"
        }}>{`bd6db2e`}</a>{`)`}</li>
    </ul>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/twilio-labs/paste/compare/@twilio-paste/list@0.0.1...@twilio-paste/list@0.0.2"
      }}>{`0.0.2`}</a>{` (2019-10-29)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>
    <h2>{`0.0.1 (2019-08-15)`}</h2>
    <p><strong parentName="p">{`Note:`}</strong>{` Version bump only for package @twilio-paste/list`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      